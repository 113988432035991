import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/constants/status';

import { apiGetUser } from 'src/invoicing/home/HomeApiRequest';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    getUserResponseStatus: Status.DEFAULT,
    username: '',
  },
  reducers: {
    resetGetUserResponseStatus(state) {
      state.getUserResponseStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetUser.pending, (state) => {
        state.username = '';
        state.getUserResponseStatus = Status.LOADING;
      })
      .addCase(apiGetUser.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.getUserResponseStatus = Status.SUCCESS;
        localStorage.setItem('userCompany', action.payload.company);
      })
      .addCase(apiGetUser.rejected, (state) => {
        state.getUserResponseStatus = Status.FAIL;
        state.username = '';
      });
  },
});

export const { resetGetUserResponseStatus } = homeSlice.actions;

export const selectGetUserResponseStatus = (state: any) => state.home.getUserResponseStatus;
export const selectUsername = (state: any) => state.home.username;

export default homeSlice.reducer;
