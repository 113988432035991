import { useDispatch } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { AdditionalServiceReceivedInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { apiDeleteAdditionalService, apiGetAdditionalService } from 'src/invoicing/services/additionalServices/AdditionalServicesApiRequest';

interface Props {
  additionalService: AdditionalServiceReceivedInterface;
  setAdditionalServiceAction: Function;
  setIsAdditionalSubViewOpen: Function;
}

export default function useAdditionalService(props: Props) {
  const { additionalService, setAdditionalServiceAction, setIsAdditionalSubViewOpen } = props;

  const dispatch = useDispatch();

  const deleteAdditionalService = () => {
    dispatch(apiDeleteAdditionalService(additionalService.id));
  };

  const editAdditionalService = () => {
    dispatch(apiGetAdditionalService(additionalService.id));
    setIsAdditionalSubViewOpen(true);
    setAdditionalServiceAction(ActionStatus.EDIT);
  };

  const viewAdditionalService = () => {
    dispatch(apiGetAdditionalService(additionalService.id));
    setIsAdditionalSubViewOpen(true);
    setAdditionalServiceAction(ActionStatus.VIEW);
  };

  return {
    deleteAdditionalService,
    editAdditionalService,
    viewAdditionalService,
  };
}
