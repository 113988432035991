import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ERROR_TOKEN_MODAL_MESSAGE } from 'src/constants/componentTexts/ModalText';

import Loader from 'src/uiComponents/loader/Loader';

import { disconnectUser } from 'src/invoicing/InvoicingLogic';
import { LOGIN, routesNameToPath } from 'src/invoicing/app/Routes';

export default function SessionExpiredModal() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      disconnectUser();
      navigate(routesNameToPath[LOGIN]);
    }, 3000);
  }, []);

  return (
    <div className="ErrorModalWrapperToken">
      <div className="ErrorModalWrapperContent">
        <p id="ErrorTokenExpiredModalMessageId" className="ErrorModalWrapperTokenContentMessage">
          {ERROR_TOKEN_MODAL_MESSAGE}
        </p>
        <Loader className="ErrorModalWrapperContentLoader" />
      </div>
    </div>
  );
}
