import React from 'react';

import {
  DATE_END_TEXT,
  DATE_SEPARATOR_SYMBOL,
  DATE_START_TEXT,
  periodQuickFiltersText,
  quickFilters,
} from 'src/constants/componentTexts/DateFilter';

import { CALENDAR_LOGO_PATH } from 'src/constants/picturesPath';
import { DATE_FILTER_FROM_DATE_KEY, DATE_FILTER_TO_DATE_KEY } from 'src/constants/componentTexts/InvoiceAppText';

import QuickFilters from 'src/invoicing/invoices/filters/QuickFilters';
import useDateFilter from 'src/invoicing/invoices/filters/periodFilter/useDateFilter';

interface Props {
  dateFilters: any;
  selectedRadioButtonId: string | null;
  setDateFilters: Function;
  setSelectedRadioButtonId: Function;
}

export default function DateFilter(props: Props) {
  const { selectedRadioButtonId, setSelectedRadioButtonId } = props;
  const {
    changeDateFilters,
    fromDate,
    onRadioButtonChange,
    setFromDate,
    setToDate,
    toDate,
  } = useDateFilter(props);

  return (
    <form className="InvoicesDateRangeForm">
      <div className="InvoicesDateRangeDatePickerContainer">
        <h2 className="InvoicesDateRangeDatePickerTitle">{`${DATE_START_TEXT} ${DATE_SEPARATOR_SYMBOL} ${DATE_END_TEXT}`}</h2>
        <div className="InvoicesDateRangeInputContainer">
          <h2 className="InvoicesDateRangeInputText">{DATE_START_TEXT}</h2>
          <input
            type="date"
            value={fromDate}
            onChange={(event) => changeDateFilters(event, DATE_FILTER_FROM_DATE_KEY, setFromDate)}
            className="InvoicesDateRangeInput"
          />
          <img
            className="InvoicesDateRangeCalendarIcon"
            src={CALENDAR_LOGO_PATH}
            alt="calendar icon"
          />
        </div>
        <div className="InvoicesDateRangeInputContainer">
          <h2 className="InvoicesDateRangeInputText">{DATE_END_TEXT}</h2>
          <input
            type="date"
            value={toDate}
            onChange={(event) => changeDateFilters(event, DATE_FILTER_TO_DATE_KEY, setToDate)}
            className="InvoicesDateRangeInput"
          />
          <img
            className="InvoicesDateRangeCalendarIcon"
            src={CALENDAR_LOGO_PATH}
            alt="calendar icon"
          />
        </div>
      </div>
      <hr className="InvoicesDateRangeLine" />
      <h2 className="InvoicesDateRangeTitle">{periodQuickFiltersText.groupTitle}</h2>
      <QuickFilters
        filters={quickFilters}
        onRadioButtonChange={onRadioButtonChange}
        selectedRadioButtonId={selectedRadioButtonId}
        setSelectedRadioButtonId={setSelectedRadioButtonId}
      />
    </form>
  );
}
