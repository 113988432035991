import React from 'react';
import { Location, NavLink, useLocation } from 'react-router-dom';
import { routesNameToPath } from 'src/invoicing/app/Routes';

interface Props {
  routeName: string;
}

export default function NavBarLink(props: Props) {
  const { routeName } = props;

  const location: Location = useLocation();
  const route: string = routesNameToPath[routeName];

  const isActiveRoute = () => (
    location.pathname === route ? 'ActiveLink' : null
  );

  return (
    <NavLink to={route} className="NoActiveLink">
      <p
        aria-hidden="true"
        className={`NavBarLink LinkNavBarFontSize ${isActiveRoute()}`}
      >
        {routeName}
      </p>
    </NavLink>
  );
}
