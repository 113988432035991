import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import { AdditionalServiceReceivedInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import {
  selectAdditionalService,
  selectGetAdditionalServiceStatus,
} from 'src/invoicing/services/additionalServices/AdditionalServicesSlice';

export default function useGettingAdditionalServiceSelected() {
  const additionalService: AdditionalServiceReceivedInterface = useSelector(selectAdditionalService);
  const getAdditionalServiceStatus: string = useSelector(selectGetAdditionalServiceStatus);

  useEffect(() => {}, [getAdditionalServiceStatus !== Status.LOADING]);

  return {
    additionalService,
    getAdditionalServiceStatus,
  };
}
