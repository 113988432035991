import React from 'react';

import { DELETE_LOGO, EDIT_LOGO, VIEW_LOGO } from 'src/constants/picturesPath';
import { SERVICES_DELETE_BUTTON_TEXT, SERVICES_EDIT_BUTTON_TEXT, SERVICES_VIEW_BUTTON_TEXT } from 'src/constants/componentTexts/ServiceText';

import { DelayRuleReceivedInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';
import {
  canAServiceBeUpdated,
  returnFixedIfPriceAmountsTypeServiceIsFixed,
  returnVariableIfPriceAmountsTypeServiceIsPercentage,
} from 'src/invoicing/services/ServicesLogic';
import useDelayRule from 'src/invoicing/services/delayRules/useDelayRule';

interface Props {
  contractAction: string;
  contractStatus: string;
  delayRule: DelayRuleReceivedInterface;
  setDelayRuleAction: Function;
  setIsDelaySubViewOpen: Function;
}

export default function DelayRule(props: Props) {
  const { contractAction, contractStatus, delayRule } = props;

  const {
    deleteDelayRule,
    editDelayRule,
    viewDelayRule,
  } = useDelayRule(props);

  return (
    <tr className="NewContractAppContentDelayRulesFormTableTbodyRow">
      <td
        id={`NewContractAppContentDelayRulesFormTableTbodyRowCauseOfDelayValue${delayRule.id}Id`}
        className="FormTextFontSize NewContractAppContentDelayRulesFormTableTbodyRowImportantValue"
      >
        {delayRule.cause_of_delay.label}
      </td>
      <td
        id={`NewContractAppContentDelayRulesFormTableTbodyRowTypeOfPriceValue${delayRule.id}Id`}
        className="FormTextFontSize NewContractAppContentDelayRulesFormTableTbodyRowValue"
      >
        {returnFixedIfPriceAmountsTypeServiceIsFixed(delayRule) || returnVariableIfPriceAmountsTypeServiceIsPercentage(delayRule)}
      </td>
      <td className="NewContractAppContentDelayRulesFormTableTbodyRowSpacer" />
      <td
        className="FormTextEditButtonStyle NewContractAppContentDelayRulesFormTableTbodyRowAction"
      >
        <img
          className="NewContractAppContentDelayRulesFormTableTbodyRowViewImg"
          src={VIEW_LOGO}
          alt={VIEW_LOGO}
          aria-hidden="true"
          onClick={viewDelayRule}
        />
        <p
          aria-hidden="true"
          onClick={viewDelayRule}
          id={`NewContractAppContentDelayRulesFormTableTbodyRowViewText${delayRule.id}Id`}
          className="NewContractAppContentDelayRulesFormTableTbodyRowEditText"
        >
          {SERVICES_VIEW_BUTTON_TEXT}
        </p>
      </td>
      <td
        className="FormTextEditButtonStyle NewContractAppContentDelayRulesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentDelayRulesFormTableTbodyRowEditImg"
              src={EDIT_LOGO}
              alt={EDIT_LOGO}
              aria-hidden="true"
              onClick={editDelayRule}
            />
            <p
              aria-hidden="true"
              onClick={editDelayRule}
              id={`NewContractAppContentDelayRulesFormTableTbodyRowEditText${delayRule.id}Id`}
              className="NewContractAppContentDelayRulesFormTableTbodyRowEditText"
            >
              {SERVICES_EDIT_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
      <td
        className="FormTextDeleteButtonStyle NewContractAppContentDelayRulesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentDelayRulesFormTableTbodyRowDeleteImg"
              src={DELETE_LOGO}
              alt={DELETE_LOGO}
              aria-hidden="true"
              onClick={deleteDelayRule}
            />
            <p
              aria-hidden="true"
              onClick={deleteDelayRule}
              id={`NewContractAppContentDelayRulesFormTableTbodyRowDeleteText${delayRule.id}Id`}
              className="NewContractAppContentDelayRulesFormTableTbodyRowDeleteText"
            >
              {SERVICES_DELETE_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
    </tr>
  );
}
