import React from 'react';

import { AMOUNT_FILTER_KEY } from 'src/constants/componentTexts/InvoiceAppText';
import { amountFilters, AMOUNT_INPUT_TEXT } from 'src/constants/componentTexts/AmountFilterText';

import DropDown from 'src/uiComponents/dropDown/DropDown';
import QuickFilters from 'src/invoicing/invoices/filters/QuickFilters';

import { InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';
import useAmountFilter from 'src/invoicing/invoices/filters/amountFilter/useAmountFilter';

import 'src/invoicing/invoices/filters/amountFilter/AmountFilter.css';

interface Props {
  dropDownValues: any
  invoicesFilters: InvoicesFiltersModel;
  setInvoicesFilters: Function;
}

export default function AmountFilter(props: Props) {
  const { dropDownValues } = props;

  const amountFilter = useAmountFilter(props);

  return (
    <DropDown title="Amount" dropDownValues={dropDownValues} dropDownValuesKey={AMOUNT_FILTER_KEY}>
      <form className="InvoicesAmountFilterForm" id="InvoicesAmountFilterId">
        <input
          type="number"
          id="AmountFilterInputId"
          placeholder={AMOUNT_INPUT_TEXT}
          className="AmountFilterInput"
          value={amountFilter.amountSelected}
          onChange={amountFilter.onSelectedAmountChange}
        />
        <hr className="InvoicesAmountLine" />
        <QuickFilters
          filters={amountFilters}
          onRadioButtonChange={amountFilter.onRadioButtonChange}
          selectedRadioButtonId={amountFilter.selectedRadioButtonId}
          setSelectedRadioButtonId={amountFilter.setSelectedRadioButtonId}
        />
      </form>
    </DropDown>
  );
}
