import { createSlice } from '@reduxjs/toolkit';

import { NEW_DELAY_RULE_ERROR_MESSAGE, UPDATE_DELAY_RULE_ERROR_MESSAGE } from 'src/constants/componentTexts/DelayRulesText';
import { Status } from 'src/constants/status';

import {
  apiDeleteDelayRule,
  apiGetDelayRule,
  apiGetContractDelayRuleParameters,
  apiGetContractDelayRules,
  apiPatchDelayRule,
  apiPostDelayRule,
} from 'src/invoicing/services/delayRules/DelayRulesApiRequest';

export const delayRulesSlice = createSlice({
  name: 'delayRules',
  initialState: {
    delayRuleParameters: {
      causes_of_delays: [],
      currency: {},
      value_types: [],
    },
    delayRules: [],
    delayRule: {},
    delayRuleErrorMessage: '',
    delayRuleParametersStatus: Status.DEFAULT,
    patchDelayRuleStatus: Status.DEFAULT,
    postDelayRuleStatus: Status.DEFAULT,
    getDelayRuleStatus: Status.DEFAULT,
    deleteDelayRuleStatus: Status.DEFAULT,
    getDelayRulesStatus: Status.DEFAULT,
  },
  reducers: {
    resetPatchDelayRuleStatus(state) {
      state.patchDelayRuleStatus = Status.DEFAULT;
    },
    resetPostDelayRuleStatus(state) {
      state.postDelayRuleStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetContractDelayRules.pending, (state) => {
        state.delayRules = [];
        state.getDelayRulesStatus = Status.LOADING;
      })
      .addCase(apiGetContractDelayRules.fulfilled, (state, action) => {
        state.delayRules = action.payload;
        state.getDelayRulesStatus = Status.SUCCESS;
      })
      .addCase(apiGetContractDelayRules.rejected, (state) => {
        state.delayRules = [];
        state.getDelayRulesStatus = Status.FAIL;
      })
      .addCase(apiGetContractDelayRuleParameters.pending, (state) => {
        state.delayRuleParametersStatus = Status.LOADING;
      })
      .addCase(apiGetContractDelayRuleParameters.fulfilled, (state, action) => {
        state.delayRuleParameters = action.payload;
        state.delayRuleParametersStatus = Status.SUCCESS;
      })
      .addCase(apiGetContractDelayRuleParameters.rejected, (state) => {
        state.delayRuleParametersStatus = Status.FAIL;
      })
      .addCase(apiGetDelayRule.pending, (state) => {
        state.getDelayRuleStatus = Status.LOADING;
      })
      .addCase(apiGetDelayRule.fulfilled, (state, action) => {
        state.getDelayRuleStatus = Status.SUCCESS;
        state.delayRule = action.payload;
      })
      .addCase(apiGetDelayRule.rejected, (state) => {
        state.getDelayRuleStatus = Status.FAIL;
      })
      .addCase(apiPostDelayRule.pending, (state) => {
        state.postDelayRuleStatus = Status.LOADING;
      })
      .addCase(apiPostDelayRule.fulfilled, (state) => {
        state.postDelayRuleStatus = Status.SUCCESS;
      })
      .addCase(apiPostDelayRule.rejected, (state) => {
        state.postDelayRuleStatus = Status.FAIL;
        state.delayRuleErrorMessage = NEW_DELAY_RULE_ERROR_MESSAGE;
      })
      .addCase(apiPatchDelayRule.pending, (state) => {
        state.patchDelayRuleStatus = Status.LOADING;
      })
      .addCase(apiPatchDelayRule.fulfilled, (state) => {
        state.patchDelayRuleStatus = Status.SUCCESS;
      })
      .addCase(apiPatchDelayRule.rejected, (state) => {
        state.patchDelayRuleStatus = Status.FAIL;
        state.delayRuleErrorMessage = UPDATE_DELAY_RULE_ERROR_MESSAGE;
      })
      .addCase(apiDeleteDelayRule.pending, (state) => {
        state.deleteDelayRuleStatus = Status.LOADING;
      })
      .addCase(apiDeleteDelayRule.fulfilled, (state) => {
        state.deleteDelayRuleStatus = Status.SUCCESS;
      })
      .addCase(apiDeleteDelayRule.rejected, (state) => {
        state.deleteDelayRuleStatus = Status.FAIL;
      });
  },
});

export const { resetPatchDelayRuleStatus, resetPostDelayRuleStatus } = delayRulesSlice.actions;

export const selectDelayRule = (state: any) => state.delayRules.delayRule;
export const selectDelayRuleErrorMessage = (state: any) => state.delayRules.delayRuleErrorMessage;
export const selectDelayRules = (state: any) => state.delayRules.delayRules;
export const selectDelayRuleParameters = (state: any) => state.delayRules.delayRuleParameters;
export const selectDelayRuleParametersStatus = (state: any) => state.delayRules.delayRuleParametersStatus;
export const selectDeleteDelayRuleStatus = (state: any) => state.delayRules.deleteDelayRuleStatus;
export const selectGetDelayRuleStatus = (state: any) => state.delayRules.getDelayRuleStatus;
export const selectGetDelayRulesStatus = (state: any) => state.delayRules.getDelayRulesStatus;
export const selectPatchDelayRuleStatus = (state: any) => state.delayRules.patchDelayRuleStatus;
export const selectPostDelayRuleStatus = (state: any) => state.delayRules.postDelayRuleStatus;

export default delayRulesSlice.reducer;
