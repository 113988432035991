import React, { useState } from 'react';

import { PERIOD_FILTER_KEY, PERIOD_QUICK_FILTER_DEFAULT_VALUE } from 'src/constants/componentTexts/InvoiceAppText';

import DateFilter from 'src/invoicing/invoices/filters/periodFilter/DateFilter';
import DropDown from 'src/uiComponents/dropDown/DropDown';

interface Props {
  dateFilters: any;
  dropDownValues: any;
  setDateFilters: Function;
}

export default function PeriodFilter(props: Props) {
  const { dateFilters, dropDownValues, setDateFilters } = props;

  const [selectedRadioButtonId, setSelectedRadioButtonId] = useState<null | string>(PERIOD_QUICK_FILTER_DEFAULT_VALUE);

  return (
    <DropDown title="Period" dropDownValues={dropDownValues} dropDownValuesKey={PERIOD_FILTER_KEY}>
      <DateFilter
        dateFilters={dateFilters}
        selectedRadioButtonId={selectedRadioButtonId}
        setDateFilters={setDateFilters}
        setSelectedRadioButtonId={setSelectedRadioButtonId}
      />
    </DropDown>
  );
}
