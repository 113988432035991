import { FormEvent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetContractParameters } from 'src/invoicing/contract/ContractApiRequest';
import {
  getUserContractCurrency,
  selectContractAction,
  selectContractParameters,
  selectContractStatus,
  selectGetContractParametersStatus,
} from 'src/invoicing/contract/ContractSlice';
import {
  ContractParameters,
  Contract,
  defaultContract,
  StoredContract,
} from 'src/invoicing/contract/ContractInterface';
import {
  createStoredContractWithContractInformations,
  getCompanyIdFromContract,
  getContractByStepFilled,
  getSelectedContractElementObjectByIdOrCode,
} from 'src/invoicing/contract/ContractLogic';

interface Props {
  contractByStepsFilled: boolean[];
  storedContract: StoredContract;
  nextComponent: string | null;
  setStoredContract: Function;
  setCurrentContractByStepType: Function;
  setcontractByStepsFilled: Function;
}

export default function useContractForm(props: Props) {
  const {
    contractByStepsFilled,
    nextComponent,
    setcontractByStepsFilled,
    setCurrentContractByStepType,
    setStoredContract,
    storedContract,
  } = props;

  const dispatch: Function = useDispatch();

  const contractAction: string = useSelector(selectContractAction);
  const getContractParameters: ContractParameters = useSelector(selectContractParameters);
  const getContractParametersStatus: string = useSelector(selectGetContractParametersStatus);
  const contractStatus: string = useSelector(selectContractStatus);

  const [contract, setContract] = useState<Contract>(storedContract.airport ? storedContract : defaultContract);

  const contractFilled = createStoredContractWithContractInformations(storedContract, getContractParameters);

  useEffect(() => { dispatch(apiGetContractParameters()); }, []);

  const submitContract = (event: FormEvent) => {
    event.preventDefault();

    dispatch(getUserContractCurrency(getSelectedContractElementObjectByIdOrCode(contract.currency, getContractParameters.currencies, 'code')));

    setStoredContract({ ...contract, company: getCompanyIdFromContract(getContractParameters) });
    setcontractByStepsFilled(getContractByStepFilled(contractByStepsFilled, contract));
    if (nextComponent) {
      setCurrentContractByStepType(nextComponent);
    }
  };

  return {
    contract,
    contractAction,
    contractFilled,
    contractStatus,
    getContractParameters,
    getContractParametersStatus,
    setContract,
    submitContract,
  };
}
