import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseApp from 'src/invoicing/app/BaseApp';
import Loader from 'src/uiComponents/loader/Loader';

import { apiGetUser } from 'src/invoicing/home/HomeApiRequest';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectGetUserResponseStatus, selectUsername } from 'src/invoicing/home/HomeSlice';

import 'src/invoicing/home/Home.css';

export default function Home() {
  const dispatch: Function = useDispatch();

  const getUsername: string = useSelector(selectUsername);
  const getUserResponseStatus = useSelector(selectGetUserResponseStatus);

  useEffect(() => { dispatch(apiGetUser()); }, []);

  return (
    <BaseApp>
      {shouldLoaderBeDisplayed([getUserResponseStatus]) ? (
        <Loader className="NewContractAppContentCancellationRulesLoader" />
      ) : (
        <div className="HomeContent">
          <p id="HomeContentUsernameId" className="HomeContentUsername">{`I'm logged with ${getUsername} account`}</p>
        </div>
      )}
    </BaseApp>
  );
}
