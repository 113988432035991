import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus, Status } from 'src/constants/status';
import { stepperText } from 'src/constants/componentTexts/StepperText';

import { apiGetOperationTypes } from 'src/invoicing/services/operationTypes/OperationTypesApiRequest';
import {
  areServicesLoaded,
  getServicescontractByStepsFilled,
  canAServiceBeAdded,
} from 'src/invoicing/services/ServicesLogic';
import { OperationTypeReceivedInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';
import {
  selectDeleteOperationTypeStatus,
  selectGetOperationTypesStatus,
  selectOperationTypes,
  selectPatchOperationTypeStatus,
  selectPostOperationTypesStatus,
} from 'src/invoicing/services/operationTypes/OperationTypesSlice';
import { selectContractAction, selectContractStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  contractByStepsFilled: boolean[];
  contractId: number | null;
  isOperationTypeSubViewOpen: boolean;
  setIsOperationTypeSubViewOpen: Function;
  setcontractByStepsFilled: Function;
  setOperationTypeAction: Function;
}

export default function useOperationTypesEditor(props: Props) {
  const {
    contractByStepsFilled,
    contractId,
    isOperationTypeSubViewOpen,
    setIsOperationTypeSubViewOpen,
    setcontractByStepsFilled,
    setOperationTypeAction,
  } = props;

  const dispatch = useDispatch();

  const apiOperationTypes: OperationTypeReceivedInterface[] | [] = useSelector(selectOperationTypes);
  const contractAction: string = useSelector(selectContractAction);
  const contractStatus: string = useSelector(selectContractStatus);
  const deleteOperationTypeStatus: string = useSelector(selectDeleteOperationTypeStatus);
  const getOperationTypesStatus: string = useSelector(selectGetOperationTypesStatus);
  const patchOperationTypeStatus: string = useSelector(selectPatchOperationTypeStatus);
  const postOperationTypeStatus: string = useSelector(selectPostOperationTypesStatus);

  const [operationTypes, setOperationTypes] = useState<OperationTypeReceivedInterface[] | []>([]);
  const [operationTypesStatus, setOperationTypesStatus] = useState(Status.DEFAULT);

  const getOperationTypesIfContractIdExistsAndOtherApiCallsNotProcessing = () => {
    if (contractId && areServicesLoaded(
      postOperationTypeStatus,
      deleteOperationTypeStatus,
      patchOperationTypeStatus,
    )) {
      setOperationTypesStatus(Status.DEFAULT);
      dispatch(apiGetOperationTypes(contractId));
    }
  };

  const filledOperationTypesStepperIfGettingOperationTypesIsSuccessfull = () => {
    if (getOperationTypesStatus === Status.SUCCESS) {
      setcontractByStepsFilled(getServicescontractByStepsFilled(
        contractByStepsFilled,
        apiOperationTypes,
        stepperText.operationTypeStepNumber,
      ));
    }
  };

  const setOperationTypesAndOperationTypesStatusBasedOnGetOperationTypesStatus = () => {
    if (getOperationTypesStatus === Status.LOADING) {
      setOperationTypes([]);
      setOperationTypesStatus(Status.LOADING);
    }
    if (getOperationTypesStatus === Status.SUCCESS) {
      setOperationTypes(apiOperationTypes);
      setOperationTypesStatus(Status.SUCCESS);
    }
  };

  useEffect(() => {
    setOperationTypesAndOperationTypesStatusBasedOnGetOperationTypesStatus();
  }, [getOperationTypesStatus]);

  useEffect(() => {
    getOperationTypesIfContractIdExistsAndOtherApiCallsNotProcessing();
  }, [contractId, isOperationTypeSubViewOpen, postOperationTypeStatus,
    deleteOperationTypeStatus, patchOperationTypeStatus]);

  useEffect(() => {
    filledOperationTypesStepperIfGettingOperationTypesIsSuccessfull();
  }, [apiOperationTypes.length !== 0]);

  const onNewOperationTypeClick = () => {
    if (canAServiceBeAdded(contractStatus, contractAction)) {
      setIsOperationTypeSubViewOpen(true);
      setOperationTypeAction(ActionStatus.NEW);
    }
  };

  return {
    contractAction,
    contractStatus,
    operationTypesStatus,
    patchOperationTypeStatus,
    postOperationTypeStatus,
    onNewOperationTypeClick,
    operationTypes,
  };
}
