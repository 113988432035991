import React, { FormEventHandler } from 'react';

import { PROJECT_TITLE } from 'src/constants/componentTexts/LoginText';
import { TARMAC_LOGO } from 'src/constants/picturesPath';
import { Status } from 'src/constants/status';

import SsoForm from 'src/invoicing/login/SsoForm';

interface Props {
  loginStatus: string;
  setUsername: Function;
  submitSso: FormEventHandler<HTMLFormElement>;
  tarmacLoginErrorMessage: string | null;
  username: string;
}

export default function ExternalLogin(props: Props) {
  const {
    loginStatus,
    setUsername,
    submitSso,
    tarmacLoginErrorMessage,
    username,
  } = props;

  const isLoggedInFail = () => (
    (tarmacLoginErrorMessage && loginStatus === Status.FAIL) && (
      <p id="LoginErrorMessage" className="LoginErrorMessage">
        {tarmacLoginErrorMessage}
      </p>
    )
  );

  return (
    <div className="LoginWrapper">
      <img src={TARMAC_LOGO} alt="Tarmac Logo" className="LoginLogo" />
      <p className="LoginLogoTitle" id="LoginLogoTitle">{PROJECT_TITLE}</p>
      {isLoggedInFail()}
      <SsoForm setUsername={setUsername} submitSso={submitSso} username={username} />
    </div>
  );
}
