import React from 'react';

interface Props {
  cancelButtonText: string;
  isDisabled?: boolean;
  sendButtonText: string;
  setIsServicesSubViewOpen: Function;
}

export default function ServicesFormButtons(props: Props) {
  const {
    cancelButtonText, sendButtonText, setIsServicesSubViewOpen, isDisabled,
  } = props;

  return (
    <div className="ServicesFormButtons">
      <button
        id="ServiceFormButtonsCancelId"
        type="button"
        className="ServicesFormButtonCancel"
        onClick={() => setIsServicesSubViewOpen(false)}
      >
        {cancelButtonText}
      </button>
      {!isDisabled && (
        <button
          id="ServiceFormButtonsSendId"
          type="submit"
          className="ServicesFormButtonSend"
          disabled={isDisabled}
        >
          {sendButtonText}
        </button>
      )}
    </div>
  );
}
