import React from 'react';

import DifferenceBetweenTwoTimings from 'src/invoicing/services/additionalServices/form/DifferenceBetweenTwoTimings';
import ListInput from 'src/uiComponents/input/ListInput';

import {
  AdditionalServiceSentInterface,
  AdditionalServiceReceivedInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { getAddInfosBasedOnTaskDataSourceChoice } from 'src/invoicing/services/ServicesLogic';
import {
  canRenderAdditionalInfoDatasourceInput,
  canRenderDifferenceBetweenTwoTimings,
  canRenderTaskDatasourceInput,
} from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';

import {
  additionalServiceFormText, ADD_SERVICE_EMPTY_VALUE,
} from 'src/constants/componentTexts/AdditionalServicesText';

interface Props {
  additionalService: AdditionalServiceSentInterface;
  additionalServiceFilled: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: any;
  additionalServiceTimingDifference: any;
  readOnly: boolean;
  setAdditionalService: Function;
  setAdditionalServiceTimingDifference: Function;
}

export default function AdditionalServiceFormCarateristics(props: Props) {
  const {
    additionalService,
    additionalServiceFilled,
    additionalServiceParameters,
    additionalServiceTimingDifference,
    readOnly,
    setAdditionalService,
    setAdditionalServiceTimingDifference,
  } = props;

  return (
    <div className="ServicesBox">
      <div className="ServicesBoxStepContent">
        <p className="ServicesBoxStepContentNumber">
          {additionalServiceFormText.parameterNumber}
        </p>
      </div>
      <div className="ServicesBoxInputWrapperAddService">
        <div className="ServicesBoxInputWrapperInputs">
          <div className="ServicesBoxInputs">
            <ListInput
              formObjectPreviousFilledValue={additionalServiceFilled?.additional_service_name?.label || ADD_SERVICE_EMPTY_VALUE}
              inputLabel={additionalServiceFormText.additionalName}
              isRequired
              optionList={additionalServiceParameters.additional_service_names}
              formObjectKey="additional_service_name"
              setFormObject={setAdditionalService}
              formObject={additionalService}
              isReadOnly={readOnly}
            />
            {canRenderTaskDatasourceInput(additionalService) && (
              <ListInput
                formObjectPreviousFilledValue={additionalServiceFilled?.arrival_or_departure_service || ADD_SERVICE_EMPTY_VALUE}
                inputLabel={additionalServiceFormText.arrDepAdditional}
                isRequired
                optionList={additionalServiceParameters.arrival_or_departure_service}
                formObjectKey="arrival_or_departure_service"
                setFormObject={setAdditionalService}
                formObject={additionalService}
                optionInputValueField="code"
                isReadOnly={readOnly}
              />
            )}
          </div>
          <div className="ServicesBoxInputs">
            <ListInput
              formObjectPreviousFilledValue={additionalServiceFilled?.data_type_to_mesure?.label || ADD_SERVICE_EMPTY_VALUE}
              inputLabel={additionalServiceFormText.dataType}
              isRequired
              isReadOnly={additionalServiceFilled !== null || readOnly}
              optionList={additionalServiceParameters.data_types_to_mesure}
              formObjectKey="data_type_to_mesure"
              setFormObject={setAdditionalService}
              formObject={additionalService}
              optionInputValueField="code"
            />
            {canRenderTaskDatasourceInput(additionalService) && (
              <ListInput
                formObjectPreviousFilledValue={additionalServiceFilled?.task_datasource?.label || ADD_SERVICE_EMPTY_VALUE}
                inputLabel={additionalServiceFormText.taskDatasource}
                isRequired
                optionList={additionalServiceParameters.task_datasources}
                formObjectKey="task_datasource"
                setFormObject={setAdditionalService}
                formObject={additionalService}
                isReadOnly={readOnly}
              />
            )}
          </div>
          <div className="ServicesBoxInputs">
            <div className="ServiceInputSpacer" />
            {canRenderAdditionalInfoDatasourceInput(additionalService) && (
              <ListInput
                formObjectPreviousFilledValue={additionalServiceFilled?.additional_information_datasource?.label || ADD_SERVICE_EMPTY_VALUE}
                inputLabel={additionalServiceFormText.addInfoDatasource}
                isRequired
                isDisabled={additionalService.task_datasource === null}
                optionList={getAddInfosBasedOnTaskDataSourceChoice(additionalService.task_datasource, additionalServiceParameters)}
                formObjectKey="additional_information_datasource"
                setFormObject={setAdditionalService}
                formObject={additionalService}
                isReadOnly={readOnly}
              />
            )}
          </div>
        </div>
        {canRenderDifferenceBetweenTwoTimings(additionalService) && (
          <DifferenceBetweenTwoTimings
            additionalServiceFilled={additionalServiceFilled}
            additionalServiceParameters={additionalServiceParameters}
            additionalServiceTimingDifference={additionalServiceTimingDifference}
            readOnly={readOnly}
            setAdditionalServiceTimingDifference={setAdditionalServiceTimingDifference}
          />
        )}
      </div>
    </div>
  );
}
