import React from 'react';

import { ADD_ITEM_LOGO } from 'src/constants/picturesPath';
import { NEW_CONTRACT_FORM_CANCELLATION_RULES_TITLE } from 'src/constants/componentTexts/CancellationRulesText';

import CancellationRules from 'src/invoicing/services/cancellationRules/CancellationRules';
import Loader from 'src/uiComponents/loader/Loader';
import NoCancellationRule from 'src/invoicing/services/cancellationRules/NoCancellationRule';

import {
  areServicesLoading,
  isApiCallStatusSuccessFullAndHasNoServiceToDisplay,
  isApiCallStatusSuccessFullAndHasServicesToDisplay,
} from 'src/invoicing/services/ServicesLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import useCancellationRuleEditor from 'src/invoicing/services/cancellationRules/useCancellationRulesEditor';

interface Props {
  contractId: number | null;
  isCancellationRuleSubViewOpen: boolean;
  setIsCancellationRuleSubViewOpen: Function;
  contractByStepsFilled: boolean[];
  setcontractByStepsFilled: Function;
  setCancellationRuleAction: Function;
}

export default function CancellationRulesEditor(props: Props) {
  const {
    setIsCancellationRuleSubViewOpen,
    setCancellationRuleAction,
  } = props;

  const {
    canAddACancellationRule,
    cancellationRules,
    contractAction,
    contractStatus,
    cancellationRulesStatus,
    getPatchCancellationRuleStatus,
    getPostCancellationRuleStatus,
    onNewCancellationRuleClick,
  } = useCancellationRuleEditor(props);

  const handleCancellationRulesDisplayBasedOnGetCancellationRulesStatus = () => {
    if (shouldLoaderBeDisplayed([cancellationRulesStatus])) {
      return <Loader className="NewContractAppContentCancellationRulesLoader" />;
    }
    if (isApiCallStatusSuccessFullAndHasServicesToDisplay(cancellationRulesStatus, cancellationRules)) {
      return (
        <CancellationRules
          cancellationRules={cancellationRules}
          contractAction={contractAction}
          contractStatus={contractStatus}
          setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
          setCancellationRuleAction={setCancellationRuleAction}
        />
      );
    }
    if (isApiCallStatusSuccessFullAndHasNoServiceToDisplay(cancellationRulesStatus, cancellationRules)) {
      return <NoCancellationRule />;
    }
    return null;
  };

  return (
    areServicesLoading(getPostCancellationRuleStatus, getPatchCancellationRuleStatus) ? (
      <Loader className="NewContractAppContentCancellationRulesLoader" />
    ) : (
      <div className="NewContractAppContentCancellationRulesForm">
        <div className="NewContractAppContentCancellationRulesFormTitle">
          <p
            id="CancellationRulesTitle"
            className="FormTitleFontSize NewContractAppContentCancellationRulesFormTitleText"
          >
            {NEW_CONTRACT_FORM_CANCELLATION_RULES_TITLE}
          </p>
          {canAddACancellationRule() && (
            <img
              className="NewContractAppContentCancellationRulesFormTitleLogo"
              id="CancellationRulesAddNewRules"
              src={ADD_ITEM_LOGO}
              alt="AddNewRulesLogo"
              aria-hidden="true"
              onClick={onNewCancellationRuleClick}
            />
          )}
        </div>
        {handleCancellationRulesDisplayBasedOnGetCancellationRulesStatus()}
      </div>
    )
  );
}
