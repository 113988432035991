import React from 'react';

import { AMOUNT_KEY, AMOUNT_TYPE } from 'src/constants/componentTexts/InvoiceDetailText';

import InvoicedServiceInput from 'src/invoicing/invoiceDetail/InvoicedServiceInput';

interface Props {
  id: string;
  isCustomService: boolean;
  prefixId: string;
  service: any;
  serviceAmount: string;
  services: any;
  setServiceAmount: Function;
  setServices: Function;
  serviceValidated: boolean;
}

export default function Amount(props: Props) {
  const {
    id,
    isCustomService,
    prefixId,
    service,
    serviceAmount,
    services,
    serviceValidated,
    setServiceAmount,
    setServices,
  } = props;

  return (
    <InvoicedServiceInput
      className="InvoicedServiceWrapperInformationServiceAmount"
      id={`InvoicedServiceAmount${prefixId}${id}`}
      isCustomService={isCustomService}
      keyService={AMOUNT_KEY}
      keySetter={setServiceAmount}
      service={service}
      serviceIndex={id}
      services={services}
      setServices={setServices}
      type={AMOUNT_TYPE}
      value={serviceAmount}
      serviceValidated={serviceValidated}
    />
  );
}
