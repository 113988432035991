import React, { ChangeEvent, useEffect, useState } from 'react';

import { ListInputProps } from 'src/uiComponents/input/InputInterface';

import { addStayUpClassNameIfInputFilled } from 'src/utils/logic/input/setUpClassForInputAnimation';

export default function ListInput(props: ListInputProps) {
  const {
    formObjectPreviousFilledValue = '',
    formObject,
    formObjectKey,
    inputLabel,
    isDisabled,
    isReadOnly,
    isRequired,
    optionList,
    optionInputValueField = 'id',
    setFormObject,
  } = props;

  const [optionInputValue, setOptionInputValue] = useState<string>(formObjectPreviousFilledValue);

  useEffect(() => {
    if (isDisabled) {
      setOptionInputValue('');
    }
  }, [isDisabled]);

  const onOptionInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setOptionInputValue(event.target.value);

    if (event.target.value === '') {
      setFormObject({ ...formObject, [formObjectKey]: null });
    }
  };

  const onOptionInputBlur = () => {
    const inputOption = optionList.find((e: any) => e.label === optionInputValue);

    if (inputOption !== undefined) {
      setFormObject({ ...formObject, [formObjectKey]: inputOption[optionInputValueField] });
    } else {
      setOptionInputValue('');
      setFormObject({ ...formObject, [formObjectKey]: null });
    }
  };

  const renderInputDropdown = () => (
    optionList.map((option: any) => (
      <option key={option[optionInputValueField] + option.label} value={option.label} aria-label={option.label}>{option.label}</option>
    ))
  );

  return (
    <div className="InputWrapper InputListWrapper">
      <input
        id={`ListInput${formObjectKey}InputId`}
        value={isDisabled ? '' : optionInputValue}
        onChange={onOptionInputChange}
        className={`InputWrapperInput InputListWrapperInput ${addStayUpClassNameIfInputFilled(optionInputValue)}`}
        list={`inputList${formObjectKey}`}
        required={isRequired}
        disabled={isDisabled}
        autoComplete="off"
        readOnly={isReadOnly}
        onBlur={() => onOptionInputBlur()}
      />
      <datalist id={`inputList${formObjectKey}`}>
        {renderInputDropdown()}
      </datalist>
      <label
        className={`InputWrapperLabel ${addStayUpClassNameIfInputFilled(optionInputValue)}`}
        id={`ListInput${formObjectKey}LabelId`}
      >
        {inputLabel}
      </label>
    </div>
  );
}
