import { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { selectContractAction } from 'src/invoicing/contract/ContractSlice';

interface Props {
  nextComponent: string | null;
  setCurrentContractByStepType: Function;
}

export default function useCancellationRulesByStep(props: Props) {
  const { nextComponent, setCurrentContractByStepType } = props;

  const contractAction: string = useSelector(selectContractAction);
  const [cancellationRuleAction, setCancellationRuleAction] = useState<string>(ActionStatus.NEW);
  const [isCancellationRuleSubViewOpen, setIsCancellationRuleSubViewOpen] = useState<boolean>(false);

  const submitCancellationRules = (event: FormEvent) => {
    event.preventDefault();

    if (nextComponent) {
      setCurrentContractByStepType(nextComponent);
    }
  };

  return {
    cancellationRuleAction,
    contractAction,
    isCancellationRuleSubViewOpen,
    setCancellationRuleAction,
    setIsCancellationRuleSubViewOpen,
    submitCancellationRules,
  };
}
