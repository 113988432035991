import React from 'react';

import { NEW_CONTRACT_FORM_NEW_DELAY_RULE_TITLE } from 'src/constants/componentTexts/DelayRulesText';

import DelayRuleForm from 'src/invoicing/services/delayRules/form/DelayRuleForm';
import Loader from 'src/uiComponents/loader/Loader';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectPostDelayRuleStatus } from 'src/invoicing/services/delayRules/DelayRulesSlice';
import useDelayRuleParameters from 'src/invoicing/services/delayRules/useDelayRuleParameters';
import useDelayRuleSubmittionRequestStatus from 'src/invoicing/services/delayRules/useDelayRuleSubmitionRequestStatus';

interface Props {
  contractId: number | null;
  setIsDelaySubViewOpen: Function;
}

export default function NewDelayRule(props: Props) {
  const { contractId, setIsDelaySubViewOpen } = props;

  const { delayRuleParameters, delayRuleParametersStatus } = useDelayRuleParameters(contractId);
  useDelayRuleSubmittionRequestStatus(selectPostDelayRuleStatus, setIsDelaySubViewOpen);

  return (
    shouldLoaderBeDisplayed([delayRuleParametersStatus]) ? (
      <Loader className="NewContractAppContentDelayRulesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="NewDelayRuleTitleId">{NEW_CONTRACT_FORM_NEW_DELAY_RULE_TITLE}</p>
        </div>
        <DelayRuleForm
          contractId={contractId}
          delayRuleParameters={delayRuleParameters}
          setIsDelaySubViewOpen={setIsDelaySubViewOpen}
        />
      </div>
    )
  );
}
