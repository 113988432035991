import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  downloadInvoiceApiRequestAsyncthunk,
  invoicingDeleteApiRequestAsyncThunk,
  invoicingGetApiRequestAsyncThunk,
  invoicingPostApiRequestAsyncThunk,
} from 'src/invoicing/api/invoicingCustomApiRequestLogic';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

export const apiGetInvoices = createAsyncThunk('invoices/apiGetInvoices', async (parameters :{ fromDate: string | null, toDate: string | null }, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/invoices?from_date=${parameters.fromDate}&to_date=${parameters.toDate}` })
));

export const apiGetDownloadInvoices = createAsyncThunk('invoices/apiGetDownloadInvoices', async (body: InvoiceModel[], { dispatch, getState, rejectWithValue }) => (
  downloadInvoiceApiRequestAsyncthunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/invoices/download_invoices', body })
));

export const apiGetInvoice = createAsyncThunk('invoices/apiGetInvoice', async (invoiceId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/invoice/${invoiceId}` })
));

export const apiRefreshInvoiceinInvoices = createAsyncThunk('invoices/apiRefreshInvoiceinInvoices', async (invoiceId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/invoice/${invoiceId}` })
));

export const apiGetInvoicesFilterParameter = createAsyncThunk('invoices/apiGetInvoicesFilterParameter', async (_, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/invoice_parameters' })
));

export const apiPostInvoiceDetail = createAsyncThunk('invoices/apiPostInvoiceDetail', async (body: { invoiceId: number, services: any }, { dispatch, getState, rejectWithValue }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/invoice/${body.invoiceId}/services`, body: body.services })
));

export const apiPostInvoiceToValidate = createAsyncThunk('invoices/apiPostInvoiceToValidate', async (body: { id: number }, { dispatch, getState, rejectWithValue }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/invoices/validate_invoices', body })
));

export const apiDeleteInvoiceService = createAsyncThunk('invoices/apiDeleteInvoiceService', async (serviceId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingDeleteApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/custom_service/${serviceId}` })
));
