import React from 'react';

import { DELETE_LOGO, EDIT_LOGO, VIEW_LOGO } from 'src/constants/picturesPath';
import { SERVICES_DELETE_BUTTON_TEXT, SERVICES_EDIT_BUTTON_TEXT, SERVICES_VIEW_BUTTON_TEXT } from 'src/constants/componentTexts/ServiceText';

import { CancellationRuleReceivedInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';
import {
  canAServiceBeUpdated,
  returnFixedIfPriceAmountsTypeServiceIsFixed,
  returnVariableIfPriceAmountsTypeServiceIsPercentage,
} from 'src/invoicing/services/ServicesLogic';
import useCancellationRule from 'src/invoicing/services/cancellationRules/useCancellationRule';

interface Props {
  cancellationRule: CancellationRuleReceivedInterface;
  contractAction: string;
  contractStatus: string;
  setCancellationRuleAction: Function;
  setIsCancellationRuleSubViewOpen: Function;
}

export default function CancellationRule(props: Props) {
  const {
    cancellationRule,
    contractAction,
    contractStatus,
  } = props;

  const {
    deleteCancellationRule,
    editCancellationRule,
    viewCancellationRule,
  } = useCancellationRule(props);

  return (
    <tr className="NewContractAppContentCancellationRulesFormTableTbodyRow">
      <td
        id={`NewContractAppContentCancellationRulesFormTableTbodyRowCauseOfCancellationValue${cancellationRule.id}Id`}
        className="FormTextFontSize NewContractAppContentCancellationRulesFormTableTbodyRowImportantValue"
      >
        {cancellationRule.cause_of_cancellation.label}
      </td>
      <td
        id={`NewContractAppContentCancellationRulesFormTableTbodyRowTypeOfPriceValue${cancellationRule.id}Id`}
        className="FormTextFontSize NewContractAppContentCancellationRulesFormTableTbodyRowValue"
      >
        {returnFixedIfPriceAmountsTypeServiceIsFixed(cancellationRule) || returnVariableIfPriceAmountsTypeServiceIsPercentage(cancellationRule)}
      </td>
      <td className="NewContractAppContentCancellationRulesFormTableTbodyRowSpacer" />
      <td
        className="FormTextEditButtonStyle NewContractAppContentCancellationRulesFormTableTbodyRowAction"
      >
        <img
          className="NewContractAppContentCancellationRulesFormTableTbodyRowViewImg"
          src={VIEW_LOGO}
          alt={VIEW_LOGO}
          onClick={viewCancellationRule}
          aria-hidden="true"
        />
        <p
          onClick={viewCancellationRule}
          aria-hidden="true"
          id={`NewContractAppContentCancellationRulesFormTableTbodyRowViewText${cancellationRule.id}Id`}
          className="NewContractAppContentCancellationRulesFormTableTbodyRowEditText"
        >
          {SERVICES_VIEW_BUTTON_TEXT}
        </p>
      </td>
      <td
        className="FormTextEditButtonStyle NewContractAppContentCancellationRulesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentCancellationRulesFormTableTbodyRowEditImg"
              src={EDIT_LOGO}
              alt={EDIT_LOGO}
              onClick={editCancellationRule}
              aria-hidden="true"
            />
            <p
              onClick={editCancellationRule}
              aria-hidden="true"
              id={`NewContractAppContentCancellationRulesFormTableTbodyRowEditText${cancellationRule.id}Id`}
              className="NewContractAppContentCancellationRulesFormTableTbodyRowEditText"
            >
              {SERVICES_EDIT_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
      <td
        className="FormTextDeleteButtonStyle NewContractAppContentCancellationRulesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentCancellationRulesFormTableTbodyRowDeleteImg"
              src={DELETE_LOGO}
              alt={DELETE_LOGO}
              onClick={deleteCancellationRule}
              aria-hidden="true"
            />
            <p
              onClick={deleteCancellationRule}
              aria-hidden="true"
              id={`NewContractAppContentCancellationRulesFormTableTbodyRowDeleteText${cancellationRule.id}Id`}
              className="NewContractAppContentCancellationRulesFormTableTbodyRowDeleteText"
            >
              {SERVICES_DELETE_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
    </tr>
  );
}
