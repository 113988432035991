import { createSlice } from '@reduxjs/toolkit';

import { LOGIN_ERROR_MESSAGE } from 'src/constants/componentTexts/LoginText';
import { Status } from 'src/constants/status';

import { isTokenValid } from 'src/utils/logic/user/userAuthorization';

import { apiFetchLogin, apiExternalAuth } from 'src/invoicing/login/LoginApiRequest';
import { disconnectUser } from 'src/invoicing/InvoicingLogic';

interface InitialState {
  authentication_url: string;
  externalAuthStatus: Status,
  errorLoginMessage: string | null,
  loginResponseStatus: string,
  username: string;
  useTarmacAuthSystem: boolean | null,
}

const initialState: InitialState = {
  authentication_url: '',
  errorLoginMessage: null,
  externalAuthStatus: Status.DEFAULT,
  loginResponseStatus: Status.DEFAULT,
  username: '',
  useTarmacAuthSystem: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetErrorLoginResponse(state) {
      state.errorLoginMessage = null;
    },
    resetLoginResponseStatus(state) {
      state.loginResponseStatus = Status.DEFAULT;
    },
    resetExternalAuthStatus(state) {
      state.externalAuthStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiFetchLogin.fulfilled, (state, action) => {
        if (isTokenValid(action.payload.refresh_token_expiry)) {
          state.loginResponseStatus = Status.SUCCESS;
          state.errorLoginMessage = null;

          localStorage.setItem('accessToken', action.payload.access_token);
          localStorage.setItem('accessTokenExpiry', action.payload.access_token_expiry);
          localStorage.setItem('refreshTokenExpiry', action.payload.refresh_token_expiry);
          localStorage.setItem('refreshToken', action.payload.refresh_token);
          localStorage.setItem('userCompany', action.payload.user.company);
        } else {
          state.loginResponseStatus = Status.FAIL;
          state.errorLoginMessage = LOGIN_ERROR_MESSAGE;
          disconnectUser();
        }
      })
      .addCase(apiFetchLogin.rejected, (state) => {
        state.loginResponseStatus = Status.FAIL;
        state.errorLoginMessage = LOGIN_ERROR_MESSAGE;
        disconnectUser();
      })
      .addCase(apiExternalAuth.pending, (state) => {
        state.externalAuthStatus = Status.LOADING;
      })
      .addCase(apiExternalAuth.fulfilled, (state, action) => {
        state.externalAuthStatus = Status.SUCCESS;
        state.useTarmacAuthSystem = action.payload.use_tarmac_auth_system;

        if (action.payload.use_tarmac_auth_system) {
          state.username = action.payload.username;
        } else {
          state.authentication_url = action.payload.authentication_url;
        }
      });
  },
});

export const { resetLoginResponseStatus, resetErrorLoginResponse, resetExternalAuthStatus } = loginSlice.actions;

export const selectErrorLoginMessage = (state: any) => state.login.errorLoginMessage;
export const selectLoginResponseStatus = (state: any) => state.login.loginResponseStatus;
export const selectUseTarmacAuthSystem = (state: any) => state.login.useTarmacAuthSystem;
export const selectExternalAuthStatus = (state: any) => state.login.externalAuthStatus;
export const selectUserName = (state: any) => state.login.username;
export const selectAuthenticationURL = (state: any) => state.login.authentication_url;

export default loginSlice.reducer;
