import React, { MouseEventHandler } from 'react';

import InvoicedServices from 'src/invoicing/invoiceDetail/InvoicedServices';
import InvoicedTurnaround from 'src/invoicing/invoiceDetail/InvoicedTurnaround';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';

interface Props {
  customServices: CustomService[];
  invoice: any;
  invoiceValidated: boolean;
  servicesDescription: any;
  setCustomServices: Function;
  setServicesDescription: Function;
  toggleValidateInvoice: MouseEventHandler<HTMLDivElement>;
}

export default function InvoicedDetail(props: Props) {
  const {
    customServices,
    invoice,
    invoiceValidated,
    servicesDescription,
    setCustomServices,
    setServicesDescription,
    toggleValidateInvoice,
  } = props;

  return (
    <>
      <InvoicedTurnaround
        customServices={customServices}
        invoice={invoice}
        invoiceValidated={invoiceValidated}
        servicesDescription={servicesDescription}
        toggleValidateInvoice={toggleValidateInvoice}
      />
      <InvoicedServices
        customServices={customServices}
        invoice={invoice}
        servicesDescription={servicesDescription}
        setCustomServices={setCustomServices}
        setServicesDescription={setServicesDescription}
      />
    </>
  );
}
