import { ContractStatus } from 'src/constants/status';

import { ContractsObject } from 'src/invoicing/contract/ContractInterface';

export default function useContractsStatusFilters(contractsFiltered: ContractsObject[]) {
  const keepContractsWithStatus = (contract: ContractsObject, contractStatus: ContractStatus) => contract.status === contractStatus;

  const inProgressContracts = contractsFiltered.filter((contract) => keepContractsWithStatus(contract, ContractStatus.IN_PROGRESS));
  const pastContracts = contractsFiltered.filter((contract) => keepContractsWithStatus(contract, ContractStatus.PAST));
  const futureContracts = contractsFiltered.filter((contract) => keepContractsWithStatus(contract, ContractStatus.FUTURE));

  return {
    totalOfFutureContracts: futureContracts.length,
    totalOfInProgressContracts: inProgressContracts.length,
    totalOfPastContracts: pastContracts.length,
  };
}
