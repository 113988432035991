import { formatTextEachWordsFirstLetterUppercase } from 'src/utils/logic/format/textFormat';

export const SERVICES_DELETE_BUTTON_TEXT = 'Delete';
export const SERVICES_DUPLICATE_BUTTON_TEXT = 'Duplicate';
export const SERVICES_EDIT_BUTTON_TEXT = 'Edit';
export const SERVICES_VIEW_BUTTON_TEXT = 'View';

export const SERVICE_CUSTOM_STEP_EMPTY_VALUE_DESCRIPTION = '[-]';
export const SERVICE_CUSTOM_STEP_EMPTY_VALUE = '';

export const VARIABLE_PRICE_TYPE = 'VariablePrice';
export const FIXED_PRICE_TYPE = 'FixedPrice';

export const HOUR_TO_MINUTE_CONVERTER = 60;
export const FIXED_AMOUNT_CODE = 'FIXED_AMOUNT';
export const PERCENTAGE_OF_BASE_PRICE_CODE = 'PERCENTAGE_OF_BASE_PRICE';

export const FIXED_PRICE_LABEL = 'Fixed amount';
export const PERCENTAGE_OF_BASE_PRICE_SYMBOL = '% of base price';
export const PERCENTAGE_OF_BASE_PRICE_TEXT = 'percentage of the base price';

export const UPPERCASE_WORDS_FIRST_LETTER_CODE = 'uppercase_words_first_letter';
export const LOWERCASE_WORD_CODE = 'lowercase';
export const UPPERCASE_FIRST_LETTER_CODE = 'default';

export const CREATE_ACTION = 'creating';
export const UPDATE_ACTION = 'updating';

export const getServiceErrorMessage = (action: string, service: string) => (
  `Error occured when ${action} this ${service}`
);

export const formattedFixedPriceLabels = {
  LOWERCASE_WORD_CODE: FIXED_PRICE_LABEL.toLowerCase(),
  UPPERCASE_WORDS_FIRST_LETTER_CODE: formatTextEachWordsFirstLetterUppercase(FIXED_PRICE_LABEL),
  UPPERCASE_FIRST_LETTER_CODE: FIXED_PRICE_LABEL,
};
