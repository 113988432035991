export interface CustomStepInterface {
  amount: string | null;
  max_value: string | null;
  min_value: string | null;
  order: number | null;
}

export interface DelayRuleReceivedInterface {
  id: number;
  cause_of_delay: { id: number; label: string };
  price: {
    amounts_type: { code: string, label: string};
    price_steps: CustomStepInterface[];
    values_type: { id: number, label: string};
  }
}

export interface DelayRuleSentInterface {
  contract: number | null;
  cause_of_delay: number | null;
  price: {
    amounts_type: string | null;
    price_steps: CustomStepInterface[];
    values_type: number | null;
  }
}

export const defaultDelayRule: DelayRuleSentInterface = {
  contract: null,
  cause_of_delay: null,
  price: {
    amounts_type: null,
    price_steps: [],
    values_type: null,
  },
};

interface AmountTypeInterface {
  code: string;
  label: string;
}

interface CauseOfDelayInterface {
  id: number;
  label: string;
}

interface CurrencyInterface {
  code: string;
  label: string;
  symbol: string;
}

interface ValueTypeInterface {
  id: number;
  label: string;
}

export interface DelayRuleParametersInterface {
  amount_types: AmountTypeInterface[],
  causes_of_delays: CauseOfDelayInterface[],
  currency: CurrencyInterface,
  value_types: ValueTypeInterface[]
}

export interface DelayRuleFilledInterface {
  cause_of_delay: { id: number; label: string; };
  id: number;
  price: {
    amounts_type: { code: string; label: string };
    price_steps: CustomStepInterface[];
    values_type: { id: number; label: string; }
  }
}
