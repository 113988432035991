import { customStepsText } from 'src/constants/componentTexts/CustomStepText';
import {
  CREATE_ACTION,
  formattedFixedPriceLabels,
  getServiceErrorMessage,
  PERCENTAGE_OF_BASE_PRICE_SYMBOL,
  UPDATE_ACTION,
} from 'src/constants/componentTexts/ServiceText';

export const NEW_CONTRACT_FORM_CANCELLATION_RULES_NO_RULE_MESSAGE = 'No cancellation rule is set up';

export const NEW_CONTRACT_FORM_CANCELLATION_RULES_TABLE_FIRST_COLUMN_TITLE = 'Causes of Cancellation';
export const NEW_CONTRACT_FORM_CANCELLATION_RULES_TABLE_SECOND_COLUMN_TITLE = 'Type of prices';
export const NEW_CONTRACT_FORM_CANCELLATION_RULES_TITLE = 'Cancellation Rules';
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_STEP_2_CHECKBOX_TITLE = 'Pick one type of cancellation price:';
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TITLE = 'New Cancellation Rule';
export const NEW_CONTRACT_FORM_EDIT_CANCELLATION_RULE_TITLE = 'Edit Cancellation Rule';
export const NEW_CONTRACT_FORM_VIEW_CANCELLATION_RULE_TITLE = 'View Cancellation Rule';

export const CANCELLATION_RULES_STEP_NUMBER = 4;
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PARAMETER_NUMBER = '1';
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PRICE_NUMBER = '3';
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TYPE_OF_PRICE_NUMBER = '2';

export const NEW_CONTRACT_FORM_SERVICES_SEND_CANCELLATION_RULE_FORM_BUTTON = 'Add this Cancellation rule';
export const EDIT_CONTRACT_FORM_SERVICES_SEND_CANCELLATION_RULE_FORM_BUTTON = 'Edit this Cancellation rule';
export const NEW_CONTRACT_FORM_SERVICES_CANCEL_CANCELLATION_RULE_FORM_BUTTON = 'Cancel';

export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TYPE_OF_PRICE_TEXT = 'Price';
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PRICE_TEXT = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_FIXED_PRICE_LABEL = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_VARIABLE_PRICE_LABEL = `Variable Price (${PERCENTAGE_OF_BASE_PRICE_SYMBOL})`;
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE__PRICE_LABEL = 'Price';
export const NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_CAUSE_OF_CANCELLATION_LABEL = 'Cause of Cancellation';

export const CANCELLATION_RULE_NAME = 'cancellation rule';

export const NEW_CANCELLATION_RULE_ERROR_MESSAGE = getServiceErrorMessage(CREATE_ACTION, CANCELLATION_RULE_NAME);
export const UPDATE_CANCELLATION_RULE_ERROR_MESSAGE = getServiceErrorMessage(UPDATE_ACTION, CANCELLATION_RULE_NAME);

export const cancellationRuleFormText = {
  causeOfCancellation: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_CAUSE_OF_CANCELLATION_LABEL,
  fixedPrice: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_FIXED_PRICE_LABEL,
  parameterNumber: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PARAMETER_NUMBER,
  typeOfPriceNumber: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TYPE_OF_PRICE_NUMBER,
  typeOfPriceText: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TYPE_OF_PRICE_TEXT,
  variablePrice: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_VARIABLE_PRICE_LABEL,
};

export const cancellationRuleFixedPriceText = {
  startWordText: 'For',
  turnaround: ' a turnaround',
  arrivalOnly: ' an arrival only',
  departureOnly: ' a departure only',
  subtitleItalicText: 'The cancellation penalties are in function of ',
  subtitleItalicBoldText: 'the time before the flight the cancellation was announced.',
  subtitleListMainText: ', the cancellation announcement time would be compared to the ',
  subtitleBoldTurnaroundText: 'STA of the arriving flight',
  subtitleBoldArrivalOnlyText: 'STA of the arriving flight',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: 'STD of the departing flight',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  priceNumber: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PRICE_NUMBER,
  priceText: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PRICE_TEXT,
  price: '',
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} penalties`,
  customStepDescriptionFirstPart: customStepsText.customStepDescriptionFirstPart,
  customStepDescriptionSecondPart: 'before STA/STD of the flights',
  customStepDescriptionAmount: ', the penalties will be ',
  customStepDescriptionPriceAmount: '',
  customStepInputValue: customStepsText.customStepInputValueWithASpaceAtTheEnd,
  customStepSeparatorBetweenTwoValue: customStepsText.customStepAndSeparatorBetweenTwoValue,
};

export const cancellationRuleVariablePriceText = {
  startWordText: 'For',
  turnaround: ' a turnaround',
  arrivalOnly: ' an arrival only',
  departureOnly: ' a departure only',
  subtitleItalicText: 'The cancellation penalties are in function of ',
  subtitleItalicBoldText: 'the time before the flight the cancellation was announced and proportional to the base price for a complete turnaround, an arrival only or a departure only.',
  subtitleItalicPart2Text: '',
  subtitleListMainText: ', the cancellation announcement time would be compared to the ',
  subtitleBoldTurnaroundText: 'STA of the arriving flight',
  subtitleBoldArrivalOnlyText: 'STA of the arriving flight',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: 'STD of the departing flight',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  priceNumber: NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_PRICE_NUMBER,
  priceText: 'Variable Price',
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} penalties`,
  customStepDescriptionFirstPart: customStepsText.customStepDescriptionFirstPart,
  customStepDescriptionSecondPart: 'before STA/STD of the flights',
  customStepDescriptionAmount: ', the penalties will be ',
  customStepDescriptionPriceAmount: ' of the base price for a complete turnaround, an arrival only or a departure only.',
  variablePriceMoney: '%',
  customStepInputValue: customStepsText.customStepInputValueWithASpaceAtTheEnd,
  customStepSeparatorBetweenTwoValue: customStepsText.customStepAndSeparatorBetweenTwoValue,
};
