import { MISSING_INFO_STATUS, TO_CHECK_STATUS, VALIDATED_STATUS } from 'src/constants/componentTexts/InvoiceAppText';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';
import useTurnaroundFlights from 'src/invoicing/invoiceDetail/useTurnaroundFlights';
import useTurnaroundFlightsPath from 'src/invoicing/invoiceDetail/useTurnaroundFlightsPath';

interface Props {
  customServices: CustomService[];
  invoice: any;
  invoiceValidated: boolean;
  servicesDescription: any;
}

export default function useInvoicedTurnaround(props: Props) {
  const {
    customServices,
    invoice,
    invoiceValidated,
    servicesDescription,
  } = props;

  const { getInvoicedTurnaroundFlight } = useTurnaroundFlights(invoice);
  const { getInvoicedTurnaroundFlightPath } = useTurnaroundFlightsPath(invoice);

  const getInvoicedTurnaroundDate = () => {
    if (invoice.departure_date === invoice.arrival_date) {
      return invoice.departure_date;
    }
    if (invoice.departure_date && !invoice.arrival_date) {
      return invoice.departure_date;
    }
    if (!invoice.departure_date && invoice.arrival_date) {
      return invoice.arrival_date;
    }
    return `${invoice.arrival_date} -> ${invoice.departure_date}`;
  };

  const getInvoiceStatusBasedOnIfInvoiceValidated = (key: keyof { code: string, label: string }) => (
    invoiceValidated ? VALIDATED_STATUS[key] : TO_CHECK_STATUS[key]
  );

  const areServicesStatusAllValidated = () => {
    const areServicesFilled = invoice.services.map((service: any) => (
      !(service?.status === TO_CHECK_STATUS.code || service?.status === MISSING_INFO_STATUS.code)
    ));

    const areCustomServicesFilled = customServices.map((customService: any) => (
      !(customService?.status === TO_CHECK_STATUS.code || customService?.status === MISSING_INFO_STATUS.code)
    ));

    const areServicesDescriptionFilled = servicesDescription.map((customService: any) => (
      !(customService?.status === TO_CHECK_STATUS.code || customService?.status === MISSING_INFO_STATUS.code)
    ));

    return (!areServicesFilled.includes(false) && !areCustomServicesFilled.includes(true) && !areServicesDescriptionFilled.includes(false));
  };

  const hasANewCustomServiceOrAServiceChangeOfStatus = () => {
    const hasCustomServices = customServices.length >= 1;
    const hasNoCustomServicesAndServiceDescription = servicesDescription.length === 0 && customServices.length === 0;

    const services = servicesDescription.map((service: any) => {
      const serviceFinded = invoice.services.find((invoiceService: any) => Number(invoiceService.id) === service.id);

      return (serviceFinded !== undefined && (serviceFinded.status !== service.status));
    });

    return (hasNoCustomServicesAndServiceDescription ? false : (hasCustomServices || services.includes(true)));
  };

  const changeInvoiceStatusIfInvoiceValidatedButServiceStatusChange = (key: keyof { code: string, label: string }) => {
    if (invoiceValidated && hasANewCustomServiceOrAServiceChangeOfStatus()) {
      return TO_CHECK_STATUS[key];
    }
    return getInvoiceStatusBasedOnIfInvoiceValidated(key);
  };

  const canDisplayToggleForInvoiceStatus = () => (
    ((!invoiceValidated && areServicesStatusAllValidated()) || (invoiceValidated && !hasANewCustomServiceOrAServiceChangeOfStatus()))
  );

  return {
    canDisplayToggleForInvoiceStatus,
    changeInvoiceStatusIfInvoiceValidatedButServiceStatusChange,
    getInvoicedTurnaroundDate,
    getInvoicedTurnaroundFlight,
    getInvoicedTurnaroundFlightPath,
  };
}
