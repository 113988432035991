import React from 'react';

import { InvoiceStatus } from 'src/constants/status';
import { QUESTION_LOGO, SEARCH_LOGO, TICK_LOGO } from 'src/constants/picturesPath';

import { InvoicesFiltersModel, defaultInvoicesFilter, InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';
import useInvoicesStatusFilters from 'src/invoicing/invoices/filters/invoicesStatusFilter/useInvoicesStatusFilters';

import StatusFilter from 'src/uiComponents/filters/StatusFilter';

interface Props {
  invoicesFilters: InvoicesFiltersModel;
  invoicesFilteredAndSorted: InvoiceModel[];
  setInvoicesFilters: Function;
}

export default function InvoicesStatusFilters(props: Props) {
  const {
    invoicesFilters,
    invoicesFilteredAndSorted,
    setInvoicesFilters,
  } = props;

  const {
    missingInfoInvoices,
    toCheckInvoices,
    validateInvoices,
  } = useInvoicesStatusFilters(invoicesFilteredAndSorted);

  return (
    <div className="StatusFilterContainer">
      <StatusFilter
        amount={toCheckInvoices.amount}
        defaultElementsFilter={defaultInvoicesFilter}
        elementsFilters={invoicesFilters}
        filterKey="toCheck"
        filterIcon={SEARCH_LOGO}
        setElementsFilters={setInvoicesFilters}
        title={InvoiceStatus.TO_CHECK}
        totalOfElements={toCheckInvoices.totalOfInvoices}
      />
      <StatusFilter
        amount={missingInfoInvoices.amount}
        defaultElementsFilter={defaultInvoicesFilter}
        elementsFilters={invoicesFilters}
        filterKey="missingInfo"
        filterIcon={QUESTION_LOGO}
        setElementsFilters={setInvoicesFilters}
        title={InvoiceStatus.MISSING_INFO}
        totalOfElements={missingInfoInvoices.totalOfInvoices}
      />
      <StatusFilter
        amount={validateInvoices.amount}
        defaultElementsFilter={defaultInvoicesFilter}
        elementsFilters={invoicesFilters}
        filterKey="validated"
        filterIcon={TICK_LOGO}
        setElementsFilters={setInvoicesFilters}
        title={InvoiceStatus.VALIDATED}
        totalOfElements={validateInvoices.totalOfInvoices}
      />
    </div>
  );
}
