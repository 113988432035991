import axios from 'axios';

import { FAILED_REFRESH_ACCESS_TOKEN_MESSAGE } from 'src/constants/errorMessage';
import { SSO_API_URL, USER_API_URL } from 'src/constants/url';

import { isTokenValid, refreshAccessTokenIfExpired } from 'src/utils/logic/user/userAuthorization';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';
import { throwErrorIfErrorDuringRefreshAccessToken } from 'src/invoicing/api/invoicingCustomApiRequestLogic';

export const invoicingGetRequest = async (dispatch: Function, baseUrl: string | undefined, url: string, getState: any) => { // eslint-disable-line
  await refreshAccessTokenIfExpired(dispatch);

  throwErrorIfErrorDuringRefreshAccessToken(getState);
  const response = await axios.get(`${baseUrl}${url}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

  return response;
};

export const invoicingPostRequest = async (dispatch: Function, baseUrl: string | undefined, url: string, getState: any, body: any) => { // eslint-disable-line
  await refreshAccessTokenIfExpired(dispatch);

  throwErrorIfErrorDuringRefreshAccessToken(getState);
  const response = await axios.post(`${baseUrl}${url}`, body, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

  return response;
};

export const invoicingDeleteRequest = async (dispatch: Function, baseUrl: string | undefined, url: string, getState: any) => { // eslint-disable-line
  await refreshAccessTokenIfExpired(dispatch);

  throwErrorIfErrorDuringRefreshAccessToken(getState);
  const response = await axios.delete(`${baseUrl}${url}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

  return response;
};

export const invoicingPatchRequest = async (dispatch: Function, baseUrl: string | undefined, url: string, getState: any, body: any) => { // eslint-disable-line
  await refreshAccessTokenIfExpired(dispatch);

  throwErrorIfErrorDuringRefreshAccessToken(getState);
  const response = await axios.patch(`${baseUrl}${url}`, body, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

  return response;
};

export const refreshAccessTokenRequest = async () => {
  const refreshTokenExpiry = localStorage.getItem('refreshTokenExpiry');

  if (refreshTokenExpiry) {
    if (isTokenValid(refreshTokenExpiry)) {
      const response = await axios.post(`${USER_API_URL}/token/refresh`, { refresh: localStorage.getItem('refreshToken') });

      return response.data;
    }
  }
  throw new Error(FAILED_REFRESH_ACCESS_TOKEN_MESSAGE);
};

export const invoicingLoginRequest = async (credentials: { username: string, password: string }) => { // eslint-disable-line
  const response = await axios.post(`${USER_API_URL}/login`, {}, {
    auth: {
      username: (credentials.username),
      password: (credentials.password),
    },
  });
  return response.data;
};

export const invoicingDownloadInvoices = async (dispatch: Function, baseUrl: string | undefined, url: string, getState: any, body: InvoiceModel[]) => {
  await refreshAccessTokenIfExpired(dispatch);

  throwErrorIfErrorDuringRefreshAccessToken(getState);

  const response = await axios.post(`${baseUrl}${url}`, body, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }, responseType: 'blob' }).then((res) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');

    link.href = downloadUrl;
    link.setAttribute('download', `invoices_export_${localStorage.getItem('userCompany')}_${new Date().toISOString().slice(0, 10)}.csv`);
    document.body.appendChild(link);
    link.click();
  });

  return response;
};

export const invoicingPostSSoRequest = async (body: { username: string, host: string }) => {
  const response = await axios.post(`${SSO_API_URL}/sso`, body);

  return response.data;
};
