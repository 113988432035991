import React, { ChangeEventHandler, MouseEventHandler } from 'react';

import { ADD_DESCRIPTION_TEXT } from 'src/constants/componentTexts/InvoiceDetailText';

interface Props {
  descriptionTextDynamicHeight: any;
  getCustomPrefixIdIfCustomService: Function;
  isDescriptionOpen: boolean;
  onDescriptionChange: ChangeEventHandler<HTMLTextAreaElement>;
  onDescriptionClick: MouseEventHandler<HTMLButtonElement>;
  serviceDescription: any;
  serviceId: string;
}

export default function InvoicedServiceDescription(props: Props) {
  const {
    descriptionTextDynamicHeight,
    getCustomPrefixIdIfCustomService,
    isDescriptionOpen,
    onDescriptionChange,
    onDescriptionClick,
    serviceDescription,
    serviceId,
  } = props;

  return (
    !isDescriptionOpen ? (
      <button
        id={`InvoicedServiceAddDescription${getCustomPrefixIdIfCustomService()}${serviceId}`}
        className="InvoicedServiceAddDescription"
        type="button"
        onClick={onDescriptionClick}
      >
        {ADD_DESCRIPTION_TEXT}
      </button>
    ) : (
      <textarea
        rows={1}
        value={serviceDescription}
        onChange={onDescriptionChange}
        className="InvoicedServiceDescription"
        autoComplete="off"
        ref={descriptionTextDynamicHeight}
        id={`InvoicedServiceDescription${getCustomPrefixIdIfCustomService()}${serviceId}`}
      />
    )
  );
}
