import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

export default function useDelayRuleSubmittionRequestStatus(submitionDelayRuleSelector: any, setIsDelaySubViewOpen: Function) {
  const patchDelayruleStatus: string = useSelector(submitionDelayRuleSelector);

  useEffect(() => {
    if (patchDelayruleStatus === Status.SUCCESS) {
      setIsDelaySubViewOpen(false);
    }
  }, [patchDelayruleStatus]);
}
