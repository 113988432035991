import React from 'react';

import { PROJECT_TITLE } from 'src/constants/componentTexts/LoginText';
import { TARMAC_LOGO } from 'src/constants/picturesPath';

import Loader from 'src/uiComponents/loader/Loader';
import LoginForm from 'src/invoicing/login/LoginForm';

import useLogin from 'src/invoicing/login/useLogin';

export default function TarmacLogin() {
  const {
    isTarmacLoginLoading,
    password,
    setPassword,
    submitLogin,
  } = useLogin();

  return (
    <div className="LoginWrapper">
      <img src={TARMAC_LOGO} alt="Tarmac Logo" className="LoginLogo" />
      <p className="LoginLogoTitle" id="LoginLogoTitle">{PROJECT_TITLE}</p>
      {isTarmacLoginLoading() ? (
        <Loader className="LoginLoader" />
      ) : (
        <LoginForm
          password={password}
          setPassword={setPassword}
          submitLogin={submitLogin}
        />
      )}
    </div>
  );
}
