import { AmountFilterInputsId, InvoiceStatus } from 'src/constants/status';

import { InvoiceModel, InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';

export default function useFilterInvoices(invoicesToFilter: InvoiceModel[], invoicesFilters: InvoicesFiltersModel) {
  const areAllStatusFiltersTurnedOff = !invoicesFilters.missingInfo && !invoicesFilters.toCheck && !invoicesFilters.validated;

  const filterInvoicesByStatus = (invoice: InvoiceModel, isStatusFilterOn:boolean, statusEnum: InvoiceStatus) => (
    isStatusFilterOn && invoice.status?.code === statusEnum
  );

  const filterInvoicesByAirport = (invoicesFiltered: InvoiceModel[]) => (
    invoicesFilters.airport
      ? invoicesFiltered.filter((invoice: any) => invoice.airport === invoicesFilters.airport)
      : invoicesFiltered
  );

  const filterInvoicesByCustomer = (invoicesFiltered: InvoiceModel[]) => (
    invoicesFilters.customer ? invoicesFiltered.filter((invoice: any) => Number(invoice.customer.id) === invoicesFilters.customer) : invoicesFiltered
  );

  const filterInvoicesByProvider = (invoicesFiltered: InvoiceModel[]) => (
    invoicesFilters.provider ? invoicesFiltered.filter((invoice: any) => Number(invoice.provider.id) === invoicesFilters.provider) : invoicesFiltered
  );

  const filterInvoicesByAmount = (invoicesFiltered: InvoiceModel[]) => {
    const { amountSelected, AmountFilteInputId } = invoicesFilters.amount;

    if (!amountSelected || !AmountFilteInputId) {
      return invoicesFiltered;
    }

    const fallbackAmount = '0';

    const amountFiltersStatusDictionary = {
      [AmountFilterInputsId.EQUAL_TO]: (invoice: InvoiceModel) => Number(invoice.amount || fallbackAmount) === Number(amountSelected),
      [AmountFilterInputsId.SUPERIOR_TO]: (invoice: InvoiceModel) => Number(invoice.amount || fallbackAmount) > Number(amountSelected),
      [AmountFilterInputsId.INFERIOR_AT]: (invoice: InvoiceModel) => Number(invoice.amount || fallbackAmount) < Number(amountSelected),
      [AmountFilterInputsId.NOT_EQUAL_TO]: (invoice: InvoiceModel) => Number(invoice.amount || fallbackAmount) !== Number(amountSelected),
      [AmountFilterInputsId.SUPERIOR_OR_EQUAL_TO]: (invoice: InvoiceModel) => Number(invoice.amount || fallbackAmount) >= Number(amountSelected),
      [AmountFilterInputsId.INFERIOR_OR_EQUAL_TO]: (invoice: InvoiceModel) => Number(invoice.amount || fallbackAmount) <= Number(amountSelected),
    };

    return invoicesFiltered.filter(amountFiltersStatusDictionary[AmountFilteInputId]);
  };

  const filterInvoicesBySearchbarValue = (invoicesFiltered: InvoiceModel[]) => {
    const { searchbarValue } = invoicesFilters;

    if (!searchbarValue) {
      return invoicesFiltered;
    }

    const invoicesFilteredByAmount = invoicesFiltered.filter((invoice) => invoice.amount?.toLocaleLowerCase().startsWith(searchbarValue.toLowerCase()));
    const invoicesFilteredByAirport = invoicesFiltered.filter((invoice) => invoice.airport?.toLocaleLowerCase().startsWith(searchbarValue.toLowerCase()));
    const invoicesFilteredByAicraftType = invoicesFiltered.filter((invoice) => invoice.aircraft_type?.toLocaleLowerCase().startsWith(searchbarValue.toLowerCase()));

    const setOfuniqueInvoices = new Set([
      ...invoicesFilteredByAmount,
      ...invoicesFilteredByAirport,
      ...invoicesFilteredByAicraftType,
    ]);

    return Array.from(setOfuniqueInvoices);
  };

  const filterInvoices = () => {
    let invoicesFiltered: InvoiceModel[] = invoicesToFilter;

    invoicesFiltered = areAllStatusFiltersTurnedOff
      ? invoicesToFilter
      : [
        ...invoicesToFilter.filter((invoice: InvoiceModel) => filterInvoicesByStatus(invoice, invoicesFilters.missingInfo, InvoiceStatus.MISSING_INFO)),
        ...invoicesToFilter.filter((invoice: InvoiceModel) => filterInvoicesByStatus(invoice, invoicesFilters.toCheck, InvoiceStatus.TO_CHECK)),
        ...invoicesToFilter.filter((invoice: InvoiceModel) => filterInvoicesByStatus(invoice, invoicesFilters.validated, InvoiceStatus.VALIDATED)),
      ];
    invoicesFiltered = filterInvoicesByAirport(invoicesFiltered);
    invoicesFiltered = filterInvoicesByAmount(invoicesFiltered);
    invoicesFiltered = filterInvoicesByCustomer(invoicesFiltered);
    invoicesFiltered = filterInvoicesByProvider(invoicesFiltered);
    invoicesFiltered = filterInvoicesBySearchbarValue(invoicesFiltered);

    return invoicesFiltered;
  };

  return {
    filterInvoices,
  };
}
