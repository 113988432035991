import React, { useEffect } from 'react';

import { differenceBetweenTwoTimingsText } from 'src/constants/componentTexts/AdditionalServicesText';
import { ARROW_LOGO } from 'src/constants/picturesPath';

import DifferenceBetweenTwoTimingsDatasource from 'src/invoicing/services/additionalServices/form/DifferenceBetweenTwoTimingsDatasource';

import {
  AdditionalServiceReceivedInterface,
  AdditionalServiceParametersInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';

import 'src/invoicing/services/additionalServices/form/DifferenceBetweenTwoTimings.css';

interface Props {
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: AdditionalServiceParametersInterface;
  additionalServiceTimingDifference: any;
  readOnly?: boolean;
  setAdditionalServiceTimingDifference: Function;
}

export default function DifferenceBetweenTwoTimings(props: Props) {
  const {
    additionalServiceFilled,
    additionalServiceParameters,
    additionalServiceTimingDifference,
    readOnly,
    setAdditionalServiceTimingDifference,
  } = props;

  useEffect(() => {
    if (!additionalServiceFilled) {
      setAdditionalServiceTimingDifference({
        ...additionalServiceTimingDifference,
        task_A_arrival_or_departure: additionalServiceTimingDifference?.task_A_arrival_or_departure || null,
        task_B_arrival_or_departure: additionalServiceTimingDifference?.task_B_arrival_or_departure || null,
        task_datasource_A: additionalServiceTimingDifference?.task_datasource_A || null,
        task_datasource_B: additionalServiceTimingDifference?.task_datasource_B || null,
        additional_information_datasource_A: additionalServiceTimingDifference?.additional_information_datasource_A || null,
        additional_information_datasource_B: additionalServiceTimingDifference?.additional_information_datasource_B || null,
        task_A_timing_reference: additionalServiceTimingDifference?.task_A_timing_reference || null,
        task_B_timing_reference: additionalServiceTimingDifference?.task_B_timing_reference || null,
      });
    }
  }, [!additionalServiceTimingDifference]);

  return (
    <div id="DifferenceBetween2TimingsWrapper" className="DifferenceBetweenTwoTimingsWrapper">
      <DifferenceBetweenTwoTimingsDatasource
        additionalServiceFilled={additionalServiceFilled}
        additionalServiceParameters={additionalServiceParameters}
        additionalServiceTimingDifference={additionalServiceTimingDifference}
        readOnly={readOnly}
        setAdditionalServiceTimingDifference={setAdditionalServiceTimingDifference}
        datasourcePart={differenceBetweenTwoTimingsText.aDatetimeText}
      />
      <div className="DifferenceBetweenTwoTimingsWrapperMiddle">
        <p className="DifferenceBetweenTwoTimingsWrapperMiddleText">
          {differenceBetweenTwoTimingsText.firstPartMiddleText}
          <strong>{differenceBetweenTwoTimingsText.aDatetimeText}</strong>
          {differenceBetweenTwoTimingsText.secondPartMiddleText}
          <strong>{differenceBetweenTwoTimingsText.bDatetimeText}</strong>
          {differenceBetweenTwoTimingsText.lastPartMiddleText}
        </p>
        <img src={ARROW_LOGO} alt={ARROW_LOGO} className="DifferenceBetweenTwoTimingsWrapperMiddleArrowImg" />
      </div>
      <DifferenceBetweenTwoTimingsDatasource
        additionalServiceFilled={additionalServiceFilled}
        additionalServiceParameters={additionalServiceParameters}
        additionalServiceTimingDifference={additionalServiceTimingDifference}
        readOnly={readOnly}
        setAdditionalServiceTimingDifference={setAdditionalServiceTimingDifference}
        datasourcePart={differenceBetweenTwoTimingsText.bDatetimeText}
      />
    </div>
  );
}
