export const formatDateToBeUserFriendly = (date: string) => {
  const GB_DATETIME_FORMAT: string = 'en-GB';

  return (`${new Intl.DateTimeFormat(GB_DATETIME_FORMAT, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(date)).toUpperCase()} - ${new Intl.DateTimeFormat(GB_DATETIME_FORMAT, {
    weekday: 'long',
    timeZone: 'UTC',
  }).format(new Date(date)).toUpperCase()}`);
};

export const displayPrettyUTCDate = (date: string) => (
  formatDateToBeUserFriendly(date).substring(0, 2)
    .concat(formatDateToBeUserFriendly(date).substring(3, 6))
    .concat(String(new Date(date).getUTCFullYear()).substring(2, 4))
);
