import { TO_CHECK_STATUS, VALIDATED_STATUS } from 'src/constants/componentTexts/InvoiceAppText';
import { CUSTOM_SERVICE_TYPE } from 'src/constants/componentTexts/InvoiceDetailText';

import { deepCopy } from 'src/utils/logic/copy';

interface Props {
  id: string;
  getServiceDescriptionIfChangedBefore: Function;
  service: any;
  serviceAmount: string;
  serviceLabel: string;
  serviceValidated: boolean;
  services: any;
  setServices: Function;
  setServiceValidated: Function;
}

export default function useServiceStatus(props: Props) {
  const {
    id,
    getServiceDescriptionIfChangedBefore,
    service,
    serviceAmount,
    serviceLabel,
    serviceValidated,
    services,
    setServices,
    setServiceValidated,
  } = props;

  const getServiceStatusCodeBadesOnServiceValidated = () => (
    !serviceValidated ? VALIDATED_STATUS.code : TO_CHECK_STATUS.code
  );

  const isServiceDescriptionAndServiceIdentical = (servicesDescriptionCopy: any, indexOfExistingServiceDescription: number) => (
    (servicesDescriptionCopy[indexOfExistingServiceDescription].description === service.description
      && servicesDescriptionCopy[indexOfExistingServiceDescription].label === service.label
      && servicesDescriptionCopy[indexOfExistingServiceDescription].amount === service.amount)
  );

  const filledCustomServiceServiceStatusField = (servicesDescriptionCopy: any) => {
    servicesDescriptionCopy.push({
      id: Number(service.id),
      description: service.description,
      type: service.type,
      amount: serviceAmount,
      label: serviceLabel,
      status: getServiceStatusCodeBadesOnServiceValidated(),
    });
  };

  const filledContractServiceServiceStatusField = (servicesDescriptionCopy: any) => {
    servicesDescriptionCopy.push({
      id: Number(service.id),
      description: service.description,
      type: service.type,
      status: getServiceStatusCodeBadesOnServiceValidated(),
    });
  };

  const toggleCustomServiceStatusChange = (servicesDescriptionCopy: any, indexOfExistingServiceDescription: number) => {
    if (service.type === CUSTOM_SERVICE_TYPE) {
      if (isServiceDescriptionAndServiceIdentical(servicesDescriptionCopy, indexOfExistingServiceDescription)) {
        servicesDescriptionCopy.splice(indexOfExistingServiceDescription, 1);
      } else {
        servicesDescriptionCopy[indexOfExistingServiceDescription].status = getServiceStatusCodeBadesOnServiceValidated();
      }
    } else if ((servicesDescriptionCopy[indexOfExistingServiceDescription].description === service.description)) {
      servicesDescriptionCopy.splice(indexOfExistingServiceDescription, 1);
    } else {
      servicesDescriptionCopy[indexOfExistingServiceDescription].status = getServiceStatusCodeBadesOnServiceValidated();
    }
  };

  const toggleExistingServiceStatus = () => {
    const servicesDescriptionCopy = deepCopy(services);
    const serviceDescriptionFinded = getServiceDescriptionIfChangedBefore(servicesDescriptionCopy);

    if (serviceDescriptionFinded !== undefined) {
      const indexOfExistingServiceDescription = servicesDescriptionCopy.indexOf(serviceDescriptionFinded);

      if (getServiceStatusCodeBadesOnServiceValidated() === service.status) {
        toggleCustomServiceStatusChange(servicesDescriptionCopy, indexOfExistingServiceDescription);
      } else {
        servicesDescriptionCopy[indexOfExistingServiceDescription].status = getServiceStatusCodeBadesOnServiceValidated();
      }
    } else if (service.type === CUSTOM_SERVICE_TYPE) {
      filledCustomServiceServiceStatusField(servicesDescriptionCopy);
    } else {
      filledContractServiceServiceStatusField(servicesDescriptionCopy);
    }
    setServiceValidated(!serviceValidated);
    setServices(servicesDescriptionCopy);
  };

  const toggleCustomServiceStatus = () => {
    const customServicesCopy = deepCopy(services);

    customServicesCopy[id].status = getServiceStatusCodeBadesOnServiceValidated();

    setServiceValidated(!serviceValidated);
    setServices(customServicesCopy);
  };

  return {
    toggleCustomServiceStatus,
    toggleExistingServiceStatus,
  };
}
