import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import { getContractByWithBasePriceStepsFilled } from 'src/invoicing/contract/ContractLogic';
import {
  selectContractAction,
  selectContractId,
  selectContractResponseStatus,
  selectPatchContractStatus,
  selectPostDuplicateContractStatus,
} from 'src/invoicing/contract/ContractSlice';
import { StoredContract } from 'src/invoicing/contract/ContractInterface';

interface Props {
  contractByStepsFilled: boolean[];
  nextComponent: string | null;
  setCurrentContractByStepType: Function;
  setcontractByStepsFilled: Function;
  setStoredContract: Function;
  storedContract: StoredContract;
}

export default function useBasePriceStep(props: Props) {
  const {
    contractByStepsFilled,
    nextComponent,
    setcontractByStepsFilled,
    setCurrentContractByStepType,
    setStoredContract,
    storedContract,
  } = props;

  const contractAction: string = useSelector(selectContractAction);
  const contractId: number | null = useSelector(selectContractId);
  const getContractResponseStatus: string = useSelector(selectContractResponseStatus);
  const patchContractStatus: string = useSelector(selectPatchContractStatus);
  const postDuplicateContractStatus: string = useSelector(selectPostDuplicateContractStatus);

  const setNextStepAndFilledBasePriceStep = (status: string) => {
    if (status === Status.SUCCESS) {
      if (contractId) {
        setStoredContract({ ...storedContract, id: contractId });
        if (nextComponent) {
          setCurrentContractByStepType(nextComponent);
        }
      }
    }
    setcontractByStepsFilled(
      getContractByWithBasePriceStepsFilled(contractByStepsFilled, status),
    );
  };

  useEffect(() => { setNextStepAndFilledBasePriceStep(getContractResponseStatus); }, [getContractResponseStatus, contractId]);
  useEffect(() => { setNextStepAndFilledBasePriceStep(patchContractStatus); }, [patchContractStatus, contractId]);
  useEffect(() => { setNextStepAndFilledBasePriceStep(postDuplicateContractStatus); }, [postDuplicateContractStatus, contractId]);

  return {
    contractAction,
    getContractResponseStatus,
    patchContractStatus,
    postDuplicateContractStatus,
    setNextStepAndFilledBasePriceStep,
  };
}
