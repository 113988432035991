import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { amountFiltersSymbols } from 'src/constants/componentTexts/AmountFilterText';

import { apiGetInvoicesFilterParameter } from 'src/invoicing/invoices/InvoicesApiRequest';
import { defaultDropDownValues, DropDownValues, InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';
import { selectGetInvoicesFilterParametersStatus, selectInvoicesFilterParameter } from 'src/invoicing/invoices/InvoicesSlice';

interface Props {
  dateFilters: any;
  invoicesFilters: InvoicesFiltersModel;
}

export default function useInvoicesFilters(props: Props) {
  const { dateFilters, invoicesFilters } = props;

  const dispatch = useDispatch();

  const invoicesFiltersParameters = useSelector(selectInvoicesFilterParameter);
  const getInvoicesFilterParametersStatus = useSelector(selectGetInvoicesFilterParametersStatus);

  const [dropDownValues, setDropDownValues] = useState<DropDownValues>({ ...defaultDropDownValues, date: { fromDate: dateFilters.fromDate, toDate: dateFilters.toDate } });

  useEffect(() => {
    setDropDownValues({ ...dropDownValues, date: { fromDate: dateFilters.fromDate, toDate: dateFilters.toDate } });
  }, [dateFilters]);

  useEffect(() => {
    setDropDownValues({
      ...dropDownValues,
      amount: {
        amountSelected: invoicesFilters.amount.amountSelected,
        amountSymbol: invoicesFilters.amount?.AmountFilteInputId ? amountFiltersSymbols[invoicesFilters.amount?.AmountFilteInputId] : null,
      },
    });
  }, [invoicesFilters.amount]);

  useEffect(() => {
    dispatch(apiGetInvoicesFilterParameter());
  }, []);

  return {
    dropDownValues,
    getInvoicesFilterParametersStatus,
    invoicesFiltersParameters,
  };
}
