import React from 'react';

import CustomSteps from 'src/invoicing/services/customSteps/customSteps';

interface Props {
  amountTypeCode: string;
  serviceText: any;
  serviceCurrency: string
  serviceName: string;
  serviceAddInfo: string;
  service: any;
  setService: Function
  serviceFilledPrice?: any;
  readOnly: boolean;
  valuesTypes?: number;
}

export default function CustomStepsPrice(props: Props) {
  const {
    amountTypeCode,
    service,
    serviceAddInfo,
    serviceText,
    serviceCurrency,
    serviceFilledPrice,
    serviceName,
    setService,
    readOnly,
    valuesTypes,
  } = props;

  return (
    <CustomSteps
      amountTypeCode={amountTypeCode}
      customStepsText={serviceText}
      customStepsAmountType={serviceCurrency}
      serviceName={serviceName}
      formObject={service}
      setFormObject={setService}
      formObjectDefaultValue={serviceFilledPrice}
      readOnly={readOnly}
      valuesTypes={valuesTypes}
      serviceAddInfo={serviceAddInfo}
    />
  );
}
