import { createSlice } from '@reduxjs/toolkit';
import { NEW_OPERATION_TYPE_ERROR_MESSAGE, UPDATE_OPERATION_TYPE_ERROR_MESSAGE } from 'src/constants/componentTexts/OperationTypesText';

import { Status } from 'src/constants/status';

import {
  apiDeleteOperationType,
  apiGetOperationTypeParameters,
  apiGetOperationTypes,
  apiGetOperationType,
  apiPatchOperationType,
  apiPostOperationType,
} from 'src/invoicing/services/operationTypes/OperationTypesApiRequest';

export const operationTypesSlice = createSlice({
  name: 'operationTypes',
  initialState: {
    operationTypeParameters: {
      amount_types: [],
      operation_names: [],
      currency: {},
    },
    operationTypes: [],
    operationType: {},
    operationtypeErrorMessage: '',
    operationTypeParametersStatus: Status.DEFAULT,
    patchOperationTypeStatus: Status.DEFAULT,
    postOperationTypeStatus: Status.DEFAULT,
    getOperationTypeStatus: Status.DEFAULT,
    deleteOperationTypeStatus: Status.DEFAULT,
    getOperationTypesStatus: Status.DEFAULT,
  },
  reducers: {
    resetPostOperationtypeStatus(state) {
      state.postOperationTypeStatus = Status.DEFAULT;
    },
    resetPatchOperationtypeStatus(state) {
      state.patchOperationTypeStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetOperationTypes.pending, (state) => {
        state.getOperationTypesStatus = Status.LOADING;
        state.operationTypes = [];
      })
      .addCase(apiGetOperationTypes.fulfilled, (state, action) => {
        state.operationTypes = action.payload;
        state.getOperationTypesStatus = Status.SUCCESS;
      })
      .addCase(apiGetOperationTypes.rejected, (state) => {
        state.getOperationTypesStatus = Status.FAIL;
      })
      .addCase(apiGetOperationTypeParameters.pending, (state) => {
        state.operationTypeParametersStatus = Status.LOADING;
      })
      .addCase(apiGetOperationTypeParameters.fulfilled, (state, action) => {
        state.operationTypeParameters = action.payload;
        state.operationTypeParametersStatus = Status.SUCCESS;
      })
      .addCase(apiGetOperationTypeParameters.rejected, (state) => {
        state.operationTypeParametersStatus = Status.FAIL;
      })
      .addCase(apiGetOperationType.pending, (state) => {
        state.getOperationTypeStatus = Status.LOADING;
      })
      .addCase(apiGetOperationType.fulfilled, (state, action) => {
        state.operationType = action.payload;
        state.getOperationTypeStatus = Status.SUCCESS;
      })
      .addCase(apiGetOperationType.rejected, (state) => {
        state.getOperationTypeStatus = Status.FAIL;
      })
      .addCase(apiPostOperationType.pending, (state) => {
        state.postOperationTypeStatus = Status.LOADING;
      })
      .addCase(apiPostOperationType.fulfilled, (state) => {
        state.postOperationTypeStatus = Status.SUCCESS;
      })
      .addCase(apiPostOperationType.rejected, (state) => {
        state.postOperationTypeStatus = Status.FAIL;
        state.operationtypeErrorMessage = NEW_OPERATION_TYPE_ERROR_MESSAGE;
      })
      .addCase(apiPatchOperationType.pending, (state) => {
        state.patchOperationTypeStatus = Status.LOADING;
      })
      .addCase(apiPatchOperationType.fulfilled, (state) => {
        state.patchOperationTypeStatus = Status.SUCCESS;
      })
      .addCase(apiPatchOperationType.rejected, (state) => {
        state.patchOperationTypeStatus = Status.FAIL;
        state.operationtypeErrorMessage = UPDATE_OPERATION_TYPE_ERROR_MESSAGE;
      })
      .addCase(apiDeleteOperationType.pending, (state) => {
        state.deleteOperationTypeStatus = Status.LOADING;
      })
      .addCase(apiDeleteOperationType.fulfilled, (state) => {
        state.deleteOperationTypeStatus = Status.SUCCESS;
      })
      .addCase(apiDeleteOperationType.rejected, (state) => {
        state.deleteOperationTypeStatus = Status.FAIL;
      });
  },
});

export const { resetPatchOperationtypeStatus, resetPostOperationtypeStatus } = operationTypesSlice.actions;

export const selectDeleteOperationTypeStatus = (state: any) => state.operationTypes.deleteOperationTypeStatus;
export const selectGetOperationTypeStatus = (state: any) => state.operationTypes.getOperationTypeStatus;
export const selectGetOperationTypesStatus = (state: any) => state.operationTypes.getOperationTypesStatus;
export const selectOperationType = (state: any) => state.operationTypes.operationType;
export const selectOperationTypeErrorMessage = (state: any) => state.operationTypes.operationtypeErrorMessage;
export const selectOperationTypeParameters = (state: any) => state.operationTypes.operationTypeParameters;
export const selectOperationTypeParametersStatus = (state: any) => state.operationTypes.operationTypeParametersStatus;
export const selectOperationTypes = (state: any) => state.operationTypes.operationTypes;
export const selectPatchOperationTypeStatus = (state: any) => state.operationTypes.patchOperationTypeStatus;
export const selectPostOperationTypesStatus = (state: any) => state.operationTypes.postOperationTypeStatus;

export default operationTypesSlice.reducer;
