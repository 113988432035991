import React from 'react';

import {
  AIRCRAFT_MODEL_LOGO, AIRLINE_LOGO_PATH, DUPLICATE_LOGO, EDIT_LOGO, VIEW_LOGO,
} from 'src/constants/picturesPath';
import { ContractStatus } from 'src/constants/status';
import { contractEditTextBasedOnStatus, contractLineText } from 'src/constants/componentTexts/ContractText';
import {
  SERVICES_DUPLICATE_BUTTON_TEXT,
  SERVICES_VIEW_BUTTON_TEXT,
} from 'src/constants/componentTexts/ServiceText';

import { displayPrettyUTCDate } from 'src/utils/logic/format/dateFormat';

import useContract from 'src/invoicing/contract/useContract';

interface Props {
  contract: any;
  setContractAction: Function;
  setIsNewContractOpen: Function;
}

export default function Contract(props: Props) {
  const { contract } = props;

  const {
    duplicateContract,
    editContract,
    getAircraftModelsFontSizebasedOnAircraftModelsLength,
    statusClassName,
    viewContract,
  } = useContract(props);

  return (
    <div className="ContractAppContractsWrapperContract" id={`ContractAppWrapper${contract.id}Id`}>
      <div className="ContractAppContractsWrapperContractContent">
        <div className="ContractAppContractsWrapperContractContentPart">
          <div className="ContractAppContractsWrapperContractContentLeftPartContent">
            <div id={`ContractAppWrapperStatus${contract.id}Id`} className={`ContractAppContractsWrapperContractContentLeftPartContentStatusBubble ${statusClassName}`} />
            <p
              id={`ContractAirportValue${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentLeftPartContentText"
            >
              {contract.airport.label}
            </p>
          </div>
          <div className="ContractAppContractsWrapperContractContentLeftPartAirlineContent">
            <img
              src={`${AIRLINE_LOGO_PATH}${contract.airline.code}.png`}
              alt={`${AIRLINE_LOGO_PATH}${contract.airline.code}.png`}
              id={`ContractAirlineValue${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentLeftPartContentAirlineImg"
            />
          </div>
          <div className="ContractAppContractsWrapperContractContentAircraftModelsWrapper">
            <img
              className="ContractAppContractsWrapperContractContentLeftPartContentImg"
              src={AIRCRAFT_MODEL_LOGO}
              alt={AIRCRAFT_MODEL_LOGO}
            />
            <div className="ContractAppContractsWrapperContractContentAircraftModels">
              {contract.aircraft_models.map((aircraftModel: { id: number, label: string }) => (
                <p
                  key={aircraftModel.id}
                  id={`ContractAircraftType${aircraftModel.id}Value${contract.id}Id`}
                  className={`ContractAppContractsWrapperContractContentAircraftModelText
                    ${getAircraftModelsFontSizebasedOnAircraftModelsLength(contract.aircraft_models)}`}
                >
                  {aircraftModel.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="ContractAppContractsWrapperContractMiddleContentPart">
          <div className="ContractAppContractsWrapperContractContentMiddlePartContent">
            <p
              id={`ContractStartDateTitle${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentMiddlePartContentText"
            >
              {contractLineText.startDate}
            </p>
            <p
              id={`ContractStartDateValue${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentMiddlePartContentDateText"
            >
              {contract?.start_datetime && displayPrettyUTCDate(contract.start_datetime)}
            </p>
          </div>
          <div className="ContractAppContractsWrapperContractContentMiddlePartRightContent">
            <p
              id={`ContractEndDateTitle${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentMiddlePartContentText"
            >
              {contract?.end_datetime && contractLineText.endDate}
            </p>
            <p
              id={`ContractEndDateValue${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentMiddlePartContentDateText"
            >
              {contract?.end_datetime && displayPrettyUTCDate(contract.end_datetime)}
            </p>
          </div>
        </div>
        <div className="ContractAppContractsWrapperContractRightContentPart">
          <div className="ContractAppContractsWrapperContractContentRightViewPartContent">
            <img
              className="ContractAppContractsWrapperContractContentRightPartContentViewImg"
              src={VIEW_LOGO}
              alt={VIEW_LOGO}
              aria-hidden="true"
              onClick={viewContract}
            />
            <p
              id={`ContractViewValue${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentRightPartContentViewText"
              onClick={viewContract}
              aria-hidden="true"
            >
              {SERVICES_VIEW_BUTTON_TEXT}
            </p>
          </div>
          <div className="ContractAppContractsWrapperContractContentRightEditPartContent">
            {contract.status !== ContractStatus.PAST && (
              <>
                <img
                  className="ContractAppContractsWrapperContractContentRightPartContentEditImg"
                  src={EDIT_LOGO}
                  alt={EDIT_LOGO}
                  onClick={editContract}
                  aria-hidden="true"
                />
                <p
                  onClick={editContract}
                  aria-hidden="true"
                  id={`ContractEditValue${contract.id}Id`}
                  className="ContractAppContractsWrapperContractContentRightPartContentEditText"
                >
                  {contractEditTextBasedOnStatus[contract.status]}
                </p>
              </>
            )}
          </div>
          <div className="ContractAppContractsWrapperContractContentRightDuplicatePartContent">
            <img
              className="ContractAppContractsWrapperContractContentRightPartContentDuplicateImg"
              src={DUPLICATE_LOGO}
              alt={DUPLICATE_LOGO}
              onClick={duplicateContract}
              aria-hidden="true"
            />
            <p
              onClick={duplicateContract}
              aria-hidden="true"
              id={`ContractDuplicateValue${contract.id}Id`}
              className="ContractAppContractsWrapperContractContentRightPartContentDuplicateText "
            >
              {SERVICES_DUPLICATE_BUTTON_TEXT}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
