import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AdditionalServiceSentInterface,
  AdditionalServiceReceivedInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import {
  apiPatchAdditionalService,
  apiPostAdditionalService,
} from 'src/invoicing/services/additionalServices/AdditionalServicesApiRequest';
import {
  createPatchAdditionalServiceBody,
  getDefaultAdditionalService,
} from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';
import {
  resetPatchAdditionalServiceStatus,
  resetPostAdditionalServiceStatus,
  selectAdditionalServiceErrorMessage,
  selectPatchAdditionalServiceStatus,
  selectPostAdditionalServiceStatus,
} from 'src/invoicing/services/additionalServices/AdditionalServicesSlice';

import { isFixedPriceFilled, isVariablePriceFilled } from 'src/invoicing/services/cancellationRules/CancellationRulesLogic';
import { displayErrorIfSubmitFailed } from 'src/invoicing/services/ServicesLogic';
import { EDIT_CONTRACT_FORM_SERVICES_SEND_ADDITIONAL_SERVICE_FORM_BUTTON, NEW_CONTRACT_FORM_SERVICES_SEND_ADDITIONAL_SERVICE_FORM_BUTTON } from 'src/constants/componentTexts/AdditionalServicesText';

interface Props {
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  contractId: number | null;
}

export default function useAdditionalServiceForm(props: Props) {
  const { additionalServiceFilled = null, contractId } = props;

  const dispatch = useDispatch();

  const additionalServiceResponseErrorMessage: string = useSelector(selectAdditionalServiceErrorMessage);
  const patchAdditionalServiceStatus: string = useSelector(selectPatchAdditionalServiceStatus);
  const postAdditionalServiceStatus: string = useSelector(selectPostAdditionalServiceStatus);

  const [additionalService, setAdditionalService] = useState<AdditionalServiceSentInterface>(getDefaultAdditionalService(additionalServiceFilled));
  const [additionalServicePrice, setAddtionalServicePrice] = useState<any>(additionalService.price);
  const [additionalServiceTimingDifference, setAdditionalServiceTimingDifference] = useState<any>(additionalService.timings_difference);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isFixedPriceChecked, setIsFixedPriceChecked] = useState<boolean>(isFixedPriceFilled(additionalServiceFilled));
  const [isVariablePriceChecked, setIsVariablePriceChecked] = useState<boolean>(isVariablePriceFilled(additionalServiceFilled));

  useEffect(() => {
    dispatch(resetPatchAdditionalServiceStatus());
    dispatch(resetPostAdditionalServiceStatus());
  }, [postAdditionalServiceStatus, patchAdditionalServiceStatus]);

  useEffect(() => {
    displayErrorIfSubmitFailed(postAdditionalServiceStatus, patchAdditionalServiceStatus, setIsErrorModalOpen, isErrorModalOpen);
  }, [additionalServiceResponseErrorMessage, postAdditionalServiceStatus, patchAdditionalServiceStatus]);

  useEffect(() => {
    if (!additionalServiceFilled) {
      setAddtionalServicePrice(null);
      setAdditionalService({
        ...additionalService,
        price: null,
        additional_information_datasource: null,
        task_datasource: null,
      });
      setIsFixedPriceChecked(false);
      setAdditionalServiceTimingDifference(null);
      setIsVariablePriceChecked(false);
    }
  }, [additionalService.data_type_to_mesure === null]);

  const submitAdditionalService = (event: FormEvent) => {
    event.preventDefault();

    if (additionalServiceFilled) {
      dispatch(apiPatchAdditionalService(createPatchAdditionalServiceBody(additionalService, contractId, additionalServiceFilled.id, additionalServicePrice, additionalServiceTimingDifference)));
    } else {
      dispatch(apiPostAdditionalService({
        ...additionalService,
        contract: contractId,
        price: additionalServicePrice,
        timings_difference: additionalServiceTimingDifference,
      }));
    }
  };

  const getSendAdditionalServiceTextButtonBasedOnUserAction = () => (
    additionalServiceFilled ? EDIT_CONTRACT_FORM_SERVICES_SEND_ADDITIONAL_SERVICE_FORM_BUTTON : NEW_CONTRACT_FORM_SERVICES_SEND_ADDITIONAL_SERVICE_FORM_BUTTON
  );

  return {
    additionalService,
    additionalServicePrice,
    additionalServiceResponseErrorMessage,
    additionalServiceTimingDifference,
    getSendAdditionalServiceTextButtonBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    setAdditionalService,
    setAddtionalServicePrice,
    setAdditionalServiceTimingDifference,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    submitAdditionalService,
  };
}
