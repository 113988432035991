import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetContractCancellationRuleParameters } from 'src/invoicing/services/cancellationRules/CancellationRulesApiRequest';
import { CancellationRuleParametersInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';
import { selectCancellationRuleParameters, selectCancellationRuleParametersStatus } from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';

export default function useCancellationRuleParameters(contractId: number | null) {
  const dispatch = useDispatch();

  const cancellationRuleParameters: CancellationRuleParametersInterface = useSelector(selectCancellationRuleParameters);
  const cancellationRuleParametersStatus: string = useSelector(selectCancellationRuleParametersStatus);

  useEffect(() => {
    if (contractId) {
      dispatch(apiGetContractCancellationRuleParameters(contractId));
    }
  }, []);

  return {
    cancellationRuleParameters,
    cancellationRuleParametersStatus,
  };
}
