import { useEffect, useState } from 'react';

import { deepCopy } from 'src/utils/logic/copy';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';

interface Props {
  customServices: CustomService[];
  invoice: any;
  setCustomServices: Function;
}

export default function useInvoicedServices(props: Props) {
  const { customServices, invoice, setCustomServices } = props;

  const [previousLengthCustomService, setPreviousLengthCustomService] = useState<number>(0);

  useEffect(() => {}, [customServices.length !== previousLengthCustomService]);

  const addCustomService = () => {
    const customServiceCopy = deepCopy(customServices);

    customServiceCopy.push({
      label: null, amount: null, description: null,
    });
    setPreviousLengthCustomService(customServiceCopy.length);
    setCustomServices(customServiceCopy);
  };

  const hasNoInvoiceServiceAndCustomService = () => (
    (invoice.services.length === 0 && customServices.length === 0)
  );

  return {
    addCustomService,
    hasNoInvoiceServiceAndCustomService,
  };
}
