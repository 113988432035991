import React from 'react';
import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

interface Props {
  additionalClassname?: string;
  invoiceId: string;
  rowId: string;
  value: string | undefined | null;
}

export default function InvoiceRow(props: Props) {
  const {
    additionalClassname = EMPTY_VALUE, invoiceId, rowId, value,
  } = props;

  return (
    <td id={`${rowId}${invoiceId}Id`} className={`InvoicesTableText ${additionalClassname}`}>
      {value}
    </td>
  );
}
