import React from 'react';

import AdditionalServiceFormCarateristics from 'src/invoicing/services/additionalServices/form/AdditionalServiceFormCaracteristics';
import AdditionalServiceFormPrice from 'src/invoicing/services/additionalServices/form/AdditionalServiceFormPrice';
import AdditionalServiceFormPriceChoices from 'src/invoicing/services/additionalServices/form/AdditionalServiceFormPriceChoices';
import ErrorModal from 'src/uiComponents/modal/ErrorModal';
import ServicesFormButtons from 'src/invoicing/services/ServicesFormButtons';

import {
  AdditionalServiceParametersInterface,
  AdditionalServiceReceivedInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import useAdditionalServiceForm from 'src/invoicing/services/additionalServices/useAdditionalServiceForm';

import {
  NEW_CONTRACT_FORM_SERVICES_CANCEL_ADDITIONAL_SERVICE_FORM_BUTTON,
} from 'src/constants/componentTexts/AdditionalServicesText';

interface Props {
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: AdditionalServiceParametersInterface;
  contractId: number | null;
  readOnly?: boolean;
  setIsAdditionalSubViewOpen: Function;
}

export default function AdditionalServiceForm(props: Props) {
  const {
    additionalServiceFilled = null,
    additionalServiceParameters,
    readOnly = false,
    setIsAdditionalSubViewOpen,
  } = props;

  const {
    additionalService,
    additionalServicePrice,
    additionalServiceResponseErrorMessage,
    additionalServiceTimingDifference,
    getSendAdditionalServiceTextButtonBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    setAdditionalService,
    setAddtionalServicePrice,
    setAdditionalServiceTimingDifference,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    submitAdditionalService,
  } = useAdditionalServiceForm(props);

  return (
    <>
      <form className="ServicesForm" onSubmit={(event) => submitAdditionalService(event)}>
        <AdditionalServiceFormCarateristics
          additionalService={additionalService}
          additionalServiceFilled={additionalServiceFilled}
          additionalServiceParameters={additionalServiceParameters}
          additionalServiceTimingDifference={additionalServiceTimingDifference}
          readOnly={readOnly}
          setAdditionalService={setAdditionalService}
          setAdditionalServiceTimingDifference={setAdditionalServiceTimingDifference}
        />
        {additionalService.data_type_to_mesure && (
          <>
            <AdditionalServiceFormPriceChoices
              isFixedPriceChecked={isFixedPriceChecked}
              isVariablePriceChecked={isVariablePriceChecked}
              readOnly={readOnly}
              setIsFixedPriceChecked={setIsFixedPriceChecked}
              setIsVariablePriceChecked={setIsVariablePriceChecked}
            />
            <AdditionalServiceFormPrice
              additionalService={additionalService}
              additionalServiceFilled={additionalServiceFilled}
              additionalServiceParameters={additionalServiceParameters}
              additionalServicePrice={additionalServicePrice}
              isFixedPriceChecked={isFixedPriceChecked}
              isVariablePriceChecked={isVariablePriceChecked}
              readOnly={readOnly}
              setAddtionalServicePrice={setAddtionalServicePrice}
            />
          </>
        )}
        <ServicesFormButtons
          cancelButtonText={NEW_CONTRACT_FORM_SERVICES_CANCEL_ADDITIONAL_SERVICE_FORM_BUTTON}
          sendButtonText={getSendAdditionalServiceTextButtonBasedOnUserAction()}
          setIsServicesSubViewOpen={setIsAdditionalSubViewOpen}
          isDisabled={readOnly}
        />
      </form>
      {isErrorModalOpen && (
        <ErrorModal
          setIsErrorModalOpen={setIsErrorModalOpen}
          errorMessage={additionalServiceResponseErrorMessage}
        />
      )}
    </>
  );
}
