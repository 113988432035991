import React, { MouseEventHandler } from 'react';

import { CODE_TYPE, LABEL_TYPE, VALIDATE_INVOICE_TEXT } from 'src/constants/componentTexts/InvoiceDetailText';

import InvoicedTurnaroundDescription from 'src/invoicing/invoiceDetail/InvoicedTurnaroundDescription';
import ToggleOnOff from 'src/uiComponents/toggle/ToggleOnOff';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';
import useInvoicedTurnaround from 'src/invoicing/invoiceDetail/useInvoicedTurnaround';

interface Props {
  customServices: CustomService[];
  invoice: any;
  invoiceValidated: boolean;
  servicesDescription: any;
  toggleValidateInvoice: MouseEventHandler<HTMLDivElement>;
}

export default function InvoicedTurnaround(props: Props) {
  const {
    customServices, invoice, invoiceValidated, servicesDescription, toggleValidateInvoice,
  } = props;

  const {
    canDisplayToggleForInvoiceStatus,
    changeInvoiceStatusIfInvoiceValidatedButServiceStatusChange,
    getInvoicedTurnaroundDate,
    getInvoicedTurnaroundFlight,
    getInvoicedTurnaroundFlightPath,
  } = useInvoicedTurnaround(props);

  return (
    <div className="InvoicedTurnaround">
      <div className="InvoicedTurnaroundUpperPart">
        <p
          className="InvoicedTurnaroundDates"
          id={`InvoicedTurnaroundDates${invoice.id}`}
        >
          {getInvoicedTurnaroundDate()}
        </p>
        <p
          className={`InvoicedStatusText InvoicedStatusText${changeInvoiceStatusIfInvoiceValidatedButServiceStatusChange(CODE_TYPE)}`}
          id={`InvoicedTurnaroundStatus${changeInvoiceStatusIfInvoiceValidatedButServiceStatusChange(CODE_TYPE)}${invoice.id}`}
        >
          {changeInvoiceStatusIfInvoiceValidatedButServiceStatusChange(LABEL_TYPE)}
        </p>
        {canDisplayToggleForInvoiceStatus() && (
          <ToggleOnOff
            id={invoice.id}
            idPrefix="invoiceStatus"
            onToggleClick={toggleValidateInvoice}
            toggleText={VALIDATE_INVOICE_TEXT}
            toggleValue={invoiceValidated}
          />
        )}
      </div>
      <InvoicedTurnaroundDescription
        customServices={customServices}
        getInvoicedTurnaroundFlight={getInvoicedTurnaroundFlight}
        getInvoicedTurnaroundFlightPath={getInvoicedTurnaroundFlightPath}
        invoice={invoice}
        servicesDescription={servicesDescription}
      />
    </div>
  );
}
