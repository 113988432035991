import React, { useState } from 'react';

import { HIDDEN_PASSWORD_LOGO, VIEW_PASSWORD_LOGO } from 'src/constants/picturesPath';

interface Props {
  password: string;
  setPassword: Function;
}

export default function PasswordInput(props: Props) {
  const { password, setPassword } = props;

  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const togglePassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const renderPasswordLogoBasedOnIsPasswordShown = () => (
    isPasswordShown ? HIDDEN_PASSWORD_LOGO : VIEW_PASSWORD_LOGO
  );

  return (
    <div>
      <input
        id="LoginFormPasswordInputId"
        className="DefaultFontSize LoginFormPasswordInput"
        type={isPasswordShown ? 'text' : 'password'}
        name="password"
        placeholder="Password"
        required
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <img
        id="LoginFormPasswordImgId"
        className="LoginFormPasswordInputImg"
        src={renderPasswordLogoBasedOnIsPasswordShown()}
        alt={renderPasswordLogoBasedOnIsPasswordShown()}
        aria-hidden="true"
        onClick={togglePassword}
      />
    </div>
  );
}
