import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { ABOUT_TARMAC_LOGO, LOGOUT_LOGO, TARMAC_LOGO } from 'src/constants/picturesPath';
import { TARMAC_PRIVACY_POLICY_URL } from 'src/constants/url';

import NavBarLink from 'src/invoicing/navBar/NavBarLink';

import { disconnectUser } from 'src/invoicing/InvoicingLogic';
import {
  CONTRACTS,
  HOME,
  INVOICES,
  LOGIN,
  routesNameToPath,
} from 'src/invoicing/app/Routes';

import 'src/invoicing/navBar/NavBar.css';

export default function NavBar() {
  const navigate: NavigateFunction = useNavigate();

  const logout = () => {
    disconnectUser();
    navigate(routesNameToPath[LOGIN]);
  };

  return (
    <div className="NavBarWrapper">
      <img
        src={TARMAC_LOGO}
        alt="Tarmac Logo"
        className="NavBarLogo"
        aria-hidden="true"
        onClick={() => navigate(routesNameToPath[HOME])}
      />
      <div className="NavBarRouteWrapper">
        <NavBarLink routeName={CONTRACTS} />
        <NavBarLink routeName={INVOICES} />
      </div>
      <a href={TARMAC_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
        <img
          src={ABOUT_TARMAC_LOGO}
          className="NavbarTermPoliciesButton"
          aria-hidden="true"
          alt="Terms and Policies button"
        />
      </a>
      <img
        src={LOGOUT_LOGO}
        className="NavBarLogoutButton"
        aria-hidden="true"
        alt="Logout button"
        onClick={() => logout()}
      />
    </div>
  );
}
