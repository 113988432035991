export const CONTRACTS_TITLE = 'CONTRACTS';
export const NEW_CONTRACT_FORM_CONTRACT_TITLE = 'Contract Scope';
export const NEW_CONTRACT_TITLE = 'New Contract';
export const EDIT_CONTRACT_TITLE = 'Edit Contract';
export const VIEW_CONTRACT_TITLE = 'View Contract';

export const CONTRACT_CANCEL_FORM_BUTTON = 'Cancel';
export const CONTRACT_NEXT_FORM_BUTTON = 'Next';
export const CONTRACT_PREVIOUS_FORM_BUTTON = 'Previous';
export const CONTRACT_SEND_FORM_BUTTON = 'Send';
export const ERROR_MODAL_CLOSE_BUTTON = 'Close';
export const NEW_CONTRACT_BUTTON = 'Add a new contract';
export const NEW_CONTRACT_LOGO_BUTTON = '+';

export const ERROR_MODAL_CONTRACT_ERROR_MESSAGE = 'You need to fill all required fields with accurate value';
export const NO_CONTRACT_MESSAGE = 'No Contract is set up yet';

export const CONTRACT_STEP_NUMBER = 1;

export const NEW_CONTRACT_FORM_INPUT_AIRCRAFT_TYPE_LABEL = 'Aircraft Type (ICAO Code)*';
export const NEW_CONTRACT_FORM_INPUT_AIRLINE_LABEL = 'Airline*';
export const NEW_CONTRACT_FORM_INPUT_AIRPORT_LABEL = 'Airport*';
export const NEW_CONTRACT_FORM_INPUT_ARRIVAL_DEPARTURE_SEPARATION_LIMIT_LABEL = 'Arrival / Departure Separation Limit (hh:mm)';
export const NEW_CONTRACT_FORM_INPUT_BASE_PRICE_LABEL = 'Base Price*';
export const NEW_CONTRACT_FORM_INPUT_CURRENCY_LABEL = 'Currency*';
export const NEW_CONTRACT_FORM_INPUT_CUSTOMER_LABEL = 'Customer*';
export const NEW_CONTRACT_FORM_INPUT_ENDING_DATE_LABEL = 'Ending Date';
export const NEW_CONTRACT_FORM_INPUT_MY_COMPANY_LABEL = 'My Company*';
export const NEW_CONTRACT_FORM_INPUT_PROVIDER_LABEL = 'Provider*';
export const NEW_CONTRACT_FORM_INPUT_STARTING_DATE_LABEL = 'Starting Date*';

export const newContractInputLabel = {
  aircraftType: NEW_CONTRACT_FORM_INPUT_AIRCRAFT_TYPE_LABEL,
  airline: NEW_CONTRACT_FORM_INPUT_AIRLINE_LABEL,
  airport: NEW_CONTRACT_FORM_INPUT_AIRPORT_LABEL,
  basePrice: NEW_CONTRACT_FORM_INPUT_BASE_PRICE_LABEL,
  currency: NEW_CONTRACT_FORM_INPUT_CURRENCY_LABEL,
  customer: NEW_CONTRACT_FORM_INPUT_CUSTOMER_LABEL,
  endingDate: NEW_CONTRACT_FORM_INPUT_ENDING_DATE_LABEL,
  myCompany: NEW_CONTRACT_FORM_INPUT_MY_COMPANY_LABEL,
  provider: NEW_CONTRACT_FORM_INPUT_PROVIDER_LABEL,
  startingDate: NEW_CONTRACT_FORM_INPUT_STARTING_DATE_LABEL,
  separationLimit: NEW_CONTRACT_FORM_INPUT_ARRIVAL_DEPARTURE_SEPARATION_LIMIT_LABEL,
};

export const contractLineText = {
  startDate: 'From',
  endDate: 'To',
};

export const contractStatusClassNames = {
  PAST: 'ContractPastStatus',
  'IN PROGRESS': 'ContractInProgressStatus',
  FUTURE: 'ContractFutureStatus',
};

export const contractEditTextBasedOnStatus: any = {
  'IN PROGRESS': 'Change End Date',
  FUTURE: 'Edit',
};
