import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  invoicingDeleteApiRequestAsyncThunk,
  invoicingGetApiRequestAsyncThunk,
  invoicingPatchApiRequestAsyncThunk,
  invoicingPostApiRequestAsyncThunk,
} from 'src/invoicing/api/invoicingCustomApiRequestLogic';

export const apiGetContractCancellationRules = createAsyncThunk('cancellationRules/apiGetContractCancellationRules', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/cancellation_rules` })
));

export const apiGetContractCancellationRuleParameters = createAsyncThunk('cancellationRules/apiGetContractCancellationRuleParameters', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/cancellation_rule_parameters` })
));

export const apiGetCancellationRule = createAsyncThunk('cancellationRules/apiGetCancellationRule', async (cancellationRuleId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/cancellation_rule/${cancellationRuleId}` })
));

export const apiPostCancellationRule = createAsyncThunk('cancellationRules/apiPostCancellationRule', async (body: any, { dispatch, getState, rejectWithValue }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/cancellation_rule', body })
));

export const apiPatchCancellationRule = createAsyncThunk('cancellationRules/apiPatchCancellationRule', async (body: { cancellationRuleBody: any, cancellationRuleId: number }, { dispatch, getState, rejectWithValue }) => (
  invoicingPatchApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/cancellation_rule/${body.cancellationRuleId}`, body: body.cancellationRuleBody })
));

export const apiDeleteCancellationRule = createAsyncThunk('cancellationRules/apiDeleteCancellationRule', async (cancellationRuleId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingDeleteApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/cancellation_rule/${cancellationRuleId}` })
));
