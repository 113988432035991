import React from 'react';

import AmountFilter from 'src/invoicing/invoices/filters/amountFilter/AmountFilter';
import PeriodFilter from 'src/invoicing/invoices/filters/periodFilter/PeriodFilter';
import Searchbar from 'src/invoicing/invoices/filters/searchBar/Searchbar';

import AirportFilter from 'src/uiComponents/filters/AirportFilter';
import CustomerFilter from 'src/uiComponents/filters/CustomerFilter';
import ProviderFilter from 'src/uiComponents/filters/ProviderFilter';

import { getAllUserAirports } from 'src/invoicing/contract/ContractLogic';

import { InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';
import useInvoicesFilters from 'src/invoicing/invoices/filters/useInvoicesFilters';

import 'src/invoicing/invoices/filters/InvoicesFilters.css';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import Loader from 'src/uiComponents/loader/Loader';

interface Props {
  dateFilters: any;
  setDateFilters: Function;
  invoicesFilters: InvoicesFiltersModel;
  setInvoicesFilters: Function;
}

export default function InvoicesFilters(props: Props) {
  const {
    dateFilters,
    setDateFilters,
    invoicesFilters,
    setInvoicesFilters,
  } = props;

  const { dropDownValues, getInvoicesFilterParametersStatus, invoicesFiltersParameters } = useInvoicesFilters(props);

  return (
    <div className="InvoicesFiltersContainer">
      {shouldLoaderBeDisplayed([getInvoicesFilterParametersStatus]) ? (
        <Loader className="InvoiceFilterParametersLoader" />
      ) : (
        <>
          <Searchbar setInvoicesFilters={setInvoicesFilters} />
          <div className="InvoicesFiltersDropDownContainer">
            <PeriodFilter
              dateFilters={dateFilters}
              dropDownValues={dropDownValues}
              setDateFilters={setDateFilters}
            />
            <AmountFilter
              dropDownValues={dropDownValues}
              invoicesFilters={invoicesFilters}
              setInvoicesFilters={setInvoicesFilters}
            />
            <AirportFilter
              filters={invoicesFilters}
              setFilters={setInvoicesFilters}
              airports={getAllUserAirports(invoicesFiltersParameters)}
            />
            <CustomerFilter
              filters={invoicesFilters}
              setFilters={setInvoicesFilters}
              customers={invoicesFiltersParameters.customers}
            />
            <ProviderFilter
              filters={invoicesFilters}
              setFilters={setInvoicesFilters}
              providers={invoicesFiltersParameters.customers}
            />
          </div>
        </>
      )}
    </div>
  );
}
