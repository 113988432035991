import React from 'react';

import {
  ADD_SERVICE_ADD_INFO_TOGGLE_VALUE,
  ADD_SERVICE_TASK_TOGGLE_VALUE,
} from 'src/constants/componentTexts/AdditionalServicesText';

interface Props {
  isAddInfoChecked: boolean;
  isTaskChecked: boolean;
  readOnly?: boolean;
  setIsAddInfoChecked: Function;
  setIsTaskChecked: Function;
  togglePartId: string;
}

export default function TaskAddInfoToggle(props: Props) {
  const {
    isAddInfoChecked,
    isTaskChecked,
    readOnly,
    setIsAddInfoChecked,
    setIsTaskChecked,
    togglePartId,
  } = props;

  const onTaskClick = () => {
    setIsTaskChecked(true);
    setIsAddInfoChecked(false);
  };

  const onAddInfoClick = () => {
    setIsTaskChecked(false);
    setIsAddInfoChecked(true);
  };

  return (
    <div className="DifferenceBetweenTwoTimingsToggle">
      <input
        type="radio"
        id={`task${togglePartId}ToggleRadioInputId`}
        name={`toggle${togglePartId}`}
        value={ADD_SERVICE_TASK_TOGGLE_VALUE}
        readOnly
        checked={isTaskChecked === true}
      />
      <label
        id={`task${togglePartId}ToggleLabelId`}
        className="radio-button"
        aria-hidden="true"
        onClick={readOnly ? () => {} : onTaskClick}
        htmlFor={`task${togglePartId}ToggleRadioInputId`}
      >
        {ADD_SERVICE_TASK_TOGGLE_VALUE}
      </label>
      <input
        type="radio"
        id={`addInfo${togglePartId}ToggleRadioInputId`}
        name={`toggle${togglePartId}`}
        value={ADD_SERVICE_ADD_INFO_TOGGLE_VALUE}
        readOnly
        checked={isAddInfoChecked === true}
      />
      <label
        id={`addInfo${togglePartId}ToggleLabelId`}
        className="radio-button"
        aria-hidden="true"
        onClick={readOnly ? () => {} : onAddInfoClick}
        htmlFor={`task${togglePartId}ToggleRadioInputId`}
      >
        {ADD_SERVICE_ADD_INFO_TOGGLE_VALUE}
      </label>
    </div>
  );
}
