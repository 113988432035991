import React, { ChangeEvent, useState } from 'react';

import { addStayUpClassNameIfInputFilled, addTransparentClassNameIfInputEmpty } from 'src/utils/logic/input/setUpClassForInputAnimation';
import { convertHHMMFormatToMinutes } from 'src/utils/logic/converter/dateConvertor';
import { isTimeFormatHHMM } from 'src/utils/logic/regex/regex';

import { getDefaultTimeInputDefaultValue } from 'src/uiComponents/input/InputLogic';
import { TimeInputProps } from 'src/uiComponents/input/InputInterface';

export default function TimeInput(props: TimeInputProps) {
  const {
    formObjectPreviousFilledValue = -1,
    formObject,
    formObjectKey,
    inputLabel,
    isReadOnly = false,
    isRequired,
    setFormObject,
  } = props;

  const [timeInputValue, setTimeInputValue] = useState<string>(getDefaultTimeInputDefaultValue(formObjectPreviousFilledValue) || '');

  const onTimeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTimeInputValue(event.target.value);

    if (event.target.value === '') {
      setFormObject({ ...formObject, [formObjectKey]: null });
      setTimeInputValue('');
    }
  };

  const onTimeBlur = () => {
    if (isTimeFormatHHMM(timeInputValue)) {
      setFormObject({ ...formObject, [formObjectKey]: convertHHMMFormatToMinutes(timeInputValue) });
    } else {
      setFormObject({ ...formObject, [formObjectKey]: null });
      setTimeInputValue('');
    }
  };

  return (
    <div className="InputWrapper">
      <input
        id={`TimeInput${formObjectKey}InputId`}
        value={timeInputValue}
        onChange={onTimeInputChange}
        className={`InputWrapperInput ${addTransparentClassNameIfInputEmpty(timeInputValue)} ${addStayUpClassNameIfInputFilled(timeInputValue)}`}
        type="text"
        required={isRequired}
        title="Required format hh:mm"
        onBlur={onTimeBlur}
        autoComplete="off"
        readOnly={isReadOnly}
      />
      <label
        className={`InputWrapperLabel ${addStayUpClassNameIfInputFilled(timeInputValue)}`}
        id={`TimeInput${formObjectKey}LabelId`}
      >
        {inputLabel}
      </label>
    </div>
  );
}
