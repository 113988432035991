import { customStepsText } from 'src/constants/componentTexts/CustomStepText';
import {
  CREATE_ACTION,
  formattedFixedPriceLabels,
  getServiceErrorMessage,
  PERCENTAGE_OF_BASE_PRICE_SYMBOL,
  UPDATE_ACTION,
} from 'src/constants/componentTexts/ServiceText';

export const NEW_CONTRACT_FORM_SERVICES_CANCEL_DELAY_RULE_FORM_BUTTON = 'Cancel';
export const NEW_CONTRACT_FORM_SERVICES_SEND_DELAY_RULE_FORM_BUTTON = 'Add this Delay rule';
export const EDIT_CONTRACT_FORM_SERVICES_SEND_DELAY_RULE_FORM_BUTTON = 'Edit this Delay rule';

export const NEW_CONTRACT_FORM_DELAYS_RULES_NO_RULE_MESSAGE = 'No delay rule is set up';

export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_STEP_2_CHECKBOX_TITLE = 'Pick one type of delay price:';
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_TITLE = 'New Delay Rule';
export const NEW_CONTRACT_FORM_EDIT_DELAY_RULE_TITLE = 'Edit Delay Rule';
export const NEW_CONTRACT_FORM_VIEW_DELAY_RULE_TITLE = 'View Delay Rule';
export const NEW_CONTRACT_FORM_DELAYS_RULES_TABLE_FIRST_COLUMN_TITLE = 'Causes of Delay';
export const NEW_CONTRACT_FORM_DELAY_RULES_TABLE_SECOND_COLUMN_TITLE = 'Type of prices';
export const NEW_CONTRACT_FORM_DELAYS_RULES_TITLE = 'Delay Rules';

export const DELAY_RULES_STEP_NUMBER = 5;
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_PARAMETER_NUMBER = '1';
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_TYPE_OF_PRICE_NUMBER = '2';
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_TYPE_OF_PRICE_TEXT = 'Price';
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_PRICE_NUMBER = '3';
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_PRICE_TEXT = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_FIXED_PRICE_LABEL = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_VARIABLE_PRICE_LABEL = `Variable Price (${PERCENTAGE_OF_BASE_PRICE_SYMBOL})`;
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE__PRICE_LABEL = 'Price';
export const NEW_CONTRACT_FORM_NEW_DELAY_RULE_CAUSE_OF_DELAY_LABEL = 'Cause of Delay';

export const DELAY_RULE_NAME = 'delay rule';

export const NEW_DELAY_RULE_ERROR_MESSAGE = getServiceErrorMessage(CREATE_ACTION, DELAY_RULE_NAME);
export const UPDATE_DELAY_RULE_ERROR_MESSAGE = getServiceErrorMessage(UPDATE_ACTION, DELAY_RULE_NAME);

export const delayRuleFormText = {
  causeOfDelay: NEW_CONTRACT_FORM_NEW_DELAY_RULE_CAUSE_OF_DELAY_LABEL,
  fixedPrice: NEW_CONTRACT_FORM_NEW_DELAY_RULE_FIXED_PRICE_LABEL,
  parameterNumber: NEW_CONTRACT_FORM_NEW_DELAY_RULE_PARAMETER_NUMBER,
  typeOfPriceNumber: NEW_CONTRACT_FORM_NEW_DELAY_RULE_TYPE_OF_PRICE_NUMBER,
  typeOfPriceText: NEW_CONTRACT_FORM_NEW_DELAY_RULE_TYPE_OF_PRICE_TEXT,
  variablePrice: NEW_CONTRACT_FORM_NEW_DELAY_RULE_VARIABLE_PRICE_LABEL,
};

export const delayRuleFixedPriceText = {
  startWordText: 'For',
  turnaround: ' a turnaround',
  arrivalOnly: ' an arrival only',
  departureOnly: ' a departure only',
  subtitleItalicText: 'The delay penalties are based on the arrival delay of the flight, calculated based on STA - ATA',
  subtitleItalicBoldText: '',
  subtitleListMainText: ', the delay time would be calculated with the time difference between the ',
  subtitleBoldTurnaroundText: 'STA and the ATA of the arriving flight',
  subtitleBoldArrivalOnlyText: 'STA and the ATA of the arriving flight',
  subtitleDepartureSpecialText: ', there is no delay rules applied for these flights',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceNumber: NEW_CONTRACT_FORM_NEW_DELAY_RULE_PRICE_NUMBER,
  priceText: NEW_CONTRACT_FORM_NEW_DELAY_RULE_PRICE_TEXT,
  price: '',
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} penalties`,
  customStepDescriptionFirstPart: customStepsText.customStepDescriptionFirstPart,
  customStepDescriptionSecondPart: 'after the STA',
  customStepDescriptionAmount: ', the penalties will be ',
  customStepDescriptionPriceAmount: '',
  customStepInputValue: customStepsText.customStepInputValueWithASpaceAtTheEnd,
  customStepSeparatorBetweenTwoValue: customStepsText.customStepAndSeparatorBetweenTwoValue,
};

export const delayRuleVariablePriceText = {
  startWordText: 'For',
  turnaround: ' a turnaround',
  arrivalOnly: ' an arrival only',
  departureOnly: ' a departure only',
  subtitleItalicText: 'The delay penalties are based on the arrival delay of the flight, calculated based on STA - ATA',
  subtitleItalicBoldText: '',
  subtitleListMainText: ', the delay time would be calculated with the time difference between the ',
  subtitleBoldTurnaroundText: 'STA and the ATA of the arriving flight',
  subtitleBoldArrivalOnlyText: 'STA and the ATA of the arriving flight',
  subtitleDepartureSpecialText: ', there is no delay rules applied for these flights',
  subtitleBoldDepartureOnlyText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  priceNumber: NEW_CONTRACT_FORM_NEW_DELAY_RULE_PRICE_NUMBER,
  priceText: 'Variable Price',
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} penalties`,
  customStepDescriptionFirstPart: customStepsText.customStepDescriptionFirstPart,
  customStepDescriptionSecondPart: 'after the STA',
  customStepDescriptionAmount: ', the penalties will be ',
  customStepDescriptionPriceAmount: ' of the base price for the category of flights concerned (complete turnaround, arrival only)',
  variablePriceMoney: '%',
  customStepInputValue: customStepsText.customStepInputValueWithASpaceAtTheEnd,
  customStepSeparatorBetweenTwoValue: customStepsText.customStepAndSeparatorBetweenTwoValue,
};
