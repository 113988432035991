import React from 'react';

import { delayRuleFormText } from 'src/constants/componentTexts/DelayRulesText';

import ListInput from 'src/uiComponents/input/ListInput';

import { DelayRuleFilledInterface, DelayRuleSentInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';

interface Props {
  delayRule: DelayRuleSentInterface;
  delayRuleFilled?: DelayRuleFilledInterface | null;
  delayRuleParameters: any;
  readOnly: boolean;
  setDelayRule: Function;
}

export default function DelayRuleFormCaracteristic(props: Props) {
  const {
    delayRule,
    delayRuleFilled,
    delayRuleParameters,
    readOnly,
    setDelayRule,
  } = props;

  return (
    <div className="ServicesBox">
      <div className="ServicesBoxStepContent">
        <p className="ServicesBoxStepContentNumber">
          {delayRuleFormText.parameterNumber}
        </p>
      </div>
      <div className="ServicesBoxInput">
        <ListInput
          formObjectPreviousFilledValue={delayRuleFilled?.cause_of_delay?.label || ''}
          inputLabel={delayRuleFormText.causeOfDelay}
          isRequired
          optionList={delayRuleParameters.causes_of_delays}
          formObjectKey="cause_of_delay"
          setFormObject={setDelayRule}
          formObject={delayRule}
          isReadOnly={readOnly}
        />
      </div>
    </div>
  );
}
