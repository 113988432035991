import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'src/constants/status';

import { apiGetContracts } from 'src/invoicing/contract/ContractApiRequest';
import { ContractFilters, ContractsObject, defaultContractFilters } from 'src/invoicing/contract/ContractInterface';
import {
  selectContracts,
  selectGetContractsResponseStatus,
} from 'src/invoicing/contract/ContractSlice';
import useFilterContracts from 'src/invoicing/contract/useFilterContracts';

export default function useContracts(isNewContractOpen: boolean) {
  const dispatch = useDispatch();

  const contracts: ContractsObject[] = useSelector(selectContracts);
  const getContractsResponseStatus: string = useSelector(selectGetContractsResponseStatus);

  const [contractsFiltered, setContractsFiltered] = useState<ContractsObject[]>([]);
  const [contractsFilters, setContractsFilters] = useState<ContractFilters>(defaultContractFilters);

  const { getContractsFiltered } = useFilterContracts();

  useEffect(() => {
    setContractsFiltered(getContractsFiltered(contracts, contractsFilters));
  }, [contractsFilters]);

  useEffect(() => { dispatch(apiGetContracts()); }, [isNewContractOpen]);

  useEffect(() => {
    setContractsFiltered(contracts);
  }, [getContractsResponseStatus !== Status.LOADING]);

  return {
    contractsFiltered,
    contractsFilters,
    getContractsResponseStatus,
    setContractsFilters,
  };
}
