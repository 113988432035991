interface Props {
  additionalServiceTimingDifference: any;
  taskDatasource: string;
}

export default function useAddInfoTask(props: Props) {
  const { additionalServiceTimingDifference, taskDatasource } = props;

  const isAddInfoInputDisable = () => (
    additionalServiceTimingDifference && additionalServiceTimingDifference[taskDatasource] === null
  );

  const isAdditionalServiceTimingDifferenceTaskDatasourceExists = () => (
    additionalServiceTimingDifference ? additionalServiceTimingDifference[taskDatasource] : null
  );

  return {
    isAddInfoInputDisable,
    isAdditionalServiceTimingDifferenceTaskDatasourceExists,
  };
}
