import { ChangeEvent } from 'react';
import { TO_CHECK_STATUS, VALIDATED_STATUS } from 'src/constants/componentTexts/InvoiceAppText';

import {
  AMOUNT_KEY, CUSTOM_SERVICE_TYPE, EMPTY_VALUE, LABEL_KEY,
} from 'src/constants/componentTexts/InvoiceDetailText';

import { deepCopy } from 'src/utils/logic/copy';

import { isAmountValidWithMax2Decimals } from 'src/invoicing/invoices/InvoicesLogic';

interface Props {
  keyService: string;
  keySetter: Function;
  service: any;
  serviceIndex: string;
  services: any;
  serviceValidated: boolean;
  setServices: Function;
}

export default function useInvoicedServiceInput(props: Props) {
  const {
    keyService,
    keySetter,
    service,
    serviceIndex,
    services,
    serviceValidated,
    setServices,
  } = props;

  const onChangeWithServiceFinded = (customServicesCopy: any, event: ChangeEvent<HTMLInputElement>, serviceFinded: any) => {
    const indexOfExistingService = customServicesCopy.indexOf(serviceFinded);

    if (event.target.value === EMPTY_VALUE) {
      customServicesCopy[indexOfExistingService][keyService] = EMPTY_VALUE;
    } else if (event.target.value !== EMPTY_VALUE && keyService === AMOUNT_KEY) {
      customServicesCopy[indexOfExistingService][keyService] = isAmountValidWithMax2Decimals(event.target.value);
    } else {
      customServicesCopy[indexOfExistingService][keyService] = event.target.value;
    }
  };

  const onChangeCustomServiceType = (customServicesCopy: any, event: ChangeEvent<HTMLInputElement>) => {
    const serviceFinded = customServicesCopy.find((customService: any) => customService?.id === Number(serviceIndex));

    if (serviceFinded !== undefined) {
      onChangeWithServiceFinded(customServicesCopy, event, serviceFinded);
    } else {
      customServicesCopy.push({
        id: Number(service.id),
        description: service?.description,
        type: service?.type,
        amount: keyService === AMOUNT_KEY ? isAmountValidWithMax2Decimals(event.target.value) : service?.amount,
        label: keyService === LABEL_KEY ? event.target.value : service?.label,
        status: serviceValidated ? VALIDATED_STATUS.code : TO_CHECK_STATUS.code,
      });
    }
  };

  const onChangeOtherServiceType = (customServicesCopy: any, event: ChangeEvent<HTMLInputElement>) => {
    customServicesCopy[serviceIndex][keyService] = null;

    if (event.target.value !== EMPTY_VALUE) {
      if (keyService === AMOUNT_KEY) {
        customServicesCopy[serviceIndex][keyService] = isAmountValidWithMax2Decimals(event.target.value);
      } else {
        customServicesCopy[serviceIndex][keyService] = event.target.value;
      }
    }
  };

  const onCustomServiceInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const customServicesCopy = deepCopy(services);

    if (service?.type === CUSTOM_SERVICE_TYPE) {
      onChangeCustomServiceType(customServicesCopy, event);
    } else {
      onChangeOtherServiceType(customServicesCopy, event);
    }

    if (keyService === AMOUNT_KEY) {
      keySetter(isAmountValidWithMax2Decimals(event.target.value));
    } else {
      keySetter(event.target.value);
    }
    setServices(customServicesCopy);
  };

  return {
    onCustomServiceInputChange,
  };
}
