import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AuthenticationRequired from 'src/invoicing/app/AuthenticationRequired';
import ContractApp from 'src/invoicing/app/ContractApp';
import Home from 'src/invoicing/home/Home';
import InvoiceApp from 'src/invoicing/app/InvoiceApp';
import Invoicing from 'src/invoicing/Invoicing';
import SSOLogin from 'src/invoicing/login/SSOLogin';

import {
  CONTRACTS,
  HOME,
  INVOICES,
  LOGIN,
  ROOT,
  routesNameToPath,
} from 'src/invoicing/app/Routes';

import 'src/invoicing/app/App.css';
import 'src/style/FontStyle.css';

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={routesNameToPath[ROOT]} element={<Invoicing />} />
        <Route path={routesNameToPath[LOGIN]} element={<SSOLogin />} />
        <Route path={routesNameToPath[HOME]} element={<AuthenticationRequired />}>
          <Route path={routesNameToPath[HOME]} element={<Home />} />
        </Route>
        <Route path={routesNameToPath[CONTRACTS]} element={<AuthenticationRequired />}>
          <Route path={routesNameToPath[CONTRACTS]} element={<ContractApp />} />
        </Route>
        <Route path={routesNameToPath[INVOICES]} element={<AuthenticationRequired />}>
          <Route path={routesNameToPath[INVOICES]} element={<InvoiceApp />} />
        </Route>
      </Routes>
    </div>
  );
}
