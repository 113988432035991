import React, { ReactNode } from 'react';

import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import useDropDown from 'src/uiComponents/dropDown/useDropDown';

import 'src/uiComponents/dropDown/DropDown.css';

interface Props {
  title: string;
  dropDownValues: any;
  dropDownValuesKey: string;
  children?: ReactNode
}

export default function DropDown(props: Props) {
  const {
    children,
    dropDownValuesKey,
    title,
  } = props;

  const {
    closeDropDown,
    displayValuePickedBasedOnDropDownValuesKey,
    isDropDownClose,
    openDropDown,
    showContent,
  } = useDropDown(props);

  return (
    <div
      className="dropDownContainer"
      id={`DropDown${dropDownValuesKey}Id`}
      tabIndex={0}
      role="button"
      onFocus={openDropDown}
      onBlur={closeDropDown}
    >
      <h2 className={`dropDownTitle ${isDropDownClose ? 'stayedUp' : EMPTY_VALUE}`}>
        {title}
      </h2>
      {isDropDownClose && (
        <p id={`DropDownValue${dropDownValuesKey}Id`} className="DropDownValue">
          {displayValuePickedBasedOnDropDownValuesKey()}
        </p>
      )}
      {(children && showContent) && (
        <div className="dropDownContent">
          {children}
        </div>
      )}
    </div>
  );
}
