import React from 'react';

import {
  additionalServiceFormText, ADD_SERVICE_EMPTY_VALUE,
} from 'src/constants/componentTexts/AdditionalServicesText';

import ListInput from 'src/uiComponents/input/ListInput';

import {
  AdditionalServiceParametersInterface,
  AdditionalServiceReceivedInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { getPreviousLabelFilled } from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';

interface Props {
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: AdditionalServiceParametersInterface;
  additionalServiceTimingDifference: any;
  readOnly?: boolean;
  setAdditionalServiceTimingDifference: Function;
  taskDatasource: string;
  taskTimingReference: string | null;
  taskTimingReferenceKey: string;
}

export default function Task(props: Props) {
  const {
    additionalServiceFilled,
    additionalServiceParameters,
    additionalServiceTimingDifference,
    readOnly,
    setAdditionalServiceTimingDifference,
    taskDatasource,
    taskTimingReference,
    taskTimingReferenceKey,
  } = props;

  return (
    <>
      <div className="DifferenceBetweenTwoTimingsInput">
        <ListInput
          formObjectPreviousFilledValue={getPreviousLabelFilled(taskDatasource, additionalServiceFilled)}
          inputLabel={additionalServiceFormText.taskDatasource}
          isRequired
          optionList={additionalServiceParameters.task_datasources}
          formObjectKey={taskDatasource}
          setFormObject={setAdditionalServiceTimingDifference}
          formObject={additionalServiceTimingDifference}
          isReadOnly={readOnly}
        />
      </div>
      <div className="DifferenceBetweenTwoTimingsInput">
        <ListInput
          formObjectPreviousFilledValue={taskTimingReference || ADD_SERVICE_EMPTY_VALUE}
          inputLabel={additionalServiceFormText.taskDatasourceTimingReference}
          isRequired
          optionList={additionalServiceParameters.tasks_timings_references}
          formObjectKey={taskTimingReferenceKey}
          setFormObject={setAdditionalServiceTimingDifference}
          formObject={additionalServiceTimingDifference}
          isReadOnly={readOnly}
          optionInputValueField="code"
        />
      </div>
    </>
  );
}
