import { FIXED_AMOUNT_CODE, PERCENTAGE_OF_BASE_PRICE_CODE } from 'src/constants/componentTexts/ServiceText';
import {
  defaultOperationType,
  OperationTypeFilledInterface,
  OperationTypeSentInterface,
  OperationTypeReceivedInterface,
  PriceInterface,
} from 'src/invoicing/services/operationTypes/OperationTypesInterface';

export const getDefaultOperationType = (operationTypeFilled: OperationTypeFilledInterface | null) => (
  operationTypeFilled ? {
    ...defaultOperationType,
    operation_name: operationTypeFilled.operation_name.id,
    price: {
      amount_type: operationTypeFilled.price.amount_type.code,
      amount: operationTypeFilled.price.amount,
    },
  } : defaultOperationType
);

export const isFixedPriceFilled = (operationTypeFilled: OperationTypeFilledInterface | null) => (
  Boolean(operationTypeFilled && operationTypeFilled.price && operationTypeFilled.price.amount_type.code === FIXED_AMOUNT_CODE)
);

export const isVariablePriceFilled = (operationTypeFilled: OperationTypeFilledInterface | null) => (
  Boolean(operationTypeFilled && operationTypeFilled.price && operationTypeFilled.price.amount_type.code === PERCENTAGE_OF_BASE_PRICE_CODE)
);

export const createOperationTypePatchBodyRequest = (operationType: OperationTypeSentInterface, contractId: number | null, operationTypeFilledId: number, operationTypePrice: PriceInterface) => (
  { operationType: { ...operationType, contract: contractId, price: operationTypePrice }, operationTypeId: operationTypeFilledId }
);

export const getPriceBasedOnUserAmountTypePicked = (operationTypeFilled: OperationTypeFilledInterface | null, amountTypeCode: string) => (
  (operationTypeFilled && operationTypeFilled?.price?.amount_type.code === amountTypeCode)
    ? operationTypeFilled.price
    : defaultOperationType.price
);

export const getAmountTypeLabel = (operationType: OperationTypeReceivedInterface) => (
  operationType?.price?.amount_type?.code === FIXED_AMOUNT_CODE ? 'Fixed' : 'Variable'
);
