import React from 'react';

import Loader from 'src/uiComponents/loader/Loader';
import DelayRuleForm from 'src/invoicing/services/delayRules/form/DelayRuleForm';

import { NEW_CONTRACT_FORM_EDIT_DELAY_RULE_TITLE } from 'src/constants/componentTexts/DelayRulesText';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectPatchDelayRuleStatus } from 'src/invoicing/services/delayRules/DelayRulesSlice';
import useDelayRuleParameters from 'src/invoicing/services/delayRules/useDelayRuleParameters';
import useDelayRuleSubmittionRequestStatus from 'src/invoicing/services/delayRules/useDelayRuleSubmitionRequestStatus';
import useGettingDelayRuleSelected from 'src/invoicing/services/delayRules/useGettingDelayRuleSelected';

interface Props {
  contractId: number | null;
  setIsDelaySubViewOpen: Function;
}

export default function EditDelayRule(props: Props) {
  const { contractId, setIsDelaySubViewOpen } = props;

  const { delayRuleParameters, delayRuleParametersStatus } = useDelayRuleParameters(contractId);
  const { delayRule, getDelayRuleStatus } = useGettingDelayRuleSelected();
  useDelayRuleSubmittionRequestStatus(selectPatchDelayRuleStatus, setIsDelaySubViewOpen);

  return (
    shouldLoaderBeDisplayed([getDelayRuleStatus, delayRuleParametersStatus]) ? (
      <Loader className="NewContractAppContentDelayRulesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="EditDelayRuleTitleId">{NEW_CONTRACT_FORM_EDIT_DELAY_RULE_TITLE}</p>
        </div>
        <DelayRuleForm
          contractId={contractId}
          delayRuleFilled={delayRule}
          delayRuleParameters={delayRuleParameters}
          setIsDelaySubViewOpen={setIsDelaySubViewOpen}
        />
      </div>
    )
  );
}
