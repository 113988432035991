import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import {
  selectDelayRule,
  selectGetDelayRuleStatus,
} from 'src/invoicing/services/delayRules/DelayRulesSlice';

export default function useGettingDelayRuleSelected() {
  const delayRule: any = useSelector(selectDelayRule);
  const getDelayRuleStatus: string = useSelector(selectGetDelayRuleStatus);

  useEffect(() => {}, [getDelayRuleStatus !== Status.LOADING]);

  return {
    delayRule,
    getDelayRuleStatus,
  };
}
