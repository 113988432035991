import React from 'react';

import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import InvoiceRow from 'src/invoicing/invoices/InvoiceRow';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

export default function InvoiceAircraftsNumber(props: { invoice: InvoiceModel }) {
  const { invoice } = props;

  const getAircraftNumberIfArrivalOrDepartureExists = (carrierIataCode: string | null, flightNumber: string | null) => (
    carrierIataCode ? `${carrierIataCode} ${flightNumber}` : EMPTY_VALUE
  );

  return (
    <>
      <InvoiceRow
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueArrivalFlight"
        value={getAircraftNumberIfArrivalOrDepartureExists(invoice?.arrival_carrier_iata_code, invoice?.arrival_flight_number)}
      />
      <InvoiceRow
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueDepartureFlight"
        value={getAircraftNumberIfArrivalOrDepartureExists(invoice?.departure_carrier_iata_code, invoice?.departure_flight_number)}
      />
    </>
  );
}
