import { createSlice } from '@reduxjs/toolkit';

import { NEW_CANCELLATION_RULE_ERROR_MESSAGE, UPDATE_CANCELLATION_RULE_ERROR_MESSAGE } from 'src/constants/componentTexts/CancellationRulesText';
import { Status } from 'src/constants/status';

import {
  apiDeleteCancellationRule,
  apiGetCancellationRule,
  apiGetContractCancellationRuleParameters,
  apiGetContractCancellationRules,
  apiPatchCancellationRule,
  apiPostCancellationRule,
} from 'src/invoicing/services/cancellationRules/CancellationRulesApiRequest';

export const cancellationRulesSlice = createSlice({
  name: 'cancellationRules',
  initialState: {
    cancellationRuleParameters: {
      amount_types: [],
      causes_of_cancellation: [],
      currency: {},
      value_types: [],
    },
    cancellationRules: [],
    cancellationRule: {},
    cancellationRuleErrorMessage: '',
    cancellationRuleParametersStatus: Status.DEFAULT,
    patchCancellationRuleStatus: Status.DEFAULT,
    postCancellationRuleStatus: Status.DEFAULT,
    getCancellationRuleStatus: Status.DEFAULT,
    deleteCancellationRuleStatus: Status.DEFAULT,
    getCancellationRulesStatus: Status.DEFAULT,
  },
  reducers: {
    resetPostCancellationRuleStatus(state) {
      state.postCancellationRuleStatus = Status.DEFAULT;
    },
    resetPatchCancellationRuleStatus(state) {
      state.patchCancellationRuleStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetContractCancellationRules.pending, (state) => {
        state.cancellationRules = [];
        state.getCancellationRulesStatus = Status.LOADING;
      })
      .addCase(apiGetContractCancellationRules.fulfilled, (state, action) => {
        state.cancellationRules = action.payload;
        state.getCancellationRulesStatus = Status.SUCCESS;
      })
      .addCase(apiGetContractCancellationRules.rejected, (state) => {
        state.cancellationRules = [];
        state.getCancellationRulesStatus = Status.FAIL;
      })
      .addCase(apiGetContractCancellationRuleParameters.pending, (state) => {
        state.cancellationRuleParametersStatus = Status.LOADING;
      })
      .addCase(apiGetContractCancellationRuleParameters.fulfilled, (state, action) => {
        state.cancellationRuleParameters = action.payload;
        state.cancellationRuleParametersStatus = Status.SUCCESS;
      })
      .addCase(apiGetContractCancellationRuleParameters.rejected, (state) => {
        state.cancellationRuleParametersStatus = Status.FAIL;
      })
      .addCase(apiGetCancellationRule.pending, (state) => {
        state.getCancellationRuleStatus = Status.LOADING;
      })
      .addCase(apiGetCancellationRule.fulfilled, (state, action) => {
        state.getCancellationRuleStatus = Status.SUCCESS;
        state.cancellationRule = action.payload;
      })
      .addCase(apiGetCancellationRule.rejected, (state) => {
        state.getCancellationRuleStatus = Status.FAIL;
      })
      .addCase(apiPostCancellationRule.pending, (state) => {
        state.postCancellationRuleStatus = Status.LOADING;
      })
      .addCase(apiPostCancellationRule.fulfilled, (state) => {
        state.postCancellationRuleStatus = Status.SUCCESS;
      })
      .addCase(apiPostCancellationRule.rejected, (state) => {
        state.postCancellationRuleStatus = Status.FAIL;
        state.cancellationRuleErrorMessage = NEW_CANCELLATION_RULE_ERROR_MESSAGE;
      })
      .addCase(apiPatchCancellationRule.pending, (state) => {
        state.patchCancellationRuleStatus = Status.LOADING;
      })
      .addCase(apiPatchCancellationRule.fulfilled, (state) => {
        state.patchCancellationRuleStatus = Status.SUCCESS;
      })
      .addCase(apiPatchCancellationRule.rejected, (state) => {
        state.patchCancellationRuleStatus = Status.FAIL;
        state.cancellationRuleErrorMessage = UPDATE_CANCELLATION_RULE_ERROR_MESSAGE;
      })
      .addCase(apiDeleteCancellationRule.pending, (state) => {
        state.deleteCancellationRuleStatus = Status.LOADING;
      })
      .addCase(apiDeleteCancellationRule.fulfilled, (state) => {
        state.deleteCancellationRuleStatus = Status.SUCCESS;
      })
      .addCase(apiDeleteCancellationRule.rejected, (state) => {
        state.deleteCancellationRuleStatus = Status.FAIL;
      });
  },
});

export const { resetPatchCancellationRuleStatus, resetPostCancellationRuleStatus } = cancellationRulesSlice.actions;

export const selectCancellationRule = (state: any) => state.cancellationRules.cancellationRule;
export const selectCancellationRuleErrorMessage = (state: any) => state.cancellationRules.cancellationRuleErrorMessage;
export const selectCancellationRuleParameters = (state: any) => state.cancellationRules.cancellationRuleParameters;
export const selectCancellationRuleParametersStatus = (state: any) => state.cancellationRules.cancellationRuleParametersStatus;
export const selectCancellationRules = (state: any) => state.cancellationRules.cancellationRules;
export const selectDeleteCancellationRuleStatus = (state: any) => state.cancellationRules.deleteCancellationRuleStatus;
export const selectGetCancellationRuleStatus = (state: any) => state.cancellationRules.getCancellationRuleStatus;
export const selectGetCancellationRulesStatus = (state: any) => state.cancellationRules.getCancellationRulesStatus;
export const selectPatchCancellationRuleStatus = (state: any) => state.cancellationRules.patchCancellationRuleStatus;
export const selectPostCancellationRuleStatus = (state: any) => state.cancellationRules.postCancellationRuleStatus;

export default cancellationRulesSlice.reducer;
