import { CUSTOM_SERVICE_TYPE } from 'src/constants/componentTexts/InvoiceDetailText';

import { addSpacesToNumber } from 'src/utils/logic/regex/regex';
import { convertDecimalNumberIntoInteger } from 'src/utils/logic/converter/NumberConvertor';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';
import { displayAmountWithTwoDecimalIfDecimal } from 'src/invoicing/invoices/InvoicesLogic';
import { formatDisplayNumberToIntegerIfNothingInTheDecimal } from 'src/invoicing/services/ServicesLogic';

export default function useCalculateInvoiceAmount(customServices: CustomService[], invoice: any, servicesDescription: any) {
  const calculateInvoiceAmountWithCustomServices = () => {
    let customServicesGrouped = customServices;
    let customServicesAmounts: string[] = [];
    let filteredServices = invoice.services;

    const filteredServicesDescription = servicesDescription.filter((filteredServiceDescription: any) => filteredServiceDescription.type === CUSTOM_SERVICE_TYPE);

    filteredServicesDescription.map((service: any) => {
      filteredServices = filteredServices.filter((filteredService: any) => Number(filteredService.id) !== service.id);
      return null;
    });

    customServicesGrouped = customServicesGrouped.concat(filteredServices).concat(filteredServicesDescription);

    if (customServicesGrouped.length > 0) {
      customServicesAmounts = customServicesGrouped.map((service: any) => service.amount);
    }

    const customServicesSum = customServicesAmounts.reduce((sum: number, currentAmount: string) => sum + convertDecimalNumberIntoInteger(currentAmount), 0) / 100;

    return `${addSpacesToNumber(formatDisplayNumberToIntegerIfNothingInTheDecimal(displayAmountWithTwoDecimalIfDecimal(customServicesSum).toString()))} ${invoice.currency_symbol}`;
  };

  return {
    calculateInvoiceAmountWithCustomServices,
  };
}
