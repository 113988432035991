import React from 'react';

import { ADD_ITEM_LOGO } from 'src/constants/picturesPath';
import { NEW_CONTRACT_FORM_OPERATION_TYPES_TITLE } from 'src/constants/componentTexts/OperationTypesText';

import Loader from 'src/uiComponents/loader/Loader';
import NoOperationType from 'src/invoicing/services/operationTypes/NoOperationType';
import OperationTypes from 'src/invoicing/services/operationTypes/OperationTypes';

import {
  areServicesLoading,
  canAServiceBeAdded,
  isApiCallStatusSuccessFullAndHasNoServiceToDisplay,
  isApiCallStatusSuccessFullAndHasServicesToDisplay,
} from 'src/invoicing/services/ServicesLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import useOperationTypesEditor from 'src/invoicing/services/operationTypes/useOperationTypesEditor';

interface Props {
  contractByStepsFilled: boolean[];
  contractId: number | null;
  isOperationTypeSubViewOpen: boolean;
  setIsOperationTypeSubViewOpen: Function;
  setcontractByStepsFilled: Function;
  setOperationTypeAction: Function;
}

export default function OperationTypesEditor(props: Props) {
  const {
    setIsOperationTypeSubViewOpen,
    setOperationTypeAction,
  } = props;

  const {
    contractAction,
    contractStatus,
    operationTypesStatus,
    patchOperationTypeStatus,
    postOperationTypeStatus,
    onNewOperationTypeClick,
    operationTypes,
  } = useOperationTypesEditor(props);

  const handleOperationTypesDisplayBasedOnGetOperationTypesStatus = () => {
    if (shouldLoaderBeDisplayed([operationTypesStatus])) {
      return <Loader className="NewContractAppContentOperationTypesLoader" />;
    }
    if (isApiCallStatusSuccessFullAndHasServicesToDisplay(operationTypesStatus, operationTypes)) {
      return (
        <OperationTypes
          contractAction={contractAction}
          contractStatus={contractStatus}
          operationTypes={operationTypes}
          setIsOperationTypeSubViewOpen={setIsOperationTypeSubViewOpen}
          setOperationTypeAction={setOperationTypeAction}
        />
      );
    }
    if (isApiCallStatusSuccessFullAndHasNoServiceToDisplay(operationTypesStatus, operationTypes)) {
      return <NoOperationType />;
    }
    return null;
  };

  return (
    (areServicesLoading(postOperationTypeStatus, patchOperationTypeStatus)) ? (
      <Loader className="NewContractAppContentOperationTypesLoader" />
    ) : (
      <div className="NewContractAppContentOperationTypesForm">
        <div className="NewContractAppContentOperationTypesFormTitle">
          <p
            id="OperationTypesTitle"
            className="FormTitleFontSize NewContractAppContentOperationTypesFormTitleText"
          >
            {NEW_CONTRACT_FORM_OPERATION_TYPES_TITLE}
          </p>
          {canAServiceBeAdded(contractStatus, contractAction) && (
            <img
              className="NewContractAppContentOperationTypesFormTitleLogo"
              id="OperationTypesAddNewRules"
              src={ADD_ITEM_LOGO}
              alt="AddNewRulesLogo"
              aria-hidden="true"
              onClick={onNewOperationTypeClick}
            />
          )}
        </div>
        {handleOperationTypesDisplayBasedOnGetOperationTypesStatus()}
      </div>
    )
  );
}
