import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';
import { LOCALES } from 'src/constants/componentTexts/InvoiceAppText';

export const convertMinutesToHHMMFormat = (minutes: number | null): string | null => {
  if (!minutes) {
    return null;
  }

  const timeHours: number = Math.floor(minutes / 60);
  const timeMinutes: number = minutes - (timeHours * 60);

  return `${timeHours.toString().padStart(2, '0')}:${timeMinutes.toString().padStart(2, '0')}`;
};

export const convertHHMMFormatToMinutes = (time: string): number | null => {
  const splitTime: string[] = time.split(':');

  if (splitTime.length !== 2) {
    return null;
  }

  return ((Number(splitTime[0]) * 60) + Number(splitTime[1]));
};

export const convertStringToDDMMYYYYUTCDate = (date: string | null, local: string = LOCALES) => (
  new Date(date || EMPTY_VALUE).toLocaleDateString(local, { timeZone: 'UTC' })
);

export const convertDateToUTCYYYYMMDD = (date: Date) => {
  const UTCDate = new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ));

  const UTCDateFormatted = UTCDate.toISOString().split('T');

  return UTCDateFormatted[0];
};

export const convertDateToLocalDateYYYYMMDDFormat = (date: Date) => {
  const utcDate = date.toLocaleDateString('fr-FR');

  return utcDate.split('/').reverse().join('-');
};
