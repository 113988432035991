import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WHITE_SPACE_TEXT_BETWEEN_CURRENCY_AND_AMOUNT } from 'src/constants/componentTexts/InvoiceText';

import { addSpacesToNumber } from 'src/utils/logic/regex/regex';

import { apiGetInvoice } from 'src/invoicing/invoices/InvoicesApiRequest';
import { formatDisplayNumberToIntegerIfNothingInTheDecimal } from 'src/invoicing/services/ServicesLogic';
import { getInvoicesToDownloadBasedOnCheckboxValue, hasInvoicesToDownload } from 'src/invoicing/invoices/InvoicesLogic';
import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';
import { resetPostInvoiceDetailServicesStatus, resetPostInvoiceToValidateStatus } from 'src/invoicing/invoices/InvoicesSlice';

interface Props {
  areInvoicesToDownload: boolean;
  invoice: InvoiceModel;
  invoicesToDownload: InvoiceModel[];
  isInvoiceDetailOpen: boolean,
  setInvoicesToDownload: Function;
  setIsInvoiceDetailOpen: Function;
}

export default function useInvoice(props: Props) {
  const {
    areInvoicesToDownload,
    invoice,
    invoicesToDownload,
    isInvoiceDetailOpen,
    setInvoicesToDownload,
    setIsInvoiceDetailOpen,
  } = props;

  const dispatch = useDispatch();

  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [isInvoiceSelected, setIsInvoiceSelected] = useState<boolean>(false);

  useEffect(() => {
    setCheckboxValue(areInvoicesToDownload);
  }, [areInvoicesToDownload]);

  useEffect(() => {
    if (hasInvoicesToDownload(invoicesToDownload)) {
      setCheckboxValue(false);
    }
  }, [invoicesToDownload]);

  useEffect(() => {
    if (!isInvoiceDetailOpen && isInvoiceSelected) {
      setIsInvoiceSelected(false);
    }
  }, [isInvoiceDetailOpen]);

  const onInvoiceCheckboxChange = () => {
    setCheckboxValue(!checkboxValue);
    setInvoicesToDownload(getInvoicesToDownloadBasedOnCheckboxValue(invoicesToDownload, invoice, checkboxValue));
  };

  const onInvoiceClick = (event: MouseEvent) => {
    const { id } = event.target as HTMLInputElement;
    const checkBoxId = invoice.id;
    if (id === checkBoxId) {
      return;
    }
    setIsInvoiceDetailOpen(true);
    setIsInvoiceSelected(true);
    dispatch(resetPostInvoiceDetailServicesStatus());
    dispatch(resetPostInvoiceToValidateStatus());
    dispatch(apiGetInvoice(Number(invoice.id)));
  };

  const getInvoiceAmountWithCurrency = () => (
    `${addSpacesToNumber(formatDisplayNumberToIntegerIfNothingInTheDecimal(invoice.amount))}${WHITE_SPACE_TEXT_BETWEEN_CURRENCY_AND_AMOUNT}${invoice.currency_symbol}`
  );

  return {
    checkboxValue,
    getInvoiceAmountWithCurrency,
    isInvoiceSelected,
    onInvoiceCheckboxChange,
    onInvoiceClick,
  };
}
