import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { getContractAction } from 'src/invoicing/contract/ContractSlice';

import BaseApp from 'src/invoicing/app/BaseApp';
import Contracts from 'src/invoicing/contract/Contracts';
import ContractAndServicesDuplicatorByStep from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/contractAndServicesDuplicatorByStep/ContractAndServicesDuplicatorByStep';
import ContractAndServicesEditorByStep from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/contractAndServicesEditorByStep/ContractAndServicesEditorByStep';
import ContractAndServicesViewerByStep from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/contractAndServicesViewerByStep/ContractAndServicesViewerByStep';
import ContractAndServicesCreatorByStep from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/contractAndServicesCreatorByStep/ContractAndServicesCreatorByStep';

import 'src/invoicing/app/ContractApp.css';

export default function ContractApp() {
  const dispatch = useDispatch();

  const [contractAction, setContractAction] = useState<string>(ActionStatus.NEW);
  const [isNewContractOpen, setIsNewContractOpen] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(getContractAction(contractAction));
  }, [contractAction]);

  const displayContractActions = () => {
    switch (contractAction) {
      case ActionStatus.DUPLICATE:
        return <ContractAndServicesDuplicatorByStep setIsNewContractOpen={setIsNewContractOpen} />;
      case ActionStatus.EDIT:
        return <ContractAndServicesEditorByStep setIsNewContractOpen={setIsNewContractOpen} />;
      case ActionStatus.VIEW:
        return <ContractAndServicesViewerByStep setIsNewContractOpen={setIsNewContractOpen} />;
      default:
        return <ContractAndServicesCreatorByStep setIsNewContractOpen={setIsNewContractOpen} />;
    }
  };

  return (
    <BaseApp>
      {isNewContractOpen
        ? displayContractActions()
        : (
          <Contracts
            setIsNewContractOpen={setIsNewContractOpen}
            isNewContractOpen={isNewContractOpen}
            setContractAction={setContractAction}
          />
        )}
    </BaseApp>
  );
}
