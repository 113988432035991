import React from 'react';

import { NEW_CONTRACT_FORM_OPERATION_TYPES_NO_RULE_MESSAGE }
  from 'src/constants/componentTexts/OperationTypesText';

import NoService from 'src/invoicing/services/NoService';

export default function NoOperationType() {
  return (
    <NoService
      serviceName="OperationType"
      noServiceMessage={NEW_CONTRACT_FORM_OPERATION_TYPES_NO_RULE_MESSAGE}
    />
  );
}
