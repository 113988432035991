import React from 'react';

import { AddServiceDataTypeToMesure } from 'src/constants/componentTexts/AdditionalServicesText';
import {
  FIXED_AMOUNT_CODE, FIXED_PRICE_TYPE,
} from 'src/constants/componentTexts/ServiceText';

import CustomStepsPrice from 'src/invoicing/services/price/CustomStepsPrice';
import DoublePrice from 'src/invoicing/services/price/DoublePrice';
import SinglePrice from 'src/invoicing/services/price/SinglePrice';

import {
  didArrivalDescriptionNeedToDisplay,
  didCompletedDescriptionNeedToDisplay,
  didDepartureDescriptionNeedToDisplay,
} from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';
import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

interface Props {
  priceSpecification?: string | null;
  service: any;
  serviceCurrency: string;
  serviceFilledPrice?: any;
  serviceAddInfo?: string;
  serviceName: string;
  serviceText: any;
  setService: Function;
  readOnly: boolean;
  valuesTypes?: number;
}

export default function FixedPrice(props: Props) {
  const {
    priceSpecification = EMPTY_VALUE, serviceAddInfo = EMPTY_VALUE, serviceName, serviceText,
  } = props;

  const fixedPriceDescription = () => (
    <div className="FixedPriceDescriptionFontSize ServiceBoxStepContentDescriptionWrapper">
      <p
        id={`${serviceName}SubtitleItalicTextId`}
        className="ServiceBoxStepContentDescriptionWrapperItalicText"
      >
        {serviceText.subtitleItalicText}
        <strong>{serviceText.subtitleItalicBoldText}</strong>
      </p>
      {didCompletedDescriptionNeedToDisplay(priceSpecification) && (
        <li id={`${serviceName}TurnaroundSubtitleTextId`}>
          {serviceText.startWordText}
          <strong>{serviceText.turnaround}</strong>
          {serviceText.subtitleTurnaroundSpecialText !== EMPTY_VALUE ? serviceText.subtitleTurnaroundSpecialText : serviceText.subtitleListMainText}
          <strong>{serviceText.subtitleBoldTurnaroundText}</strong>
        </li>
      )}
      {didArrivalDescriptionNeedToDisplay(priceSpecification) && (
        <li id={`${serviceName}ArrivalOnlySubtitleTextId`}>
          {serviceText.startWordText}
          <strong>{serviceText.arrivalOnly}</strong>
          {serviceText.subtitleArrivalSpecialText !== EMPTY_VALUE ? serviceText.subtitleArrivalSpecialText : serviceText.subtitleListMainText}
          <strong>{serviceText.subtitleBoldArrivalOnlyText}</strong>
        </li>
      )}
      {didDepartureDescriptionNeedToDisplay(priceSpecification) && (
        <li id={`${serviceName}DepartureOnlySubtitleTextId`}>
          {serviceText.startWordText}
          <strong>{serviceText.departureOnly}</strong>
          {serviceText.subtitleDepartureSpecialText !== EMPTY_VALUE ? serviceText.subtitleDepartureSpecialText : serviceText.subtitleListMainText}
          <strong>{serviceText.subtitleBoldDepartureOnlyText}</strong>
        </li>
      )}
    </div>
  );

  const renderMultipleScenarioPrice = () => {
    switch (priceSpecification) {
      case AddServiceDataTypeToMesure.COMPLETION_OF_ONE_TASK:
      case AddServiceDataTypeToMesure.YES_NO:
        return <DoublePrice typeOfPrice={FIXED_PRICE_TYPE} amountTypeCode={FIXED_AMOUNT_CODE} {...props} />;
      case AddServiceDataTypeToMesure.NUMERIC_DATA:
      case AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS:
        return <CustomStepsPrice amountTypeCode={FIXED_AMOUNT_CODE} serviceAddInfo={serviceAddInfo} {...props} />;
      default:
        return <SinglePrice typeOfPrice={FIXED_PRICE_TYPE} amountTypeCode={FIXED_AMOUNT_CODE} {...props} />;
    }
  };

  const renderServicePrice = () => {
    switch (serviceName) {
      case 'NewAdditionalService':
        return renderMultipleScenarioPrice();
      case 'NewDelayRule':
      case 'NewCancellationRule':
        return <CustomStepsPrice amountTypeCode={FIXED_AMOUNT_CODE} serviceAddInfo={serviceAddInfo} {...props} />;
      default:
        return <SinglePrice typeOfPrice={FIXED_PRICE_TYPE} amountTypeCode={FIXED_AMOUNT_CODE} {...props} />;
    }
  };

  return (
    <>
      <div className="ServicesBoxStepContent">
        <div className="ServiceBoxStepContentWrapper">
          <p className="ServicesBoxStepContentNumber">
            {serviceText.priceNumber}
          </p>
          <p className="ServicesSubViewBoxTitle" id={`${serviceName}FixedPriceTitleId`}>
            {serviceText.priceText}
          </p>
        </div>
        <div className="ServiceBoxStepContentDescriptionWrapper">
          {fixedPriceDescription()}
        </div>
      </div>
      {renderServicePrice()}
    </>
  );
}
