import React from 'react';

import {
  CONTRACT_CANCEL_FORM_BUTTON,
  CONTRACT_NEXT_FORM_BUTTON,
  CONTRACT_PREVIOUS_FORM_BUTTON,
  CONTRACT_SEND_FORM_BUTTON,
} from 'src/constants/componentTexts/ContractText';

import useContractByStepNavigation from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/useContractByStepNavigation';

interface Props {
  contractAction: string;
  currentContractByStepType: string;
  nextComponent: string | null;
  previousComponent: string | null;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
}

export default function ContractByStepNavigationButtons(props: Props) {
  const {
    nextComponent,
    previousComponent,
    setCurrentContractByStepType,
  } = props;

  const {
    onCancelClick,
    sendButtonGoBackContractApp,
  } = useContractByStepNavigation(props);

  return (
    <div className="NewContractAppContentFormContentButtonsWrapper">
      <div className="NewContractAppContentFormContentButtonsWrapperButton">
        {previousComponent && (
          <button
            id="PreviousStepContractCreateByStepNavigationButton"
            type="button"
            className="NewContractAppContentFormContentButtonsWrapperButtonGrey"
            onClick={() => setCurrentContractByStepType(previousComponent)}
          >
            {CONTRACT_PREVIOUS_FORM_BUTTON}
          </button>
        )}
      </div>
      <div
        className="NewContractAppContentFormContentButtonsWrapperButton"
        id="CancelContractCreateByStepNavigationButton"
        aria-hidden="true"
        onClick={onCancelClick}
      >
        <p className="NewContractAppContentFormContentButtonsWrapperButtonRed">
          {CONTRACT_CANCEL_FORM_BUTTON}
        </p>
      </div>
      <div className="NewContractAppContentFormContentButtonsWrapperButton">
        <button
          id="NextStepContractCreateByStepNavigationButton"
          type={!nextComponent ? 'button' : 'submit'}
          className="NewContractAppContentFormContentButtonsWrapperButtonGreen"
          onClick={() => sendButtonGoBackContractApp()}
        >
          {!nextComponent ? CONTRACT_SEND_FORM_BUTTON : CONTRACT_NEXT_FORM_BUTTON}
        </button>
      </div>
    </div>
  );
}
