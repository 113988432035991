import { formattedFixedPriceLabels } from 'src/constants/componentTexts/ServiceText';

export const BASE_PRICE_STEP_NUMBER = 2;
export const NEW_CONTRACT_FORM_BASE_PRICE_TITLE = 'Base Price';

export const basePriceFormText = {
  basePriceTitle: NEW_CONTRACT_FORM_BASE_PRICE_TITLE,
  typeOfPriceCheckboxLabel: 'Pick one type of base price :',
  fixedPrice: formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE,
  completeTurnaroundLabel: 'Price for a complete turnaround*',
  arrivalTurnaround: 'Price for an arrival flight only*',
  departureTurnaround: 'Price for a departure flight only*',
};
