import React from 'react';

import { addSpacesToNumber } from 'src/utils/logic/regex/regex';

import { INVOICE_STATUS_FILTER_EXCLUDING_TAXES_TEXT } from 'src/constants/componentTexts/InvoiceStatusFilter';

import { displayAmountWithTwoDecimalIfDecimal } from 'src/invoicing/invoices/InvoicesLogic';

import 'src/uiComponents/filters/StatusFilter.css';

interface Props {
  amount?: number | null;
  defaultElementsFilter: any;
  filterKey: string;
  filterIcon: string;
  elementsFilters: any;
  setElementsFilters: Function;
  title: string;
  totalOfElements: number;
}

export default function StatusFilter(props: Props) {
  const {
    amount = null,
    defaultElementsFilter,
    filterKey,
    filterIcon,
    elementsFilters,
    setElementsFilters,
    title,
    totalOfElements,
  } = props;

  const toggleSelectedElementsFilter = () => {
    const newFilters = { ...elementsFilters, [filterKey]: !elementsFilters[filterKey] };
    const areAllStatusFiltersTurnedOn = Object.values(newFilters).every((filter) => filter);

    if (areAllStatusFiltersTurnedOn) {
      setElementsFilters(defaultElementsFilter);
      return;
    }

    setElementsFilters(newFilters);
  };

  const displayTheSelectedFilter = () => elementsFilters[filterKey] && filterKey;

  const displayAmountStatusFilterIfExists = () => (
    amount !== 0 ? (
      <>
        <p className="StatusFilterAmount">{`${amount && addSpacesToNumber(displayAmountWithTwoDecimalIfDecimal(amount).toString())} $`}</p>
        <p className="StatusFilterHT">{INVOICE_STATUS_FILTER_EXCLUDING_TAXES_TEXT}</p>
      </>
    ) : (
      <p className="StatusFilterAmount">0 $</p>
    )
  );

  return (
    <div
      id={`StatusFilter${filterKey}Id`}
      className={`StatusFilterButton StatusFilterButtonBorderTop__${filterKey} StatusFilterButtonSelected__${displayTheSelectedFilter()}`}
      onClick={toggleSelectedElementsFilter}
      aria-hidden="true"
    >
      <div className="StatusFilterHeader">
        <img src={filterIcon} alt="filterIcon" className={`StatusFilterImage StatusFilterImage__${filterKey}`} />
        <p className="StatusFilterTitle">{title}</p>
        <p id={`StatusFilterTotalOfElements${filterKey}Id`} className={`StatusFilterTotalOfElements StatusFilterTotalOfElements__${filterKey}`}>{totalOfElements}</p>
      </div>
      <div className="StatusFilterContent">
        {amount !== null && displayAmountStatusFilterIfExists()}
      </div>
    </div>
  );
}
