import React from 'react';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';

import ContractByStepNavigationButtons from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepNavigationButtons';
import ContractForm from 'src/invoicing/contract/form/ContractForm';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  nextComponent: string | null;
  previousComponent: string | null;
  setStoredContract: Function;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setcontractByStepsFilled: Function;
  storedContract: StoredContract;
}

export default function ContractStep(props: Props) {
  const {
    contractByStepsFilled,
    currentContractByStepType,
    storedContract,
    nextComponent,
    previousComponent,
    setStoredContract,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    setcontractByStepsFilled,
  } = props;

  return (
    <ContractForm
      contractByStepsFilled={contractByStepsFilled}
      currentContractByStepType={currentContractByStepType}
      storedContract={storedContract}
      nextComponent={nextComponent}
      previousComponent={previousComponent}
      setStoredContract={setStoredContract}
      setCurrentContractByStepType={setCurrentContractByStepType}
      setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
      setcontractByStepsFilled={setcontractByStepsFilled}
      ContractFormButtons={ContractByStepNavigationButtons}
    />
  );
}
