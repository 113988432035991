const EMPTY_VALUE = '';

export const formatTextEachWordsFirstLetterUppercase = (sentenceToFormat: string) => {
  const sentenceToFormatSplitted: string[] = sentenceToFormat.split(' ');

  return sentenceToFormatSplitted.reduce((sentence: string, word: string) => {
    if (sentence === EMPTY_VALUE) {
      return `${sentence}${word.charAt(0).toUpperCase() + word.slice(1)}`;
    }
    return `${sentence} ${word.charAt(0).toUpperCase() + word.slice(1)}`;
  }, EMPTY_VALUE);
};
