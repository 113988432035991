import React from 'react';
import { useDispatch } from 'react-redux';

import { NEW_CONTRACT_BUTTON, NEW_CONTRACT_LOGO_BUTTON } from 'src/constants/componentTexts/ContractText';
import { ActionStatus } from 'src/constants/status';

import { resetContractStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  setContractAction: Function;
  setIsNewContractOpen: Function;
}

export default function NewContractButton(props: Props) {
  const { setContractAction, setIsNewContractOpen } = props;

  const dispatch = useDispatch();

  const onAddNewContractClick = () => {
    setIsNewContractOpen(true);
    setContractAction(ActionStatus.NEW);
    dispatch(resetContractStatus());
  };

  return (
    <div className="ContractAppActionsNewContractButton" aria-hidden="true" onClick={onAddNewContractClick}>
      <p className="ButtonFontSize ContractAppActionsNewContractButtonIcon">
        {NEW_CONTRACT_LOGO_BUTTON}
      </p>
      <p
        id="ContractAppNewContractButtonText"
        className="ButtonFontSize ContractAppActionsNewContractButtonText"
      >
        {NEW_CONTRACT_BUTTON}
      </p>
    </div>
  );
}
