import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  DATE_FILTER_FROM_DATE_KEY,
  DATE_FILTER_TO_DATE_KEY,
  PERIOD_QUICK_FILTER_DEFAULT_VALUE,
} from 'src/constants/componentTexts/InvoiceAppText';
import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import { apiGetInvoices } from 'src/invoicing/invoices/InvoicesApiRequest';
import { isSubmitFiltersDisabled } from 'src/invoicing/invoices/InvoicesLogic';
import usePeriodQuickFilter from 'src/invoicing/invoices/filters/periodFilter/usePeriodQuickFilter';

interface Props {
  dateFilters: any;
  setDateFilters: Function;
  setSelectedRadioButtonId: Function;
}

export default function useDateFilter(props: Props) {
  const { dateFilters, setDateFilters, setSelectedRadioButtonId } = props;

  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const periodQuickFilter = usePeriodQuickFilter();

  const [fromDate, setFromDate] = useState<string>(dateFilters.fromDate);
  const [toDate, setToDate] = useState<string>(dateFilters.toDate);

  const setPeriodQuickFilterIdWhenValueChange = (dateToChangeKey: string, event: ChangeEvent<HTMLInputElement>) => {
    if (dateToChangeKey === DATE_FILTER_FROM_DATE_KEY) {
      if (dateFilters.toDate === EMPTY_VALUE && event.target.value === EMPTY_VALUE) {
        setSelectedRadioButtonId(PERIOD_QUICK_FILTER_DEFAULT_VALUE);
      }
    } else if (dateToChangeKey === DATE_FILTER_TO_DATE_KEY) {
      if (dateFilters.fromDate === EMPTY_VALUE && event.target.value === EMPTY_VALUE) {
        setSelectedRadioButtonId(PERIOD_QUICK_FILTER_DEFAULT_VALUE);
      }
    } else {
      setSelectedRadioButtonId(null);
    }
  };

  const changeDateFilters = (event: ChangeEvent<HTMLInputElement>, dateToChangeKey: string, setDate: Function) => {
    event.preventDefault();

    const dateChosenInUTCFormat = `${event.target.value}T00:00:00Z`;
    const UTCDateInYYYYMMDDFormat = dateChosenInUTCFormat.split('T')[0];

    setDate(UTCDateInYYYYMMDDFormat);
    setDateFilters({ ...dateFilters, [dateToChangeKey]: UTCDateInYYYYMMDDFormat });
    setPeriodQuickFilterIdWhenValueChange(dateToChangeKey, event);
  };

  const submitInvoicesWithDate = () => {
    dispatch(apiGetInvoices({ fromDate: dateFilters.fromDate, toDate: dateFilters.toDate }));
  };

  const onRadioButtonChange = (event: FormEvent<HTMLFieldSetElement>) => {
    const radioButton = event.target as HTMLInputElement;
    const selectedPeriod = periodQuickFilter[radioButton.id as keyof typeof periodQuickFilter]();

    setFromDate(selectedPeriod.fromDate);
    setToDate(selectedPeriod.toDate);
    setDateFilters({ fromDate: selectedPeriod.fromDate, toDate: selectedPeriod.toDate });
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (!isSubmitFiltersDisabled(dateFilters)) {
      submitInvoicesWithDate();
    } else if (dateFilters.fromDate === EMPTY_VALUE && dateFilters.toDate === EMPTY_VALUE) {
      submitInvoicesWithDate();
    }
  }, [fromDate, toDate]);

  return {
    changeDateFilters,
    fromDate,
    onRadioButtonChange,
    setFromDate,
    setToDate,
    toDate,
  };
}
