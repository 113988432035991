import React, { ChangeEvent, useState } from 'react';

import { NumberInputProps } from 'src/uiComponents/input/InputInterface';

import { addStayUpClassNameIfInputFilled } from 'src/utils/logic/input/setUpClassForInputAnimation';
import { isNumberValid } from 'src/utils/logic/regex/regex';

export default function NumberInput(props: NumberInputProps) {
  const {
    amountTypeCode = '',
    formObject,
    formObjectPreviousFilledValue = '',
    formObjectKey,
    inputLabel,
    isDisabled,
    isReadOnly,
    isRequired,
    setFormObject,
  } = props;

  const [numberInputValue, setNumberInputValue] = useState<string>(formObjectPreviousFilledValue || '');

  const onNumberInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (isNumberValid(event.target.value)) {
      setNumberInputValue(event.target.value);
      setFormObject({ ...formObject, [formObjectKey]: event.target.value });
    } else {
      setNumberInputValue('');
      setFormObject({ ...formObject, [formObjectKey]: null });
    }
  };

  return (
    <div className="InputWrapper">
      <input
        id={`NumberInput${amountTypeCode}${formObjectKey}InputId`}
        value={numberInputValue}
        onChange={onNumberInputChange}
        min="0"
        className={`InputWrapperInput  ${addStayUpClassNameIfInputFilled(numberInputValue)}`}
        type="number"
        required={isRequired}
        disabled={isDisabled}
        readOnly={isReadOnly}
        step=".01"
      />
      <label
        className={`InputWrapperLabel ${addStayUpClassNameIfInputFilled(numberInputValue)}`}
        id={`NumberInput${amountTypeCode}${formObjectKey}LabelId`}
      >
        {inputLabel}
      </label>
    </div>
  );
}
