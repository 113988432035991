export interface BooleanPriceInterface {
  amount_if_true: string | null;
  amount_if_false: string | null;
  amounts_type: string | null;
}

export interface TimingsDifferenceReceivedInterface {
  task_A_arrival_or_departure: string | null,
  task_datasource_A: { id: number, label: string } | null;
  task_A_timing_reference: string | null;
  additional_information_datasource_A: { id: number, label: string} | null;
  task_B_arrival_or_departure: string | null,
  task_datasource_B: { id: number, label: string } | null;
  task_B_timing_reference: string | null;
  additional_information_datasource_B: { id: number, label: string} | null;
}

export interface AdditionalServiceReceivedInterface {
  id: number;
  arrival_or_departure_service: string;
  additional_service_name: { id: number; label: string };
  additional_information_datasource: {id: number, label: string} | null;
  data_type_to_mesure: { code: string; label: string };
  task_datasource: { id: number; label: string} | null;
  timings_difference: TimingsDifferenceReceivedInterface | null;
  price: any;
}

export interface TimingsDifferenceSentInterface {
  task_A_arrival_or_departure: string | null,
  task_datasource_A: number | null,
  task_A_timing_reference: string | null,
  task_B_arrival_or_departure: string | null,
  task_datasource_B: number | null,
  task_B_timing_reference: string | null,
  additional_information_datasource_A: number | null;
  additional_information_datasource_B: number | null;
}

export interface AdditionalServiceSentInterface {
  contract: number | null;
  arrival_or_departure_service: string | null;
  additional_service_name: number | null;
  additional_information_datasource: number | null;
  data_type_to_mesure: string | null;
  task_datasource: number | null;
  timings_difference: TimingsDifferenceSentInterface | null;
  price: any;
}

export const defaultAdditionalService: AdditionalServiceSentInterface = {
  contract: null,
  arrival_or_departure_service: null,
  additional_service_name: null,
  additional_information_datasource: null,
  data_type_to_mesure: null,
  timings_difference: null,
  task_datasource: null,
  price: null,
};

interface AdditionalServiceNameInterface {
  id: number;
  label: string;
}

interface AmountTypeInterface {
  code: string;
  label: string;
}

interface ArrivalOrDepartureServiceInterface {
  code: string;
  label: string;
}

interface CurrencyInterface {
  code: string;
  label: string;
  symbol: string;
}

interface DataTypeToMesureInterface {
  code: string;
  label: string;
}

interface AdditionalInformationDatasourceInterface {
  id: number;
  label: string;
}

interface TaskDatasourceInterface {
  id: number;
  label: string;
  additional_information_datasources: AdditionalInformationDatasourceInterface[] | [];
}

interface TasksTimingsReferenceInterface {
  code: string;
  label: string;
}

interface ValueTypeInterface {
  id: number;
  label: string;
}
export interface AdditionalServiceParametersInterface {
  additional_service_names: AdditionalServiceNameInterface[];
  amount_types: AmountTypeInterface[];
  arrival_or_departure_service: ArrivalOrDepartureServiceInterface[];
  arrival_or_departure_task: ArrivalOrDepartureServiceInterface[];
  currency: CurrencyInterface;
  data_types_to_mesure: DataTypeToMesureInterface[];
  task_datasources: TaskDatasourceInterface[];
  tasks_timings_references: TasksTimingsReferenceInterface[];
  value_types: ValueTypeInterface[];
}
