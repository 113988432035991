import { combineReducers, configureStore } from '@reduxjs/toolkit';

import additionalServicesReducer from 'src/invoicing/services/additionalServices/AdditionalServicesSlice';
import cancellationRulesReducer from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';
import contractReducer from 'src/invoicing/contract/ContractSlice';
import delayRulesReducer from 'src/invoicing/services/delayRules/DelayRulesSlice';
import homeReducer from 'src/invoicing/home/HomeSlice';
import invoicesReducer from 'src/invoicing/invoices/InvoicesSlice';
import invoicingReducer from 'src/invoicing/InvoicingSlice';
import loginReducer from 'src/invoicing/login/LoginSlice';
import operationTypesReducer from 'src/invoicing/services/operationTypes/OperationTypesSlice';

const rootReducer = combineReducers({
  additionalServices: additionalServicesReducer,
  cancellationRules: cancellationRulesReducer,
  contract: contractReducer,
  delayRules: delayRulesReducer,
  home: homeReducer,
  invoices: invoicesReducer,
  invoicing: invoicingReducer,
  login: loginReducer,
  operationTypes: operationTypesReducer,
});

export default configureStore({ reducer: rootReducer });
