import React from 'react';

interface Props {
  noServiceMessage: string;
  serviceName: string
}

export default function NoService(props: Props) {
  const { noServiceMessage, serviceName } = props;

  return (
    <div className="NewContractAppContentNoService">
      <p
        id={`${serviceName}sNo${serviceName}Text`}
        className="NewContractAppContentNoServicesText"
      >
        {noServiceMessage}
      </p>
    </div>
  );
}
