import { createSlice } from '@reduxjs/toolkit';

import { ActionStatus, Status } from 'src/constants/status';

import {
  apiGetContract,
  apiGetContractFilters,
  apiGetContracts,
  apiGetContractParameters,
  apiPatchContract,
  apiPostNewContract,
  apiPostDuplicateContract,
  apiDeleteNewContract,
} from 'src/invoicing/contract/ContractApiRequest';

export const contractSlice = createSlice({
  name: 'contract',
  initialState: {
    contractId: null,
    contracts: [],
    contract: {},
    contractStatus: '',
    contractParameters: {
      companies: [],
      aircraft_models: [],
      company_business_groups: [],
      currencies: [],
    },
    userContractCurrency: {},
    contractResponseErrorMessage: '',
    contractResponseStatus: Status.DEFAULT,
    getContractsResponseStatus: Status.DEFAULT,
    getContractStatus: Status.DEFAULT,
    patchContractStatus: Status.DEFAULT,
    postDuplicateContractStatus: Status.DEFAULT,
    contractAction: ActionStatus.NEW,
    deleteContractStatus: Status.DEFAULT,
    getContractParametersStatus: Status.DEFAULT,
    getContractFiltersStatus: Status.DEFAULT,
  },
  reducers: {
    resetContractResponseStatus(state) {
      state.contractResponseStatus = Status.DEFAULT;
    },
    resetPatchContractStatus(state) {
      state.patchContractStatus = Status.DEFAULT;
    },
    resetGetContractsResponseStatus(state) {
      state.getContractsResponseStatus = Status.DEFAULT;
    },
    resetPostDuplicateContractStatus(state) {
      state.postDuplicateContractStatus = Status.DEFAULT;
    },
    getUserContractCurrency(state, action) {
      state.userContractCurrency = action.payload;
    },
    getContractAction(state, action) {
      state.contractAction = action.payload;
    },
    resetContractStatus(state) {
      state.contractStatus = '';
    },
    resetDeleteContractStatus(state) {
      state.deleteContractStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetContractParameters.pending, (state) => {
        state.getContractParametersStatus = Status.LOADING;
      })
      .addCase(apiGetContractParameters.fulfilled, (state, action) => {
        state.contractParameters = action.payload;
        state.getContractParametersStatus = Status.SUCCESS;
      })
      .addCase(apiGetContractParameters.rejected, (state) => {
        state.getContractParametersStatus = Status.FAIL;
      })
      .addCase(apiGetContractFilters.pending, (state) => {
        state.getContractFiltersStatus = Status.LOADING;
      })
      .addCase(apiGetContractFilters.fulfilled, (state, action) => {
        state.getContractFiltersStatus = Status.SUCCESS;
        state.contractParameters = action.payload;
      })
      .addCase(apiGetContractFilters.rejected, (state) => {
        state.getContractFiltersStatus = Status.FAIL;
      })
      .addCase(apiGetContracts.pending, (state) => {
        state.getContractsResponseStatus = Status.LOADING;
      })
      .addCase(apiGetContracts.fulfilled, (state, action) => {
        state.contracts = action.payload;
        state.getContractsResponseStatus = Status.SUCCESS;
      })
      .addCase(apiGetContracts.rejected, (state) => {
        state.getContractsResponseStatus = Status.FAIL;
      })
      .addCase(apiGetContract.pending, (state) => {
        state.getContractStatus = Status.LOADING;
      })
      .addCase(apiGetContract.fulfilled, (state, action) => {
        state.getContractStatus = Status.SUCCESS;
        state.contract = action.payload;
        state.contractId = action.payload.id;
        state.contractStatus = action.payload.status;
      })
      .addCase(apiGetContract.rejected, (state) => {
        state.getContractStatus = Status.FAIL;
      })
      .addCase(apiPostNewContract.pending, (state) => {
        state.contractResponseStatus = Status.LOADING;
      })
      .addCase(apiPostNewContract.fulfilled, (state, action) => {
        state.contractResponseStatus = Status.SUCCESS;
        state.contractId = action.payload.id;
      })
      .addCase(apiPostNewContract.rejected, (state, action: any) => {
        state.contractResponseErrorMessage = action.payload;
        state.contractResponseStatus = Status.FAIL;
      })
      .addCase(apiPostDuplicateContract.pending, (state) => {
        state.postDuplicateContractStatus = Status.LOADING;
      })
      .addCase(apiPostDuplicateContract.fulfilled, (state, action) => {
        state.postDuplicateContractStatus = Status.SUCCESS;
        state.contractId = action.payload.id;
      })
      .addCase(apiPostDuplicateContract.rejected, (state, action: any) => {
        state.contractResponseErrorMessage = action.payload;
        state.postDuplicateContractStatus = Status.FAIL;
      })
      .addCase(apiPatchContract.pending, (state) => {
        state.patchContractStatus = Status.LOADING;
      })
      .addCase(apiPatchContract.fulfilled, (state) => {
        state.patchContractStatus = Status.SUCCESS;
      })
      .addCase(apiPatchContract.rejected, (state, action: any) => {
        state.contractResponseErrorMessage = action.payload;
        state.patchContractStatus = Status.FAIL;
      })
      .addCase(apiDeleteNewContract.pending, (state) => {
        state.deleteContractStatus = Status.LOADING;
      })
      .addCase(apiDeleteNewContract.fulfilled, (state) => {
        state.deleteContractStatus = Status.SUCCESS;
      })
      .addCase(apiDeleteNewContract.rejected, (state) => {
        state.deleteContractStatus = Status.FAIL;
      });
  },
});

export const {
  getContractAction,
  getUserContractCurrency,
  resetContractResponseStatus,
  resetContractStatus,
  resetDeleteContractStatus,
  resetGetContractsResponseStatus,
  resetPatchContractStatus,
  resetPostDuplicateContractStatus,
} = contractSlice.actions;

export const selectContract = (state: any) => state.contract.contract;
export const selectContractAction = (state: any) => state.contract.contractAction;
export const selectContractId = (state: any) => state.contract.contractId;
export const selectContractFiltersParameters = (state: any) => state.contract.contractParameters;
export const selectContractFiltersParametersStatus = (state: any) => state.contract.getContractFiltersStatus;
export const selectContractParameters = (state: any) => state.contract.contractParameters;
export const selectContractResponseErrorMessage = (state: any) => state.contract.contractResponseErrorMessage;
export const selectContractResponseStatus = (state: any) => state.contract.contractResponseStatus;
export const selectContracts = (state: any) => state.contract.contracts;
export const selectContractStatus = (state: any) => state.contract.contractStatus;
export const selectDeleteContractStatus = (state: any) => state.contract.deleteContractStatus;
export const selectGetContractParametersStatus = (state: any) => state.contract.getContractParametersStatus;
export const selectGetContractsResponseStatus = (state: any) => state.contract.getContractsResponseStatus;
export const selectGetContractStatus = (state: any) => state.contract.getContractStatus;
export const selectPatchContractStatus = (state: any) => state.contract.patchContractStatus;
export const selectPostDuplicateContractStatus = (state: any) => state.contract.postDuplicateContractStatus;
export const selectUserContractCurrency = (state: any) => state.contract.userContractCurrency;

export default contractSlice.reducer;
