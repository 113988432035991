import { useEffect, useState } from 'react';

import {
  AdditionalServiceReceivedInterface,
  TimingsDifferenceSentInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';

import { getKeyValueFromObject } from 'src/utils/logic/object';

interface Props {
  additionalServiceTimingDifference: TimingsDifferenceSentInterface,
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  datasourcePart: string;
  setAdditionalServiceTimingDifference: Function;
}

export default function useToggleDifferenceBetweenTwoTimings(props: Props) {
  const {
    additionalServiceFilled,
    datasourcePart,
    additionalServiceTimingDifference,
    setAdditionalServiceTimingDifference,
  } = props;

  const addInfoDatasourceFieldName = `additional_information_datasource_${datasourcePart}`;
  const taskDatasourceTimingReferenceFieldName = `task_${datasourcePart}_timing_reference`;
  const taskDatasourceFieldName = `task_datasource_${datasourcePart}`;
  const taskArrivalOrDepartureFieldName = `task_${datasourcePart}_arrival_or_departure`;

  const [isTaskChecked, setIsTaskChecked] = useState(additionalServiceFilled ? getKeyValueFromObject(additionalServiceFilled?.timings_difference, taskDatasourceTimingReferenceFieldName) !== null : true);
  const [isAddInfoChecked, setIsAddInfoChecked] = useState(additionalServiceFilled ? getKeyValueFromObject(additionalServiceFilled?.timings_difference, addInfoDatasourceFieldName) !== null : false);

  useEffect(() => {
    if (isTaskChecked) {
      if (additionalServiceFilled) {
        setAdditionalServiceTimingDifference({ ...additionalServiceTimingDifference, [addInfoDatasourceFieldName]: null });
      } else {
        setAdditionalServiceTimingDifference({
          ...additionalServiceTimingDifference,
          [addInfoDatasourceFieldName]: null,
          [taskDatasourceFieldName]: null,
        });
      }
    }
  }, [isTaskChecked]);

  useEffect(() => {
    if (isAddInfoChecked) {
      if (additionalServiceFilled) {
        setAdditionalServiceTimingDifference({ ...additionalServiceTimingDifference, [taskDatasourceTimingReferenceFieldName]: null });
      } else {
        setAdditionalServiceTimingDifference({
          ...additionalServiceTimingDifference,
          [taskDatasourceTimingReferenceFieldName]: null,
          [taskDatasourceFieldName]: null,
        });
      }
    }
  }, [isAddInfoChecked]);

  const getTaskDatasourceTimingReferenceFilled = () => {
    let taskDatasourceTimingReferenceFilled = null;

    const timingReference = getKeyValueFromObject(additionalServiceFilled?.timings_difference, taskDatasourceTimingReferenceFieldName);

    if (timingReference) {
      taskDatasourceTimingReferenceFilled = timingReference;
    }
    return taskDatasourceTimingReferenceFilled;
  };

  return {
    addInfoDatasourceFieldName,
    getTaskDatasourceTimingReferenceFilled,
    isAddInfoChecked,
    isTaskChecked,
    setIsAddInfoChecked,
    setIsTaskChecked,
    taskArrivalOrDepartureFieldName,
    taskDatasourceFieldName,
    taskDatasourceTimingReferenceFieldName,
  };
}
