import React from 'react';

import { CircularProgress } from '@mui/material';

export default function Loader(props: { className: string }) {
  const { className } = props;

  return (
    <div className={className} id={`${className}Id`}>
      <CircularProgress className="loading" color="primary" />
    </div>
  );
}
