import { FAILED_REFRESH_ACCESS_TOKEN_MESSAGE } from 'src/constants/errorMessage';
import { INVOICING_API_URL, USER_API_URL } from 'src/constants/url';

import { formatErrorMessage } from 'src/invoicing/api/utils';
import { InvalidRefreshTokenError } from 'src/utils/error/CustomError';

import { ApiRequestParameters } from 'src/invoicing/InvoicingCustomApiRequestInterface';
import {
  invoicingDeleteRequest,
  invoicingDownloadInvoices,
  invoicingGetRequest,
  invoicingPatchRequest,
  invoicingPostRequest,
} from 'src/invoicing/api/invoicingCustomApiRequests';
import { refreshAccessTokenApi } from 'src/invoicing/api/RefreshTokenApiRequest';

export const throwErrorIfErrorDuringRefreshAccessToken = (getState: any) => {
  const { invoicing } = getState();

  if (invoicing.errorMessageToken === FAILED_REFRESH_ACCESS_TOKEN_MESSAGE) {
    throw new InvalidRefreshTokenError();
  }
};

export const refreshAccessTokenAndApiCall = async (apiRequestParameters: ApiRequestParameters) => {
  await apiRequestParameters.thunkApi.dispatch(refreshAccessTokenApi());

  const response = await apiRequestParameters.invoicingRequest(apiRequestParameters.thunkApi.dispatch, apiRequestParameters.baseUrl, apiRequestParameters.url, apiRequestParameters.thunkApi.getState, apiRequestParameters?.body);

  return response.data;
};

export const invoicingAsyncThunk = async (apiRequestParameters: ApiRequestParameters) => {
  try {
    const response = await apiRequestParameters.invoicingRequest(apiRequestParameters.thunkApi.dispatch, apiRequestParameters.baseUrl, apiRequestParameters.url, apiRequestParameters.thunkApi.getState, apiRequestParameters?.body);

    return response.data;
  } catch (invoicingGetApiRequestError: any) {
    const apiRequestError = formatErrorMessage(invoicingGetApiRequestError.response.data);

    if (invoicingGetApiRequestError.response.status === 401) {
      return refreshAccessTokenAndApiCall(apiRequestParameters);
    }
    return apiRequestParameters.thunkApi.rejectWithValue(apiRequestError);
  }
};

export const userApiRequestAsyncThunk = async (apiRequestParameters: ApiRequestParameters) => {
  const parameters: ApiRequestParameters = { ...apiRequestParameters, baseUrl: USER_API_URL, invoicingRequest: invoicingGetRequest };

  const response = await invoicingAsyncThunk(parameters);

  return response;
};

export const invoicingGetApiRequestAsyncThunk = async (apiRequestParameters: ApiRequestParameters) => {
  const parameters: ApiRequestParameters = { ...apiRequestParameters, baseUrl: INVOICING_API_URL, invoicingRequest: invoicingGetRequest };

  const response = await invoicingAsyncThunk(parameters);

  return response;
};

export const invoicingPostApiRequestAsyncThunk = async (apiRequestParameters: ApiRequestParameters) => {
  const parameters: ApiRequestParameters = { ...apiRequestParameters, baseUrl: INVOICING_API_URL, invoicingRequest: invoicingPostRequest };

  const response = await invoicingAsyncThunk(parameters);

  return response;
};

export const invoicingPatchApiRequestAsyncThunk = async (apiRequestParameters: ApiRequestParameters) => {
  const parameters: ApiRequestParameters = { ...apiRequestParameters, baseUrl: INVOICING_API_URL, invoicingRequest: invoicingPatchRequest };

  const response = await invoicingAsyncThunk(parameters);

  return response;
};

export const invoicingDeleteApiRequestAsyncThunk = async (apiRequestParameters: ApiRequestParameters) => {
  const parameters: ApiRequestParameters = { ...apiRequestParameters, baseUrl: INVOICING_API_URL, invoicingRequest: invoicingDeleteRequest };

  const response = await invoicingAsyncThunk(parameters);

  return response;
};

export const downloadInvoiceApiRequestAsyncthunk = async (apiRequestParameters: ApiRequestParameters) => {
  const parameters: ApiRequestParameters = { ...apiRequestParameters, baseUrl: INVOICING_API_URL, invoicingRequest: invoicingDownloadInvoices };

  const response = await invoicingAsyncThunk(parameters);

  return response;
};
