import React from 'react';

import { SERVICE_CUSTOM_STEP_EMPTY_VALUE_DESCRIPTION } from 'src/constants/componentTexts/ServiceText';

import { CustomStepsObject } from 'src/invoicing/services/customSteps/CustomStepInterface';
import { formatDisplayNumberToIntegerIfNothingInTheDecimal } from 'src/invoicing/services/ServicesLogic';

interface Props {
  customStep: CustomStepsObject;
  customStepIndex: number;
  customStepsAmountType: string;
  customStepsText: any;
  serviceName: string;
  serviceAddInfo: string;
}

export default function CustomStepDescription(props: Props) {
  const {
    customStep, customStepIndex, customStepsAmountType, customStepsText, serviceAddInfo, serviceName,
  } = props;

  return (
    <div className="CustomStepsCustomStepContentStepDescription">
      <p id={`${serviceName}${customStepIndex}DescriptionId`} className="CustomStepsCustomStepContentStepDescriptionText">
        {`${customStepsText.customStepDescriptionFirstPart} ${serviceAddInfo !== '' ? `"${serviceAddInfo}" from` : ''}
        ${formatDisplayNumberToIntegerIfNothingInTheDecimal(customStep.min_value) || SERVICE_CUSTOM_STEP_EMPTY_VALUE_DESCRIPTION} ${customStepsText.customStepInputValue}
        ${customStepsText.customStepSeparatorBetweenTwoValue}
        ${formatDisplayNumberToIntegerIfNothingInTheDecimal(customStep.max_value) || SERVICE_CUSTOM_STEP_EMPTY_VALUE_DESCRIPTION} ${customStepsText.customStepInputValue}${customStepsText.customStepDescriptionSecondPart}${customStepsText.customStepDescriptionAmount}
        ${customStep.amount || SERVICE_CUSTOM_STEP_EMPTY_VALUE_DESCRIPTION} ${customStepsAmountType}
        ${customStepsText.customStepDescriptionPriceAmount}`}
      </p>
    </div>
  );
}
