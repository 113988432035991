import {
  CREATE_ACTION,
  formattedFixedPriceLabels,
  getServiceErrorMessage,
  PERCENTAGE_OF_BASE_PRICE_SYMBOL,
  UPDATE_ACTION,
} from 'src/constants/componentTexts/ServiceText';

export const OPERATION_TYPES_STEP_NUMBER = 3;

export const NEW_CONTRACT_FORM_OPERATION_TYPES_NO_RULE_MESSAGE = 'No operation type is set up';

export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_TITLE = 'New Operation Type';
export const NEW_CONTRACT_FORM_EDIT_OPERATION_TYPE_TITLE = 'Edit Operation Type';
export const NEW_CONTRACT_FORM_VIEW_OPERATION_TYPE_TITLE = 'View Operation Type';
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_TYPE_OF_PRICE_CHECKBOX_TITLE = 'Pick one type of price for this operation type:';
export const NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_FIRST_COLUMN_TITLE = 'Operations';
export const NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_SECOND_COLUMN_TITLE = 'Type of prices';
export const NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_THIRD_COLUMN_TITLE = 'Values';
export const NEW_CONTRACT_FORM_OPERATION_TYPES_TITLE = 'Operation Types';
export const NEW_CONTRACT_FORM_SERVICES_CANCEL_OPERATION_TYPE_FORM_BUTTON = 'Cancel';
export const NEW_CONTRACT_FORM_SERVICES_SEND_OPERATION_TYPE_FORM_BUTTON = 'Add the Operation type';
export const EDIT_CONTRACT_FORM_SERVICES_SEND_OPERATION_TYPE_FORM_BUTTON = 'Edit the Operation type';

export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_SUBTITLE_TEXT = 'The price will replace the base price for the same kind of flights';
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_SUBTITLE_LIST_MAIN_TEXT = ', the price would replace the base price of a turnaround with ';

export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PARAMETER_NUMBER = '1';
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_TYPE_OF_PRICE_NUMBER = '2';
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_TYPE_OF_PRICE_TEXT = 'Price';
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PRICE_NUMBER = '3';
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PRICE_TEXT = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_LABEL = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_VARIABLE_PRICE_LABEL = `Variable Price (${PERCENTAGE_OF_BASE_PRICE_SYMBOL})`;
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE__PRICE_LABEL = `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this operation type`;
export const NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_CAUSE_OF_DELAY_LABEL = 'Operation Name';

export const OPERATION_TYPE_NAME = 'operation type';

export const NEW_OPERATION_TYPE_ERROR_MESSAGE = getServiceErrorMessage(CREATE_ACTION, OPERATION_TYPE_NAME);
export const UPDATE_OPERATION_TYPE_ERROR_MESSAGE = getServiceErrorMessage(UPDATE_ACTION, OPERATION_TYPE_NAME);

export const operationTypeFormText = {
  operationName: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_CAUSE_OF_DELAY_LABEL,
  fixedPrice: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_LABEL,
  parameterNumber: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PARAMETER_NUMBER,
  typeOfPriceNumber: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_TYPE_OF_PRICE_NUMBER,
  typeOfPriceText: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_TYPE_OF_PRICE_TEXT,
  variablePrice: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_VARIABLE_PRICE_LABEL,
};

export const operationTypeFixedPriceText = {
  startWordText: 'For',
  turnaround: ' a turnaround',
  arrivalOnly: ' an arrival only',
  departureOnly: ' a departure only',
  subtitleItalicText: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_SUBTITLE_TEXT,
  subtitleItalicBoldText: '',
  subtitleListMainText: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_SUBTITLE_LIST_MAIN_TEXT,
  subtitleBoldTurnaroundText: 'one arrival linked to a departure flight',
  subtitleBoldArrivalOnlyText: 'only an arriving flight',
  subtitleDepartureSpecialText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  subtitleBoldDepartureOnlyText: 'only a departing flight',
  priceNumber: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PRICE_NUMBER,
  priceText: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PRICE_TEXT,
  price: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE__PRICE_LABEL,
};

export const operationTypeVariablePriceText = {
  startWordText: 'For',
  turnaround: ' a turnaround',
  arrivalOnly: ' an arrival only',
  departureOnly: ' a departure only',
  subtitleItalicText: 'The value to input is the percentage of the original base price to apply when this operation type occurs.',
  subtitleItalicBoldText: '',
  subtitleListMainText: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_FIXED_PRICE_SUBTITLE_LIST_MAIN_TEXT,
  subtitleBoldTurnaroundText: 'one arrival linked to a departure flight',
  subtitleBoldArrivalOnlyText: 'only an arriving flight',
  subtitleDepartureSpecialText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  subtitleBoldDepartureOnlyText: 'only a departing flight',
  priceNumber: NEW_CONTRACT_FORM_NEW_OPERATION_TYPE_PRICE_NUMBER,
  priceText: 'Variable Price',
  price: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} for this operation type`,
  variablePriceMoney: '%',
};
