import React from 'react';

import { DELETE_LOGO, EDIT_LOGO, VIEW_LOGO } from 'src/constants/picturesPath';
import { SERVICES_DELETE_BUTTON_TEXT, SERVICES_EDIT_BUTTON_TEXT, SERVICES_VIEW_BUTTON_TEXT } from 'src/constants/componentTexts/ServiceText';

import { AdditionalServiceReceivedInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import {
  canAServiceBeUpdated,
  returnFixedIfPriceAmountsTypeServiceIsFixed,
  returnVariableIfPriceAmountsTypeServiceIsPercentage,
} from 'src/invoicing/services/ServicesLogic';
import useAdditionalService from 'src/invoicing/services/additionalServices/useAdditionalService';

interface Props {
  additionalService: AdditionalServiceReceivedInterface;
  contractAction: string;
  contractStatus: string;
  setAdditionalServiceAction: Function;
  setIsAdditionalSubViewOpen: Function;
}

export default function AdditionalService(props: Props) {
  const { additionalService, contractAction, contractStatus } = props;

  const {
    deleteAdditionalService,
    editAdditionalService,
    viewAdditionalService,
  } = useAdditionalService(props);

  return (
    <tr className="NewContractAppContentAdditionalServicesFormTableTbodyRow">
      <td
        id={`NewContractAppContentAdditionalServicesFormTableTbodyRowServiceNameValue${additionalService.id}Id`}
        className="FormTextFontSize NewContractAppContentAdditionalServicesFormTableTbodyRowImportantValue"
      >
        {additionalService.additional_service_name.label}
      </td>
      <td
        id={`NewContractAppContentAdditionalServicesFormTableTbodyRowTypeOfPriceValue${additionalService.id}Id`}
        className="FormTextFontSize NewContractAppContentAdditionalServicesFormTableTbodyRowValue"
      >
        {returnFixedIfPriceAmountsTypeServiceIsFixed(additionalService) || returnVariableIfPriceAmountsTypeServiceIsPercentage(additionalService)}
      </td>
      <td
        id={`NewContractAppContentAdditionalServicesFormTableTbodyRowMeasurementValue${additionalService.id}Id`}
        className="FormTextFontSize NewContractAppContentAdditionalServicesFormTableTbodyRowValueThirdColumn"
      >
        {additionalService.data_type_to_mesure.label}
      </td>
      <td className="NewContractAppContentAdditionalServicesFormTableTbodyRowSpacer" />
      <td
        className="FormTextEditButtonStyle NewContractAppContentAdditionalServicesFormTableTbodyRowAction"
      >
        <img
          className="NewContractAppContentAdditionalServicesFormTableTbodyRowViewImg"
          src={VIEW_LOGO}
          alt={VIEW_LOGO}
          onClick={viewAdditionalService}
          aria-hidden="true"
        />
        <p
          onClick={viewAdditionalService}
          aria-hidden="true"
          id={`NewContractAppContentAdditionalServicesFormTableTbodyRowViewText${additionalService.id}Id`}
          className="NewContractAppContentAdditionalServicesFormTableTbodyRowEditText"
        >
          {SERVICES_VIEW_BUTTON_TEXT}
        </p>
      </td>
      <td
        className="FormTextEditButtonStyle NewContractAppContentAdditionalServicesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentAdditionalServicesFormTableTbodyRowEditImg"
              src={EDIT_LOGO}
              alt={EDIT_LOGO}
              onClick={editAdditionalService}
              aria-hidden="true"
            />
            <p
              onClick={editAdditionalService}
              aria-hidden="true"
              id={`NewContractAppContentAdditionalServicesFormTableTbodyRowEditText${additionalService.id}Id`}
              className="NewContractAppContentAdditionalServicesFormTableTbodyRowEditText"
            >
              {SERVICES_EDIT_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
      <td
        className="FormTextDeleteButtonStyle NewContractAppContentAdditionalServicesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentAdditionalServicesFormTableTbodyRowDeleteImg"
              src={DELETE_LOGO}
              alt={DELETE_LOGO}
              onClick={deleteAdditionalService}
              aria-hidden="true"
            />
            <p
              onClick={deleteAdditionalService}
              aria-hidden="true"
              id={`NewContractAppContentAdditionalServicesFormTableTbodyRowDeleteText${additionalService.id}Id`}
              className="NewContractAppContentAdditionalServicesFormTableTbodyRowDeleteText"
            >
              {SERVICES_DELETE_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
    </tr>
  );
}
