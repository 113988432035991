import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  EDIT_CONTRACT_FORM_SERVICES_SEND_CANCELLATION_RULE_FORM_BUTTON,
  NEW_CONTRACT_FORM_SERVICES_SEND_CANCELLATION_RULE_FORM_BUTTON,
} from 'src/constants/componentTexts/CancellationRulesText';

import {
  apiPatchCancellationRule,
  apiPostCancellationRule,
} from 'src/invoicing/services/cancellationRules/CancellationRulesApiRequest';
import {
  CancelaltionRuleFilledInterface,
  CancellationRuleSentInterface,
  defaultCancellationRule,
} from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';
import {
  createPatchCancellationRuleBody,
  getDefaultCancellationRule,
  isFixedPriceFilled,
  isVariablePriceFilled,
} from 'src/invoicing/services/cancellationRules/CancellationRulesLogic';
import {
  resetPatchCancellationRuleStatus,
  resetPostCancellationRuleStatus,
  selectCancellationRuleErrorMessage,
  selectPatchCancellationRuleStatus,
  selectPostCancellationRuleStatus,
} from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';

import { displayErrorIfSubmitFailed } from 'src/invoicing/services/ServicesLogic';

interface Props {
  cancellationRuleFilled?: CancelaltionRuleFilledInterface | null;
  contractId: number | null;
}

export default function useCancellationRuleForm(props: Props) {
  const { cancellationRuleFilled, contractId } = props;

  const dispatch = useDispatch();

  const cancellationRuleResponseErrorMessage: string = useSelector(selectCancellationRuleErrorMessage);
  const patchCancellationRuleStatus: string = useSelector(selectPatchCancellationRuleStatus);
  const postCancellationRuleStatus: string = useSelector(selectPostCancellationRuleStatus);

  const [cancellationRule, setCancellationRule] = useState<CancellationRuleSentInterface>(getDefaultCancellationRule(cancellationRuleFilled, defaultCancellationRule));
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isFixedPriceChecked, setIsFixedPriceChecked] = useState<boolean>(isFixedPriceFilled(cancellationRuleFilled));
  const [isVariablePriceChecked, setIsVariablePriceChecked] = useState<boolean>(isVariablePriceFilled(cancellationRuleFilled));

  useEffect(() => {
    dispatch(resetPatchCancellationRuleStatus());
    dispatch(resetPostCancellationRuleStatus());
  }, [postCancellationRuleStatus, patchCancellationRuleStatus]);

  useEffect(() => {
    displayErrorIfSubmitFailed(postCancellationRuleStatus, patchCancellationRuleStatus, setIsErrorModalOpen, isErrorModalOpen);
  }, [cancellationRuleResponseErrorMessage, postCancellationRuleStatus, patchCancellationRuleStatus]);

  const submitCancellationRule = (event: FormEvent) => {
    event.preventDefault();

    if (cancellationRuleFilled) {
      dispatch(apiPatchCancellationRule(createPatchCancellationRuleBody(cancellationRule, contractId, cancellationRuleFilled.id)));
    } else {
      dispatch(apiPostCancellationRule({ ...cancellationRule, contract: contractId }));
    }
  };

  const getSendCancellationRuleButtoBasedOnUserAction = () => (
    cancellationRuleFilled ? EDIT_CONTRACT_FORM_SERVICES_SEND_CANCELLATION_RULE_FORM_BUTTON : NEW_CONTRACT_FORM_SERVICES_SEND_CANCELLATION_RULE_FORM_BUTTON
  );

  return {
    cancellationRule,
    cancellationRuleResponseErrorMessage,
    getSendCancellationRuleButtoBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    setCancellationRule,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    submitCancellationRule,
  };
}
