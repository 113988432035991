import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  invoicingDeleteApiRequestAsyncThunk,
  invoicingGetApiRequestAsyncThunk,
  invoicingPatchApiRequestAsyncThunk,
  invoicingPostApiRequestAsyncThunk,
} from 'src/invoicing/api/invoicingCustomApiRequestLogic';

export const apiGetAdditionalServices = createAsyncThunk('additionalServices/apiGetAdditionalServices', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/additional_services` })
));

export const apiGetAdditionalServiceParameters = createAsyncThunk('additionalServices/apiGetAdditionalServiceParameters', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/additional_service_parameters` })
));

export const apiGetAdditionalService = createAsyncThunk('additionalServices/apiGetAdditionalService', async (additionalServiceId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/additional_service/${additionalServiceId}` })
));

export const apiPostAdditionalService = createAsyncThunk('additionalServices/apiPostAdditionalService', async (body: any, { dispatch, getState, rejectWithValue }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/additional_service', body })
));

export const apiPatchAdditionalService = createAsyncThunk('additionalServices/apiPatchAdditionalService', async (body: { additionalService: any, additionalServiceId: number }, { dispatch, getState, rejectWithValue }) => (
  invoicingPatchApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/additional_service/${body.additionalServiceId}`, body: body.additionalService })
));

export const apiDeleteAdditionalService = createAsyncThunk('additionalServices/apiDeleteAdditionalService', async (additionalServiceId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingDeleteApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/additional_service/${additionalServiceId}` })
));
