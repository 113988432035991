import React from 'react';

import InvoiceDate from 'src/invoicing/invoices/InvoiceDate';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

export default function InvoiceDates(props: { invoice: InvoiceModel }) {
  const { invoice } = props;

  return (
    <>
      <InvoiceDate
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueArrivalDate"
        invoiceDate={invoice?.arrival_date}
      />
      <InvoiceDate
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueDepartureDate"
        invoiceDate={invoice?.departure_date}
      />
    </>
  );
}
