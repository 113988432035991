import React, { ChangeEvent, useState } from 'react';

import { SEARCHBAR_PLACEHOLDER, SEARCHBAR_DEFAULT_VALUE } from 'src/constants/componentTexts/SearchbarText';
import { TARMAC_SEARCH_LOGO } from 'src/constants/picturesPath';

import { InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';

interface Props {
  setInvoicesFilters: Function;
}

export default function Searchbar({ setInvoicesFilters }: Props) {
  const [searchbarValue, setSearchbarValue] = useState(SEARCHBAR_DEFAULT_VALUE);

  const searchbarOnChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setSearchbarValue(changeEvent.target.value);
    setInvoicesFilters((currentFilter: InvoicesFiltersModel) => ({
      ...currentFilter,
      searchbarValue: changeEvent.target.value,
    }));
  };

  return (
    <div className="InvoicesFiltersSearchBarWrapper">
      <div className="InvoicesFiltersSearchBarContainer">
        <img src={TARMAC_SEARCH_LOGO} alt="seachInputIcon" className="InvoicesFiltersSearchBarImage" />
        <input
          placeholder={SEARCHBAR_PLACEHOLDER}
          className="InvoicesFiltersSearchBarInput"
          onChange={searchbarOnChange}
          value={searchbarValue}
        />
      </div>
    </div>
  );
}
