import { useEffect, useState } from 'react';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

import { getInvoicesToDownloadWhenAllSelectedOrNot, hasInvoicesToDownload } from 'src/invoicing/invoices/InvoicesLogic';

interface Props {
  invoices: InvoiceModel[];
  invoicesToDownload: InvoiceModel[];
  setInvoicesToDownload: Function;
}

export default function useInvoices(props: Props) {
  const { invoices, invoicesToDownload, setInvoicesToDownload } = props;

  const [areInvoicesToDownload, setAreInvoicesToDownload] = useState<boolean>(false);

  useEffect(() => {
    if (hasInvoicesToDownload(invoicesToDownload)) {
      setAreInvoicesToDownload(false);
    }
  }, [invoicesToDownload]);

  const onInvoicesToDownloadChange = () => {
    setAreInvoicesToDownload(!areInvoicesToDownload);
    setInvoicesToDownload(getInvoicesToDownloadWhenAllSelectedOrNot(invoices, areInvoicesToDownload));
  };

  return {
    areInvoicesToDownload,
    onInvoicesToDownloadChange,
  };
}
