import React, { MouseEventHandler } from 'react';

import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import 'src/uiComponents/toggle/Toggle.css';

interface Props {
  id: number;
  idPrefix: string;
  onToggleClick: MouseEventHandler<HTMLDivElement>
  toggleText?: string;
  toggleValue: boolean;
}

export default function ToggleOnOff(props: Props) {
  const {
    id,
    idPrefix,
    onToggleClick,
    toggleText = EMPTY_VALUE,
    toggleValue,
  } = props;

  const getToggleClassNameBasedOnIfValueOn = () => (
    toggleValue
      ? 'ToggleOnOffSwitchCircleOn'
      : 'ToggleOnOffSwitchCircleOff'
  );

  const getToggleClassNameBehaviorBasedOnIsOn = () => (
    toggleValue ? 'on' : 'off'
  );

  return (
    <div id={`ToggleOnOff${idPrefix}${id}Id`} className="ToggleOnOff" onClick={onToggleClick} aria-hidden="true">
      <div className={`ToggleOnOffSwitch ${getToggleClassNameBehaviorBasedOnIsOn()}`}>
        <div id={`ToggleOnOffSwitcher${idPrefix}${id}Id`} className={`ToggleOnOffSwitchCircle ${getToggleClassNameBasedOnIfValueOn()}`} />
      </div>
      <p className="ToggleOnOffSwitchText">{toggleText}</p>
    </div>
  );
}
