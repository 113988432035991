import { ActionStatus, Status } from 'src/constants/status';
import {
  Airline,
  Airport,
  Contract,
  CompanyBusinessGroup,
  ContractParameters,
  Company,
  ContractsObject,
  StoredContract,
  defaultBasePrice,
  BasePrice,
} from 'src/invoicing/contract/ContractInterface';

export const getAllUserAirlines = (contractParameters: ContractParameters) => {
  const userAirlines: Airline[] = [];

  contractParameters.company_business_groups.map((option: CompanyBusinessGroup) => {
    if (userAirlines.findIndex((airline) => airline.label === option.airline.label) === -1) {
      return userAirlines.push(option.airline);
    }
    return null;
  });
  return userAirlines;
};

export const getAllUserAirportsAllowedWithSelectedAirline = (contractParameters: ContractParameters, contract: Contract) => {
  const userAirports: Airport[] = [];

  if (contract.airline) {
    contractParameters.company_business_groups.map((option: CompanyBusinessGroup) => {
      if (contract.airline === option.airline.code) {
        return userAirports.push(option.airport);
      }
      return null;
    });
  }
  return userAirports;
};

export const addAirportToUserAirportsIfItDoesNotExist = (userAirports: Airport[], option: CompanyBusinessGroup) => {
  if (userAirports.findIndex((airport) => airport.label === option.airport.label) === -1) {
    userAirports.push(option.airport);
  }
  return userAirports;
};

export const getAllUserAirports = (contractParameters: ContractParameters) => {
  const emptyUserAirports: Airport[] = [];

  return contractParameters.company_business_groups.reduce(addAirportToUserAirportsIfItDoesNotExist, emptyUserAirports);
};

export const getUserCompanyIfExists = (contractParameters: ContractParameters) => {
  if (localStorage.getItem('userCompany')) {
    const userCompany = localStorage.getItem('userCompany');

    if (userCompany) {
      const company = contractParameters.companies.find((contractParameterCompany: Company) => contractParameterCompany.label === userCompany);

      if (company !== undefined) {
        return userCompany;
      }
    }
  }
  return null;
};

export const getCompanyIdFromContract = (contractParameters: ContractParameters) => {
  if (localStorage.getItem('userCompany')) {
    const userCompany = localStorage.getItem('userCompany');

    if (userCompany) {
      const company = contractParameters.companies.find((contractParameterCompany: Company) => contractParameterCompany.label === userCompany);

      if (company !== undefined) {
        return company.id;
      }
    }
  }
  return null;
};

export const getContractByWithBasePriceStepsFilled = (contractByStepsFilled: boolean[], postContractResponseStatus: string) => {
  const contractByStepsFilledCopy = contractByStepsFilled;

  contractByStepsFilledCopy[1] = (postContractResponseStatus === Status.SUCCESS);

  return contractByStepsFilledCopy;
};

export const getContractByStepFilled = (contractByStepsFilled: boolean[], contract: Contract) => {
  const contractByStepsFilledCopy = contractByStepsFilled;

  contractByStepsFilledCopy[0] = Boolean(contract.airport);

  return contractByStepsFilledCopy;
};

export const hasContractsToDisplay = (contracts: any) => (
  contracts.length !== 0
);

export const getSelectedContractElementObjectByIdOrCode = (contractElement: number | string | null, contractParameterObjectSearched: any, elementKey: string) => {
  const elementObject = contractParameterObjectSearched.find((element: any) => element[elementKey] === contractElement);

  if (elementObject !== undefined) {
    return elementObject;
  }
  return null;
};

export const getSelectedContractAircraftModelsLabelsOrIds = (aircraftModelsOfContract: string[] | null, contractParameterAircraftModels: any, aircraftModelKey: string) => {
  const aircraftModels: any[] = [];

  aircraftModelsOfContract?.map((contractAircraftModel: string) => {
    const aircraftModelFinded = contractParameterAircraftModels.find((aircraftModel: { id: number, label: string }) => aircraftModel.label === contractAircraftModel);

    if (aircraftModelFinded !== undefined) {
      aircraftModels.push(aircraftModelFinded[aircraftModelKey]);
    }
    return null;
  });

  if (aircraftModels.length > 0) {
    return aircraftModels;
  }
  return null;
};

const getContractSelectedAirportOrAirlineObjectByCode = (contractElement: string | null, contractParameters: ContractParameters, contractKey: string) => {
  const pickedOption: {code: string, label: string}[] = [];

  contractParameters.company_business_groups.map((option: any) => {
    if (contractElement === option[contractKey].code) {
      return pickedOption.push(option[contractKey]);
    }
    return null;
  });

  return pickedOption[0];
};

export const createStoredContractWithContractInformations = (contract: Contract, contractParameters: ContractParameters) => (
  {
    id: 0,
    aircraft_models: getSelectedContractAircraftModelsLabelsOrIds(contract.aircraft_models, contractParameters.aircraft_models, 'label'),
    airline: getContractSelectedAirportOrAirlineObjectByCode(contract.airline, contractParameters, 'airline'),
    airport: getContractSelectedAirportOrAirlineObjectByCode(contract.airport, contractParameters, 'airport'),
    arrival_departure_separation_threshold_in_minutes: contract.arrival_departure_separation_threshold_in_minutes,
    base_price: {
      amount_for_complete_turnaround: '',
      amount_for_arrival_only_turnaround: null,
      amount_for_departure_only_turnaround: null,
    },
    company: null,
    customer: getSelectedContractElementObjectByIdOrCode(contract.customer, contractParameters.companies, 'id'),
    provider: getSelectedContractElementObjectByIdOrCode(contract.provider, contractParameters.companies, 'id'),
    end_datetime: contract.end_datetime,
    start_datetime: contract.start_datetime,
    currency: getSelectedContractElementObjectByIdOrCode(contract.currency, contractParameters.currencies, 'code'),
  }
);

export const getDefaultBasePrice = (storedContract: StoredContract) => (
  storedContract.base_price.amount_for_complete_turnaround !== '' ? storedContract.base_price : defaultBasePrice
);

export const getPreviousFilledContract = (filledContract: ContractsObject) => (
  {
    id: filledContract.id,
    aircraft_models: filledContract.aircraft_models.map((aircraftModel: { id: number, label: string }) => aircraftModel.label),
    airline: filledContract.airline.code,
    airport: filledContract.airport.code,
    arrival_departure_separation_threshold_in_minutes: filledContract.arrival_departure_separation_threshold_in_minutes,
    base_price: {
      amount_for_complete_turnaround: filledContract.base_price.amount_for_complete_turnaround,
      amount_for_arrival_only_turnaround: filledContract.base_price.amount_for_arrival_only_turnaround,
      amount_for_departure_only_turnaround: filledContract.base_price.amount_for_departure_only_turnaround,
    },
    company: filledContract.company.id,
    customer: filledContract.customer.id,
    provider: filledContract.provider.id,
    end_datetime: filledContract.end_datetime,
    start_datetime: filledContract.start_datetime,
    currency: filledContract.currency.code,
  }
);

export const isFieldReadOnlyWithEditAction = (contractAction: string) => (
  contractAction === ActionStatus.EDIT
);

export const isFieldReadOnlyWithViewAction = (contractAction: string) => (
  contractAction === ActionStatus.VIEW
);

export const createPatchContractBody = (storedContract: StoredContract, basePrice: BasePrice, contractParameters: any) => (
  {
    contract: {
      aircraft_models: getSelectedContractAircraftModelsLabelsOrIds(storedContract.aircraft_models, contractParameters.aircraft_models, 'id'),
      airline: storedContract.airline,
      airport: storedContract.airport,
      arrival_departure_separation_threshold_in_minutes: storedContract.arrival_departure_separation_threshold_in_minutes,
      base_price: basePrice,
      company: storedContract.company,
      customer: storedContract.customer,
      provider: storedContract.provider,
      end_datetime: storedContract.end_datetime,
      start_datetime: storedContract.start_datetime,
      currency: storedContract.currency,
    },
    contractId: storedContract.id,
  }
);

export const createDuplicationFilledContract = (contract: any) => (
  {
    id: contract.id,
    aircraft_models: contract.aircraft_models,
    airline: contract.airline,
    airport: contract.airport,
    arrival_departure_separation_threshold_in_minutes: contract.arrival_departure_separation_threshold_in_minutes,
    base_price: {
      amount_for_complete_turnaround: contract.base_price.amount_for_complete_turnaround,
      amount_for_arrival_only_turnaround: contract.base_price.amount_for_arrival_only_turnaround,
      amount_for_departure_only_turnaround: contract.base_price.amount_for_departure_only_turnaround,
    },
    company: contract.company,
    customer: contract.customer,
    provider: contract.provider,
    end_datetime: null,
    start_datetime: null,
    currency: contract.currency,
  }
);

export const areResponseApiRequestStatusFailed = (postNewContractStatus: string, patchContract: string, duplicateContractStatus: string) => (
  postNewContractStatus === Status.FAIL || patchContract === Status.FAIL || duplicateContractStatus === Status.FAIL
);
