import React from 'react';

import {
  NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_FIRST_COLUMN_TITLE,
  NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_SECOND_COLUMN_TITLE,
  NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_THIRD_COLUMN_TITLE,
} from 'src/constants/componentTexts/AdditionalServicesText';

import { AdditionalServiceReceivedInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';

import AdditionalService from 'src/invoicing/services/additionalServices/AdditionalService';

interface Props {
  additionalServices: AdditionalServiceReceivedInterface[];
  contractAction: string;
  contractStatus: string;
  setAdditionalServiceAction: Function;
  setIsAdditionalSubViewOpen: Function;
}

export default function AdditionalServices(props: Props) {
  const {
    additionalServices,
    contractAction,
    contractStatus,
    setAdditionalServiceAction,
    setIsAdditionalSubViewOpen,
  } = props;

  return (
    <div className="NewContractAppContentAdditionalServicesFormTableWrapper">
      <table
        id="NewContractAppContentAdditionalServicesFormTableId"
        className="NewContractAppContentAdditionalServicesFormTable"
      >
        <thead>
          <tr className="NewContractAppContentAdditionalServicesFormTableTheadRow">
            <th
              id="NewContractAppContentAdditionalServicesFormTableTheadRowServiceNameValueId"
              className="FormTextFontSize NewContractAppContentAdditionalServicesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_FIRST_COLUMN_TITLE}
            </th>
            <th
              id="NewContractAppContentAdditionalServicesFormTableTheadRowTypeOfPriceValueId"
              className="FormTextFontSize NewContractAppContentAdditionalServicesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_SECOND_COLUMN_TITLE}
            </th>
            <th
              id="NewContractAppContentAdditionalServicesFormTableTheadRowMeasurementValueId"
              className="FormTextFontSize NewContractAppContentAdditionalServicesFormTableTheadRowValueThirdColumn"
            >
              {NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_THIRD_COLUMN_TITLE}
            </th>
          </tr>
        </thead>
        <tbody>
          {additionalServices.map((additionalService: AdditionalServiceReceivedInterface) => (
            <AdditionalService
              additionalService={additionalService}
              contractAction={contractAction}
              contractStatus={contractStatus}
              key={additionalService.id.toString()}
              setAdditionalServiceAction={setAdditionalServiceAction}
              setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
