import React from 'react';

import { CONTRACTS_TITLE } from 'src/constants/componentTexts/ContractText';

import Contract from 'src/invoicing/contract/Contract';
import ContractsFilters from 'src/invoicing/contract/ContractsFilters';
import ContractStatusFilters from 'src/invoicing/contract/ContractStatusFilters';
import Loader from 'src/uiComponents/loader/Loader';
import NewContractButton from 'src/invoicing/contract/NewContractButton';
import NoContract from 'src/invoicing/contract/NoContract';

import { hasContractsToDisplay } from 'src/invoicing/contract/ContractLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import useContracts from 'src/invoicing/contract/useContracts';

interface Props {
  isNewContractOpen: boolean;
  setContractAction: Function;
  setIsNewContractOpen: Function;
}

export default function Contracts(props: Props) {
  const { isNewContractOpen, setContractAction, setIsNewContractOpen } = props;

  const {
    contractsFiltered,
    contractsFilters,
    getContractsResponseStatus,
    setContractsFilters,
  } = useContracts(isNewContractOpen);

  const displayNoContractOrContractsBasedOnDataReceivedAndFiltered = () => (
    hasContractsToDisplay(contractsFiltered) ? (
      <div className="ContractAppContractsWrapper">
        {contractsFiltered.map((contract: any) => (
          <Contract
            contract={contract}
            key={contract.id}
            setIsNewContractOpen={setIsNewContractOpen}
            setContractAction={setContractAction}
          />
        ))}
      </div>
    ) : <NoContract />
  );

  return (
    <div className="DefaultBackGroundColor ContractAppContent">
      <div className="ContractAppContentTitle TitleFontSize">
        <p id="ContractAppTitle" className="ContractAppContentTitleText">{CONTRACTS_TITLE}</p>
        <ContractStatusFilters
          contractsFilters={contractsFilters}
          contractsFiltered={contractsFiltered}
          setContractsFilters={setContractsFilters}
        />
      </div>
      <div className="ContractAppActions">
        <ContractsFilters contractsFilters={contractsFilters} setContractsFilters={setContractsFilters} />
        <NewContractButton setIsNewContractOpen={setIsNewContractOpen} setContractAction={setContractAction} />
      </div>
      {shouldLoaderBeDisplayed([getContractsResponseStatus]) ? (
        <Loader className="NewContractAppContentCancellationRulesLoader" />
      ) : displayNoContractOrContractsBasedOnDataReceivedAndFiltered()}
    </div>
  );
}
