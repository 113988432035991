import { createAsyncThunk } from '@reduxjs/toolkit';

import { refreshAccessTokenRequest } from 'src/invoicing/api/invoicingCustomApiRequests';

export const refreshAccessTokenApi = createAsyncThunk('invoicing/getInvoicingAccessTokenApi', async (_, { rejectWithValue }) => {
  try {
    const response = await refreshAccessTokenRequest();

    return response;
  } catch (refreshAccessTokenError: any) {
    return rejectWithValue(JSON.stringify(refreshAccessTokenError));
  }
});
