const TARMAC_STATIC_URL = process.env.REACT_APP_TARMAC_STATIC_URL;

export const ABOUT_TARMAC_LOGO = `${TARMAC_STATIC_URL}/img/icon/QUESTION_MARK.svg`;
export const ADD_ITEM_LOGO = `${TARMAC_STATIC_URL}/img/icon/ADD_ICON.png`;
export const AIRCRAFT_MODEL_LOGO = `${TARMAC_STATIC_URL}/img/icon/AIRCRAFT_MODEL.png`;
export const AIRLINE_LOGO_PATH = `${TARMAC_STATIC_URL}/img/airlines/`;
export const ARROW_LOGO = `${TARMAC_STATIC_URL}/img/icon/ARROW_ICON.png`;
export const CALENDAR_LOGO_PATH = `${TARMAC_STATIC_URL}/img/icon/calendar.png`;
export const DELETE_LOGO = `${TARMAC_STATIC_URL}/img/icon/DELETE.png`;
export const DOWNLOAD_LOGO = `${TARMAC_STATIC_URL}/img/icon/download.png`;
export const DUPLICATE_LOGO = `${TARMAC_STATIC_URL}/img/icon/DUPLICATE_ICON.png`;
export const EDIT_LOGO = `${TARMAC_STATIC_URL}/img/icon/EDIT_ICON.png`;
export const HIDDEN_PASSWORD_LOGO = `${TARMAC_STATIC_URL}/img/icon/HIDDEN_PASSWORD_ICON.svg`;
export const IN_PROGRESS_LOGO = `${TARMAC_STATIC_URL}/img/icon/double_arrow.png`;
export const LOGOUT_LOGO = `${TARMAC_STATIC_URL}/img/icon/logout.png`;
export const PAST_FUTURE_LOGO = `${TARMAC_STATIC_URL}/img/icon/simple_arrow.png`;
export const QUESTION_LOGO = `${TARMAC_STATIC_URL}/img/icon/question.png`;
export const SAVE_LOGO = `${TARMAC_STATIC_URL}/img/icon/save.png`;
export const SEARCH_LOGO = `${TARMAC_STATIC_URL}/img/icon/search.png`;
export const SORT_LOGO = `${TARMAC_STATIC_URL}/img/icon/sort.png`;
export const TARMAC_LOGO = `${TARMAC_STATIC_URL}/img/Logo-White.png`;
export const TARMAC_SEARCH_LOGO = `${TARMAC_STATIC_URL}/img/icon/SEARCH_ICON.png`;
export const TICK_LOGO = `${TARMAC_STATIC_URL}/img/icon/tick.png`;
export const VIEW_LOGO = `${TARMAC_STATIC_URL}/img/icon/VIEW.png`;
export const VIEW_PASSWORD_LOGO = `${TARMAC_STATIC_URL}/img/icon/VIEW_PASSWORD_ICON.svg`;
