import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  EDIT_CONTRACT_FORM_SERVICES_SEND_DELAY_RULE_FORM_BUTTON,
  NEW_CONTRACT_FORM_SERVICES_SEND_DELAY_RULE_FORM_BUTTON,
} from 'src/constants/componentTexts/DelayRulesText';

import { apiPatchDelayRule, apiPostDelayRule } from 'src/invoicing/services/delayRules/DelayRulesApiRequest';
import { createPatchDelayRuleBody, getDefaultDelayRule } from 'src/invoicing/services/delayRules/DelayRulesLogic';
import { defaultDelayRule, DelayRuleFilledInterface, DelayRuleSentInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';
import { isFixedPriceFilled, isVariablePriceFilled } from 'src/invoicing/services/cancellationRules/CancellationRulesLogic';
import {
  resetPatchDelayRuleStatus,
  resetPostDelayRuleStatus,
  selectDelayRuleErrorMessage,
  selectPatchDelayRuleStatus,
  selectPostDelayRuleStatus,
} from 'src/invoicing/services/delayRules/DelayRulesSlice';

import { displayErrorIfSubmitFailed } from 'src/invoicing/services/ServicesLogic';

interface Props {
  contractId: number | null;
  delayRuleFilled?: DelayRuleFilledInterface | null;
  setIsDelaySubViewOpen: Function;
}

export default function useDelayRuleForm(props: Props) {
  const {
    contractId,
    delayRuleFilled = null,
  } = props;

  const dispatch = useDispatch();

  const delayRuleResponseErrorMessage: string = useSelector(selectDelayRuleErrorMessage);
  const patchDelayRuleStatus: string = useSelector(selectPatchDelayRuleStatus);
  const postDelayRuleStatus: string = useSelector(selectPostDelayRuleStatus);

  const [delayRule, setDelayRule] = useState<DelayRuleSentInterface>(getDefaultDelayRule(delayRuleFilled, defaultDelayRule));
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isFixedPriceChecked, setIsFixedPriceChecked] = useState<boolean>(isFixedPriceFilled(delayRuleFilled));
  const [isVariablePriceChecked, setIsVariablePriceChecked] = useState<boolean>(isVariablePriceFilled(delayRuleFilled));

  useEffect(() => {
    dispatch(resetPatchDelayRuleStatus());
    dispatch(resetPostDelayRuleStatus());
  }, [postDelayRuleStatus, patchDelayRuleStatus]);

  useEffect(() => {
    displayErrorIfSubmitFailed(postDelayRuleStatus, patchDelayRuleStatus, setIsErrorModalOpen, isErrorModalOpen);
  }, [delayRuleResponseErrorMessage, postDelayRuleStatus, patchDelayRuleStatus]);

  const submitDelayRule = (event: FormEvent) => {
    event.preventDefault();

    if (delayRuleFilled) {
      dispatch(apiPatchDelayRule(createPatchDelayRuleBody(delayRule, contractId, delayRuleFilled.id)));
    } else {
      dispatch(apiPostDelayRule({ ...delayRule, contract: contractId }));
    }
  };

  const getSendDelayRuleTextButtonBasedOnUserAction = () => (
    delayRuleFilled ? EDIT_CONTRACT_FORM_SERVICES_SEND_DELAY_RULE_FORM_BUTTON : NEW_CONTRACT_FORM_SERVICES_SEND_DELAY_RULE_FORM_BUTTON
  );

  return {
    delayRule,
    delayRuleResponseErrorMessage,
    getSendDelayRuleTextButtonBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    setDelayRule,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    submitDelayRule,
  };
}
