import React, { FormEventHandler } from 'react';

import { SSO_SUBMIT_BUTTON } from 'src/constants/componentTexts/LoginText';

interface Props {
  setUsername: Function;
  submitSso: FormEventHandler<HTMLFormElement>;
  username: string;
}

export default function SsoForm(props: Props) {
  const { setUsername, submitSso, username } = props;

  return (
    <div className="LoginSSOWrapper">
      <form className="LoginSSO" onSubmit={submitSso}>
        <input
          id="LoginSSOUsernameInputId"
          className="DefaultFontSize LoginFormUserNameInput"
          type="text"
          name="username"
          placeholder="Username"
          required
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <button id="LoginSSOSubmitButtonId" type="submit" className="DefaultFontSize LoginSSOSubmitButton">
          {SSO_SUBMIT_BUTTON}
        </button>
      </form>
    </div>
  );
}
