import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AdditionalServiceParametersInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { apiGetAdditionalServiceParameters } from 'src/invoicing/services/additionalServices/AdditionalServicesApiRequest';
import { selectAdditionalServiceParameters, selectAdditionalServiceParametersStatus } from 'src/invoicing/services/additionalServices/AdditionalServicesSlice';

export default function useAdditionalServiceParameters(contractId: number | null) {
  const dispatch = useDispatch();

  const additionalServiceParameters: AdditionalServiceParametersInterface = useSelector(selectAdditionalServiceParameters);
  const getAdditionalServiceParametersStatus: string = useSelector(selectAdditionalServiceParametersStatus);

  useEffect(() => {
    if (contractId) {
      dispatch(apiGetAdditionalServiceParameters(contractId));
    }
  }, []);

  return {
    additionalServiceParameters,
    getAdditionalServiceParametersStatus,
  };
}
