import React, { ChangeEvent, useState } from 'react';

import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import {
  addStayUpClassNameIfInputFilled,
  addTransparentClassNameIfInputEmpty,
} from 'src/utils/logic/input/setUpClassForInputAnimation';

import { DateTimeInputProps } from 'src/uiComponents/input/InputInterface';
import { getDateTimeInputDefaultValue } from 'src/uiComponents/input/InputLogic';

export default function DateTimeInput(props: DateTimeInputProps) {
  const {
    formObjectPreviousFilledValue = EMPTY_VALUE,
    formObject,
    formObjectKey,
    inputLabel,
    isReadOnly = false,
    isRequired,
    setFormObject,
  } = props;

  const [dateTimeInputValue, setDateTimeInputValue] = useState<string>(getDateTimeInputDefaultValue(formObjectPreviousFilledValue));

  const onDateTimeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setDateTimeInputValue(event.target.value);

    if (event.target.value === EMPTY_VALUE) {
      setFormObject({ ...formObject, [formObjectKey]: null });
      setDateTimeInputValue(EMPTY_VALUE);
    } else {
      const dateChosenInUTCFormat = `${event.target.value}:00Z`;

      setFormObject({ ...formObject, [formObjectKey]: dateChosenInUTCFormat });
    }
  };

  return (
    <div className="InputWrapper">
      <input
        id={`DateTimeInput${formObjectKey}InputId`}
        value={dateTimeInputValue}
        onChange={onDateTimeInputChange}
        className={`InputWrapperInput ${addTransparentClassNameIfInputEmpty(dateTimeInputValue)} ${addStayUpClassNameIfInputFilled(dateTimeInputValue)}`}
        type="datetime-local"
        required={isRequired}
        readOnly={isReadOnly}
      />
      <label
        className={`InputWrapperLabel ${addStayUpClassNameIfInputFilled(dateTimeInputValue)}`}
        id={`DateTimeInput${formObjectKey}LabelId`}
      >
        {inputLabel}
      </label>
    </div>
  );
}
