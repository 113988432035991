import React from 'react';

import ListInput from 'src/uiComponents/input/ListInput';

interface Props {
  aircrafts: any;
  filters: any;
  setFilters: Function;
}

export default function AircraftFilter(props: Props) {
  const { aircrafts, filters, setFilters } = props;

  return (
    <div className="ContractAppContractsFiltersAircraft">
      <ListInput
        inputLabel="Aircraft"
        isRequired
        optionList={aircrafts}
        formObjectKey="aircraft_model"
        setFormObject={setFilters}
        formObject={filters}
      />
    </div>
  );
}
