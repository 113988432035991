import React from 'react';

import InvoicedTurnaroundAircraft from 'src/invoicing/invoiceDetail/InvoicedTurnaroundAircraft';
import InvoicedTurnaroundFlights from 'src/invoicing/invoiceDetail/InvoicedTurnaroundFlights';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';
import useCalculateInvoiceAmount from 'src/invoicing/invoiceDetail/useCalculateInvoiceAmount';

interface Props {
  customServices: CustomService[];
  getInvoicedTurnaroundFlight: Function;
  getInvoicedTurnaroundFlightPath: Function;
  invoice: any;
  servicesDescription: any;
}

export default function InvoicedTurnaroundDescription(props: Props) {
  const {
    customServices,
    getInvoicedTurnaroundFlight,
    getInvoicedTurnaroundFlightPath,
    invoice,
    servicesDescription,
  } = props;

  const { calculateInvoiceAmountWithCustomServices } = useCalculateInvoiceAmount(customServices, invoice, servicesDescription);

  return (
    <div className="InvoicedTurnaroundFlightInformation">
      <InvoicedTurnaroundFlights
        getInvoicedTurnaroundFlight={getInvoicedTurnaroundFlight}
        getInvoicedTurnaroundFlightPath={getInvoicedTurnaroundFlightPath}
        invoiceId={invoice.id}
      />
      <InvoicedTurnaroundAircraft invoice={invoice} />
      <p
        id={`InvoicedTurnaroundAmount${invoice.id}`}
        className="InvoicedTurnaroundAmount"
      >
        {calculateInvoiceAmountWithCustomServices()}
      </p>
      <div />
    </div>
  );
}
