import React from 'react';

import { DELETE_LOGO, EDIT_LOGO, VIEW_LOGO } from 'src/constants/picturesPath';
import {
  SERVICES_DELETE_BUTTON_TEXT,
  SERVICES_EDIT_BUTTON_TEXT,
  SERVICES_VIEW_BUTTON_TEXT,
} from 'src/constants/componentTexts/ServiceText';

import { canAServiceBeUpdated } from 'src/invoicing/services/ServicesLogic';
import { getAmountTypeLabel } from 'src/invoicing/services/operationTypes/OperationTypesLogic';
import { OperationTypeReceivedInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';
import useOperationType from 'src/invoicing/services/operationTypes/useOperationType';

interface Props {
  contractAction: string;
  contractStatus: string;
  operationType: OperationTypeReceivedInterface;
  setIsOperationTypeSubViewOpen: Function;
  setOperationTypeAction: Function;
}

export default function OperationType(props: Props) {
  const { contractAction, contractStatus, operationType } = props;

  const {
    deleteOperationType,
    editOperationType,
    getOperationTypeValuePrice,
    viewOperationType,
  } = useOperationType(props);

  return (
    <tr className="NewContractAppContentOperationTypesFormTableTbodyRow">
      <td
        id={`NewContractAppContentOperationTypesFormTableTbodyRowOperationValue${operationType.id}Id`}
        className="FormTextFontSize NewContractAppContentOperationTypesFormTableTbodyRowImportantValue"
      >
        {operationType.operation_name.label}
      </td>
      <td
        id={`NewContractAppContentOperationTypesFormTableTbodyRowTypeOfPriceValue${operationType.id}Id`}
        className="FormTextFontSize NewContractAppContentOperationTypesFormTableTbodyRowValue"
      >
        {getAmountTypeLabel(operationType)}
      </td>
      <td
        id={`NewContractAppContentOperationTypesFormTableTbodyRowValuesValue${operationType.id}Id`}
        className="FormTextFontSize NewContractAppContentOperationTypesFormTableTbodyRowValue"
      >
        {getOperationTypeValuePrice()}
      </td>
      <td className="NewContractAppContentOperationTypesFormTableTbodyRowSpacer" />
      <td
        className="FormTextEditButtonStyle NewContractAppContentOperationTypesFormTableTbodyRowAction"
      >
        <img
          className="NewContractAppContentOperationTypesFormTableTbodyRowViewImg"
          src={VIEW_LOGO}
          alt={VIEW_LOGO}
          onClick={viewOperationType}
          aria-hidden="true"
        />
        <p
          onClick={viewOperationType}
          aria-hidden="true"
          id={`NewContractAppContentOperationTypesFormTableTbodyRowViewText${operationType.id}Id`}
          className="NewContractAppContentOperationTypesFormTableTbodyRowEditText"
        >
          {SERVICES_VIEW_BUTTON_TEXT}
        </p>
      </td>
      <td
        className="FormTextEditButtonStyle NewContractAppContentOperationTypesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentOperationTypesFormTableTbodyRowEditImg"
              src={EDIT_LOGO}
              alt={EDIT_LOGO}
              onClick={editOperationType}
              aria-hidden="true"
            />
            <p
              onClick={editOperationType}
              aria-hidden="true"
              id={`NewContractAppContentOperationTypesFormTableTbodyRowEditText${operationType.id}Id`}
              className="NewContractAppContentOperationTypesFormTableTbodyRowEditText"
            >
              {SERVICES_EDIT_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
      <td
        className="FormTextDeleteButtonStyle NewContractAppContentOperationTypesFormTableTbodyRowAction"
      >
        {canAServiceBeUpdated(contractStatus, contractAction) && (
          <>
            <img
              className="NewContractAppContentOperationTypesFormTableTbodyRowDeleteImg"
              src={DELETE_LOGO}
              alt={DELETE_LOGO}
              onClick={deleteOperationType}
              aria-hidden="true"
            />
            <p
              onClick={deleteOperationType}
              aria-hidden="true"
              id={`NewContractAppContentOperationTypesFormTableTbodyRowDeleteText${operationType.id}Id`}
              className="NewContractAppContentOperationTypesFormTableTbodyRowDeleteText"
            >
              {SERVICES_DELETE_BUTTON_TEXT}
            </p>
          </>
        )}
      </td>
    </tr>
  );
}
