import React from 'react';

import CheckboxPriceTypeInput from 'src/uiComponents/input/CheckboxPriceTypeInput';

import {
  additionalServiceFormText,
  NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_CHECKBOX_TITLE,
} from 'src/constants/componentTexts/AdditionalServicesText';

interface Props {
  isFixedPriceChecked: boolean;
  isVariablePriceChecked: boolean;
  readOnly: boolean;
  setIsFixedPriceChecked: Function;
  setIsVariablePriceChecked: Function;
}

export default function AdditionalServiceFormPriceChoices(props: Props) {
  const {
    isFixedPriceChecked,
    isVariablePriceChecked,
    readOnly,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
  } = props;

  return (
    <div className="ServicesBox">
      <div className="ServicesBoxStepContent">
        <p className="ServicesBoxStepContentNumber">
          {additionalServiceFormText.typeOfPriceNumber}
        </p>
        <p className="ServicesSubViewBoxTitle" id="NewAdditionalServicePriceTitleId">
          {additionalServiceFormText.typeOfPriceText}
        </p>
      </div>
      <div className="ServicesBoxCheckbox">
        <p className="InputWrapperLabel FormTextFontSize" id="CheckboxLabelId">
          {NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_CHECKBOX_TITLE}
        </p>
        <div className="ServicesBoxCheckboxContent">
          <CheckboxPriceTypeInput
            checkboxKey="fixed_price"
            checkboxName={additionalServiceFormText.fixedPrice}
            checkboxFixedPriceSetter={setIsFixedPriceChecked}
            checkboxVariablePriceSetter={setIsVariablePriceChecked}
            checkboxValue={isFixedPriceChecked}
            isRequired
            isReadOnly={readOnly}
          />
          <CheckboxPriceTypeInput
            checkboxKey="variable_price"
            checkboxName={additionalServiceFormText.variablePrice}
            checkboxFixedPriceSetter={setIsFixedPriceChecked}
            checkboxVariablePriceSetter={setIsVariablePriceChecked}
            checkboxValue={isVariablePriceChecked}
            isRequired
            isReadOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
}
