import React, { useState } from 'react';

import {
  ContractStep,
  defaultStoredContract,
  StoredContract,
} from 'src/invoicing/contract/ContractInterface';

import AdditionalServicesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/additionalServicesByStep/AdditionalServicesByStep';
import BasePriceByStep from 'src/invoicing/contractAndServicesByStep/contractByStep/basePriceByStep/BasePriceByStep';
import CancellationRulesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/cancellationRulesByStep/CancellationRulesByStep';
import ContractByStep from 'src/invoicing/contractAndServicesByStep/contractByStep/ContractByStep';
import DelayRulesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/delayRulesbyStep/DelayRulesByStep';
import OperationTypeByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/operationTypesByStep/OperationTypesByStep';

import { getPreviousFilledContract } from 'src/invoicing/contract/ContractLogic';

interface Props {
  filledContract?: any;
  setIsContractByStepManagerOpen: Function;
}

export default function ContractByStepManager(props: Props) {
  const { filledContract = null, setIsContractByStepManagerOpen } = props;

  const defaultContractStepType: string = 'contractByStep';

  const [contractByStepsFilled, setcontractByStepsFilled] = useState<boolean[]>([false, false, false, false, false, false]);
  const [currentContractByStepType, setCurrentContractByStepType] = useState<string>(defaultContractStepType);
  const [storedContract, setStoredContract] = useState<StoredContract>(filledContract ? getPreviousFilledContract(filledContract) : defaultStoredContract);
  const contractBySteps: ContractStep[] = [
    {
      type: 'contractByStep',
      component: ContractByStep,
      params: {
        contractByStepsFilled,
        currentContractByStepType,
        nextComponent: 'basePriceByStep',
        previousComponent: null,
        setcontractByStepsFilled,
        setCurrentContractByStepType,
        setIsContractByStepManagerOpen,
        setStoredContract,
        storedContract,
      },
    },
    {
      type: 'basePriceByStep',
      component: BasePriceByStep,
      params: {
        contractByStepsFilled,
        currentContractByStepType,
        nextComponent: 'operationTypeByStep',
        previousComponent: 'contractByStep',
        setcontractByStepsFilled,
        setCurrentContractByStepType,
        setIsContractByStepManagerOpen,
        setStoredContract,
        storedContract,
      },
    },
    {
      type: 'operationTypeByStep',
      component: OperationTypeByStep,
      params: {
        contractByStepsFilled,
        currentContractByStepType,
        nextComponent: 'cancellationRulesByStep',
        previousComponent: null,
        setcontractByStepsFilled,
        setCurrentContractByStepType,
        setIsContractByStepManagerOpen,
        setStoredContract,
        storedContract,
      },
    },
    {
      type: 'cancellationRulesByStep',
      component: CancellationRulesByStep,
      params: {
        contractByStepsFilled,
        currentContractByStepType,
        nextComponent: 'delayRulesByStep',
        previousComponent: 'operationTypeByStep',
        setcontractByStepsFilled,
        setCurrentContractByStepType,
        setIsContractByStepManagerOpen,
        setStoredContract,
        storedContract,
      },
    },
    {
      type: 'delayRulesByStep',
      component: DelayRulesByStep,
      params: {
        contractByStepsFilled,
        currentContractByStepType,
        nextComponent: 'additionalServicesByStep',
        previousComponent: 'cancellationRulesByStep',
        setcontractByStepsFilled,
        setCurrentContractByStepType,
        setIsContractByStepManagerOpen,
        setStoredContract,
        storedContract,
      },
    },
    {
      type: 'additionalServicesByStep',
      component: AdditionalServicesByStep,
      params: {
        contractByStepsFilled,
        currentContractByStepType,
        nextComponent: null,
        previousComponent: 'delayRulesByStep',
        setcontractByStepsFilled,
        setCurrentContractByStepType,
        setIsContractByStepManagerOpen,
        setStoredContract,
        storedContract,
      },
    },
  ];

  const currentContractByStepObject: ContractStep | undefined = contractBySteps
    .find((contractByStep: any) => contractByStep.type === currentContractByStepType);

  const CurrentContractByStepComponent: any = currentContractByStepObject?.component;

  return (
    <CurrentContractByStepComponent
      {...currentContractByStepObject?.params}//eslint-disable-line
    />
  );
}
