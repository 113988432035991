import { EMPTY_VALUE, FLIGHTS_SEPARATOR } from 'src/constants/componentTexts/InvoiceDetailText';

export default function useTurnaroundFlightsPath(invoice: any) {
  const isDepartureFlightOnly = () => (
    !invoice.origin_airport_iata_code && invoice.destination_airport_iata_code
  );

  const isFullTurnaround = () => (
    invoice.origin_airport_iata_code && invoice.destination_airport_iata_code
  );

  const getOriginAirport = () => {
    if (isDepartureFlightOnly()) {
      return `${invoice.airport} ${FLIGHTS_SEPARATOR} `;
    }
    return `${invoice.origin_airport_iata_code} ${FLIGHTS_SEPARATOR}`;
  };

  const getAirportHub = () => {
    if (isDepartureFlightOnly()) {
      return `${invoice.destination_airport_iata_code}`;
    }
    return ` ${invoice.airport} `;
  };

  const getDestinationAirport = () => {
    if (isFullTurnaround()) {
      return `${FLIGHTS_SEPARATOR} ${invoice.destination_airport_iata_code}`;
    }
    return EMPTY_VALUE;
  };

  const getInvoicedTurnaroundFlightPath = () => (
    [getOriginAirport(), getAirportHub(), getDestinationAirport()]
  );

  return {
    getInvoicedTurnaroundFlightPath,
  };
}
