/* eslint-disable */
export enum Status {
  DEFAULT = 'default',
  SUCCESS = 'success',
  FAIL = 'fail',
  LOADING = 'loading',
}

export enum ContractStatus {
  PAST = 'PAST',
  IN_PROGRESS = 'IN PROGRESS',
  FUTURE = 'FUTURE',
}

export enum ActionStatus {
  NEW =  'New',
  EDIT =  'Edit',
  VIEW = 'View',
  DUPLICATE = 'Duplicate',
}

export enum InvoiceStatus {
  VALIDATED = 'VALIDATED',
  MISSING_INFO = 'MISSING_INFO',
  TO_CHECK = 'TO_CHECK',
}

export enum InvoicesSortStatus {
  STATUS_ASC = 'statusAsc',
  STATUS_DESC = 'statusDesc',
  ARRIVAL_DATE_ASC = 'arrival_dateAsc',
  ARRIVAL_DATE_DESC = 'arrival_dateDesc',
  DEPARTURE_DATE_ASC = 'departure_dateAsc',
  DEPARTURE_DATE_DESC = 'departure_dateDesc',
  AIRPORT_ASC = 'airportAsc',
  AIRPORT_DESC = 'airportDesc',
  AIRLINE_ASC = 'airlineAsc',
  AIRLINE_DESC = 'airlineDesc',
  ARRIVAL_FLIGHT_NUMBER_ASC = 'arrival_flight_numberAsc',
  ARRIVAL_FLIGHT_NUMBER_DESC = 'arrival_flight_numberDesc',  
  DEPARTURE_FLIGHT_NUMBER_ASC = 'departure_flight_numberAsc',
  DEPARTURE_FLIGHT_NUMBER_DESC = 'departure_flight_numberDesc',
  AIRCRAFT_TYPE_ASC = 'aircraft_typeAsc',
  AIRCRAFT_TYPE_DESC = 'aircraft_typeDesc',
  AIRCRAFT_REGISTRATION_ASC = 'registrationAsc',
  AIRCRAFT_REGISTRATION_DESC = 'registrationDesc',
  AMOUNT_ASC = 'amountAsc',
  AMOUNT_DESC = 'amountDesc',
}

export enum AmountFilterInputsId {
  EQUAL_TO = 'inputRadioEqualTo',
  SUPERIOR_TO = 'inputRadioSuperiorTo',
  INFERIOR_AT = 'inputRadioInferiorAt',
  NOT_EQUAL_TO = 'inputRadioNotEqualTo',
  SUPERIOR_OR_EQUAL_TO = 'inputRadioSuperiorOrEqualTo',
  INFERIOR_OR_EQUAL_TO = 'inputRadioInferiorOrEqualTo',
};
/* eslint-enable */
