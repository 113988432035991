import React, { FormEventHandler } from 'react';

interface Props {
  filters: {
    id: string;
    text: string;
  }[],
  onRadioButtonChange: FormEventHandler<HTMLFieldSetElement>;
  selectedRadioButtonId: string | null;
  setSelectedRadioButtonId: Function;
}

export default function QuickFilters(props: Props) {
  const {
    filters,
    onRadioButtonChange,
    selectedRadioButtonId,
    setSelectedRadioButtonId,
  } = props;

  return (
    <fieldset className="InvoicesDateRangeFieldset" onChange={onRadioButtonChange}>
      {filters.map(({ id, text }) => (
        <div className="InvoicesDateRangeRadioInputContainer" key={id}>
          <input
            className="InvoicesDateRangeRadioInput"
            type="radio"
            id={id}
            name="periodFilterShortcuts"
            onChange={(event) => setSelectedRadioButtonId(event.target.id)}
            checked={selectedRadioButtonId === id}
          />
          <label className="InvoicesDateRangeInputLabel" htmlFor={id}>{text}</label>
        </div>
      ))}
    </fieldset>
  );
}
