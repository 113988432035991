import React from 'react';

import { ActionStatus } from 'src/constants/status';
import { DELAY_RULES_STEP_NUMBER } from 'src/constants/componentTexts/DelayRulesText';

import ContractByStepNavigationButtons from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepNavigationButtons';
import DelayRulesEditor from 'src/invoicing/services/delayRules/DelayRulesEditor';
import EditDelayRule from 'src/invoicing/services/delayRules/EditDelayRule';
import NewDelayRule from 'src/invoicing/services/delayRules/NewDelayRule';
import Stepper from 'src/uiComponents/stepper/Stepper';
import ViewDelayRule from 'src/invoicing/services/delayRules/ViewDelayRule';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';
import useDelayRulesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/delayRulesbyStep/useDelayRulesByStep';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  storedContract: StoredContract;
  nextComponent: string | null;
  previousComponent: string | null;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setcontractByStepsFilled: Function;
}

export default function DelayRulesByStep(props: Props) {
  const {
    contractByStepsFilled,
    currentContractByStepType,
    storedContract,
    nextComponent,
    previousComponent,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    setcontractByStepsFilled,
  } = props;

  const {
    contractAction,
    delayRuleAction,
    isDelaySubViewOpen,
    setDelayRuleAction,
    setIsDelaySubViewOpen,
    submitDelays,
  } = useDelayRulesByStep(props);

  const displayDelayRuleSubViewActions = () => {
    switch (delayRuleAction) {
      case ActionStatus.EDIT:
        return (
          <EditDelayRule
            contractId={storedContract.id}
            setIsDelaySubViewOpen={setIsDelaySubViewOpen}
          />
        );
      case ActionStatus.VIEW:
        return (
          <ViewDelayRule
            contractId={storedContract.id}
            setIsDelaySubViewOpen={setIsDelaySubViewOpen}
          />
        );
      default:
        return (
          <NewDelayRule
            contractId={storedContract.id}
            setIsDelaySubViewOpen={setIsDelaySubViewOpen}
          />
        );
    }
  };

  return (
    <>
      <Stepper
        actualContractByStep={DELAY_RULES_STEP_NUMBER}
        setCurrentContractByStepType={setCurrentContractByStepType}
        contractByStepsFilled={contractByStepsFilled}
      />
      {isDelaySubViewOpen ? (
        displayDelayRuleSubViewActions()
      ) : (
        <form onSubmit={(event) => submitDelays(event)}>
          <DelayRulesEditor
            contractId={storedContract.id}
            isDelaySubViewOpen={isDelaySubViewOpen}
            setIsDelaySubViewOpen={setIsDelaySubViewOpen}
            contractByStepsFilled={contractByStepsFilled}
            setcontractByStepsFilled={setcontractByStepsFilled}
            setDelayRuleAction={setDelayRuleAction}

          />
          <ContractByStepNavigationButtons
            contractAction={contractAction}
            currentContractByStepType={currentContractByStepType}
            nextComponent={nextComponent}
            previousComponent={previousComponent}
            setCurrentContractByStepType={setCurrentContractByStepType}
            setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
          />
        </form>
      )}
    </>
  );
}
