import React from 'react';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';
import { BASE_PRICE_STEP_NUMBER } from 'src/constants/componentTexts/BasePriceText';

import Stepper from 'src/uiComponents/stepper/Stepper';
import BasePriceStep from 'src/invoicing/contract/basePrice/BasePriceStep';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  nextComponent: string | null;
  previousComponent: string | null;
  setStoredContract: Function;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setcontractByStepsFilled: Function;
  storedContract: StoredContract;
}

export default function BasePriceByStep(props: Props) {
  const {
    contractByStepsFilled,
    currentContractByStepType,
    nextComponent,
    previousComponent,
    setStoredContract,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    setcontractByStepsFilled,
    storedContract,
  } = props;

  return (
    <>
      <Stepper
        actualContractByStep={BASE_PRICE_STEP_NUMBER}
        setCurrentContractByStepType={setCurrentContractByStepType}
        contractByStepsFilled={contractByStepsFilled}
      />
      <BasePriceStep
        contractByStepsFilled={contractByStepsFilled}
        currentContractByStepType={currentContractByStepType}
        storedContract={storedContract}
        nextComponent={nextComponent}
        previousComponent={previousComponent}
        setStoredContract={setStoredContract}
        setCurrentContractByStepType={setCurrentContractByStepType}
        setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
        setcontractByStepsFilled={setcontractByStepsFilled}
      />
    </>
  );
}
