import {
  additionalServiceEmptyPrice,
  AddServiceDataTypeToMesure,
  ADD_SERVICE_EMPTY_VALUE,
  dataTypeForCompletedDescriptionPrice,
  dataTypeToMesureAuthorizedToDisplayAddInfosDataSource,
  dataTypeToMesureAuthorizedToDisplayTaskDataSource,
  dataTypeWithPrice,
} from 'src/constants/componentTexts/AdditionalServicesText';
import { AdditionalServiceSentInterface, defaultAdditionalService } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { getAddInfosBasedOnTaskDataSourceChoice, getPriceStepsBasedOnUserAmountTypePicked } from 'src/invoicing/services/ServicesLogic';
import { getKeyValueFromObject } from 'src/utils/logic/object';

export const getAdditionalServiceTextBasedOnDataTypeToMesurePicked = (additionalService: AdditionalServiceSentInterface, defaultTexts: any, additionalServicePriceText: any) => (
  additionalService?.data_type_to_mesure ? additionalServicePriceText[additionalService.data_type_to_mesure] : defaultTexts
);

export const createPatchAdditionalServiceBody = (additionalService: AdditionalServiceSentInterface, contractId: number | null, additionalServiceFilledId: number, additionalServicePrice: any, additionalServiceTimingDifference: any) => (
  {
    additionalService: {
      ...additionalService, contract: contractId, price: additionalServicePrice, timings_difference: additionalServiceTimingDifference,
    },
    additionalServiceId: additionalServiceFilledId,
  }
);

export const getFilledAddtionalServicePriceBasedOnDataTypetoMesure = (additionalServiceFilled: any) => {
  switch (additionalServiceFilled?.data_type_to_mesure.code) {
    case AddServiceDataTypeToMesure.NUMERIC_DATA:
    case AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS:
      return {
        amounts_type: additionalServiceFilled.price.amounts_type.code,
        values_type: additionalServiceFilled.price.values_type.id,
        price_steps: additionalServiceFilled.price.price_steps,
      };
    case AddServiceDataTypeToMesure.YES_NO:
    case AddServiceDataTypeToMesure.COMPLETION_OF_ONE_TASK:
    default:
      return {
        amount_if_true: additionalServiceFilled.price.amount_if_true,
        amount_if_false: additionalServiceFilled.price.amount_if_false,
        amounts_type: additionalServiceFilled.price.amounts_type.code,
      };
  }
};

export const getTimingDifference = (additionalServiceFilled: any) => (
  additionalServiceFilled?.data_type_to_mesure.code === AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS
    ? {
      task_A_arrival_or_departure: additionalServiceFilled.timings_difference?.task_A_arrival_or_departure.code,
      task_datasource_A: additionalServiceFilled.timings_difference.task_datasource_A.id,
      task_A_timing_reference: additionalServiceFilled.timings_difference?.task_A_timing_reference || null,
      task_B_arrival_or_departure: additionalServiceFilled.timings_difference?.task_B_arrival_or_departure.code,
      task_datasource_B: additionalServiceFilled.timings_difference.task_datasource_B.id,
      task_B_timing_reference: additionalServiceFilled.timings_difference?.task_B_timing_reference || null,
      additional_information_datasource_A: additionalServiceFilled.timings_difference.additional_information_datasource_A?.id || null,
      additional_information_datasource_B: additionalServiceFilled.timings_difference.additional_information_datasource_B?.id || null,
    }
    : null
);

export const getDefaultAdditionalService = (additionalServiceFilled: any | null) => (
  additionalServiceFilled ? {
    ...defaultAdditionalService,
    arrival_or_departure_service: additionalServiceFilled.arrival_or_departure_service,
    additional_service_name: additionalServiceFilled.additional_service_name.id,
    additional_information_datasource: additionalServiceFilled.additional_information_datasource ? additionalServiceFilled.additional_information_datasource.id : null,
    data_type_to_mesure: additionalServiceFilled.data_type_to_mesure.code,
    timings_difference: getTimingDifference(additionalServiceFilled),
    task_datasource: additionalServiceFilled.task_datasource ? additionalServiceFilled.task_datasource.id : null,
    price: getFilledAddtionalServicePriceBasedOnDataTypetoMesure(additionalServiceFilled),
  } : defaultAdditionalService
);

export const getPriceBasedOnUserAmountTypePicked = (filledService: any, amountTypeCode: string) => (
  (filledService && filledService?.price?.amounts_type.code === amountTypeCode)
    ? filledService?.price
    : { amount_if_true: null, amount_if_false: null, amounts_type: null }
);

export const getAdditionalServiceEmptyPriceBasedOnUserdDataTypePicked = (service: any) => {
  if (service?.data_type_to_mesure) {
    switch (service?.data_type_to_mesure) {
      case AddServiceDataTypeToMesure.COMPLETION_OF_ONE_TASK:
        return additionalServiceEmptyPrice.COMPLETION_OF_ONE_TASK;
      case AddServiceDataTypeToMesure.YES_NO:
        return additionalServiceEmptyPrice.YES_NO;
      case AddServiceDataTypeToMesure.NUMERIC_DATA:
        return additionalServiceEmptyPrice.NUMERIC_DATA;
      case AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS:
        return additionalServiceEmptyPrice.DIFFERENCE_BETWEEN_TWO_TIMINGS;
      default:
        return additionalServiceEmptyPrice.COMPLETION_OF_ONE_TASK;
    }
  }
  return null;
};

export const getAdditionalServicePriceBasedOnUserdDataTypePicked = (filledService: any, amountTypeCode: string, service: any) => {
  if (filledService?.data_type_to_mesure.code) {
    switch (filledService?.data_type_to_mesure.code) {
      case AddServiceDataTypeToMesure.NUMERIC_DATA:
      case AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS:
        return getPriceStepsBasedOnUserAmountTypePicked(filledService, amountTypeCode);
      case AddServiceDataTypeToMesure.COMPLETION_OF_ONE_TASK:
      case AddServiceDataTypeToMesure.YES_NO:
      default:
        return getPriceBasedOnUserAmountTypePicked(filledService, amountTypeCode);
    }
  } else {
    return getAdditionalServiceEmptyPriceBasedOnUserdDataTypePicked(service);
  }
};

export const getAddInfoDatasourceLabel = (additionalService: any, additionalServiceParameters: any) => {
  const taskAddInfosDatasource = getAddInfosBasedOnTaskDataSourceChoice(additionalService.task_datasource, additionalServiceParameters);

  const addInfo = taskAddInfosDatasource.find((taskAddInfo: any) => taskAddInfo.id === additionalService.additional_information_datasource);

  if (addInfo !== undefined) {
    return addInfo.label;
  }
  return '';
};

export const didDepartureDescriptionNeedToDisplay = (priceSpecification: string | null) => (
  priceSpecification ? !dataTypeWithPrice.includes(priceSpecification) : true
);

export const didCompletedDescriptionNeedToDisplay = (priceSpecification: string | null) => (
  priceSpecification ? !dataTypeForCompletedDescriptionPrice.includes(priceSpecification) : true
);

export const didArrivalDescriptionNeedToDisplay = (priceSpecification: string | null) => (
  priceSpecification ? !dataTypeForCompletedDescriptionPrice.includes(priceSpecification) : true
);

export const canRenderTaskDatasourceInput = (additionalService: AdditionalServiceSentInterface) => (
  (additionalService?.data_type_to_mesure && dataTypeToMesureAuthorizedToDisplayTaskDataSource
    .includes(additionalService?.data_type_to_mesure))
);

export const canRenderAdditionalInfoDatasourceInput = (additionalService: AdditionalServiceSentInterface) => (
  (additionalService?.data_type_to_mesure && dataTypeToMesureAuthorizedToDisplayAddInfosDataSource
    .includes(additionalService?.data_type_to_mesure))
);

export const canRenderDifferenceBetweenTwoTimings = (additionalService: AdditionalServiceSentInterface) => (
  (additionalService?.data_type_to_mesure && additionalService?.data_type_to_mesure === AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS)
);

export const getPreviousLabelFilled = (addServiceDifferenceKey: string, additionalServiceFilled: any): string => {
  let previousTaskDatasourceLabelFilled: string = ADD_SERVICE_EMPTY_VALUE;

  const addServiceTaskDifference: any = getKeyValueFromObject(additionalServiceFilled?.timings_difference, addServiceDifferenceKey);

  if (addServiceTaskDifference && typeof addServiceTaskDifference === 'object') {
    previousTaskDatasourceLabelFilled = addServiceTaskDifference.label;
  }
  return previousTaskDatasourceLabelFilled;
};
