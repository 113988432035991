import React, { useEffect, useState } from 'react';

import { ADD_ITEM_LOGO } from 'src/constants/picturesPath';

import { CustomStepsObject } from 'src/invoicing/services/customSteps/CustomStepInterface';

import CustomStep from 'src/invoicing/services/customSteps/customStep';
import CustomStepDescription from 'src/invoicing/services/customSteps/customStepDescription';

import 'src/invoicing/services/customSteps/customSteps.css';

interface Props {
  amountTypeCode: string;
  customStepsAmountType: string;
  customStepsText: any;
  formObject: any;
  formObjectDefaultValue?: any;
  setFormObject: Function;
  serviceAddInfo: string;
  serviceName: string;
  readOnly: boolean;
  valuesTypes?: number;
}

export default function CustomSteps(props: Props) {
  const {
    amountTypeCode,
    customStepsAmountType,
    customStepsText,
    formObject,
    formObjectDefaultValue = [{
      min_value: null, max_value: null, amount: null, order: 0,
    }],
    serviceAddInfo,
    serviceName,
    setFormObject,
    readOnly,
    valuesTypes,
  } = props;

  const [customSteps, setCustomSteps] = useState<CustomStepsObject[]>(formObjectDefaultValue);
  const [previousCustomSteps, setPreviousCustomSteps] = useState<any>([]);
  const [previousLengthCustomSteps, setPreviousLengthCustomSteps] = useState<number>(formObjectDefaultValue[0].min_value ? formObjectDefaultValue.length : 0);

  useEffect(() => {}, [customSteps.length !== previousLengthCustomSteps]);
  useEffect(() => {}, [previousCustomSteps !== customSteps]);

  const renderCustomSteps = () => (
    customSteps.map((customStep: any, index: number) => (
      <CustomStep
        amountTypeCode={amountTypeCode}
        customStepIndex={index}
        customSteps={customSteps}
        customStepsAmountType={customStepsAmountType}
        customStepsText={customStepsText}
        formObject={formObject}
        key={customStepsAmountType + index}
        serviceName={serviceName}
        setCustomSteps={setCustomSteps}
        setFormObject={setFormObject}
        setPreviousCustomSteps={setPreviousCustomSteps}
        readOnly={readOnly}
        valuesTypes={valuesTypes}
      />
    ))
  );

  const renderCustomStepsDescription = () => (
    customSteps.map((customStep: any, index: number) => (
      <CustomStepDescription
        customStep={customStep}
        customStepIndex={index}
        customStepsAmountType={customStepsAmountType}
        customStepsText={customStepsText}
        key={customStep.amount + index}
        serviceName={serviceName}
        serviceAddInfo={serviceAddInfo}
      />
    ))
  );

  const addCustomStep = () => {
    const customStepsCopy: CustomStepsObject[] = [...customSteps];

    customStepsCopy.push({
      min_value: null, max_value: null, amount: null, order: customStepsCopy.length,
    });
    setPreviousLengthCustomSteps(customStepsCopy.length);
    setCustomSteps(customStepsCopy);
  };

  return (
    <div className="CustomSteps">
      {renderCustomSteps()}
      {renderCustomStepsDescription()}
      {!readOnly && (
        <div className="CustomStepsAddNew">
          <img
            id={`${serviceName}AddNewCustomStep`}
            aria-hidden="true"
            onClick={readOnly ? () => {} : () => addCustomStep()}
            className="CustomStepsAddNewLogo"
            src={ADD_ITEM_LOGO}
            alt={ADD_ITEM_LOGO}
          />
        </div>
      )}
    </div>
  );
}
