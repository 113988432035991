import React from 'react';

import { NEW_CONTRACT_FORM_VIEW_DELAY_RULE_TITLE } from 'src/constants/componentTexts/DelayRulesText';

import DelayRuleForm from 'src/invoicing/services/delayRules/form/DelayRuleForm';
import Loader from 'src/uiComponents/loader/Loader';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import useDelayRuleParameters from 'src/invoicing/services/delayRules/useDelayRuleParameters';
import useGettingDelayRuleSelected from 'src/invoicing/services/delayRules/useGettingDelayRuleSelected';

interface Props {
  contractId: number | null;
  setIsDelaySubViewOpen: Function;
}

export default function ViewDelayRule(props: Props) {
  const { contractId, setIsDelaySubViewOpen } = props;

  const { delayRuleParameters, delayRuleParametersStatus } = useDelayRuleParameters(contractId);
  const { delayRule, getDelayRuleStatus } = useGettingDelayRuleSelected();

  return (
    shouldLoaderBeDisplayed([getDelayRuleStatus, delayRuleParametersStatus]) ? (
      <Loader className="NewContractAppContentDelayRulesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="ViewDelayRuleTitleId">{NEW_CONTRACT_FORM_VIEW_DELAY_RULE_TITLE}</p>
        </div>
        <DelayRuleForm
          contractId={contractId}
          delayRuleFilled={delayRule}
          delayRuleParameters={delayRuleParameters}
          readOnly
          setIsDelaySubViewOpen={setIsDelaySubViewOpen}
        />
      </div>
    )
  );
}
