import React from 'react';

import { NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TITLE } from 'src/constants/componentTexts/CancellationRulesText';

import CancellationRuleForm from 'src/invoicing/services/cancellationRules/form/CancellationRuleForm';
import Loader from 'src/uiComponents/loader/Loader';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectPostCancellationRuleStatus } from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';
import useCancellationRuleParameters from 'src/invoicing/services/cancellationRules/useCancellationRuleParameters';
import useCancellationRuleSubmittionRequestStatus from 'src/invoicing/services/cancellationRules/useCancellationRuleSubmittionRequestStatus';

import 'src/invoicing/services/Services.css';

interface Props {
  contractId: number | null;
  setIsCancellationRuleSubViewOpen: Function;
}

export default function NewCancellationRule(props: Props) {
  const { contractId, setIsCancellationRuleSubViewOpen } = props;

  const { cancellationRuleParameters, cancellationRuleParametersStatus } = useCancellationRuleParameters(contractId);
  useCancellationRuleSubmittionRequestStatus(selectPostCancellationRuleStatus, setIsCancellationRuleSubViewOpen);

  return (
    shouldLoaderBeDisplayed([cancellationRuleParametersStatus]) ? (
      <Loader className="NewContractAppContentCancellationRulesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="NewCancellationRuleTitleId">{NEW_CONTRACT_FORM_NEW_CANCELLATION_RULE_TITLE}</p>
        </div>
        <CancellationRuleForm
          cancellationRuleParameters={cancellationRuleParameters}
          contractId={contractId}
          setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
        />
      </div>
    )
  );
}
