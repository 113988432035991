import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  EDIT_CONTRACT_FORM_SERVICES_SEND_OPERATION_TYPE_FORM_BUTTON,
  NEW_CONTRACT_FORM_SERVICES_SEND_OPERATION_TYPE_FORM_BUTTON,
} from 'src/constants/componentTexts/OperationTypesText';

import {
  apiPatchOperationType,
  apiPostOperationType,
} from 'src/invoicing/services/operationTypes/OperationTypesApiRequest';
import {
  OperationTypeFilledInterface,
  OperationTypeSentInterface,
  PriceInterface,
} from 'src/invoicing/services/operationTypes/OperationTypesInterface';
import {
  createOperationTypePatchBodyRequest,
  getDefaultOperationType,
  isFixedPriceFilled,
  isVariablePriceFilled,
} from 'src/invoicing/services/operationTypes/OperationTypesLogic';
import {
  resetPatchOperationtypeStatus,
  resetPostOperationtypeStatus,
  selectOperationTypeErrorMessage,
  selectPatchOperationTypeStatus,
  selectPostOperationTypesStatus,
} from 'src/invoicing/services/operationTypes/OperationTypesSlice';

import { displayErrorIfSubmitFailed } from 'src/invoicing/services/ServicesLogic';

interface Props {
  contractId: number | null;
  operationTypeFilled?: OperationTypeFilledInterface | null;
}

export default function useOperationTypeForm(props: Props) {
  const {
    contractId,
    operationTypeFilled = null,
  } = props;

  const dispatch = useDispatch();

  const operationTypeResponseErrorMessage: string = useSelector(selectOperationTypeErrorMessage);
  const postOperationTypeStatus: string = useSelector(selectPostOperationTypesStatus);
  const patchOperationTypeStatus: string = useSelector(selectPatchOperationTypeStatus);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<OperationTypeSentInterface>(getDefaultOperationType(operationTypeFilled));
  const [operationTypePrice, setOperationTypePrice] = useState<PriceInterface>(operationType.price);
  const [isFixedPriceChecked, setIsFixedPriceChecked] = useState<boolean>(isFixedPriceFilled(operationTypeFilled));
  const [isVariablePriceChecked, setIsVariablePriceChecked] = useState<boolean>(isVariablePriceFilled(operationTypeFilled));

  useEffect(() => {
    dispatch(resetPostOperationtypeStatus());
    dispatch(resetPatchOperationtypeStatus());
  }, [postOperationTypeStatus, patchOperationTypeStatus]);

  useEffect(() => {
    displayErrorIfSubmitFailed(postOperationTypeStatus, patchOperationTypeStatus, setIsErrorModalOpen, isErrorModalOpen);
  }, [operationTypeResponseErrorMessage, postOperationTypeStatus, patchOperationTypeStatus]);

  const submitOperationType = (event: FormEvent) => {
    event.preventDefault();

    if (operationTypeFilled) {
      dispatch(apiPatchOperationType(createOperationTypePatchBodyRequest(operationType, contractId, operationTypeFilled.id, operationTypePrice)));
    } else {
      dispatch(apiPostOperationType({ ...operationType, contract: contractId, price: operationTypePrice }));
    }
  };

  const getSendOperationTypeButtonBasedOnUserAction = () => (
    operationTypeFilled ? EDIT_CONTRACT_FORM_SERVICES_SEND_OPERATION_TYPE_FORM_BUTTON : NEW_CONTRACT_FORM_SERVICES_SEND_OPERATION_TYPE_FORM_BUTTON
  );

  return {
    getSendOperationTypeButtonBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    operationType,
    operationTypePrice,
    operationTypeResponseErrorMessage,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    setOperationType,
    setOperationTypePrice,
    submitOperationType,
  };
}
