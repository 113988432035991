import { FocusEvent, useState } from 'react';

import { AMOUNT_FILTER_KEY, PERIOD_FILTER_KEY } from 'src/constants/componentTexts/InvoiceAppText';

import { DropDownValues } from 'src/invoicing/invoices/InvoicesInterface';
import { displayAmountWithTwoDecimalIfDecimal } from 'src/invoicing/invoices/InvoicesLogic';

import { convertStringToDDMMYYYYUTCDate } from 'src/utils/logic/converter/dateConvertor';

interface Props {
  dropDownValues: DropDownValues;
  dropDownValuesKey: string;
}

export default function useDropDown(props: Props) {
  const { dropDownValues, dropDownValuesKey } = props;

  const [isDropDownClose, setIsDropDownClose] = useState<Boolean>(true);
  const [showContent, setShowContent] = useState(false);

  const openDropDown = () => {
    setShowContent(true);
    setIsDropDownClose(false);
  };

  const closeDropDown = (event: FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsDropDownClose(true);
      setShowContent(false);
    }
  };

  const displayValuePickedBasedOnDropDownValuesKey = () => {
    if (dropDownValuesKey === PERIOD_FILTER_KEY) {
      if (dropDownValues[dropDownValuesKey].fromDate && dropDownValues[dropDownValuesKey].toDate) {
        return (dropDownValues[dropDownValuesKey].fromDate === dropDownValues[dropDownValuesKey].toDate)
          ? convertStringToDDMMYYYYUTCDate(dropDownValues[dropDownValuesKey].fromDate)
          : `${convertStringToDDMMYYYYUTCDate(dropDownValues[dropDownValuesKey].fromDate)} - ${convertStringToDDMMYYYYUTCDate(dropDownValues[dropDownValuesKey].toDate)}`;
      }
    }
    if (dropDownValuesKey === AMOUNT_FILTER_KEY) {
      if (dropDownValues[dropDownValuesKey].amountSelected && dropDownValues[dropDownValuesKey].amountSymbol) {
        return `${dropDownValues[dropDownValuesKey].amountSymbol} ${displayAmountWithTwoDecimalIfDecimal(dropDownValues[dropDownValuesKey]?.amountSelected)}`;
      }
    }
    setIsDropDownClose(false);
    return null;
  };

  return {
    closeDropDown,
    displayValuePickedBasedOnDropDownValuesKey,
    isDropDownClose,
    openDropDown,
    showContent,
  };
}
