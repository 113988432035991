export const convertDecimalNumberIntoInteger = (value: string) => {
  if (value) {
    if (value.includes('.')) {
      const valueWithoutDecimal = value.replace('.', '');
      return Number(valueWithoutDecimal);
    }
    return Number(`${value}00`);
  }
  return 0;
};
