import React from 'react';

import {
  NEW_CONTRACT_FORM_CANCELLATION_RULES_TABLE_FIRST_COLUMN_TITLE,
  NEW_CONTRACT_FORM_CANCELLATION_RULES_TABLE_SECOND_COLUMN_TITLE,
} from 'src/constants/componentTexts/CancellationRulesText';

import { CancellationRuleReceivedInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';

import CancellationRule from 'src/invoicing/services/cancellationRules/CancellationRule';

interface Props {
  cancellationRules: CancellationRuleReceivedInterface[];
  contractAction: string;
  contractStatus: string;
  setCancellationRuleAction: Function;
  setIsCancellationRuleSubViewOpen: Function;
}

export default function CancellationRules(props: Props) {
  const {
    cancellationRules,
    contractAction,
    contractStatus,
    setCancellationRuleAction,
    setIsCancellationRuleSubViewOpen,
  } = props;

  return (
    <div className="NewContractAppContentCancellationRulesFormTableWrapper">
      <table
        id="NewContractAppContentCancellationRulesFormTableId"
        className="NewContractAppContentCancellationRulesFormTable"
      >
        <thead>
          <tr className="NewContractAppContentCancellationRulesFormTableTheadRow">
            <th
              id="NewContractAppContentCancellationRulesFormTableTheadRowCauseOfCancellationValueId"
              className="FormTextFontSize NewContractAppContentCancellationRulesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_CANCELLATION_RULES_TABLE_FIRST_COLUMN_TITLE}
            </th>
            <th
              id="NewContractAppContentCancellationRulesFormTableTheadRowTypeOfPriceValueId"
              className="FormTextFontSize NewContractAppContentCancellationRulesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_CANCELLATION_RULES_TABLE_SECOND_COLUMN_TITLE}
            </th>
          </tr>
        </thead>
        <tbody>
          {cancellationRules.map((cancellationRule: any) => (
            <CancellationRule
              cancellationRule={cancellationRule}
              contractAction={contractAction}
              contractStatus={contractStatus}
              key={cancellationRule.id.toString()}
              setCancellationRuleAction={setCancellationRuleAction}
              setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
