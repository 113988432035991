import React from 'react';

import useCustomStep from 'src/invoicing/services/customSteps/useCustomStep';

import { CustomStepsObject } from 'src/invoicing/services/customSteps/CustomStepInterface';
import { formatDisplayNumberToIntegerIfNothingInTheDecimal } from 'src/invoicing/services/ServicesLogic';

interface Props {
  amountTypeCode: string;
  customStepIndex: number;
  customSteps: CustomStepsObject[];
  customStepsAmountType: string;
  customStepsText: any;
  formObject: any;
  serviceName: string;
  setCustomSteps: Function;
  setFormObject: Function;
  setPreviousCustomSteps: Function;
  readOnly: boolean;
  valuesTypes?: number;
}

export default function CustomStep(props: Props) {
  const {
    customStepIndex,
    customSteps,
    customStepsAmountType,
    customStepsText,
    serviceName,
    readOnly,
  } = props;

  const {
    onCustomStepInputChange,
  } = useCustomStep(props);

  return (
    <div className="CustomStepsCustomStepContent">
      <div className="CustomStepsCustomStepContentStep">
        <p className="CustomStepsCustomStepContentStepText">
          {`Step ${customStepIndex + 1}`}
        </p>
      </div>
      <div className="CustomStepsCustomStepContentMinDuration">
        <p
          id={`${serviceName}MinDurationText${customStepIndex}Id`}
          className="CustomStepsCustomStepContentMinDurationLeftText"
        >
          {customStepsText.customStepMinDurationText}
        </p>
        <input
          id={`${serviceName}MinDuration${customStepIndex}Id`}
          onChange={(event) => onCustomStepInputChange(event, 'min_value')}
          value={formatDisplayNumberToIntegerIfNothingInTheDecimal(customSteps[customStepIndex].min_value)}
          className="CustomStepsCustomStepContentMinDurationInput"
          type="number"
          {...customStepsText.customStepInputValue === 'minutes' && { min: 0, step: 1 }}
          required
          readOnly={readOnly}
        />
        <p className="CustomStepsCustomStepContentMinDurationRightText">
          {customStepsText.customStepInputValue}
        </p>
      </div>
      <div className="CustomStepsCustomStepContentMaxDuration">
        <p
          id={`${serviceName}MaxDurationText${customStepIndex}Id`}
          className="CustomStepsCustomStepContentMaxDurationLeftText"
        >
          {customStepsText.customStepMaxDurationText}
        </p>
        <input
          id={`${serviceName}MaxDuration${customStepIndex}Id`}
          className="CustomStepsCustomStepContentMaxDurationInput"
          onChange={(event) => onCustomStepInputChange(event, 'max_value')}
          value={formatDisplayNumberToIntegerIfNothingInTheDecimal(customSteps[customStepIndex].max_value)}
          type="number"
          {...customStepsText.customStepInputValue === 'minutes' && { min: 0, step: 1 }}
          required
          readOnly={readOnly}
        />
        <p className="CustomStepsCustomStepContentMaxDurationRightText">
          {customStepsText.customStepInputValue}
        </p>
      </div>
      <div className="CustomStepsCustomStepContentAmount">
        <p
          id={`${serviceName}AmountText${customStepIndex}Id`}
          className="CustomStepsCustomStepContentAmountLeftText"
        >
          {customStepsText.customStepAmountText}
        </p>
        <input
          id={`${serviceName}Amount${customStepIndex}Id`}
          className="CustomStepsCustomStepContentAmountInput"
          type="number"
          onChange={(event) => onCustomStepInputChange(event, 'amount')}
          value={customSteps[customStepIndex].amount || ''}
          required
          readOnly={readOnly}
        />
        <p
          id={`${serviceName}AmountType${customStepIndex}Id`}
          className="CustomStepsCustomStepContentAmountRightText"
        >
          {customStepsAmountType}
        </p>
      </div>
    </div>
  );
}
