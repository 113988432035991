import React from 'react';

import { operationTypeFormText } from 'src/constants/componentTexts/OperationTypesText';

import ListInput from 'src/uiComponents/input/ListInput';
import { OperationTypeFilledInterface, OperationTypeSentInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';

interface Props {
  operationType: OperationTypeSentInterface
  operationTypeFilled?: OperationTypeFilledInterface | null;
  operationTypeParameters: any;
  readOnly: boolean;
  setOperationType: Function;
}

export default function OperationTypeFormCaracteristic(props: Props) {
  const {
    operationType,
    operationTypeFilled,
    operationTypeParameters,
    readOnly,
    setOperationType,
  } = props;

  return (
    <div className="ServicesBox">
      <div className="ServicesBoxStepContent">
        <p className="ServicesBoxStepContentNumber">
          {operationTypeFormText.parameterNumber}
        </p>
      </div>
      <div className="ServicesBoxInput">
        <ListInput
          formObjectPreviousFilledValue={operationTypeFilled?.operation_name?.label || ''}
          inputLabel={operationTypeFormText.operationName}
          isRequired
          optionList={operationTypeParameters.operation_names}
          formObjectKey="operation_name"
          setFormObject={setOperationType}
          formObject={operationType}
          isReadOnly={readOnly}
        />
      </div>
    </div>
  );
}
