import React from 'react';

import { INVOICE_DETAIL_TITLE } from 'src/constants/componentTexts/InvoiceDetailText';

export default function InvoiceDetailTitle() {
  return (
    <header className="InvoiceDetailTilte">
      <div className="InvoiceDetailTitleContent">
        <h1 className="InvoiceDetailTitleContentText">{INVOICE_DETAIL_TITLE}</h1>
      </div>
    </header>
  );
}
