import React from 'react';

import { AIRLINE_LOGO_PATH } from 'src/constants/picturesPath';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

export default function InvoicePicture(props: { invoice: InvoiceModel }) {
  const { invoice } = props;

  return (
    <td id={`InvoiceTableBodyRowValueAirline${invoice.id}Id`} className="InvoicesTableText Airline">
      <img
        src={`${AIRLINE_LOGO_PATH}${invoice.departure_carrier_iata_code || invoice.arrival_carrier_iata_code}.png`}
        alt={`${AIRLINE_LOGO_PATH}${invoice.departure_carrier_iata_code || invoice.arrival_carrier_iata_code}.png`}
        id={`InvoiceTableBodyRowValueAirlinetImg${invoice.id}Id`}
        className="InvoicesTableImg"
      />
    </td>
  );
}
