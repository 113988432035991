import React from 'react';

import { ADD_CUSTOM_SERVICE_SYMBOL, NO_SERVICE_MESSAGE } from 'src/constants/componentTexts/InvoiceDetailText';

import InvoicedService from 'src/invoicing/invoiceDetail/InvoicedService';

import { CustomService } from 'src/invoicing/invoices/InvoicesInterface';
import useInvoicedServices from 'src/invoicing/invoiceDetail/useInvoicedServices';

interface Props {
  customServices: CustomService[];
  invoice: any;
  servicesDescription: any;
  setCustomServices: Function;
  setServicesDescription: Function;
}

export default function InvoicedServices(props: Props) {
  const {
    customServices,
    invoice,
    servicesDescription,
    setCustomServices,
    setServicesDescription,
  } = props;

  const { addCustomService, hasNoInvoiceServiceAndCustomService } = useInvoicedServices(props);

  return (
    <div className="InvoicedServicesWrapper">
      <div className="InvoicedServices">
        {invoice.services.length > 0 && (
          invoice.services.map((service: any, index: number) => (
            <InvoicedService
              invoiceId={invoice.id}
              isCustomService={false}
              key={index}
              invoiceCurrency={invoice.currency_symbol}
              invoiceServices={invoice.services}
              service={service}
              setServices={setServicesDescription}
              services={servicesDescription}
              id={service.id}
            />
          ))
        )}
        {customServices.length > 0 && (
          customServices.map((customService: any, index: number) => (
            <InvoicedService
              invoiceId={invoice.id}
              isCustomService
              customServiceType="custom_service"
              key={index}
              invoiceCurrency={invoice.currency_symbol}
              invoiceServices={invoice.services}
              service={customService}
              setServices={setCustomServices}
              services={customServices}
              id={index.toString()}
            />
          ))
        )}
        {hasNoInvoiceServiceAndCustomService() && (
          <p className="NoInvoicedService">
            {NO_SERVICE_MESSAGE}
          </p>
        )}
      </div>
      <div className="InvoicedServicesNewServiceWrapper">
        <button
          id="InvoicedServicesNewServiceButtonId"
          className="InvoicedServicesNewServiceButton"
          onClick={addCustomService}
          type="button"
        >
          {ADD_CUSTOM_SERVICE_SYMBOL}
        </button>
      </div>
    </div>
  );
}
