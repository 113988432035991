import React from 'react';

import FixedPrice from 'src/invoicing/services/price/fixedPrice/FixedPrice';
import VariablePrice from 'src/invoicing/services/price/variablePrice/VariablePrice';

import { FIXED_AMOUNT_CODE, PERCENTAGE_OF_BASE_PRICE_CODE } from 'src/constants/componentTexts/ServiceText';
import {
  operationTypeVariablePriceText,
  operationTypeFixedPriceText,
} from 'src/constants/componentTexts/OperationTypesText';

import { getPriceBasedOnUserAmountTypePicked } from 'src/invoicing/services/operationTypes/OperationTypesLogic';
import { OperationTypeFilledInterface, PriceInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';

interface Props {
  isFixedPriceChecked: boolean;
  isVariablePriceChecked: boolean;
  operationTypeFilled: OperationTypeFilledInterface | null;
  operationTypeParameters: any;
  operationTypePrice: PriceInterface;
  readOnly: boolean;
  setOperationTypePrice: Function;
}

export default function OperationTypeFormPrice(props: Props) {
  const {
    isFixedPriceChecked,
    isVariablePriceChecked,
    operationTypeFilled,
    operationTypeParameters,
    operationTypePrice,
    readOnly,
    setOperationTypePrice,
  } = props;

  return (
    <div className="ServicesBox">
      {isFixedPriceChecked && (
        <FixedPrice
          service={operationTypePrice}
          serviceCurrency={operationTypeParameters?.currency?.symbol}
          serviceName="NewOperationType"
          serviceText={operationTypeFixedPriceText}
          setService={setOperationTypePrice}
          serviceFilledPrice={getPriceBasedOnUserAmountTypePicked(operationTypeFilled, FIXED_AMOUNT_CODE)}
          readOnly={readOnly}
        />
      )}
      {isVariablePriceChecked && (
        <VariablePrice
          service={operationTypePrice}
          serviceCurrency={operationTypeVariablePriceText.variablePriceMoney}
          serviceName="NewOperationType"
          serviceText={operationTypeVariablePriceText}
          setService={setOperationTypePrice}
          serviceFilledPrice={getPriceBasedOnUserAmountTypePicked(operationTypeFilled, PERCENTAGE_OF_BASE_PRICE_CODE)}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}
