import { EMPTY_VALUE, FLIGHTS_SEPARATOR } from 'src/constants/componentTexts/InvoiceDetailText';

export default function useTurnaroundFlights(invoice: any) {
  const isDepartureFlightOnly = () => (
    invoice.departure_carrier_iata_code && !invoice.arrival_carrier_iata_code
  );

  const isFullTurnaround = () => (
    invoice.departure_carrier_iata_code && invoice.arrival_carrier_iata_code
  );

  const getArrivalFlight = () => {
    if (isDepartureFlightOnly()) {
      return `${invoice.departure_carrier_iata_code}${invoice.departure_flight_number}`;
    }
    return `${invoice.arrival_carrier_iata_code}${invoice.arrival_flight_number}`;
  };

  const getDepartureFlight = () => {
    if (isFullTurnaround()) {
      return `${invoice.departure_carrier_iata_code}${invoice.departure_flight_number}`;
    }
    return EMPTY_VALUE;
  };

  const getFlightsSepaeratorIfArrivalAndDepartureExists = () => {
    if (isFullTurnaround()) {
      return ` ${FLIGHTS_SEPARATOR} `;
    }
    return EMPTY_VALUE;
  };

  const getInvoicedTurnaroundFlight = () => (
    [getArrivalFlight(), getFlightsSepaeratorIfArrivalAndDepartureExists(), getDepartureFlight()]
  );

  return {
    getInvoicedTurnaroundFlight,
  };
}
