import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SSO_LOGIN_ERROR_MESSAGE } from 'src/constants/componentTexts/LoginText';
import { Status } from 'src/constants/status';

import { ROOT, routesNameToPath } from 'src/invoicing/app/Routes';

import { apiGetUser } from 'src/invoicing/home/HomeApiRequest';
import { resetGetUserResponseStatus, selectGetUserResponseStatus } from 'src/invoicing/home/HomeSlice';

import {
  selectLoginResponseStatus,
  resetLoginResponseStatus,
  resetErrorLoginResponse,
  selectErrorLoginMessage,
  selectAuthenticationURL,
  resetExternalAuthStatus,
  selectUseTarmacAuthSystem,
  selectExternalAuthStatus,
} from 'src/invoicing/login/LoginSlice';
import { SSO_DOMAIN } from 'src/constants/url';

export default function useSSO() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cookies = new Cookies();

  const authenticationUrl: string = useSelector(selectAuthenticationURL);
  const errorLoginMessage: string = useSelector(selectErrorLoginMessage);
  const externalAuthStatus: string = useSelector(selectExternalAuthStatus);
  const getUserResponseStatus: string = useSelector(selectGetUserResponseStatus);
  const loginResponseStatus: string = useSelector(selectLoginResponseStatus);
  const useTarmacAuthSystem: boolean = useSelector(selectUseTarmacAuthSystem);

  const [isDefaultLoginOpen, setIsDefaultLoginOpen] = useState<boolean>(false);
  const [loginStatus, setLoginStatus] = useState<string>(Status.DEFAULT);
  const [tarmacLoginErrorMessage, setTarmacLoginErrorMessage] = useState<string | null>(null);

  const resetStateForLoginAndGoToInvoices = () => {
    cookies.remove('refresh_token_expiry', { domain: SSO_DOMAIN });
    cookies.remove('refresh_token', { domain: SSO_DOMAIN });
    cookies.remove('sessionid', { domain: SSO_DOMAIN });
    setTarmacLoginErrorMessage(null);
    dispatch(resetLoginResponseStatus());
    dispatch(resetExternalAuthStatus());
    dispatch(resetGetUserResponseStatus());
    setLoginStatus(Status.DEFAULT);
    navigate(routesNameToPath[ROOT]);
  };

  useEffect(() => {
    setTarmacLoginErrorMessage(null);
    if (cookies?.get('refresh_token')) {
      localStorage.setItem('refreshTokenExpiry', cookies?.get('refresh_token_expiry'));
      localStorage.setItem('refreshToken', cookies?.get('refresh_token'));
      localStorage.setItem('accessToken', '');

      dispatch(apiGetUser());
    } else if (cookies?.get('error')) {
      setTarmacLoginErrorMessage(SSO_LOGIN_ERROR_MESSAGE);
      setLoginStatus(Status.FAIL);
    }
  }, []);

  useEffect(() => {
    if (getUserResponseStatus === Status.SUCCESS) {
      resetStateForLoginAndGoToInvoices();
    }
  }, [getUserResponseStatus]);

  useEffect(() => {
    if (loginResponseStatus === Status.SUCCESS) {
      resetStateForLoginAndGoToInvoices();
    }
    if (loginResponseStatus === Status.FAIL) {
      setTarmacLoginErrorMessage(errorLoginMessage);
      setLoginStatus(Status.FAIL);
      setIsDefaultLoginOpen(false);
    }
    dispatch(resetErrorLoginResponse());
  }, [loginResponseStatus]);

  useEffect(() => {
    if (externalAuthStatus === Status.SUCCESS) {
      if (useTarmacAuthSystem) {
        setIsDefaultLoginOpen(true);
      } else {
        window.location.assign(authenticationUrl);
      }
    }
  }, [externalAuthStatus]);

  return {
    externalAuthStatus,
    isDefaultLoginOpen,
    loginStatus,
    setIsDefaultLoginOpen,
    useTarmacAuthSystem,
    tarmacLoginErrorMessage,
  };
}
