import { ChangeEvent } from 'react';

import { isNumberValid } from 'src/utils/logic/regex/regex';

import { CustomStepsObject } from 'src/invoicing/services/customSteps/CustomStepInterface';
import { deepCopy } from 'src/utils/logic/copy';
import { NEW_ADDITIONAL_SERVICE_NAME } from 'src/constants/componentTexts/AdditionalServicesText';

interface Props {
  amountTypeCode: string;
  customStepIndex: number;
  customSteps: CustomStepsObject[];
  formObject: any;
  serviceName: string;
  setCustomSteps: Function;
  setFormObject: Function;
  setPreviousCustomSteps: Function;
  valuesTypes?: number;
}

export default function useCustomStep(props: Props) {
  const {
    amountTypeCode,
    customStepIndex,
    customSteps,
    formObject,
    serviceName,
    setCustomSteps,
    setFormObject,
    setPreviousCustomSteps,
    valuesTypes,
  } = props;

  const reRenderCustomStepsComponent = () => (
    setPreviousCustomSteps([])
  );

  const onCustomStepInputChange = (event: ChangeEvent<HTMLInputElement>, customStepKey: string) => {
    event.preventDefault();
    reRenderCustomStepsComponent();

    const customStepsCopy: CustomStepsObject[] = deepCopy(customSteps);

    customStepsCopy[customStepIndex][customStepKey] = null;
    if (isNumberValid(event.target.value)) {
      customStepsCopy[customStepIndex][customStepKey] = event.target.value;
    }

    setCustomSteps(customStepsCopy);

    if (serviceName === NEW_ADDITIONAL_SERVICE_NAME) {
      setFormObject({
        ...formObject,
        amounts_type: amountTypeCode,
        price_steps: customStepsCopy,
        values_type: valuesTypes,
      });
    } else {
      setFormObject({ ...formObject, price: { amounts_type: amountTypeCode, price_steps: customStepsCopy, values_type: valuesTypes } });
    }
  };

  return {
    onCustomStepInputChange,
  };
}
