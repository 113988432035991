import React, { useEffect } from 'react';
import NumberInput from 'src/uiComponents/input/NumberInput';

interface Props {
  amountTypeCode: string;
  readOnly: boolean;
  service: any;
  serviceCurrency: string;
  serviceFilledPrice?: any;
  serviceText: any;
  setService: Function;
  typeOfPrice: string;
}

export default function DoublePrice(props: Props) {
  const {
    amountTypeCode,
    readOnly,
    service,
    serviceCurrency,
    serviceFilledPrice,
    serviceText,
    setService,
    typeOfPrice,
  } = props;

  useEffect(() => {
    setService({ ...service, amounts_type: amountTypeCode });
  }, []);

  return (
    <div className="ServicesBoxInputDoublePrice">
      <div className="ServicesBoxInputDoubleFixedPrice">
        <p
          className="FormTextFontSize ServicesBoxInputCurrencyText"
          id={`${typeOfPrice}CurrencyFirstInputTextId`}
        >
          {serviceCurrency}
        </p>
        <NumberInput
          inputLabel={serviceText.firstPrice}
          isRequired
          formObjectKey="amount_if_true"
          setFormObject={setService}
          formObject={service}
          formObjectPreviousFilledValue={serviceFilledPrice.amount_if_true}
          isReadOnly={readOnly}
        />
      </div>
      <div className="ServicesBoxInputDoubleFixedPrice">
        <p
          className="FormTextFontSize ServicesBoxInputCurrencyText"
          id={`${typeOfPrice}CurrencySecondInputTextId`}
        >
          {serviceCurrency}
        </p>
        <NumberInput
          inputLabel={serviceText.secondPrice}
          isRequired
          formObjectKey="amount_if_false"
          setFormObject={setService}
          formObject={service}
          formObjectPreviousFilledValue={serviceFilledPrice.amount_if_false}
          isReadOnly={readOnly}
        />
      </div>
    </div>
  );
}
