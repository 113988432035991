import { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { selectContractAction } from 'src/invoicing/contract/ContractSlice';

interface Props {
  nextComponent: string | null;
  setCurrentContractByStepType: Function;
}

export default function useDelayRulesByStep(props: Props) {
  const { nextComponent, setCurrentContractByStepType } = props;

  const contractAction: string = useSelector(selectContractAction);

  const [delayRuleAction, setDelayRuleAction] = useState<string>(ActionStatus.NEW);
  const [isDelaySubViewOpen, setIsDelaySubViewOpen] = useState<boolean>(false);

  const submitDelays = (event: FormEvent) => {
    event.preventDefault();

    if (nextComponent) {
      setCurrentContractByStepType(nextComponent);
    }
  };

  return {
    contractAction,
    delayRuleAction,
    isDelaySubViewOpen,
    setDelayRuleAction,
    setIsDelaySubViewOpen,
    submitDelays,
  };
}
