import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import {
  selectGetOperationTypeStatus,
  selectOperationType,
} from 'src/invoicing/services/operationTypes/OperationTypesSlice';

export default function useGettingOperationTypeSelected() {
  const getOperationTypeStatus: string = useSelector(selectGetOperationTypeStatus);
  const operationType: any = useSelector(selectOperationType);

  useEffect(() => {}, [getOperationTypeStatus !== Status.LOADING]);

  return {
    getOperationTypeStatus,
    operationType,
  };
}
