export interface Company {
  id: number;
  label: string;
}

export interface AircraftModel {
  id: number;
  label: string;
}

export interface Airport {
  code: string;
  label: string;
}

export interface Airline {
  code: string;
  label: string;
}

export interface Currency {
  id: number;
  code: string;
  label: string;
  symbol: string;
}

export interface CompanyBusinessGroup {
  airport: Airport;
  airline: Airline;
}

export interface ContractFilters {
  airport: string | null;
  aircraft_model: number | null;
  customer: number | null;
  provider: number | null;
  past: boolean;
  inProgress: boolean;
  future: boolean;
}

export const defaultContractFilters: ContractFilters = {
  aircraft_model: null,
  airport: null,
  customer: null,
  provider: null,
  past: false,
  inProgress: false,
  future: false,
};
export interface ContractParameters {
  companies: Company[];
  aircraft_models: AircraftModel[];
  company_business_groups: CompanyBusinessGroup[];
  currencies: Currency[];
}

export interface BasePrice {
  amount_for_complete_turnaround: string;
  amount_for_arrival_only_turnaround: string | null;
  amount_for_departure_only_turnaround: string | null;
}

export interface Contract {
  aircraft_models: string[] | null,
  airline: string | null,
  airport: string | null,
  arrival_departure_separation_threshold_in_minutes: number | null,
  base_price: BasePrice,
  company: number | null,
  customer: number | null,
  end_datetime: string | null,
  provider: number | null,
  start_datetime: string | null,
  currency: string | null;
}

export const defaultContract: Contract = {
  aircraft_models: null,
  airline: null,
  airport: null,
  arrival_departure_separation_threshold_in_minutes: null,
  base_price: {
    amount_for_complete_turnaround: '',
    amount_for_arrival_only_turnaround: null,
    amount_for_departure_only_turnaround: null,
  },
  company: null,
  customer: null,
  provider: null,
  end_datetime: null,
  start_datetime: null,
  currency: null,
};

export interface StoredContract {
  id: number,
  aircraft_models: string[] | null,
  airline: string | null,
  airport: string | null,
  arrival_departure_separation_threshold_in_minutes: number | null,
  base_price: BasePrice,
  company: number | null,
  customer: number | null,
  end_datetime: string | null,
  provider: number | null,
  start_datetime: string | null,
  currency: string | null;
}

export const defaultStoredContract: StoredContract = {
  id: 0,
  aircraft_models: null,
  airline: null,
  airport: null,
  arrival_departure_separation_threshold_in_minutes: null,
  base_price: {
    amount_for_complete_turnaround: '',
    amount_for_arrival_only_turnaround: null,
    amount_for_departure_only_turnaround: null,
  },
  company: null,
  customer: null,
  provider: null,
  end_datetime: null,
  start_datetime: null,
  currency: null,
};

export interface ContractStep {
  type: string;
  component: any;
  params: {
    storedContract: StoredContract;
    setStoredContract: Function;
    setCurrentContractByStepType: Function;
    nextComponent: string | null;
    previousComponent: string | null;
    setIsContractByStepManagerOpen: Function;
    contractByStepsFilled: boolean[];
    setcontractByStepsFilled: Function;
    currentContractByStepType: string;
  }
}

export interface ContractsObject {
  aircraft_models: AircraftModel[];
  airline: Airline;
  airport: Airport;
  arrival_departure_separation_threshold_in_minutes: number | null;
  base_price: BasePrice;
  company: Company;
  currency: { code: string; label: string };
  customer: { id: number; label: string };
  end_datetime: string | null;
  id: number;
  provider: { id: number; label: string };
  start_datetime: string;
  status: string;
}

export const defaultBasePrice: BasePrice = {
  amount_for_complete_turnaround: '',
  amount_for_arrival_only_turnaround: null,
  amount_for_departure_only_turnaround: null,
};
