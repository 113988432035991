import React, { MouseEventHandler } from 'react';

interface Props {
  className: string;
  id: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function CloseButton(props: Props) {
  return (
    <button {...props} type="button">
      X
    </button>
  );
}
