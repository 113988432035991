import { Status } from 'src/constants/status';

export const isTokenExpiryValid = (tokenExpiration: number) => (tokenExpiration > 0);

export const getTokenExpirationTimeLimit = (tokenExpirationTimeLimit: string) => {
  if (tokenExpirationTimeLimit !== null) {
    const expirationTime: number = Date.parse(`${tokenExpirationTimeLimit}Z`);

    return expirationTime - Date.parse(new Date().toJSON());
  }
  return 0;
};

export const shouldLoaderBeDisplayed = (receivedStatus: string[]) => (
  receivedStatus.some((status: string) => (
    status === Status.LOADING || status === Status.FAIL || status === Status.DEFAULT
  ))
);

export const disconnectUser = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('accessTokenExpiry');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenExpiry');
  localStorage.removeItem('userCompany');
};
