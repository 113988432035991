import React from 'react';

import useInvoicing from 'src/invoicing/useInvoicing';
import RedirectInvoices from 'src/routing/RedirectInvoices';
import RedirectLogin from 'src/routing/RedirectLogin';

export default function Invoicing() {
  const {
    isLoggedIn,
  } = useInvoicing();

  if (isLoggedIn()) {
    return (<RedirectInvoices />);
  }
  return (<RedirectLogin />);
}
