import React from 'react';

import { NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_NO_RULE_MESSAGE } from 'src/constants/componentTexts/AdditionalServicesText';

import NoService from 'src/invoicing/services/NoService';

export default function NoAdditionalService() {
  return (
    <NoService
      serviceName="AdditionalService"
      noServiceMessage={NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_NO_RULE_MESSAGE}
    />
  );
}
