import { NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TITLE } from 'src/constants/componentTexts/AdditionalServicesText';
import { NEW_CONTRACT_FORM_BASE_PRICE_TITLE } from 'src/constants/componentTexts/BasePriceText';
import { NEW_CONTRACT_FORM_CANCELLATION_RULES_TITLE } from 'src/constants/componentTexts/CancellationRulesText';
import { NEW_CONTRACT_FORM_CONTRACT_TITLE } from 'src/constants/componentTexts/ContractText';
import { NEW_CONTRACT_FORM_DELAYS_RULES_TITLE } from 'src/constants/componentTexts/DelayRulesText';
import { NEW_CONTRACT_FORM_OPERATION_TYPES_TITLE } from 'src/constants/componentTexts/OperationTypesText';

export const stepperText = {
  contractScope: NEW_CONTRACT_FORM_CONTRACT_TITLE,
  contractScopeStepNumber: 1,
  basePrice: NEW_CONTRACT_FORM_BASE_PRICE_TITLE,
  basePriceStepNumber: 2,
  operationTypes: NEW_CONTRACT_FORM_OPERATION_TYPES_TITLE,
  operationTypeStepNumber: 3,
  cancellationRules: NEW_CONTRACT_FORM_CANCELLATION_RULES_TITLE,
  cancellationRulesStepNumber: 4,
  delayRules: NEW_CONTRACT_FORM_DELAYS_RULES_TITLE,
  delayRulesStepNumber: 5,
  additionalServices: NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TITLE,
  additionalServicesStepNumber: 6,
};
