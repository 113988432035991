import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';
import { AmountFilterInputsId } from 'src/constants/status';

export interface InvoiceModel {
  id: string,
  aircraft_type: string | null;
  airport: string | null;
  amount: string | null;
  customer: { id: string; label: string; } | null;
  provider: { id: string; label: string; } | null;
  arrival_carrier_iata_code: string | null;
  arrival_date: string | null;
  arrival_flight_number: string | null;
  currency_symbol: string | null;
  departure_carrier_iata_code: string | null;
  departure_date: string | null;
  departure_flight_number: string | null;
  aircraft_registration: string | null;
  status: { code: string, label: string } | null;
}

export interface InvoicesFiltersModel {
  validated: boolean,
  missingInfo: boolean,
  toCheck: boolean,
  airport: string | null;
  customer: number | null;
  provider: number | null;
  amount: {
    amountSelected: number | null,
    AmountFilteInputId: AmountFilterInputsId | null,
  };
  searchbarValue: string | null,
}

export const defaultInvoicesFilter: InvoicesFiltersModel = {
  validated: false,
  missingInfo: false,
  toCheck: false,
  airport: null,
  customer: null,
  provider: null,
  amount: {
    amountSelected: null,
    AmountFilteInputId: null,
  },
  searchbarValue: null,
};

export interface CustomService {
  amount: string | null;
  label: string | null;
  description: string | null;
}

export interface DropDownValues {
  date: {
    fromDate: string | null;
    toDate: string | null;
  },
  amount: {
    amountSelected: number | null;
    amountSymbol: string | null;
  },
}

export const defaultDropDownValues: DropDownValues = {
  date: {
    fromDate: EMPTY_VALUE,
    toDate: EMPTY_VALUE,
  },
  amount: {
    amountSelected: null,
    amountSymbol: null,
  },
};
