import React, { useState } from 'react';

import {
  Checkbox,
  MenuItem,
  InputLabel,
  ListItemText,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';

import { MenuProps } from 'src/constants/componentTexts/InputText';

interface Props {
  formObject: any;
  formObjectKey: string;
  formObjectPreviousFilledValue: string[];
  inputLabel: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired: boolean;
  optionList: any;
  setFormObject: Function;
}

export default function MultiCheckboxListInput(props: Props) {
  const {
    formObject,
    formObjectKey,
    formObjectPreviousFilledValue,
    inputLabel,
    isReadOnly,
    isRequired,
    optionList,
    setFormObject,
  } = props;

  const [selected, setSelected] = useState<any>(formObjectPreviousFilledValue);

  const optionsName = optionList.map((option: { id: number, label: string }) => option.label);

  const handleChange = (event: SelectChangeEvent<any>) => {
    // eslint-disable-next-line
    const value = event.target.value;

    setSelected(value);

    if (value.length === 0) {
      setFormObject({ ...formObject, [formObjectKey]: null });
    } else {
      setFormObject({ ...formObject, [formObjectKey]: value });
    }
  };

  return (
    <FormControl variant="standard">
      <InputLabel id={`MultiCheckBoxListInputLabel${formObjectKey}Id`}>{inputLabel}</InputLabel>
      <Select
        labelId={`MultiCheckBoxListInputLabel${formObjectKey}Id`}
        multiple
        required={isRequired}
        readOnly={isReadOnly}
        value={selected}
        onChange={handleChange}
        renderValue={(selectedElement) => selectedElement.join(', ')}
        MenuProps={MenuProps}
        id={`MultiCheckBoxListInputSelect${formObjectKey}Id`}
      >
        {optionsName.map((option: string, index: number) => (
          <MenuItem key={index} value={option} id={`MultiCheckBoxListInputOption${index}Id`}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
