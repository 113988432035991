import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

export default function useOperationTypeSubmittionRequestStatus(submittionRequestStatusSelector: any, setIsOperationTypeViewOpen: Function) {
  const submittionRequestOperationTypeStatus: string = useSelector(submittionRequestStatusSelector);

  useEffect(() => {
    if (submittionRequestOperationTypeStatus === Status.SUCCESS) {
      setIsOperationTypeViewOpen(false);
    }
  }, [submittionRequestOperationTypeStatus]);
}
