import { DelayRuleSentInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';

export const createPatchDelayRuleBody = (delayRule: DelayRuleSentInterface, contractId: number | null, delayRuleFilledId: number) => (
  {
    delayRule:
      {
        ...delayRule,
        contract: contractId,
      },
    delayRuleId: delayRuleFilledId,
  }
);

export const getDefaultDelayRule = (delayRuleFilled: any | null, defaultDelayRule: DelayRuleSentInterface) => (
  delayRuleFilled ? {
    ...defaultDelayRule,
    cause_of_delay: delayRuleFilled.cause_of_delay.id,
    price: {
      amounts_type: delayRuleFilled.price.amounts_type.code,
      price_steps: delayRuleFilled.price.price_steps,
      values_type: delayRuleFilled.price.values_type.id,
    },
  } : defaultDelayRule
);
