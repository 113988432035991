import React from 'react';
import { useDispatch } from 'react-redux';

import { DELETE_INVOICE_SERVICE_MESSAGE, NO_BUTTON_TEXT, YES_BUTTON_TEXT } from 'src/constants/componentTexts/ModalText';
import { apiDeleteInvoiceService } from 'src/invoicing/invoices/InvoicesApiRequest';

interface Props {
  canServiceBeDeleted: Function;
  customServiceType: string | null;
  invoiceId: number;
  serviceId: number;
  services: any;
  setHasServiceDeleted: Function;
  setIsDeleteServiceModalOpened: Function;
}

export default function DeleteServiceModal(props: Props) {
  const {
    canServiceBeDeleted,
    customServiceType,
    serviceId,
    services,
    setHasServiceDeleted,
    setIsDeleteServiceModalOpened,
  } = props;

  const dispatch = useDispatch();

  const onClickToDeleteService = () => {
    const serviceIndex = services.indexOf(serviceId);

    if (customServiceType) {
      services.splice(serviceIndex, 1);
      setHasServiceDeleted(true);
    } else {
      dispatch(apiDeleteInvoiceService(serviceId));
    }
    setIsDeleteServiceModalOpened(false);
  };

  return (
    <div className="ErrorModalWrapper">
      <div className="ErrorModalWrapperContent">
        <p id={`DeleteInvoiceServiceMessage${serviceId}Id`} className="ErrorModalWrapperTokenContentMessage">
          {DELETE_INVOICE_SERVICE_MESSAGE}
        </p>
        <div className="DeleteServiceModalButtons">
          <button
            id={`DeleteInvoiceServiceYesButton${serviceId}Id`}
            className="DeleteInvoiceServiceYesButton"
            type="button"
            onClick={canServiceBeDeleted() ? () => onClickToDeleteService() : () => {}}
          >
            {YES_BUTTON_TEXT}
          </button>
          <button
            id={`DeleteInvoiceServiceNoButton${serviceId}Id`}
            className="DeleteInvoiceServiceNoButton"
            type="button"
            onClick={() => setIsDeleteServiceModalOpened(false)}
          >
            {NO_BUTTON_TEXT}
          </button>
        </div>
      </div>
    </div>
  );
}
