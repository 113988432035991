import React from 'react';

import {
  NEW_CONTRACT_FORM_CONTRACT_TITLE,
  newContractInputLabel,
} from 'src/constants/componentTexts/ContractText';

import DateTimeInput from 'src/uiComponents/input/DateTimeInput';
import ListInput from 'src/uiComponents/input/ListInput';
import Loader from 'src/uiComponents/loader/Loader';
import MultiCheckboxListInput from 'src/uiComponents/input/MultiCheckboxListInput';
import TextInput from 'src/uiComponents/input/TextInput';
import TimeInput from 'src/uiComponents/input/TimeInput';

import {
  getAllUserAirlines,
  getAllUserAirportsAllowedWithSelectedAirline,
  getUserCompanyIfExists,
  isFieldReadOnlyWithEditAction,
  isFieldReadOnlyWithViewAction,
} from 'src/invoicing/contract/ContractLogic';
import { isFieldReadOnlyWhenContractInProgressOrViewContractAction } from 'src/invoicing/services/ServicesLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { StoredContract } from 'src/invoicing/contract/ContractInterface';
import useContractForm from 'src/invoicing/contract/form/useContractForm';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  ContractFormButtons: any;
  nextComponent: string | null;
  previousComponent: string | null;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setStoredContract: Function;
  setcontractByStepsFilled: Function;
  storedContract: StoredContract;
}

export default function ContractForm(props: Props) {
  const { ContractFormButtons } = props;

  const {
    contract,
    contractAction,
    contractFilled,
    contractStatus,
    getContractParameters,
    getContractParametersStatus,
    setContract,
    submitContract,
  } = useContractForm(props);

  return (
    shouldLoaderBeDisplayed([getContractParametersStatus]) ? (
      <Loader className="NewContractAppContentOperationTypesLoader" />
    ) : (
      <form className="NewContractAppContentForm" onSubmit={(event) => submitContract(event)}>
        <div className="NewContractAppContentFormWrapper">
          <div className="NewContractAppContentFormTitle">
            <p className="FormTitleFontSize NewContractAppContentFormTitleText">{NEW_CONTRACT_FORM_CONTRACT_TITLE}</p>
          </div>
          <div className="NewContractAppContentFormContent">
            <div className="NewContractAppContentFormContentInputWrapper">
              <TextInput
                inputLabel={newContractInputLabel.myCompany}
                isRequired
                readOnly
                defaultValue={getUserCompanyIfExists(getContractParameters)}
              />
              <ListInput
                formObjectPreviousFilledValue={contractFilled?.provider?.label || ''}
                inputLabel={newContractInputLabel.provider}
                isRequired
                optionList={getContractParameters.companies}
                formObjectKey="provider"
                setFormObject={setContract}
                formObject={contract}
                isReadOnly={isFieldReadOnlyWithEditAction(contractAction) || isFieldReadOnlyWithViewAction(contractAction)}
              />
              <ListInput
                formObjectPreviousFilledValue={contractFilled?.customer?.label || ''}
                inputLabel={newContractInputLabel.customer}
                isRequired
                optionList={getContractParameters.companies}
                formObjectKey="customer"
                setFormObject={setContract}
                formObject={contract}
                isReadOnly={isFieldReadOnlyWithEditAction(contractAction) || isFieldReadOnlyWithViewAction(contractAction)}
              />
              <ListInput
                formObjectPreviousFilledValue={contractFilled?.currency?.label || ''}
                inputLabel={newContractInputLabel.currency}
                isRequired
                optionList={getContractParameters.currencies}
                formObjectKey="currency"
                setFormObject={setContract}
                formObject={contract}
                optionInputValueField="code"
                isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
              />
            </div>
            <div className="NewContractAppContentFormContentInputWrapper">
              <ListInput
                formObjectPreviousFilledValue={contractFilled?.airline?.label || ''}
                inputLabel={newContractInputLabel.airline}
                isRequired
                optionList={getAllUserAirlines(getContractParameters)}
                formObjectKey="airline"
                setFormObject={setContract}
                formObject={contract}
                optionInputValueField="code"
                isReadOnly={isFieldReadOnlyWithEditAction(contractAction) || isFieldReadOnlyWithViewAction(contractAction)}
              />
              <ListInput
                formObjectPreviousFilledValue={contractFilled?.airport?.label || ''}
                inputLabel={newContractInputLabel.airport}
                isRequired
                optionList={getAllUserAirportsAllowedWithSelectedAirline(getContractParameters, contract)}
                isDisabled={contract.airline === null}
                formObjectKey="airport"
                setFormObject={setContract}
                formObject={contract}
                optionInputValueField="code"
                isReadOnly={isFieldReadOnlyWithEditAction(contractAction) || isFieldReadOnlyWithViewAction(contractAction)}
              />
              <MultiCheckboxListInput
                formObject={contract}
                formObjectKey="aircraft_models"
                formObjectPreviousFilledValue={contractFilled?.aircraft_models || []}
                inputLabel={newContractInputLabel.aircraftType}
                isReadOnly={isFieldReadOnlyWithEditAction(contractAction) || isFieldReadOnlyWithViewAction(contractAction)}
                isRequired
                optionList={getContractParameters.aircraft_models}
                setFormObject={setContract}
              />
              <TimeInput
                formObjectPreviousFilledValue={contractFilled.arrival_departure_separation_threshold_in_minutes || 0}
                inputLabel={newContractInputLabel.separationLimit}
                isRequired={false}
                formObjectKey="arrival_departure_separation_threshold_in_minutes"
                setFormObject={setContract}
                formObject={contract}
                isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
              />
            </div>
            <div className="NewContractAppContentFormContentInputWrapper">
              <DateTimeInput
                formObjectPreviousFilledValue={contractFilled.start_datetime || ''}
                inputLabel={newContractInputLabel.startingDate}
                isRequired
                formObjectKey="start_datetime"
                setFormObject={setContract}
                formObject={contract}
                isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
              />
              <DateTimeInput
                formObjectPreviousFilledValue={contractFilled?.end_datetime || ''}
                inputLabel={newContractInputLabel.endingDate}
                isRequired={false}
                formObjectKey="end_datetime"
                setFormObject={setContract}
                formObject={contract}
                isReadOnly={isFieldReadOnlyWithViewAction(contractAction)}
              />
            </div>
          </div>
        </div>
        <ContractFormButtons
          {...props}//eslint-disable-line 
        />
      </form>
    )
  );
}
