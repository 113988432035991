import { ActionStatus, ContractStatus, Status } from 'src/constants/status';
import { FIXED_AMOUNT_CODE, PERCENTAGE_OF_BASE_PRICE_CODE, SERVICE_CUSTOM_STEP_EMPTY_VALUE } from 'src/constants/componentTexts/ServiceText';

export const hasServicesToDisplay = (services: any) => (
  services.length !== 0
);

export const returnFixedIfPriceAmountsTypeServiceIsFixed = (service: any) => (
  service?.price?.amounts_type?.code === FIXED_AMOUNT_CODE && 'Fixed'
);

export const returnVariableIfPriceAmountsTypeServiceIsPercentage = (service: any) => (
  service?.price?.amounts_type?.code === PERCENTAGE_OF_BASE_PRICE_CODE && 'Variable'
);

export const getServicescontractByStepsFilled = (contractByStepsFilled: boolean[], services: any, contractByStep: number) => {
  const contractByStepsFilledCopy = contractByStepsFilled;
  const contractByStepsFilledCopyIndex = contractByStep - 1;

  contractByStepsFilledCopy[contractByStepsFilledCopyIndex] = services.length !== 0;

  return contractByStepsFilledCopy;
};

export const getAddInfosBasedOnTaskDataSourceChoice = (addServiceTaskDatasource: number | null, addServiceParameters: any) => {
  if (addServiceTaskDatasource) {
    const taskDatasource = addServiceParameters.task_datasources.find((addServiceParameterTask: any) => addServiceParameterTask.id === addServiceTaskDatasource);

    if (taskDatasource !== undefined) {
      return taskDatasource.additional_information_datasources;
    }
  }
  return [];
};

export const areServicesLoaded = (postServiceStatus: string, deleteServiceStatus: string, patchServiceStatus: string) => (
  postServiceStatus !== Status.LOADING && deleteServiceStatus !== Status.LOADING && patchServiceStatus !== Status.LOADING
);

export const areServicesLoading = (postServiceStatus: string, patchServiceStatus: string) => (
  postServiceStatus === Status.LOADING || patchServiceStatus === Status.LOADING
);

export const getPriceStepsBasedOnUserAmountTypePicked = (filledService: any, amountTypeCode: string) => (
  (filledService && filledService?.price?.amounts_type.code === amountTypeCode)
    ? filledService?.price?.price_steps
    : [{
      min_value: null, max_value: null, amount: null, order: 0,
    }]
);

const doesContractAllowEdition = (contractStatus: string, contractAction: string) => (
  contractAction === ActionStatus.DUPLICATE || (contractStatus !== ContractStatus.IN_PROGRESS && contractAction !== ActionStatus.VIEW)
);

export const canAServiceBeAdded = (contractStatus: string, contractAction: string) => (
  doesContractAllowEdition(contractStatus, contractAction)
);

export const canAServiceBeUpdated = (contractStatus: string, contractAction: string) => (
  doesContractAllowEdition(contractStatus, contractAction)
);

export const isFieldReadOnlyWhenContractInProgressOrViewContractAction = (contractStatus: string, contractAction: string) => (
  ((contractStatus === ContractStatus.IN_PROGRESS && contractAction !== ActionStatus.DUPLICATE) || contractAction === ActionStatus.VIEW)
);

export const formatDisplayNumberToIntegerIfNothingInTheDecimal = (value: string | null) => {
  if (value) {
    const splitValue: string[] = value.split('.');

    return (splitValue.includes('00') || splitValue.length === 1) ? splitValue[0] : value;
  }
  return SERVICE_CUSTOM_STEP_EMPTY_VALUE;
};

export const displayErrorIfSubmitFailed = (postServiceStattus: string, patchServiceStatus: string, setIsErrorModalOpen: Function, isErrorModalOpen: boolean) => {
  if ([postServiceStattus, patchServiceStatus].includes(Status.FAIL)) {
    setIsErrorModalOpen(!isErrorModalOpen);
  }
};

export const isApiCallStatusisLoadingOrIsSetToDefault = (apiCallStatus: string) => (
  apiCallStatus === Status.LOADING || apiCallStatus === Status.DEFAULT
);

export const isApiCallStatusSuccessFullAndHasServicesToDisplay = (apiCallStatus: string, services: any) => (
  apiCallStatus === Status.SUCCESS && hasServicesToDisplay(services)
);

export const isApiCallStatusSuccessFullAndHasNoServiceToDisplay = (apiCallStatus: string, services: any) => (
  apiCallStatus === Status.SUCCESS && !hasServicesToDisplay(services)
);
