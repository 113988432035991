import React from 'react';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';
import Invoice from 'src/invoicing/invoices/Invoice';
import InvoiceTableColumnHeader from 'src/invoicing/invoices/InvoiceTableColumnHeader';

import { invoicesTitle, SELECT_ALL_VALIDATED_INVOICES_TEXT } from 'src/constants/componentTexts/InvoiceAppText';
import { InvoicesSortStatus } from 'src/constants/status';

import { InvoicesContextProvider } from 'src/invoicing/invoices/InvoicesContext';
import useInvoices from 'src/invoicing/invoices/useInvoices';

interface Props {
  invoices: InvoiceModel[];
  invoicesToDownload: InvoiceModel[];
  invoicesSortStatus: InvoicesSortStatus;
  setInvoicesToDownload: Function;
  setInvoicesSortStatus: Function;
  isInvoiceDetailOpen: boolean;
  setIsInvoiceDetailOpen: Function;
}

export default function Invoices(props: Props) {
  const {
    invoices,
    invoicesSortStatus,
    invoicesToDownload,
    setInvoicesToDownload,
    isInvoiceDetailOpen,
    setInvoicesSortStatus,
    setIsInvoiceDetailOpen,
  } = props;

  const {
    areInvoicesToDownload,
    onInvoicesToDownloadChange,
  } = useInvoices(props);

  return (
    <div className="Invoices">
      <table className="InvoicesTable">
        <thead className="InvoicesTableHead">
          <tr className="InvoicesTableRow">
            <th id="InvoiceTableHeadRowValueCheckboxId">
              <input
                type="checkbox"
                checked={areInvoicesToDownload}
                title={SELECT_ALL_VALIDATED_INVOICES_TEXT}
                onChange={onInvoicesToDownloadChange}
                className="InvoicesTableCheckbox"
              />
            </th>
            <InvoicesContextProvider invoicesSortStatus={invoicesSortStatus} setInvoicesSortStatus={setInvoicesSortStatus}>
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueStatusId"
                invoiceSortStatusAscending={InvoicesSortStatus.STATUS_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.STATUS_DESC}
                text={invoicesTitle.status}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueArrivalDateId"
                invoiceSortStatusAscending={InvoicesSortStatus.ARRIVAL_DATE_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.ARRIVAL_DATE_DESC}
                text={invoicesTitle.arrivalDate}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueDepartureDateId"
                invoiceSortStatusAscending={InvoicesSortStatus.DEPARTURE_DATE_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.DEPARTURE_DATE_DESC}
                text={invoicesTitle.departureDate}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueAirportId"
                invoiceSortStatusAscending={InvoicesSortStatus.AIRPORT_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.AIRPORT_DESC}
                text={invoicesTitle.airport}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueAirlineId"
                invoiceSortStatusAscending={InvoicesSortStatus.AIRLINE_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.AIRLINE_DESC}
                text={invoicesTitle.airline}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueArrivalFlightId"
                invoiceSortStatusAscending={InvoicesSortStatus.ARRIVAL_FLIGHT_NUMBER_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.ARRIVAL_FLIGHT_NUMBER_DESC}
                text={invoicesTitle.arrivalFlight}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueDepartureFlightId"
                invoiceSortStatusAscending={InvoicesSortStatus.DEPARTURE_FLIGHT_NUMBER_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.DEPARTURE_FLIGHT_NUMBER_DESC}
                text={invoicesTitle.departureFlight}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueAircraftTypeId"
                invoiceSortStatusAscending={InvoicesSortStatus.AIRCRAFT_TYPE_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.AIRCRAFT_TYPE_DESC}
                text={invoicesTitle.aircraftType}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueRegistrationId"
                invoiceSortStatusAscending={InvoicesSortStatus.AIRCRAFT_REGISTRATION_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.AIRCRAFT_REGISTRATION_DESC}
                text={invoicesTitle.registration}
              />
              <InvoiceTableColumnHeader
                id="InvoiceTableHeadRowValueAmountId"
                invoiceSortStatusAscending={InvoicesSortStatus.AMOUNT_ASC}
                invoiceSortStatusDescending={InvoicesSortStatus.AMOUNT_DESC}
                text={invoicesTitle.amount}
              />
            </InvoicesContextProvider>
          </tr>
        </thead>
        <tbody className="InvoiceAppInvoicesTableBody">
          {invoices.map((invoice: InvoiceModel) => (
            <Invoice
              areInvoicesToDownload={areInvoicesToDownload}
              key={invoice?.id}
              invoice={invoice}
              invoicesToDownload={invoicesToDownload}
              isInvoiceDetailOpen={isInvoiceDetailOpen}
              setIsInvoiceDetailOpen={setIsInvoiceDetailOpen}
              setInvoicesToDownload={setInvoicesToDownload}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
