import { AmountFilterInputsId } from 'src/constants/status';

export const AMOUNT_INPUT_TEXT = 'Enter an amount';

const amountFiltersText = {
  equalTo: '= equal to ...',
  superiorTo: '> superior to ...',
  inferiorAt: '< inferior at ...',
  notEqualTo: '≠ not equal to ...',
  superiorOrEqualTo: '≥ superior or equal to ...',
  inferiorOrEqualTo: '≤  inferior or equal to ...',
};

export const amountFilters = [
  {
    id: AmountFilterInputsId.EQUAL_TO,
    text: amountFiltersText.equalTo,
  },
  {
    id: AmountFilterInputsId.SUPERIOR_TO,
    text: amountFiltersText.superiorTo,
  },
  {
    id: AmountFilterInputsId.INFERIOR_AT,
    text: amountFiltersText.inferiorAt,
  },
  {
    id: AmountFilterInputsId.NOT_EQUAL_TO,
    text: amountFiltersText.notEqualTo,
  },
  {
    id: AmountFilterInputsId.SUPERIOR_OR_EQUAL_TO,
    text: amountFiltersText.superiorOrEqualTo,
  },
  {
    id: AmountFilterInputsId.INFERIOR_OR_EQUAL_TO,
    text: amountFiltersText.inferiorOrEqualTo,
  },
];

export const amountFiltersSymbols = {
  [AmountFilterInputsId.EQUAL_TO]: '=',
  [AmountFilterInputsId.SUPERIOR_TO]: '>',
  [AmountFilterInputsId.INFERIOR_AT]: '<',
  [AmountFilterInputsId.NOT_EQUAL_TO]: '≠',
  [AmountFilterInputsId.SUPERIOR_OR_EQUAL_TO]: '≥',
  [AmountFilterInputsId.INFERIOR_OR_EQUAL_TO]: '≤',
};
