import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NO_SERVICE_TEXT } from 'src/constants/componentTexts/InvoiceDetailText';
import { VALIDATED_STATUS } from 'src/constants/componentTexts/InvoiceAppText';

import { convertPxToVmax } from 'src/utils/logic/converter/unitConvertor';

import { apiGetInvoice } from 'src/invoicing/invoices/InvoicesApiRequest';
import useServiceDescription from 'src/invoicing/invoiceDetail/useServiceDescription';
import useServiceStatus from 'src/invoicing/invoiceDetail/useServiceStatus';

interface Props {
  customServiceType?: string;
  id: string;
  invoiceId: number;
  invoiceServices: any;
  service: any;
  services: any;
  setServices: Function;
}

export default function useInvoicedService(props: Props) {
  const {
    customServiceType,
    id,
    invoiceId,
    invoiceServices,
    service,
    services,
    setServices,
  } = props;

  const dispatch = useDispatch();

  const getServiceValueIfExists = () => (
    service.value !== null ? ` - ${service.value}` : `${NO_SERVICE_TEXT}`
  );

  const [hasServiceDeleted, setHasServiceDeleted] = useState<boolean>(false);
  const [isDeleteServiceModalOpened, setIsDeleteServiceModalOpened] = useState<boolean>(false);
  const [isDescriptionOpen, setisDescriptionOpen] = useState<boolean>(service.description || false);
  const [serviceDescription, setServiceDescription] = useState<string>(service.description || NO_SERVICE_TEXT);
  const [serviceLabel, setServiceLabel] = useState<string>(service.label ? `${service.label}${getServiceValueIfExists()}` : NO_SERVICE_TEXT);
  const [serviceAmount, setServiceAmount] = useState<string>(service.amount || NO_SERVICE_TEXT);
  const [serviceValidated, setServiceValidated] = useState<boolean>(service.status === VALIDATED_STATUS.code);

  const descriptionTextDynamicHeight = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (descriptionTextDynamicHeight && descriptionTextDynamicHeight.current) {
      descriptionTextDynamicHeight.current.style.height = '0.2vmax';

      const scrollHeight = convertPxToVmax(descriptionTextDynamicHeight.current.scrollHeight, 18.2);
      descriptionTextDynamicHeight.current.style.height = `${scrollHeight}vmax`;
    }
  }, [serviceDescription]);

  const {
    getServiceDescriptionIfChangedBefore,
    onCustomServiceDescriptionChange,
    onExistingServiceDescriptionChange,
    onDescriptionClick,
  } = useServiceDescription({
    id,
    invoiceServices,
    isDescriptionOpen,
    service,
    serviceAmount,
    serviceLabel,
    services,
    setisDescriptionOpen,
    setServices,
    setServiceDescription,
  });

  const { toggleCustomServiceStatus, toggleExistingServiceStatus } = useServiceStatus({
    id,
    getServiceDescriptionIfChangedBefore,
    service,
    serviceAmount,
    serviceLabel,
    serviceValidated,
    services,
    setServices,
    setServiceValidated,
  });

  useEffect(() => {
    if (hasServiceDeleted) {
      dispatch(apiGetInvoice(invoiceId));
      setHasServiceDeleted(false);
    }
  }, [hasServiceDeleted]);

  const canServiceBeDeleted = () => {
    if (customServiceType) {
      const customServiceFound = services[id];

      return customServiceFound !== undefined ? customServiceFound.status !== VALIDATED_STATUS.code : false;
    }
    const createdCustomServiceFound = services.find((serviceToFind: any) => Number(id) === Number(serviceToFind.id));

    return createdCustomServiceFound !== undefined
      ? createdCustomServiceFound.status !== VALIDATED_STATUS.code
      : service.status !== VALIDATED_STATUS.code;
  };

  const getDeletedPictureClassname = () => (
    canServiceBeDeleted() ? 'CanBeDeleted' : 'CantBeDeleted'
  );

  const getCustomPrefixIdIfCustomService = () => (
    customServiceType || NO_SERVICE_TEXT
  );

  return {
    canServiceBeDeleted,
    descriptionTextDynamicHeight,
    getCustomPrefixIdIfCustomService,
    getDeletedPictureClassname,
    isDeleteServiceModalOpened,
    isDescriptionOpen,
    onCustomServiceDescriptionChange,
    onExistingServiceDescriptionChange,
    onDescriptionClick,
    serviceAmount,
    serviceDescription,
    serviceLabel,
    serviceValidated,
    setHasServiceDeleted,
    setIsDeleteServiceModalOpened,
    setServiceAmount,
    setServiceLabel,
    toggleCustomServiceStatus,
    toggleExistingServiceStatus,
  };
}
