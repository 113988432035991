import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import { apiExternalAuth, apiFetchLogin } from 'src/invoicing/login/LoginApiRequest';

import { resetLoginResponseStatus, selectUserName } from 'src/invoicing/login/LoginSlice';
import useSSO from 'src/invoicing/login/useSSO';

export default function useLogin() {
  const dispatch = useDispatch();

  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>(useSelector(selectUserName));

  const {
    externalAuthStatus,
    isDefaultLoginOpen,
    loginStatus,
    setIsDefaultLoginOpen,
    useTarmacAuthSystem,
    tarmacLoginErrorMessage,
  } = useSSO();

  const submitLogin = (event: FormEvent, usernameForLogin: string) => {
    event.preventDefault();

    dispatch(apiFetchLogin({ username: usernameForLogin, password }));
    setIsDefaultLoginOpen(false);
  };

  const submitSso = (event: FormEvent) => {
    event.preventDefault();

    dispatch(resetLoginResponseStatus());
    dispatch(apiExternalAuth({ username, host: `${window.location.href}` }));
  };

  const isTarmacLoginLoading = () => (
    externalAuthStatus === Status.LOADING || externalAuthStatus === Status.DEFAULT
  );

  return {
    isDefaultLoginOpen,
    loginStatus,
    isTarmacLoginLoading,
    password,
    setPassword,
    setUsername,
    submitLogin,
    username,
    useTarmacAuthSystem,
    submitSso,
    tarmacLoginErrorMessage,
  };
}
