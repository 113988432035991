import React, { ChangeEvent, useEffect, useState } from 'react';

import { addStayUpClassNameIfInputFilled }
  from 'src/utils/logic/input/setUpClassForInputAnimation';

import { TextInputProps } from 'src/uiComponents/input/InputInterface';

import 'src/uiComponents/input/Input.css';

export default function TextInput(props: TextInputProps) {
  const {
    defaultValue,
    inputLabel,
    isRequired,
    readOnly,
  } = props;

  const setDefaultValue = () => (
    (defaultValue !== null && defaultValue !== undefined) ? defaultValue : ''
  );

  const [textInputValue, setTextInputValue] = useState<string>(setDefaultValue());

  useEffect(() => {
    if (defaultValue) {
      setTextInputValue(defaultValue);
    }
  }, [defaultValue]);

  const onTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextInputValue(event.target.value);
  };

  return (
    <div className="InputWrapper">
      <input
        id={`TextInput${textInputValue}InputId`}
        value={textInputValue}
        onChange={onTextInputChange}
        className={`InputWrapperInput  ${addStayUpClassNameIfInputFilled(textInputValue)}`}
        type="text"
        required={isRequired}
        readOnly={readOnly}
      />
      <label
        className={`InputWrapperLabel ${addStayUpClassNameIfInputFilled(textInputValue)}`}
        id={`TextInput${textInputValue}LabelId`}
      >
        {inputLabel}
      </label>
    </div>
  );
}
