import React from 'react';

import { additionalServiceFormText } from 'src/constants/componentTexts/AdditionalServicesText';

import ListInput from 'src/uiComponents/input/ListInput';

import {
  AdditionalServiceParametersInterface,
  AdditionalServiceReceivedInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { getAddInfosBasedOnTaskDataSourceChoice } from 'src/invoicing/services/ServicesLogic';
import { getPreviousLabelFilled } from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';
import useAddInfoTask from 'src/invoicing/services/additionalServices/form/useAddInfoTask';

interface Props {
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: AdditionalServiceParametersInterface;
  additionalServiceTimingDifference: any;
  readOnly?: boolean;
  setAdditionalServiceTimingDifference: Function;
  taskDatasource: string;
  addInfoDatasource: string;
}

export default function AddInfo(props: Props) {
  const {
    additionalServiceFilled,
    additionalServiceParameters,
    additionalServiceTimingDifference,
    readOnly,
    setAdditionalServiceTimingDifference,
    taskDatasource,
    addInfoDatasource,
  } = props;

  const {
    isAddInfoInputDisable,
    isAdditionalServiceTimingDifferenceTaskDatasourceExists,
  } = useAddInfoTask(props);

  return (
    <>
      <div className="DifferenceBetweenTwoTimingsInput">
        <ListInput
          formObjectPreviousFilledValue={getPreviousLabelFilled(taskDatasource, additionalServiceFilled)}
          inputLabel={additionalServiceFormText.taskDatasource}
          isRequired
          optionList={additionalServiceParameters.task_datasources}
          formObjectKey={taskDatasource}
          setFormObject={setAdditionalServiceTimingDifference}
          formObject={additionalServiceTimingDifference}
          isReadOnly={readOnly}
        />
      </div>
      <div className="DifferenceBetweenTwoTimingsInput">
        <ListInput
          formObjectPreviousFilledValue={getPreviousLabelFilled(addInfoDatasource, additionalServiceFilled)}
          inputLabel={additionalServiceFormText.addInfoDatasource}
          isRequired
          isDisabled={isAddInfoInputDisable()}
          optionList={getAddInfosBasedOnTaskDataSourceChoice(isAdditionalServiceTimingDifferenceTaskDatasourceExists(), additionalServiceParameters)}
          formObjectKey={addInfoDatasource}
          setFormObject={setAdditionalServiceTimingDifference}
          formObject={additionalServiceTimingDifference}
          isReadOnly={readOnly}
        />
      </div>
    </>
  );
}
