import React from 'react';

import ListInput from 'src/uiComponents/input/ListInput';

interface Props {
  customers: any;
  filters: any;
  setFilters: Function;
}

export default function CustomerFilter(props: Props) {
  const { filters, customers, setFilters } = props;

  return (
    <div className="InvoicesCustomerFilter">
      <ListInput
        inputLabel="Customer"
        isRequired
        optionList={customers}
        formObjectKey="customer"
        setFormObject={setFilters}
        formObject={filters}
      />
    </div>
  );
}
