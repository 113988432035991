import React from 'react';

interface Props {
  checkboxKey: string;
  checkboxName: string;
  checkboxFixedPriceSetter: Function;
  checkboxValue: boolean;
  checkboxVariablePriceSetter: Function;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired: boolean;
}

export default function CheckboxPriceTypeInput(props: Props) {
  const {
    checkboxKey,
    checkboxName,
    checkboxFixedPriceSetter,
    checkboxValue,
    checkboxVariablePriceSetter,
    isDisabled = false,
    isReadOnly,
    isRequired,
  } = props;

  const setCheckedCheckboxBasedOnCheckBoxKeySend = () => {
    if (checkboxKey === 'fixed_price') {
      checkboxFixedPriceSetter(!checkboxValue); checkboxVariablePriceSetter(checkboxValue);
    } else {
      checkboxVariablePriceSetter(!checkboxValue); checkboxFixedPriceSetter(checkboxValue);
    }
  };

  return (
    <div className="CheckboxInputContent">
      <p className="FormTextFontSize">{checkboxName}</p>
      <input
        type="radio"
        id={`Checkbox${checkboxKey}Id`}
        checked={checkboxValue === true}
        onChange={!isReadOnly ? () => setCheckedCheckboxBasedOnCheckBoxKeySend() : () => {}}
        disabled={isDisabled}
        required={isRequired}
        readOnly={isReadOnly}
        className="CheckboxInputContentBox"
      />
    </div>
  );
}
