import { createAsyncThunk } from '@reduxjs/toolkit';

import { invoicingLoginRequest, invoicingPostSSoRequest } from 'src/invoicing/api/invoicingCustomApiRequests';

export const apiFetchLogin = createAsyncThunk('login/apiFetchLogin', async (credentials: { username: string, password: string }) => {
  const response = await invoicingLoginRequest(credentials);

  return response;
});

export const apiExternalAuth = createAsyncThunk('login/apiExternalAuth', async (body: { username: string, host: string }) => {
  const response = await invoicingPostSSoRequest(body);

  return response;
});
