import { createSlice } from '@reduxjs/toolkit';

import { NEW_ADDITIONAL_SERVICE_ERROR_MESSAGE, UPDATE_ADDITIONAL_SERVICE_ERROR_MESSAGE } from 'src/constants/componentTexts/AdditionalServicesText';
import { Status } from 'src/constants/status';

import {
  apiDeleteAdditionalService,
  apiGetAdditionalService,
  apiGetAdditionalServiceParameters,
  apiGetAdditionalServices,
  apiPatchAdditionalService,
  apiPostAdditionalService,
} from 'src/invoicing/services/additionalServices/AdditionalServicesApiRequest';

export const additionalServicesSlice = createSlice({
  name: 'additionalServices',
  initialState: {
    additionalServiceParameters: {
      additional_service_names: [],
      arrival_or_departure_service: [],
      data_types_to_mesure: [],
      tasks_timings_references: [],
      task_datasources: [],
      currency: {},
      value_types: [],
    },
    additionalServices: [],
    additionalService: {},
    additionalServiceErrorMessage: '',
    getAdditionalServiceParametersStatus: Status.DEFAULT,
    postAdditionalServiceStatus: Status.DEFAULT,
    patchAdditionalServiceStatus: Status.DEFAULT,
    deleteAdditionalServiceStatus: Status.DEFAULT,
    getAdditionalServiceStatus: Status.DEFAULT,
    getAdditionalServicesStatus: Status.DEFAULT,
  },
  reducers: {
    resetPostAdditionalServiceStatus(state) {
      state.postAdditionalServiceStatus = Status.DEFAULT;
    },
    resetPatchAdditionalServiceStatus(state) {
      state.patchAdditionalServiceStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetAdditionalServices.pending, (state) => {
        state.additionalServices = [];
        state.getAdditionalServicesStatus = Status.LOADING;
      })
      .addCase(apiGetAdditionalServices.fulfilled, (state, action) => {
        state.additionalServices = action.payload;
        state.getAdditionalServicesStatus = Status.SUCCESS;
      })
      .addCase(apiGetAdditionalServices.rejected, (state) => {
        state.additionalServices = [];
        state.getAdditionalServicesStatus = Status.FAIL;
      })
      .addCase(apiGetAdditionalServiceParameters.pending, (state) => {
        state.getAdditionalServiceParametersStatus = Status.LOADING;
      })
      .addCase(apiGetAdditionalServiceParameters.fulfilled, (state, action) => {
        state.additionalServiceParameters = action.payload;
        state.getAdditionalServiceParametersStatus = Status.SUCCESS;
      })
      .addCase(apiGetAdditionalServiceParameters.rejected, (state) => {
        state.getAdditionalServiceParametersStatus = Status.FAIL;
      })
      .addCase(apiGetAdditionalService.pending, (state) => {
        state.getAdditionalServiceStatus = Status.LOADING;
      })
      .addCase(apiGetAdditionalService.fulfilled, (state, action) => {
        state.getAdditionalServiceStatus = Status.SUCCESS;
        state.additionalService = action.payload;
      })
      .addCase(apiGetAdditionalService.rejected, (state) => {
        state.getAdditionalServiceStatus = Status.FAIL;
      })
      .addCase(apiPostAdditionalService.pending, (state) => {
        state.postAdditionalServiceStatus = Status.LOADING;
      })
      .addCase(apiPostAdditionalService.fulfilled, (state) => {
        state.postAdditionalServiceStatus = Status.SUCCESS;
      })
      .addCase(apiPostAdditionalService.rejected, (state) => {
        state.postAdditionalServiceStatus = Status.FAIL;
        state.additionalServiceErrorMessage = NEW_ADDITIONAL_SERVICE_ERROR_MESSAGE;
      })
      .addCase(apiPatchAdditionalService.pending, (state) => {
        state.patchAdditionalServiceStatus = Status.LOADING;
      })
      .addCase(apiPatchAdditionalService.fulfilled, (state) => {
        state.patchAdditionalServiceStatus = Status.SUCCESS;
      })
      .addCase(apiPatchAdditionalService.rejected, (state) => {
        state.patchAdditionalServiceStatus = Status.FAIL;
        state.additionalServiceErrorMessage = UPDATE_ADDITIONAL_SERVICE_ERROR_MESSAGE;
      })
      .addCase(apiDeleteAdditionalService.pending, (state) => {
        state.deleteAdditionalServiceStatus = Status.LOADING;
      })
      .addCase(apiDeleteAdditionalService.fulfilled, (state) => {
        state.deleteAdditionalServiceStatus = Status.SUCCESS;
      })
      .addCase(apiDeleteAdditionalService.rejected, (state) => {
        state.deleteAdditionalServiceStatus = Status.FAIL;
      });
  },
});

export const { resetPatchAdditionalServiceStatus, resetPostAdditionalServiceStatus } = additionalServicesSlice.actions;

export const selectAdditionalService = (state: any) => state.additionalServices.additionalService;
export const selectAdditionalServiceErrorMessage = (state: any) => state.additionalServices.additionalServiceErrorMessage;
export const selectAdditionalServices = (state: any) => state.additionalServices.additionalServices;
export const selectAdditionalServiceParameters = (state: any) => state.additionalServices.additionalServiceParameters;
export const selectAdditionalServiceParametersStatus = (state: any) => state.additionalServices.getAdditionalServiceParametersStatus;
export const selectDeleteAdditionalServiceStatus = (state: any) => state.additionalServices.deleteAdditionalServiceStatus;
export const selectGetAdditionalServiceStatus = (state: any) => state.additionalServices.getAdditionalServiceStatus;
export const selectGetAdditionalServicesStatus = (state: any) => state.additionalServices.getAdditionalServicesStatus;
export const selectPatchAdditionalServiceStatus = (state: any) => state.additionalServices.patchAdditionalServiceStatus;
export const selectPostAdditionalServiceStatus = (state: any) => state.additionalServices.postAdditionalServiceStatus;

export default additionalServicesSlice.reducer;
