import { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { selectContractAction } from 'src/invoicing/contract/ContractSlice';

interface Props {
  nextComponent: string | null;
  setCurrentContractByStepType: Function;
}

export default function useOperationTypesByStep(props: Props) {
  const {
    nextComponent,
    setCurrentContractByStepType,
  } = props;

  const contractAction: string = useSelector(selectContractAction);
  const [isOperationTypeSubViewOpen, setIsOperationTypeSubViewOpen] = useState<boolean>(false);
  const [operationTypeAction, setOperationTypeAction] = useState<string>(ActionStatus.NEW);

  const submitOperationTypes = (event: FormEvent) => {
    event.preventDefault();

    if (nextComponent) {
      setCurrentContractByStepType(nextComponent);
    }
  };

  return {
    contractAction,
    isOperationTypeSubViewOpen,
    operationTypeAction,
    setIsOperationTypeSubViewOpen,
    setOperationTypeAction,
    submitOperationTypes,
  };
}
