import React from 'react';

import { NO_INVOICE_MESSAGE } from 'src/constants/componentTexts/InvoiceAppText';

export default function NoInvoice() {
  return (
    <div className="InvoiceAppContentNoContract">
      <p id="InvoiceAppNoInvoiceText" className="InvoiceAppContentNoContractText">{NO_INVOICE_MESSAGE}</p>
    </div>
  );
}
