export const CONTRACTS = 'Contracts';
export const HOME = 'Home';
export const INVOICES = 'Invoices';
export const LOGIN = 'Login';
export const ROOT = 'Root';

export const routesNameToPath: {[key: string]: string} = {
  Contracts: '/contracts',
  Home: '/home',
  Invoices: '/invoices',
  Login: '/login',
  Root: '/',
};
