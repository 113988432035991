import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  invoicingDeleteApiRequestAsyncThunk,
  invoicingGetApiRequestAsyncThunk,
  invoicingPatchApiRequestAsyncThunk,
  invoicingPostApiRequestAsyncThunk,
} from 'src/invoicing/api/invoicingCustomApiRequestLogic';

export const apiGetOperationTypes = createAsyncThunk('operationTypes/apiGetOperationTypes', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/operation_types` })
));

export const apiGetOperationTypeParameters = createAsyncThunk('operationTypes/apiGetOperationTypeParameters', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/operation_type_parameters` })
));

export const apiGetOperationType = createAsyncThunk('operationTypes/apiGetOperationType', async (operationTypeId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/operation_type/${operationTypeId}` })
));

export const apiPostOperationType = createAsyncThunk('operationTypes/apiPostOperationType', async (body: any, { dispatch, getState, rejectWithValue }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/operation_type', body })
));

export const apiPatchOperationType = createAsyncThunk('operationTypes/apiPatchOperationType', async (body: { operationType: any, operationTypeId: number }, { dispatch, getState, rejectWithValue }) => (
  invoicingPatchApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/operation_type/${body.operationTypeId}`, body: body.operationType })
));

export const apiDeleteOperationType = createAsyncThunk('operationTypes/apiDeleteOperationType', async (operationTypeId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingDeleteApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/operation_type/${operationTypeId}` })
));
