import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Status } from 'src/constants/status';
import { TO_CHECK_STATUS, VALIDATED_STATUS } from 'src/constants/componentTexts/InvoiceAppText';

import {
  apiGetInvoice, apiPostInvoiceDetail, apiPostInvoiceToValidate, apiRefreshInvoiceinInvoices,
} from 'src/invoicing/invoices/InvoicesApiRequest';
import {
  resetDeleteInvoiceStatus,
  resetPostInvoiceDetailServicesStatus,
  resetPostInvoiceToValidateStatus,
  selectDeleteInvoiceStatus,
  selectGetInvoiceStatus,
  selectInvoice,
  selectPostInvoiceDetailServicesStatus,
  selectPostInvoiceToValidateStatus,
} from 'src/invoicing/invoices/InvoicesSlice';

export default function useInvoiceDetail(setIsInvoiceDetailOpen: Function) {
  const dispatch = useDispatch();

  const getInvoiceStatus: string = useSelector(selectGetInvoiceStatus);
  const invoice: any = useSelector(selectInvoice);
  const postInvoiceDetailServicesStatus: string = useSelector(selectPostInvoiceDetailServicesStatus);
  const postInvoiceToValidateStatus: string = useSelector(selectPostInvoiceToValidateStatus);
  const deleteServiceStatus: string = useSelector(selectDeleteInvoiceStatus);

  const [customServices, setCustomServices] = useState([]);
  const [invoiceValidated, setInvoiceValidated] = useState<boolean>(false);
  const [servicesDescription, setServicesDescription] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [isActionDoneOnInvoiceDetail, setIsActionDoneOnInvoiceDetail] = useState<boolean>(false);

  useEffect(() => {}, [servicesDescription]);
  useEffect(() => {}, [getInvoiceStatus !== Status.LOADING]);

  useEffect(() => {
    if (deleteServiceStatus === Status.SUCCESS) {
      dispatch(apiGetInvoice(invoice.id));
      setServicesDescription([]);
      setCustomServices([]);
      dispatch(resetDeleteInvoiceStatus());
      setIsActionDoneOnInvoiceDetail(true);
    }
    if (deleteServiceStatus === Status.FAIL) {
      setIsErrorModalOpen(true);
      dispatch(resetDeleteInvoiceStatus());
    }
  }, [deleteServiceStatus]);

  useEffect(() => {
    if (postInvoiceToValidateStatus === Status.SUCCESS) {
      dispatch(resetPostInvoiceToValidateStatus());
      setIsActionDoneOnInvoiceDetail(true);
    }
  }, [postInvoiceToValidateStatus]);

  useEffect(() => {
    if (postInvoiceDetailServicesStatus === Status.SUCCESS) {
      setCustomServices([]);
      setServicesDescription([]);
      setInvoiceValidated(invoice?.status?.code === VALIDATED_STATUS.code);
      dispatch(resetPostInvoiceDetailServicesStatus());
      setIsActionDoneOnInvoiceDetail(true);
    }
  }, [postInvoiceDetailServicesStatus]);

  useEffect(() => {
    if (getInvoiceStatus === Status.SUCCESS) {
      setInvoiceValidated(invoice?.status?.code === VALIDATED_STATUS.code);
    }
  }, [invoice]);

  const onInvoiceDetailClose = () => {
    setIsInvoiceDetailOpen(false);
    if (isActionDoneOnInvoiceDetail) {
      dispatch(apiRefreshInvoiceinInvoices(invoice.id));
    }
  };

  const toggleValidateInvoice = () => {
    setInvoiceValidated(!invoiceValidated);
  };

  const hasCustomServicesOrServicesDescription = () => (
    (servicesDescription.length !== 0 || customServices.length !== 0)
  );

  const hasInvoiceStatusChangedBasedOnInvoiceStatusReceived = () => (
    (invoice.status.code === TO_CHECK_STATUS.code && invoiceValidated) || (invoice.status.code === VALIDATED_STATUS.code && !invoiceValidated)
  );

  const submitInvoiceDetail = (event: FormEvent) => {
    event.preventDefault();

    if (servicesDescription.length > 0 || customServices.length > 0) {
      dispatch(apiPostInvoiceDetail({ invoiceId: invoice.id, services: { servicesDescription, customServices } }));
    }
    if (hasInvoiceStatusChangedBasedOnInvoiceStatusReceived()) {
      dispatch(apiPostInvoiceToValidate({ id: Number(invoice.id) }));
    }
  };

  const isInvoiceLoading = () => (
    getInvoiceStatus === Status.LOADING || getInvoiceStatus === Status.FAIL
    || postInvoiceDetailServicesStatus === Status.LOADING || postInvoiceDetailServicesStatus === Status.FAIL
    || postInvoiceToValidateStatus === Status.LOADING || postInvoiceToValidateStatus === Status.FAIL
    || deleteServiceStatus === Status.LOADING || deleteServiceStatus === Status.FAIL
  );

  return {
    customServices,
    hasCustomServicesOrServicesDescription,
    hasInvoiceStatusChangedBasedOnInvoiceStatusReceived,
    invoice,
    invoiceValidated,
    isErrorModalOpen,
    isInvoiceLoading,
    onInvoiceDetailClose,
    servicesDescription,
    setCustomServices,
    setIsErrorModalOpen,
    setServicesDescription,
    submitInvoiceDetail,
    toggleValidateInvoice,
  };
}
