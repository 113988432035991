export const NO_SERVICE_MESSAGE = 'Currently no services';
export const ADD_CUSTOM_SERVICE_SYMBOL = '+';
export const VALIDATE_INVOICE_TEXT = 'Validate this invoice';
export const ADD_DESCRIPTION_TEXT = '+ Add a description';
export const INVOICE_DETAIL_TITLE = 'Invoice Detail';

export const NO_SERVICE_TEXT = '';
export const EMPTY_VALUE = '';

export const AMOUNT_KEY = 'amount';
export const LABEL_KEY = 'label';

export const CUSTOM_SERVICE_TYPE = 'custom_service';

export const disableServices = ['base_price', 'operation_type', 'cancellation', 'delay', 'additionnal_service'];

export const AMOUNT_TYPE = 'number';
export const CODE_TYPE = 'code';
export const LABEL_TYPE = 'label';

export const FLIGHTS_SEPARATOR = '->';
