import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DOWNLOAD_BUTTON_NAME } from 'src/constants/componentTexts/InvoiceAppText';
import { DOWNLOAD_LOGO } from 'src/constants/picturesPath';
import { apiGetDownloadInvoices } from 'src/invoicing/invoices/InvoicesApiRequest';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

interface Props {
  invoicesToDownload: InvoiceModel[];
  setInvoicesToDownload: Function;
}

export default function DownloadButton(props: Props) {
  const { invoicesToDownload, setInvoicesToDownload } = props;

  const dispatch = useDispatch();

  useEffect(() => {}, [invoicesToDownload]);

  const onValidateClick = () => {
    dispatch(apiGetDownloadInvoices(invoicesToDownload));
    setInvoicesToDownload([]);
  };

  return (
    <button
      onClick={onValidateClick}
      aria-hidden="true"
      type="button"
      disabled={invoicesToDownload.length === 0}
      className="InvoicesDownloadButton"
    >
      <img src={DOWNLOAD_LOGO} alt={DOWNLOAD_LOGO} className="InvoicesDownloadImg" />
      {DOWNLOAD_BUTTON_NAME}
    </button>
  );
}
