import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetContractDelayRuleParameters } from 'src/invoicing/services/delayRules/DelayRulesApiRequest';
import { selectDelayRuleParameters, selectDelayRuleParametersStatus } from 'src/invoicing/services/delayRules/DelayRulesSlice';

export default function useDelayRuleParameters(contractId: number | null) {
  const dispatch = useDispatch();

  const delayRuleParameters = useSelector(selectDelayRuleParameters);
  const delayRuleParametersStatus = useSelector(selectDelayRuleParametersStatus);

  useEffect(() => {
    if (contractId) {
      dispatch(apiGetContractDelayRuleParameters(contractId));
    }
  }, []);

  return {
    delayRuleParameters,
    delayRuleParametersStatus,
  };
}
