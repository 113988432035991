import React from 'react';

import ContractByStepManager from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepManager';

import { NEW_CONTRACT_TITLE } from 'src/constants/componentTexts/ContractText';

import 'src/invoicing/contract/Contract.css';

interface Props {
  setIsNewContractOpen: Function;
}

export default function ContractAndServicesCreatorByStep(props: Props) {
  const { setIsNewContractOpen } = props;

  return (
    <div className="DefaultBackGroundColor NewContractAppContent">
      <div className="NewContractAppContentTitle TitleFontSize">
        <p id="NewContractAppTitle">{NEW_CONTRACT_TITLE}</p>
      </div>
      <ContractByStepManager setIsContractByStepManagerOpen={setIsNewContractOpen} />
    </div>
  );
}
