import React from 'react';

import { ADD_ITEM_LOGO } from 'src/constants/picturesPath';
import { NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TITLE } from 'src/constants/componentTexts/AdditionalServicesText';

import AdditionalServices from 'src/invoicing/services/additionalServices/AdditionalServices';
import Loader from 'src/uiComponents/loader/Loader';
import NoAdditionalService from 'src/invoicing/services/additionalServices/NoAdditionalService';

import {
  areServicesLoading,
  canAServiceBeAdded,
  isApiCallStatusSuccessFullAndHasNoServiceToDisplay,
  isApiCallStatusSuccessFullAndHasServicesToDisplay,
} from 'src/invoicing/services/ServicesLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import useAdditionalServicesEditor from 'src/invoicing/services/additionalServices/useAdditionalServicesEditor';

interface Props {
  contractByStepsFilled: boolean[];
  contractId: number | null;
  isAdditionalSubViewOpen: boolean;
  setAdditionalServiceAction: Function;
  setIsAdditionalSubViewOpen: Function;
  setcontractByStepsFilled: Function;
}

export default function AdditionalServicesEditor(props: Props) {
  const { setAdditionalServiceAction, setIsAdditionalSubViewOpen } = props;

  const {
    additionalServices,
    contractAction,
    contractStatus,
    additionalServicesStatus,
    onNewAddtionalServiceClick,
    patchAdditionalServiceStatus,
    postAdditionalServiceStatus,
  } = useAdditionalServicesEditor(props);

  const handleAddServicesDisplayBasedOnGetAddServicesStatus = () => {
    if (shouldLoaderBeDisplayed([additionalServicesStatus])) {
      return <Loader className="NewContractAppContentAdditionalServicesLoader" />;
    }
    if (isApiCallStatusSuccessFullAndHasServicesToDisplay(additionalServicesStatus, additionalServices)) {
      return (
        <AdditionalServices
          additionalServices={additionalServices}
          contractAction={contractAction}
          contractStatus={contractStatus}
          setAdditionalServiceAction={setAdditionalServiceAction}
          setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
        />
      );
    }
    if (isApiCallStatusSuccessFullAndHasNoServiceToDisplay(additionalServicesStatus, additionalServices)) {
      return <NoAdditionalService />;
    }
    return null;
  };

  return (
    areServicesLoading(postAdditionalServiceStatus, patchAdditionalServiceStatus) ? (
      <Loader className="NewContractAppContentAdditionalServicesLoader" />
    ) : (
      <div className="NewContractAppContentAdditionalServicesForm">
        <div className="NewContractAppContentAdditionalServicesFormTitle">
          <p
            id="AdditionalServicesTitle"
            className="FormTitleFontSize NewContractAppContentAdditionalServicesFormTitleText"
          >
            {NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TITLE}
          </p>
          {canAServiceBeAdded(contractStatus, contractAction) && (
            <img
              className="NewContractAppContentAdditionalServicesFormTitleLogo"
              id="AdditionalServicesAddNewRules"
              src={ADD_ITEM_LOGO}
              alt="AddNewRulesLogo"
              aria-hidden="true"
              onClick={onNewAddtionalServiceClick}
            />
          )}
        </div>
        {handleAddServicesDisplayBasedOnGetAddServicesStatus()}
      </div>
    )
  );
}
