import React from 'react';

import { additionalServiceFormText } from 'src/constants/componentTexts/AdditionalServicesText';

import AddInfo from 'src/invoicing/services/additionalServices/form/AddInfo';
import ListInput from 'src/uiComponents/input/ListInput';
import Task from 'src/invoicing/services/additionalServices/form/Task';
import TaskAddInfoToggle from 'src/invoicing/services/additionalServices/form/TaskAddInfoToggle';

import {
  AdditionalServiceParametersInterface,
  AdditionalServiceReceivedInterface,
} from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { getPreviousLabelFilled } from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';
import useToggleDifferenceBetweenTwoTimings from 'src/invoicing/services/additionalServices/form/useToggleDifferenceBetweenTwoTimings';

interface Props {
  additionalServiceFilled?: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: AdditionalServiceParametersInterface;
  additionalServiceTimingDifference: any;
  datasourcePart: string;
  readOnly?: boolean;
  setAdditionalServiceTimingDifference: Function;
}

export default function DifferenceBetweenTwoTimingsDatasource(props: Props) {
  const {
    additionalServiceFilled,
    additionalServiceParameters,
    additionalServiceTimingDifference,
    datasourcePart,
    readOnly,
    setAdditionalServiceTimingDifference,
  } = props;

  const {
    addInfoDatasourceFieldName,
    getTaskDatasourceTimingReferenceFilled,
    isAddInfoChecked,
    isTaskChecked,
    setIsAddInfoChecked,
    setIsTaskChecked,
    taskArrivalOrDepartureFieldName,
    taskDatasourceFieldName,
    taskDatasourceTimingReferenceFieldName,
  } = useToggleDifferenceBetweenTwoTimings({
    datasourcePart,
    additionalServiceTimingDifference,
    additionalServiceFilled,
    setAdditionalServiceTimingDifference,
  });

  return (
    <div className={`DifferenceBetweenTwoTimingsWrapperPart${datasourcePart}`}>
      <div className={`DifferenceBetweenTwoTimingsWrapperPart${datasourcePart}HeaderContent`}>
        <div className={`DifferenceBetweenTwoTimingsWrapperPart${datasourcePart}HeaderContentToggle`}>
          <TaskAddInfoToggle
            isAddInfoChecked={isAddInfoChecked}
            isTaskChecked={isTaskChecked}
            setIsAddInfoChecked={setIsAddInfoChecked}
            setIsTaskChecked={setIsTaskChecked}
            togglePartId={datasourcePart}
            readOnly={readOnly}
          />
        </div>
        <div className={`DifferenceBetweenTwoTimingsWrapperPart${datasourcePart}HeaderContentText`}>
          <p id={`Timing${datasourcePart}Text`} className={`DifferenceBetweenTwoTimingsWrapperPart${datasourcePart}Text`}>
            {datasourcePart}
          </p>
        </div>
      </div>
      <div className="DifferenceBetweenTwoTimingsWrapperSpacer" />
      <div className="DifferenceBetweenTwoTimingsInput">
        <ListInput
          formObjectPreviousFilledValue={getPreviousLabelFilled(taskArrivalOrDepartureFieldName, additionalServiceFilled)}
          inputLabel={additionalServiceFormText.taskArrivalOrDeparture}
          isRequired
          optionList={additionalServiceParameters.arrival_or_departure_task}
          formObjectKey={taskArrivalOrDepartureFieldName}
          setFormObject={setAdditionalServiceTimingDifference}
          formObject={additionalServiceTimingDifference}
          optionInputValueField="code"
          isReadOnly={readOnly}
        />
      </div>
      {isTaskChecked && (
        <Task
          additionalServiceFilled={additionalServiceFilled}
          additionalServiceParameters={additionalServiceParameters}
          additionalServiceTimingDifference={additionalServiceTimingDifference}
          readOnly={readOnly}
          setAdditionalServiceTimingDifference={setAdditionalServiceTimingDifference}
          taskDatasource={taskDatasourceFieldName}
          taskTimingReferenceKey={taskDatasourceTimingReferenceFieldName}
          taskTimingReference={getTaskDatasourceTimingReferenceFilled()}
        />
      )}
      {isAddInfoChecked && (
        <AddInfo
          additionalServiceFilled={additionalServiceFilled}
          additionalServiceParameters={additionalServiceParameters}
          additionalServiceTimingDifference={additionalServiceTimingDifference}
          readOnly={readOnly}
          setAdditionalServiceTimingDifference={setAdditionalServiceTimingDifference}
          taskDatasource={taskDatasourceFieldName}
          addInfoDatasource={addInfoDatasourceFieldName}
        />
      )}
    </div>
  );
}
