import React from 'react';

import {
  NEW_CONTRACT_FORM_SERVICES_CANCEL_OPERATION_TYPE_FORM_BUTTON,
} from 'src/constants/componentTexts/OperationTypesText';

import ErrorModal from 'src/uiComponents/modal/ErrorModal';
import OperationTypeFormCaracteristic from 'src/invoicing/services/operationTypes/form/OperationTypeFormCaracteristic';
import OperationTypeFormPrice from 'src/invoicing/services/operationTypes/form/OperationTypeFormPrice';
import OperationTypeFormPriceChoices from 'src/invoicing/services/operationTypes/form/OperationTypeFormPriceChoices';
import ServicesFormButtons from 'src/invoicing/services/ServicesFormButtons';

import { OperationTypeFilledInterface, OperationTypeParametersInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';
import useOperationTypeForm from 'src/invoicing/services/operationTypes/form/useOperationTypeForm';

interface Props {
  contractId: number | null;
  operationTypeFilled?: OperationTypeFilledInterface | null;
  operationTypeParameters: OperationTypeParametersInterface;
  readOnly?: boolean;
  setIsOperationTypeViewOpen: Function;
}

export default function OperationTypeForm(props: Props) {
  const {
    operationTypeFilled = null,
    operationTypeParameters,
    readOnly = false,
    setIsOperationTypeViewOpen,
  } = props;

  const {
    getSendOperationTypeButtonBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    operationType,
    operationTypePrice,
    operationTypeResponseErrorMessage,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    setOperationType,
    setOperationTypePrice,
    submitOperationType,
  } = useOperationTypeForm(props);

  return (
    <>
      <form className="ServicesForm" onSubmit={(event) => submitOperationType(event)}>
        <OperationTypeFormCaracteristic
          operationType={operationType}
          operationTypeFilled={operationTypeFilled}
          operationTypeParameters={operationTypeParameters}
          readOnly={readOnly}
          setOperationType={setOperationType}
        />
        <OperationTypeFormPriceChoices
          isFixedPriceChecked={isFixedPriceChecked}
          isVariablePriceChecked={isVariablePriceChecked}
          readOnly={readOnly}
          setIsFixedPriceChecked={setIsFixedPriceChecked}
          setIsVariablePriceChecked={setIsVariablePriceChecked}
        />
        <OperationTypeFormPrice
          isFixedPriceChecked={isFixedPriceChecked}
          isVariablePriceChecked={isVariablePriceChecked}
          operationTypeFilled={operationTypeFilled}
          operationTypeParameters={operationTypeParameters}
          operationTypePrice={operationTypePrice}
          readOnly={readOnly}
          setOperationTypePrice={setOperationTypePrice}
        />
        <ServicesFormButtons
          cancelButtonText={NEW_CONTRACT_FORM_SERVICES_CANCEL_OPERATION_TYPE_FORM_BUTTON}
          sendButtonText={getSendOperationTypeButtonBasedOnUserAction()}
          setIsServicesSubViewOpen={setIsOperationTypeViewOpen}
          isDisabled={readOnly}
        />
      </form>
      {isErrorModalOpen && (
        <ErrorModal
          setIsErrorModalOpen={setIsErrorModalOpen}
          errorMessage={operationTypeResponseErrorMessage}
        />
      )}
    </>
  );
}
