import { FIXED_AMOUNT_CODE, PERCENTAGE_OF_BASE_PRICE_CODE } from 'src/constants/componentTexts/ServiceText';
import { CancellationRuleSentInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';

export const getDefaultCancellationRule = (cancellationRuleFilled: any | null, defaultCancellationRule: CancellationRuleSentInterface) => (
  cancellationRuleFilled ? {
    ...defaultCancellationRule,
    cause_of_cancellation: cancellationRuleFilled.cause_of_cancellation.id,
    price: {
      amounts_type: cancellationRuleFilled.price.amounts_type.code,
      price_steps: cancellationRuleFilled.price.price_steps,
      values_type: cancellationRuleFilled.price.values_type.id,
    },
  } : defaultCancellationRule
);

export const isFixedPriceFilled = (cancellationRuleFilled : any) => (
  Boolean(cancellationRuleFilled && cancellationRuleFilled.price && cancellationRuleFilled.price.amounts_type.code === FIXED_AMOUNT_CODE)
);

export const isVariablePriceFilled = (cancellationRuleFilled : any) => (
  Boolean(cancellationRuleFilled && cancellationRuleFilled.price && cancellationRuleFilled.price.amounts_type.code === PERCENTAGE_OF_BASE_PRICE_CODE)
);

export const createPatchCancellationRuleBody = (cancellationRule: CancellationRuleSentInterface, contractId: number | null, cancellationRuleFilledId: number) => (
  {
    cancellationRuleBody: {
      ...cancellationRule,
      contract: contractId,
    },
    cancellationRuleId: cancellationRuleFilledId,
  }
);
