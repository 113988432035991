import React from 'react';

import { ActionStatus } from 'src/constants/status';
import { ADDITIONAL_SERVICES_STEP_NUMBER } from 'src/constants/componentTexts/AdditionalServicesText';

import AdditionalServicesEditor from 'src/invoicing/services/additionalServices/AdditionalServicesEditor';
import ContractByStepNavigationButtons from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepNavigationButtons';
import EditAdditionalService from 'src/invoicing/services/additionalServices/EditAdditionalService';
import NewAdditionalService from 'src/invoicing/services/additionalServices/NewAdditionalService';
import Stepper from 'src/uiComponents/stepper/Stepper';
import ViewAdditionalService from 'src/invoicing/services/additionalServices/ViewAdditionalService';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';
import useAdditionalServicesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/additionalServicesByStep/useAdditionalServicesByStep';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  storedContract: StoredContract;
  nextComponent: string | null;
  previousComponent: string | null;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setcontractByStepsFilled: Function;
}

export default function AdditionalServicesByStep(props: Props) {
  const {
    contractByStepsFilled,
    currentContractByStepType,
    storedContract,
    nextComponent,
    previousComponent,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    setcontractByStepsFilled,
  } = props;

  const {
    additionalServiceAction,
    contractAction,
    isAdditionalSubViewOpen,
    setAdditionalServiceAction,
    setIsAdditionalSubViewOpen,
    submitAdditionalServices,
  } = useAdditionalServicesByStep(props);

  const displayAdditionalServiceSubViewActions = () => {
    switch (additionalServiceAction) {
      case ActionStatus.EDIT:
        return (
          <EditAdditionalService
            contractId={storedContract.id}
            setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
          />
        );
      case ActionStatus.VIEW:
        return (
          <ViewAdditionalService
            contractId={storedContract.id}
            setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
          />
        );
      default:
        return (
          <NewAdditionalService
            contractId={storedContract.id}
            setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
          />
        );
    }
  };

  return (
    <>
      <Stepper
        actualContractByStep={ADDITIONAL_SERVICES_STEP_NUMBER}
        setCurrentContractByStepType={setCurrentContractByStepType}
        contractByStepsFilled={contractByStepsFilled}
      />
      {isAdditionalSubViewOpen ? (
        displayAdditionalServiceSubViewActions()
      ) : (
        <form onSubmit={(event) => submitAdditionalServices(event)}>
          <AdditionalServicesEditor
            contractByStepsFilled={contractByStepsFilled}
            contractId={storedContract.id}
            isAdditionalSubViewOpen={isAdditionalSubViewOpen}
            setAdditionalServiceAction={setAdditionalServiceAction}
            setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
            setcontractByStepsFilled={setcontractByStepsFilled}
          />
          <ContractByStepNavigationButtons
            contractAction={contractAction}
            currentContractByStepType={currentContractByStepType}
            nextComponent={nextComponent}
            previousComponent={previousComponent}
            setCurrentContractByStepType={setCurrentContractByStepType}
            setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
          />
        </form>
      )}
    </>
  );
}
