export const DATE_END_TEXT = 'to';
export const DATE_SEPARATOR_SYMBOL = '-';
export const DATE_START_TEXT = 'from';

export const periodQuickFiltersText = {
  groupTitle: 'Quick filters',
  lastSixMonths: 'Last 6 months',
  lastThreeMonths: 'Last 3 months',
  thisMonthSoFar: 'This month so far',
  previousMonth: 'Previous month',
  previousWeek: 'Previous week',
  previousYear: 'Previous year',
  today: 'Today',
  thisWeekSoFar: 'This week so far',
  thisYearSoFar: 'This year so far',
  yesterday: 'Yesterday',
};

export const quickFilters = [
  {
    id: 'inputRadioToday',
    text: periodQuickFiltersText.today,
  },
  {
    id: 'inputRadioYesterday',
    text: periodQuickFiltersText.yesterday,
  },
  {
    id: 'inputRadioThisWeekSoFar',
    text: periodQuickFiltersText.thisWeekSoFar,
  },
  {
    id: 'inputRadioPreviousWeek',
    text: periodQuickFiltersText.previousWeek,
  },
  {
    id: 'inputRadioThisMonthSoFar',
    text: periodQuickFiltersText.thisMonthSoFar,
  },
  {
    id: 'inputRadioPreviousMonth',
    text: periodQuickFiltersText.previousMonth,
  },
  {
    id: 'inputRadioLastThreeMonths',
    text: periodQuickFiltersText.lastThreeMonths,
  },
  {
    id: 'inputRadioLastSixMonths',
    text: periodQuickFiltersText.lastSixMonths,
  },
  {
    id: 'inputRadioThisYearSoFar',
    text: periodQuickFiltersText.thisYearSoFar,
  },
  {
    id: 'inputRadioPreviousYear',
    text: periodQuickFiltersText.previousYear,
  },
];
