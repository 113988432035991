import React from 'react';

import { NEW_CONTRACT_FORM_EDIT_OPERATION_TYPE_TITLE } from 'src/constants/componentTexts/OperationTypesText';

import Loader from 'src/uiComponents/loader/Loader';
import OperationTypeForm from 'src/invoicing/services/operationTypes/form/OperationTypeForm';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectPatchOperationTypeStatus } from 'src/invoicing/services/operationTypes/OperationTypesSlice';
import useOperationTypeParameters from 'src/invoicing/services/operationTypes/useOperationTypeParameters';
import useGettingOperationTypeSelected from 'src/invoicing/services/operationTypes/useGettingOperationTypeSelected';
import useOperationTypeSubmittionRequestStatus from 'src/invoicing/services/operationTypes/useOperationTypeSubmittionRequestStatus';

interface Props {
  contractId: number | null;
  setIsOperationTypeViewOpen: Function;
}

export default function EditOperationType(props: Props) {
  const { contractId, setIsOperationTypeViewOpen } = props;

  const { operationTypeParameters, operationTypeParametersStatus } = useOperationTypeParameters(contractId);
  const { getOperationTypeStatus, operationType } = useGettingOperationTypeSelected();
  useOperationTypeSubmittionRequestStatus(selectPatchOperationTypeStatus, setIsOperationTypeViewOpen);

  return (
    shouldLoaderBeDisplayed([getOperationTypeStatus, operationTypeParametersStatus]) ? (
      <Loader className="NewContractAppContentOperationTypesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="EditOperationTypeTitleId">{NEW_CONTRACT_FORM_EDIT_OPERATION_TYPE_TITLE}</p>
        </div>
        <OperationTypeForm
          contractId={contractId}
          operationTypeFilled={operationType}
          operationTypeParameters={operationTypeParameters}
          setIsOperationTypeViewOpen={setIsOperationTypeViewOpen}
        />
      </div>
    )
  );
}
