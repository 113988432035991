import { getTokenExpirationTimeLimit, isTokenExpiryValid } from 'src/invoicing/InvoicingLogic';

export default function useInvoicing() {
  const isLoggedIn = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshTokenExpiry = localStorage.getItem('refreshTokenExpiry');

    if (refreshTokenExpiry && refreshToken) {
      if (isTokenExpiryValid(getTokenExpirationTimeLimit(refreshTokenExpiry))) {
        return true;
      }
      return false;
    }
    return false;
  };

  return {
    isLoggedIn,
  };
}
