import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavBar from 'src/invoicing/navBar/NavBar';
import SessionExpiredModal from 'src/uiComponents/modal/UserDisconnectionModal';

import { resetHasToDisconnect, selectHasToDisconnect } from 'src/invoicing/InvoicingSlice';

export default function BaseApp(props: HTMLAttributes<HTMLElement>) {
  const { children } = props;

  const dispatch = useDispatch();

  const [hasToDisconnect, setHasToDisconnect] = useState<boolean>(false);

  const sliceHasToDisconnect = useSelector(selectHasToDisconnect);

  useEffect(() => {
    if (sliceHasToDisconnect) {
      setHasToDisconnect(true);
      dispatch(resetHasToDisconnect());
    }
  }, [sliceHasToDisconnect]);

  return (
    <div className="HomeWrapper">
      <NavBar />
      {children}
      {hasToDisconnect && <SessionExpiredModal />}
    </div>
  );
}
