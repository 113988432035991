import React from 'react';

import { NEW_CONTRACT_FORM_CANCELLATION_RULES_NO_RULE_MESSAGE }
  from 'src/constants/componentTexts/CancellationRulesText';

import NoService from 'src/invoicing/services/NoService';

export default function NoCancellationRule() {
  return (
    <NoService
      serviceName="CancellationRule"
      noServiceMessage={NEW_CONTRACT_FORM_CANCELLATION_RULES_NO_RULE_MESSAGE}
    />
  );
}
