import React from 'react';

import { INVOICES_TITLE } from 'src/constants/componentTexts/InvoiceAppText';

import BaseApp from 'src/invoicing/app/BaseApp';
import DownloadButton from 'src/uiComponents/buttons/DownloadButton';
import Loader from 'src/uiComponents/loader/Loader';
import InvoiceDetail from 'src/invoicing/invoiceDetail/InvoiceDetail';
import Invoices from 'src/invoicing/invoices/Invoices';
import InvoicesFilters from 'src/invoicing/invoices/filters/InvoicesFilters';
import InvoicesStatusFilters from 'src/invoicing/invoices/filters/invoicesStatusFilter/InvoicesStatusFilters';
import NoInvoice from 'src/invoicing/invoices/NoInvoice';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { hasInvoicesToDisplay } from 'src/invoicing/invoices/InvoicesLogic';
import useInvoiceApp from 'src/invoicing/app/useInvoiceApp';

import 'src/invoicing/app/InvoiceApp.css';

export default function InvoiceApp() {
  const {
    dateFilters,
    getInvoicesStatus,
    invoicesFilters,
    invoicesFilteredAndSorted,
    invoicesSortStatus,
    invoicesToDownload,
    isInvoiceDetailOpen,
    setDateFilters,
    setInvoicesFilters,
    setInvoicesSortStatus,
    setInvoicesToDownload,
    setIsInvoiceDetailOpen,
  } = useInvoiceApp();

  const displayNoInvoiceOrInvoicesBasedOnDataReceived = () => (
    hasInvoicesToDisplay(invoicesFilteredAndSorted) ? (
      <Invoices
        invoices={invoicesFilteredAndSorted}
        invoicesToDownload={invoicesToDownload}
        invoicesSortStatus={invoicesSortStatus}
        setInvoicesToDownload={setInvoicesToDownload}
        setInvoicesSortStatus={setInvoicesSortStatus}
        isInvoiceDetailOpen={isInvoiceDetailOpen}
        setIsInvoiceDetailOpen={setIsInvoiceDetailOpen}
      />
    ) : <NoInvoice />
  );

  return (
    <BaseApp>
      <div className="InvoiceAppContent">
        <div className="InvoiceAppContentTitle TitleFontSize">
          <p id="InvoiceAppTitle" className="InvoiceAppTitle">{INVOICES_TITLE}</p>
          <InvoicesStatusFilters
            invoicesFilters={invoicesFilters}
            invoicesFilteredAndSorted={invoicesFilteredAndSorted}
            setInvoicesFilters={setInvoicesFilters}
          />
        </div>
        <InvoicesFilters
          dateFilters={dateFilters}
          setDateFilters={setDateFilters}
          invoicesFilters={invoicesFilters}
          setInvoicesFilters={setInvoicesFilters}
        />
        <DownloadButton
          invoicesToDownload={invoicesToDownload}
          setInvoicesToDownload={setInvoicesToDownload}
        />
        {shouldLoaderBeDisplayed([getInvoicesStatus]) ? (
          <Loader className="NewContractAppContentCancellationRulesLoader" />
        ) : (
          displayNoInvoiceOrInvoicesBasedOnDataReceived()
        )}
      </div>
      {isInvoiceDetailOpen && (
        <InvoiceDetail setIsInvoiceDetailOpen={setIsInvoiceDetailOpen} />
      )}
    </BaseApp>
  );
}
