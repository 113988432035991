import React from 'react';

import {
  ERROR_MODAL_CLOSE_BUTTON,
} from 'src/constants/componentTexts/ContractText';

import 'src/uiComponents/modal/Modal.css';

interface Props {
  errorMessage: any;
  setIsErrorModalOpen: Function;
}

export default function ErrorModal(props: Props) {
  const { errorMessage, setIsErrorModalOpen } = props;

  return (
    <div id="ErrorModalId" className="ErrorModalWrapper">
      <div className="ErrorModalWrapperContent">
        <p
          id="ErrorModalErrorApiText"
          className="ErrorModalWrapperContentErrorMessage"
        >
          {errorMessage}
        </p>
        <div className="ErrorModalWrapperContentButton">
          <button
            className="ErrorModalWrapperContentCloseButton"
            type="button"
            onClick={() => setIsErrorModalOpen(false)}
          >
            {ERROR_MODAL_CLOSE_BUTTON}
          </button>
        </div>
      </div>
    </div>
  );
}
