import { convertDateToUTCYYYYMMDD, convertDateToLocalDateYYYYMMDDFormat } from 'src/utils/logic/converter/dateConvertor';

export default function usePeriodQuickFilter() {
  const daysInWeek = 7;
  const lengthOfDaysInWeekArray = 6;

  const inputRadioToday = () => ({
    fromDate: convertDateToUTCYYYYMMDD(new Date()),
    toDate: convertDateToUTCYYYYMMDD(new Date()),
  });

  const inputRadioYesterday = () => {
    const todaysDate = new Date();
    const yesterdayDate = new Date(todaysDate.setDate(todaysDate.getDate() - 1));

    return {
      fromDate: convertDateToUTCYYYYMMDD(yesterdayDate),
      toDate: convertDateToUTCYYYYMMDD(yesterdayDate),
    };
  };

  const inputRadioThisWeekSoFar = () => {
    const latestMonday = new Date();
    latestMonday.setDate(latestMonday.getDate() - (latestMonday.getDay() - 1));

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(latestMonday),
      toDate: convertDateToUTCYYYYMMDD(new Date()),
    };
  };

  const inputRadioPreviousWeek = () => {
    const mondayOfLastWeek = new Date();
    mondayOfLastWeek.setDate(mondayOfLastWeek.getDate() - (mondayOfLastWeek.getDay() - 1) - daysInWeek);
    const sundayOfLastWeek = new Date(mondayOfLastWeek.getFullYear(), mondayOfLastWeek.getMonth(), mondayOfLastWeek.getDate() + lengthOfDaysInWeekArray);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(mondayOfLastWeek),
      toDate: convertDateToLocalDateYYYYMMDDFormat(sundayOfLastWeek),
    };
  };

  const inputRadioThisMonthSoFar = () => {
    const todaysDate = new Date();

    const firstDayOfMonth = new Date(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth(), 1);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(firstDayOfMonth),
      toDate: convertDateToUTCYYYYMMDD(todaysDate),
    };
  };

  const inputRadioPreviousMonth = () => {
    const todaysDate = new Date();
    const firstDayOfLastMonth = new Date(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth() - 1, 1);
    const lastDayOfLastMonth = new Date(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth(), 0);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(firstDayOfLastMonth),
      toDate: convertDateToLocalDateYYYYMMDDFormat(lastDayOfLastMonth),
    };
  };

  const inputRadioLastThreeMonths = () => {
    const todaysDate = new Date();
    const firstDayOfThirdMonthBehind = new Date(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth() - 2, 1);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(firstDayOfThirdMonthBehind),
      toDate: convertDateToUTCYYYYMMDD(todaysDate),
    };
  };

  const inputRadioLastSixMonths = () => {
    const todaysDate = new Date();
    const firstDayOfSixMonthsBehind = new Date(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth() - 5, 1);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(firstDayOfSixMonthsBehind),
      toDate: convertDateToUTCYYYYMMDD(todaysDate),
    };
  };

  const inputRadioThisYearSoFar = () => {
    const todaysDate = new Date();
    const firstDayOfTheYear = new Date(todaysDate.getUTCFullYear(), 0, 1);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(firstDayOfTheYear),
      toDate: convertDateToUTCYYYYMMDD(todaysDate),
    };
  };

  const inputRadioPreviousYear = () => {
    const todaysDate = new Date();
    const lastYearStart = new Date(todaysDate.getUTCFullYear() - 1, 0, 1);
    const lastYearEnd = new Date(todaysDate.getUTCFullYear() - 1, 11, 31);

    return {
      fromDate: convertDateToLocalDateYYYYMMDDFormat(lastYearStart),
      toDate: convertDateToLocalDateYYYYMMDDFormat(lastYearEnd),
    };
  };

  return {
    inputRadioToday,
    inputRadioYesterday,
    inputRadioThisWeekSoFar,
    inputRadioPreviousMonth,
    inputRadioPreviousWeek,
    inputRadioThisMonthSoFar,
    inputRadioLastThreeMonths,
    inputRadioLastSixMonths,
    inputRadioThisYearSoFar,
    inputRadioPreviousYear,
  };
}
