import React from 'react';

import { AMOUNT_TYPE, disableServices } from 'src/constants/componentTexts/InvoiceDetailText';

import { formatDisplayNumberToIntegerIfNothingInTheDecimal } from 'src/invoicing/services/ServicesLogic';
import useInvoicedServiceInput from 'src/invoicing/invoiceDetail/useInvoicedServiceInput';

interface Props {
  className: string;
  id: string;
  isCustomService: boolean;
  keyService: string;
  keySetter: Function;
  service: any;
  serviceIndex: string;
  services: any;
  serviceValidated: boolean;
  setServices: Function;
  type: string;
  value: string;
}

export default function InvoicedServiceInput(props: Props) {
  const {
    className,
    id,
    isCustomService,
    service,
    type,
    value,
  } = props;

  const { onCustomServiceInputChange } = useInvoicedServiceInput(props);

  return (
    <input
      id={id}
      className={className}
      type={type}
      value={type === AMOUNT_TYPE ? formatDisplayNumberToIntegerIfNothingInTheDecimal(value) : value}
      disabled={!isCustomService && disableServices.includes(service?.type)}
      autoComplete="off"
      onChange={isCustomService || !disableServices.includes(service?.type) ? onCustomServiceInputChange : () => {}}
      required
    />
  );
}
