import React from 'react';

import { NO_CONTRACT_MESSAGE } from 'src/constants/componentTexts/ContractText';

export default function NoContract() {
  return (
    <div className="ContractAppContentNoContract">
      <p id="ContractAppNoContractText" className="ContractAppContentNoContractText">{NO_CONTRACT_MESSAGE}</p>
    </div>
  );
}
