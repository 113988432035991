import React from 'react';

import {
  NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_FIRST_COLUMN_TITLE,
  NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_SECOND_COLUMN_TITLE,
  NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_THIRD_COLUMN_TITLE,
} from 'src/constants/componentTexts/OperationTypesText';

import { OperationTypeReceivedInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';

import OperationType from 'src/invoicing/services/operationTypes/OperationType';

interface Props {
  contractAction: string;
  contractStatus: string;
  operationTypes: OperationTypeReceivedInterface[];
  setIsOperationTypeSubViewOpen: Function;
  setOperationTypeAction: Function;
}

export default function OperationTypes(props: Props) {
  const {
    contractAction, contractStatus, operationTypes, setIsOperationTypeSubViewOpen, setOperationTypeAction,
  } = props;

  return (
    <div className="NewContractAppContentOperationTypesFormTableWrapper">
      <table
        id="NewContractAppContentOperationTypesFormTableId"
        className="NewContractAppContentOperationTypesFormTable"
      >
        <thead>
          <tr className="NewContractAppContentOperationTypesFormTableTheadRow">
            <th
              id="NewContractAppContentOperationTypesFormTableTheadRowOperationValueId"
              className="FormTextFontSize NewContractAppContentOperationTypesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_FIRST_COLUMN_TITLE}
            </th>
            <th
              id="NewContractAppContentOperationTypesFormTableTheadRowTypeOfPriceValueId"
              className="FormTextFontSize NewContractAppContentOperationTypesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_SECOND_COLUMN_TITLE}
            </th>
            <th
              id="NewContractAppContentOperationTypesFormTableTheadRowValuesValueId"
              className="FormTextFontSize NewContractAppContentOperationTypesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_OPERATION_TYPES_TABLE_THIRD_COLUMN_TITLE}
            </th>
          </tr>
        </thead>
        <tbody>
          {operationTypes.map((operationType: any) => (
            <OperationType
              contractAction={contractAction}
              contractStatus={contractStatus}
              operationType={operationType}
              key={operationType.id.toString()}
              setIsOperationTypeSubViewOpen={setIsOperationTypeSubViewOpen}
              setOperationTypeAction={setOperationTypeAction}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
