import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus, Status } from 'src/constants/status';
import { stepperText } from 'src/constants/componentTexts/StepperText';

import { apiGetContractDelayRules } from 'src/invoicing/services/delayRules/DelayRulesApiRequest';
import { DelayRuleReceivedInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';
import {
  areServicesLoaded,
  canAServiceBeAdded,
  getServicescontractByStepsFilled,
} from 'src/invoicing/services/ServicesLogic';
import { selectContractAction, selectContractStatus } from 'src/invoicing/contract/ContractSlice';
import {
  selectDelayRules,
  selectDeleteDelayRuleStatus,
  selectGetDelayRulesStatus,
  selectPatchDelayRuleStatus,
  selectPostDelayRuleStatus,
} from 'src/invoicing/services/delayRules/DelayRulesSlice';

interface Props {
  contractByStepsFilled: boolean[];
  contractId: number | null;
  isDelaySubViewOpen: boolean;
  setIsDelaySubViewOpen: Function;
  setcontractByStepsFilled: Function;
  setDelayRuleAction: Function;
}

export default function useDelayRulesEditor(props: Props) {
  const {
    contractByStepsFilled,
    contractId,
    isDelaySubViewOpen,
    setIsDelaySubViewOpen,
    setcontractByStepsFilled,
    setDelayRuleAction,
  } = props;

  const dispatch = useDispatch();

  const contractAction: string = useSelector(selectContractAction);
  const contractStatus: string = useSelector(selectContractStatus);
  const getDelayRules: DelayRuleReceivedInterface[] | [] = useSelector(selectDelayRules);
  const getDelayRulesStatus: string = useSelector(selectGetDelayRulesStatus);
  const getDeleteDelayRuleStatus : string = useSelector(selectDeleteDelayRuleStatus);
  const getPatchDelayRuleStatus: string = useSelector(selectPatchDelayRuleStatus);
  const getPostDelayRuleStatus: string = useSelector(selectPostDelayRuleStatus);

  const [delayRules, setDelayRules] = useState<DelayRuleReceivedInterface[] | []>([]);
  const [delayRulesStatus, setDelayRulesStatus] = useState(Status.DEFAULT);

  const getDelayRulesIfContractIdExistsAndOtherApiCallsNotProcessing = () => {
    if (contractId && areServicesLoaded(
      getPostDelayRuleStatus,
      getDeleteDelayRuleStatus,
      getPatchDelayRuleStatus,
    )) {
      setDelayRulesStatus(Status.DEFAULT);
      dispatch(apiGetContractDelayRules(contractId));
    }
  };

  const filledDelayRulesStepperIfGettingDelayRulesIsSuccessfull = () => {
    if (getDelayRulesStatus === Status.SUCCESS) {
      setcontractByStepsFilled(getServicescontractByStepsFilled(
        contractByStepsFilled,
        getDelayRules,
        stepperText.delayRulesStepNumber,
      ));
    }
  };

  const setDelayRulesAndDelayRulesStatusBasedOnGetDelayRulesStatus = () => {
    if (getDelayRulesStatus === Status.LOADING) {
      setDelayRules([]);
      setDelayRulesStatus(Status.LOADING);
    }
    if (getDelayRulesStatus === Status.SUCCESS) {
      setDelayRules(getDelayRules);
      setDelayRulesStatus(Status.SUCCESS);
    }
  };

  useEffect(() => {
    setDelayRulesAndDelayRulesStatusBasedOnGetDelayRulesStatus();
  }, [getDelayRulesStatus]);

  useEffect(() => {
    getDelayRulesIfContractIdExistsAndOtherApiCallsNotProcessing();
  }, [contractId, isDelaySubViewOpen, getPostDelayRuleStatus,
    getDeleteDelayRuleStatus, getPatchDelayRuleStatus]);

  useEffect(() => {
    filledDelayRulesStepperIfGettingDelayRulesIsSuccessfull();
  }, [getDelayRules.length !== 0]);

  const onNewDelayRuleClick = () => {
    if (canAServiceBeAdded(contractStatus, contractAction)) {
      setIsDelaySubViewOpen(true);
      setDelayRuleAction(ActionStatus.NEW);
    }
  };

  const canAddADelayRule = () => (
    delayRulesStatus === Status.SUCCESS
      && canAServiceBeAdded(contractStatus, contractAction)
      && delayRules.length < 1
  );

  return {
    canAddADelayRule,
    contractAction,
    contractStatus,
    delayRules,
    delayRulesStatus,
    getPatchDelayRuleStatus,
    getPostDelayRuleStatus,
    onNewDelayRuleClick,
  };
}
