import { useContext } from 'react';
import { InvoicesContext } from 'src/invoicing/invoices/InvoicesContext';

export default function useInvoicesContext() {
  const invoicesContext = useContext(InvoicesContext);

  if (!invoicesContext) {
    throw new Error('useInvoiceContext must be used inside a InvoiceContextProvider');
  }

  return invoicesContext;
}
