import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

export default function useCancellationRuleSubmittionRequestStatus(submittionRequestStatusSelector: any, setIsCancellationRuleSubViewOpen: Function) {
  const patchCancellationRuleStatus: string = useSelector(submittionRequestStatusSelector);

  useEffect(() => {
    if (patchCancellationRuleStatus === Status.SUCCESS) {
      setIsCancellationRuleSubViewOpen(false);
    }
  }, [patchCancellationRuleStatus]);
}
