import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import { convertMinutesToHHMMFormat } from 'src/utils/logic/converter/dateConvertor';

export const getDateTimeInputDefaultValue = (dateTimeDefaultValue: string) => (
  dateTimeDefaultValue !== EMPTY_VALUE ? dateTimeDefaultValue.substring(0, dateTimeDefaultValue.length - 4) : dateTimeDefaultValue
);

export const getDefaultTimeInputDefaultValue = (timeDefaultValue: number) => (
  timeDefaultValue === -1 ? EMPTY_VALUE : convertMinutesToHHMMFormat(timeDefaultValue)
);
