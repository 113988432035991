import { InvoiceStatus } from 'src/constants/status';

import { convertDecimalNumberIntoInteger } from 'src/utils/logic/converter/NumberConvertor';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

export default function useInvoicesStatusFilters(invoicesFilteredAndSorted: InvoiceModel[]) {
  const keepInvoicesWithStatus = (invoice: InvoiceModel, invoiceStatus: InvoiceStatus) => invoice.status?.code === invoiceStatus;

  const validateInvoices = invoicesFilteredAndSorted.filter((invoice) => keepInvoicesWithStatus(invoice, InvoiceStatus.VALIDATED));
  const missingInfoInvoices = invoicesFilteredAndSorted.filter((invoice) => keepInvoicesWithStatus(invoice, InvoiceStatus.MISSING_INFO));
  const toCheckInvoices = invoicesFilteredAndSorted.filter((invoice) => keepInvoicesWithStatus(invoice, InvoiceStatus.TO_CHECK));

  const calculateSumOfIntegerAmount = (previousSum: number, currentSum: InvoiceModel) => (
    (((previousSum) + convertDecimalNumberIntoInteger(currentSum.amount || '0')))
  );

  const formatIntegerInvoiceAmountIntoDecimalAmount = (statusInvoices: any) => (
    statusInvoices.reduce(calculateSumOfIntegerAmount, 0) / 100
  );

  return {
    validateInvoices: {
      amount: formatIntegerInvoiceAmountIntoDecimalAmount(validateInvoices),
      totalOfInvoices: validateInvoices.length,
    },
    missingInfoInvoices: {
      amount: formatIntegerInvoiceAmountIntoDecimalAmount(missingInfoInvoices),
      totalOfInvoices: missingInfoInvoices.length,
    },
    toCheckInvoices: {
      amount: formatIntegerInvoiceAmountIntoDecimalAmount(toCheckInvoices),
      totalOfInvoices: toCheckInvoices.length,
    },
  };
}
