import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetOperationTypeParameters } from 'src/invoicing/services/operationTypes/OperationTypesApiRequest';
import {
  selectOperationTypeParameters,
  selectOperationTypeParametersStatus,
} from 'src/invoicing/services/operationTypes/OperationTypesSlice';

export default function useOperationTypeParameters(contractId: number | null) {
  const dispatch = useDispatch();

  const operationTypeParameters = useSelector(selectOperationTypeParameters);
  const operationTypeParametersStatus: string = useSelector(selectOperationTypeParametersStatus);

  useEffect(() => {
    if (contractId) {
      dispatch(apiGetOperationTypeParameters(contractId));
    }
  }, []);

  return {
    operationTypeParameters,
    operationTypeParametersStatus,
  };
}
