import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus, Status } from 'src/constants/status';
import { stepperText } from 'src/constants/componentTexts/StepperText';

import { AdditionalServiceReceivedInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import { apiGetAdditionalServices } from 'src/invoicing/services/additionalServices/AdditionalServicesApiRequest';
import {
  areServicesLoaded,
  canAServiceBeAdded,
  getServicescontractByStepsFilled,
} from 'src/invoicing/services/ServicesLogic';
import {
  selectAdditionalServices,
  selectDeleteAdditionalServiceStatus,
  selectGetAdditionalServicesStatus,
  selectPatchAdditionalServiceStatus,
  selectPostAdditionalServiceStatus,
} from 'src/invoicing/services/additionalServices/AdditionalServicesSlice';
import { selectContractAction, selectContractStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  contractByStepsFilled: boolean[];
  contractId: number | null;
  isAdditionalSubViewOpen: boolean;
  setAdditionalServiceAction: Function;
  setIsAdditionalSubViewOpen: Function;
  setcontractByStepsFilled: Function;
}

export default function useAdditionalServicesEditor(props: Props) {
  const {
    contractByStepsFilled,
    contractId,
    isAdditionalSubViewOpen,
    setAdditionalServiceAction,
    setIsAdditionalSubViewOpen,
    setcontractByStepsFilled,
  } = props;

  const dispatch = useDispatch();

  const apiAdditionalServices: AdditionalServiceReceivedInterface[] | [] = useSelector(selectAdditionalServices);
  const contractAction: string = useSelector(selectContractAction);
  const contractStatus: string = useSelector(selectContractStatus);
  const deleteAdditionalServiceStatus: string = useSelector(selectDeleteAdditionalServiceStatus);
  const getAdditionalServicesStatus: string = useSelector(selectGetAdditionalServicesStatus);
  const postAdditionalServiceStatus: string = useSelector(selectPostAdditionalServiceStatus);
  const patchAdditionalServiceStatus: string = useSelector(selectPatchAdditionalServiceStatus);

  const [additionalServices, setAdditionalServices] = useState< AdditionalServiceReceivedInterface[] | []>([]);
  const [additionalServicesStatus, setAdditionalServicesStatus] = useState(Status.DEFAULT);

  const getAddServicesIfContractIdExistsAndOtherApiCallsNotProcessing = () => {
    if (contractId && areServicesLoaded(postAdditionalServiceStatus, deleteAdditionalServiceStatus, patchAdditionalServiceStatus)) {
      setAdditionalServicesStatus(Status.DEFAULT);
      dispatch(apiGetAdditionalServices(contractId));
    }
  };

  const filledAddServicesStepperIfGettingAddServicesIsSuccessfull = () => {
    if (getAdditionalServicesStatus === Status.SUCCESS) {
      setcontractByStepsFilled(getServicescontractByStepsFilled(
        contractByStepsFilled,
        apiAdditionalServices,
        stepperText.additionalServicesStepNumber,
      ));
    }
  };

  const setAddServicesAndAddServicesStatusBasedOnGetAddServicesStatus = () => {
    if (getAdditionalServicesStatus === Status.LOADING) {
      setAdditionalServices([]);
      setAdditionalServicesStatus(Status.LOADING);
    }
    if (getAdditionalServicesStatus === Status.SUCCESS) {
      setAdditionalServices(apiAdditionalServices);
      setAdditionalServicesStatus(Status.SUCCESS);
    }
  };

  useEffect(() => {
    setAddServicesAndAddServicesStatusBasedOnGetAddServicesStatus();
  }, [getAdditionalServicesStatus]);

  useEffect(() => {
    getAddServicesIfContractIdExistsAndOtherApiCallsNotProcessing();
  }, [contractId, isAdditionalSubViewOpen, postAdditionalServiceStatus,
    patchAdditionalServiceStatus, deleteAdditionalServiceStatus]);

  useEffect(() => {
    filledAddServicesStepperIfGettingAddServicesIsSuccessfull();
  }, [apiAdditionalServices.length !== 0]);

  const onNewAddtionalServiceClick = () => {
    if (canAServiceBeAdded(contractStatus, contractAction)) {
      setIsAdditionalSubViewOpen(true);
      setAdditionalServiceAction(ActionStatus.NEW);
    }
  };

  return {
    additionalServices,
    contractAction,
    contractStatus,
    additionalServicesStatus,
    onNewAddtionalServiceClick,
    patchAdditionalServiceStatus,
    postAdditionalServiceStatus,
  };
}
