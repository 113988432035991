import { useDispatch } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { apiDeleteCancellationRule, apiGetCancellationRule } from 'src/invoicing/services/cancellationRules/CancellationRulesApiRequest';
import { CancellationRuleReceivedInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';

interface Props {
  cancellationRule: CancellationRuleReceivedInterface;
  setCancellationRuleAction: Function;
  setIsCancellationRuleSubViewOpen: Function;
}

export default function useCancellationRule(props: Props) {
  const { cancellationRule, setCancellationRuleAction, setIsCancellationRuleSubViewOpen } = props;

  const dispatch = useDispatch();

  const deleteCancellationRule = () => {
    dispatch(apiDeleteCancellationRule(cancellationRule.id));
  };

  const editCancellationRule = () => {
    dispatch(apiGetCancellationRule(cancellationRule.id));
    setIsCancellationRuleSubViewOpen(true);
    setCancellationRuleAction(ActionStatus.EDIT);
  };

  const viewCancellationRule = () => {
    dispatch(apiGetCancellationRule(cancellationRule.id));
    setIsCancellationRuleSubViewOpen(true);
    setCancellationRuleAction(ActionStatus.VIEW);
  };

  return {
    deleteCancellationRule,
    editCancellationRule,
    viewCancellationRule,
  };
}
