import React from 'react';

import { ActionStatus } from 'src/constants/status';
import { OPERATION_TYPES_STEP_NUMBER } from 'src/constants/componentTexts/OperationTypesText';

import ContractByStepNavigationButtons from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepNavigationButtons';
import EditOperationType from 'src/invoicing/services/operationTypes/EditOperationType';
import NewOperationType from 'src/invoicing/services/operationTypes/NewOperationType';
import OperationTypesEditor from 'src/invoicing/services/operationTypes/OperationTypesEditor';
import Stepper from 'src/uiComponents/stepper/Stepper';
import ViewOperationType from 'src/invoicing/services/operationTypes/ViewOperationType';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';
import useOperationTypesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/operationTypesByStep/useOperationTypesByStep';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  nextComponent: string | null;
  previousComponent: string | null;
  setcontractByStepsFilled: Function;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setStoredContract: Function;
  storedContract: StoredContract;
}

export default function OperationTypesByStep(props: Props) {
  const {
    contractByStepsFilled,
    currentContractByStepType,
    nextComponent,
    previousComponent,
    setcontractByStepsFilled,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    storedContract,
  } = props;

  const {
    contractAction,
    isOperationTypeSubViewOpen,
    operationTypeAction,
    setIsOperationTypeSubViewOpen,
    setOperationTypeAction,
    submitOperationTypes,
  } = useOperationTypesByStep(props);

  const displayOperationTypeSubViewActions = () => {
    switch (operationTypeAction) {
      case ActionStatus.EDIT:
        return (
          <EditOperationType
            contractId={storedContract.id}
            setIsOperationTypeViewOpen={setIsOperationTypeSubViewOpen}
          />
        );
      case ActionStatus.VIEW:
        return (
          <ViewOperationType
            contractId={storedContract.id}
            setIsOperationTypeViewOpen={setIsOperationTypeSubViewOpen}
          />
        );
      default:
        return (
          <NewOperationType
            contractId={storedContract.id}
            setIsOperationTypeViewOpen={setIsOperationTypeSubViewOpen}
          />
        );
    }
  };

  return (
    <>
      <Stepper
        actualContractByStep={OPERATION_TYPES_STEP_NUMBER}
        setCurrentContractByStepType={setCurrentContractByStepType}
        contractByStepsFilled={contractByStepsFilled}
      />
      {isOperationTypeSubViewOpen ? (
        displayOperationTypeSubViewActions()
      ) : (
        <form onSubmit={(event) => submitOperationTypes(event)}>
          <OperationTypesEditor
            contractId={storedContract.id}
            isOperationTypeSubViewOpen={isOperationTypeSubViewOpen}
            setIsOperationTypeSubViewOpen={setIsOperationTypeSubViewOpen}
            contractByStepsFilled={contractByStepsFilled}
            setcontractByStepsFilled={setcontractByStepsFilled}
            setOperationTypeAction={setOperationTypeAction}
          />
          <ContractByStepNavigationButtons
            contractAction={contractAction}
            currentContractByStepType={currentContractByStepType}
            nextComponent={nextComponent}
            previousComponent={previousComponent}
            setCurrentContractByStepType={setCurrentContractByStepType}
            setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
          />
        </form>
      )}
    </>
  );
}
