import React from 'react';

import {
  NEW_CONTRACT_FORM_DELAYS_RULES_TABLE_FIRST_COLUMN_TITLE,
  NEW_CONTRACT_FORM_DELAY_RULES_TABLE_SECOND_COLUMN_TITLE,
} from 'src/constants/componentTexts/DelayRulesText';

import DelayRule from 'src/invoicing/services/delayRules/DelayRule';

import { DelayRuleReceivedInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';

interface Props {
  contractAction: string;
  contractStatus: string;
  delayRules: DelayRuleReceivedInterface[];
  setDelayRuleAction: Function;
  setIsDelaySubViewOpen: Function;
}

export default function DelayRules(props: Props) {
  const {
    contractAction,
    contractStatus,
    delayRules,
    setDelayRuleAction,
    setIsDelaySubViewOpen,
  } = props;

  return (
    <div className="NewContractAppContentDelayRulesFormTableWrapper">
      <table
        id="NewContractAppContentDelayRulesFormTableId"
        className="NewContractAppContentDelayRulesFormTable"
      >
        <thead>
          <tr className="NewContractAppContentDelayRulesFormTableTheadRow">
            <th
              id="NewContractAppContentDelayRulesFormTableTheadRowCauseOfDelayValueId"
              className="FormTextFontSize NewContractAppContentDelayRulesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_DELAYS_RULES_TABLE_FIRST_COLUMN_TITLE}
            </th>
            <th
              id="NewContractAppContentDelayRulesFormTableTheadRowTypeOfPriceValueId"
              className="FormTextFontSize NewContractAppContentDelayRulesFormTableTheadRowValue"
            >
              {NEW_CONTRACT_FORM_DELAY_RULES_TABLE_SECOND_COLUMN_TITLE}
            </th>
          </tr>
        </thead>
        <tbody>
          {delayRules.map((delayRule: any) => (
            <DelayRule
              contractAction={contractAction}
              contractStatus={contractStatus}
              delayRule={delayRule}
              key={delayRule.id.toString()}
              setDelayRuleAction={setDelayRuleAction}
              setIsDelaySubViewOpen={setIsDelaySubViewOpen}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
