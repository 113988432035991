import { useState } from 'react';
import { InvoicesSortStatus } from 'src/constants/status';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

export default function useOrderInvoices() {
  const FALLBACK_VALUE = '';
  const [invoicesSortStatus, setInvoicesSortStatus] = useState<InvoicesSortStatus>(InvoicesSortStatus.ARRIVAL_DATE_DESC);

  const sortNumberAsc = (numberOne: number, numberTwo: number) => numberOne - numberTwo;
  const sortNumberDesc = (numberOne: number, numberTwo: number) => numberTwo - numberOne;

  const sortAlphabeticalAsc = (stringOne: string, stringTwo: string) => stringOne.localeCompare(stringTwo);
  const sortAlphabeticalDesc = (stringOne: string, stringTwo: string) => stringTwo.localeCompare(stringOne);

  const sortDateAsc = (dateOne: Date, dateTwo: Date) => dateOne.getTime() - dateTwo.getTime();
  const sortDateDesc = (dateOne: Date, dateTwo: Date) => dateTwo.getTime() - dateOne.getTime();

  const sortDictionary = {
    [InvoicesSortStatus.AMOUNT_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortNumberAsc(Number(a.amount), Number(b.amount)),
    [InvoicesSortStatus.AMOUNT_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortNumberDesc(Number(a.amount), Number(b.amount)),

    [InvoicesSortStatus.AIRCRAFT_TYPE_ASC]: ((a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc(a.aircraft_type || FALLBACK_VALUE, b.aircraft_type || FALLBACK_VALUE)),
    [InvoicesSortStatus.AIRCRAFT_TYPE_DESC]: ((a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc(a.aircraft_type || FALLBACK_VALUE, b.aircraft_type || FALLBACK_VALUE)),

    [InvoicesSortStatus.DEPARTURE_FLIGHT_NUMBER_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc(a.departure_flight_number || FALLBACK_VALUE, b.departure_flight_number || FALLBACK_VALUE),
    [InvoicesSortStatus.DEPARTURE_FLIGHT_NUMBER_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc(a.departure_flight_number || FALLBACK_VALUE, b.departure_flight_number || FALLBACK_VALUE),

    [InvoicesSortStatus.ARRIVAL_FLIGHT_NUMBER_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc(a.arrival_flight_number || FALLBACK_VALUE, b.arrival_flight_number || FALLBACK_VALUE),
    [InvoicesSortStatus.ARRIVAL_FLIGHT_NUMBER_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc(a.arrival_flight_number || FALLBACK_VALUE, b.arrival_flight_number || FALLBACK_VALUE),

    [InvoicesSortStatus.AIRPORT_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc(a.airport || FALLBACK_VALUE, b.airport || FALLBACK_VALUE),
    [InvoicesSortStatus.AIRPORT_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc(a.airport || FALLBACK_VALUE, b.airport || FALLBACK_VALUE),

    [InvoicesSortStatus.AIRLINE_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc((a.departure_carrier_iata_code || a.arrival_carrier_iata_code) || FALLBACK_VALUE, (b.departure_carrier_iata_code || b.arrival_carrier_iata_code) || FALLBACK_VALUE),
    [InvoicesSortStatus.AIRLINE_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc((a.departure_carrier_iata_code || a.arrival_carrier_iata_code) || FALLBACK_VALUE, (b.departure_carrier_iata_code || b.arrival_carrier_iata_code) || FALLBACK_VALUE),

    [InvoicesSortStatus.AIRCRAFT_REGISTRATION_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc((a.aircraft_registration || a.aircraft_registration) || FALLBACK_VALUE, (b.aircraft_registration || b.aircraft_registration) || FALLBACK_VALUE),
    [InvoicesSortStatus.AIRCRAFT_REGISTRATION_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc((a.aircraft_registration || a.aircraft_registration) || FALLBACK_VALUE, (b.aircraft_registration || b.aircraft_registration) || FALLBACK_VALUE),

    [InvoicesSortStatus.DEPARTURE_DATE_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortDateAsc(new Date(a.departure_date || FALLBACK_VALUE), new Date(b.departure_date || FALLBACK_VALUE)),
    [InvoicesSortStatus.DEPARTURE_DATE_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortDateDesc(new Date(a.departure_date || FALLBACK_VALUE), new Date(b.departure_date || FALLBACK_VALUE)),

    [InvoicesSortStatus.ARRIVAL_DATE_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortDateAsc(new Date(a.arrival_date || FALLBACK_VALUE), new Date(b.arrival_date || FALLBACK_VALUE)),
    [InvoicesSortStatus.ARRIVAL_DATE_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortDateDesc(new Date(a.arrival_date || FALLBACK_VALUE), new Date(b.arrival_date || FALLBACK_VALUE)),

    [InvoicesSortStatus.STATUS_ASC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalAsc(a.status?.code || FALLBACK_VALUE, b.status?.code || FALLBACK_VALUE),
    [InvoicesSortStatus.STATUS_DESC]: (a: InvoiceModel, b: InvoiceModel) => sortAlphabeticalDesc(a.status?.code || FALLBACK_VALUE, b.status?.code || FALLBACK_VALUE),
  };

  const sortInvoices = (invoicesToOrder: InvoiceModel[]) => {
    if (!invoicesSortStatus) {
      return invoicesToOrder;
    }

    const orderedInvoices = [...invoicesToOrder];

    return orderedInvoices.sort(sortDictionary[invoicesSortStatus]);
  };

  return { sortInvoices, invoicesSortStatus, setInvoicesSortStatus };
}
