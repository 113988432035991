import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus, Status } from 'src/constants/status';
import { stepperText } from 'src/constants/componentTexts/StepperText';

import { apiGetContractCancellationRules } from 'src/invoicing/services/cancellationRules/CancellationRulesApiRequest';
import {
  areServicesLoaded,
  canAServiceBeAdded,
  getServicescontractByStepsFilled,
} from 'src/invoicing/services/ServicesLogic';
import { CancellationRuleReceivedInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';
import {
  selectCancellationRules,
  selectDeleteCancellationRuleStatus,
  selectGetCancellationRulesStatus,
  selectPatchCancellationRuleStatus,
  selectPostCancellationRuleStatus,
} from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';
import { selectContractAction, selectContractStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  contractId: number | null;
  isCancellationRuleSubViewOpen: boolean;
  contractByStepsFilled: boolean[];
  setIsCancellationRuleSubViewOpen: Function;
  setcontractByStepsFilled: Function;
  setCancellationRuleAction: Function;
}

export default function useCancellationRuleEditor(props: Props) {
  const {
    contractByStepsFilled,
    contractId,
    isCancellationRuleSubViewOpen,
    setcontractByStepsFilled,
    setIsCancellationRuleSubViewOpen,
    setCancellationRuleAction,
  } = props;

  const dispatch = useDispatch();

  const contractAction: string = useSelector(selectContractAction);
  const contractStatus: string = useSelector(selectContractStatus);
  const getCancellationRulesStatus: string = useSelector(selectGetCancellationRulesStatus);
  const getDeleteCancellationRuleStatus: string = useSelector(selectDeleteCancellationRuleStatus);
  const getContractCancellationRules: CancellationRuleReceivedInterface[] | [] = useSelector(selectCancellationRules);
  const getPatchCancellationRuleStatus: string = useSelector(selectPatchCancellationRuleStatus);
  const getPostCancellationRuleStatus: string = useSelector(selectPostCancellationRuleStatus);

  const [cancellationRules, setCancellationRules] = useState<CancellationRuleReceivedInterface[] | []>([]);
  const [cancellationRulesStatus, setCancellationRulesStatus] = useState(Status.DEFAULT);

  const getCancellationRulesIfContractIdExistsAndOtherApiCallsNotProcessing = () => {
    if (contractId && areServicesLoaded(
      getPostCancellationRuleStatus,
      getDeleteCancellationRuleStatus,
      getPatchCancellationRuleStatus,
    )) {
      setCancellationRulesStatus(Status.DEFAULT);
      dispatch(apiGetContractCancellationRules(contractId));
    }
  };

  const filledCancellationRulesStepperIfGettingCancellationRulesIsSuccessfull = () => {
    if (getCancellationRulesStatus === Status.SUCCESS) {
      setcontractByStepsFilled(getServicescontractByStepsFilled(
        contractByStepsFilled,
        getContractCancellationRules,
        stepperText.cancellationRulesStepNumber,
      ));
    }
  };

  const setCancellationRulesAndCancellationRulesStatusBasedOnGetCancellationRulesStatus = () => {
    if (getCancellationRulesStatus === Status.LOADING) {
      setCancellationRules([]);
      setCancellationRulesStatus(Status.LOADING);
    }
    if (getCancellationRulesStatus === Status.SUCCESS) {
      setCancellationRules(getContractCancellationRules);
      setCancellationRulesStatus(Status.SUCCESS);
    }
  };

  useEffect(() => {
    setCancellationRulesAndCancellationRulesStatusBasedOnGetCancellationRulesStatus();
  }, [getCancellationRulesStatus]);

  useEffect(() => {
    getCancellationRulesIfContractIdExistsAndOtherApiCallsNotProcessing();
  }, [contractId, isCancellationRuleSubViewOpen, getPostCancellationRuleStatus,
    getDeleteCancellationRuleStatus, getPatchCancellationRuleStatus]);

  useEffect(() => {
    filledCancellationRulesStepperIfGettingCancellationRulesIsSuccessfull();
  }, [getContractCancellationRules.length !== 0]);

  const onNewCancellationRuleClick = () => {
    if (canAServiceBeAdded(contractStatus, contractAction)) {
      setIsCancellationRuleSubViewOpen(true);
      setCancellationRuleAction(ActionStatus.NEW);
    }
  };

  const canAddACancellationRule = () => (
    cancellationRulesStatus === Status.SUCCESS
    && canAServiceBeAdded(contractStatus, contractAction)
    && cancellationRules.length < 1
  );

  return {
    canAddACancellationRule,
    cancellationRules,
    contractAction,
    contractStatus,
    cancellationRulesStatus,
    getPatchCancellationRuleStatus,
    getPostCancellationRuleStatus,
    onNewCancellationRuleClick,
  };
}
