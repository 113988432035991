import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus, Status } from 'src/constants/status';

import { apiDeleteNewContract } from 'src/invoicing/contract/ContractApiRequest';
import { resetDeleteContractStatus, selectContractId, selectDeleteContractStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  contractAction: string;
  currentContractByStepType: string;
  nextComponent: string | null;
  setIsContractByStepManagerOpen: Function;
}

export default function useContractByStepNavigation(props: Props) {
  const {
    contractAction,
    currentContractByStepType,
    nextComponent,
    setIsContractByStepManagerOpen,
  } = props;

  const dispatch = useDispatch();

  const contractId: number = useSelector(selectContractId);
  const deleteContractStatus: string = useSelector(selectDeleteContractStatus);

  useEffect(() => {
    if (deleteContractStatus === Status.SUCCESS) {
      setIsContractByStepManagerOpen(false);
      dispatch(resetDeleteContractStatus());
    }
  }, [deleteContractStatus !== Status.LOADING]);

  const sendButtonGoBackContractApp = () => {
    if (!nextComponent) {
      setIsContractByStepManagerOpen(false);
    }
  };

  const onNewContractCancelClick = () => {
    dispatch(apiDeleteNewContract(contractId));
  };

  const onCancelClick = () => {
    const contractByStepsAllowedToDeleteContract: string[] = ['operationTypeByStep', 'cancellationRulesByStep', 'delayRulesByStep', 'additionalServicesByStep'];

    return (contractAction === ActionStatus.NEW && contractByStepsAllowedToDeleteContract.includes(currentContractByStepType))
      ? onNewContractCancelClick()
      : setIsContractByStepManagerOpen(false);
  };

  return {
    onCancelClick,
    sendButtonGoBackContractApp,
  };
}
