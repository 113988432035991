export interface CustomStepInterface {
  amount: string | null;
  max_value: string | null;
  min_value: string | null;
  order: number | null;
}

export interface CancellationRuleSentInterface {
  contract: number | null;
  cause_of_cancellation: number | null;
  price: {
    amounts_type: string | null;
    price_steps: CustomStepInterface[];
    values_type: number | null;
  }
}

export const defaultCancellationRule: CancellationRuleSentInterface = {
  contract: null,
  cause_of_cancellation: null,
  price: {
    amounts_type: null,
    price_steps: [],
    values_type: null,
  },
};

export interface CancellationRuleReceivedInterface {
  id: number;
  cause_of_cancellation: { id: number; label: string };
  price: {
    amounts_type: { code: string, label: string};
    price_steps: CustomStepInterface[];
    values_type: { id: number, label: string};
  }
}

interface AmountTypeInterface {
  code: string;
  label: string;
}

interface CauseofCancellationInterface {
  id: number;
  label: string;
}

interface CurrencyInterface {
  code: string;
  label: string;
  symbol: string;
}

interface ValueTypeInterface {
  id: number;
  label: string;
}
export interface CancellationRuleParametersInterface {
  amount_types: AmountTypeInterface[],
  causes_of_cancellation: CauseofCancellationInterface[],
  currency: CurrencyInterface,
  value_types: ValueTypeInterface[],
}

export interface CancelaltionRuleFilledInterface {
  id: number;
  cause_of_cancellation: { id: number; label: string; };
  price: {
    amounts_type: { code: string; label: string; };
    price_steps: CustomStepInterface[];
    values_type: { id: number; label: string; };
  };
}
