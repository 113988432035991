import React from 'react';
import { Outlet } from 'react-router-dom';

import SessionExpiredModal from 'src/uiComponents/modal/UserDisconnectionModal';

import useInvoicing from 'src/invoicing/useInvoicing';

export default function AuthenticationRequired() {
  const { isLoggedIn } = useInvoicing();

  return isLoggedIn() ? <Outlet /> : <SessionExpiredModal />;
}
