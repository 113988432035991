import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import { apiGetInvoices } from 'src/invoicing/invoices/InvoicesApiRequest';
import { defaultInvoicesFilter, InvoiceModel, InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';
import { selectGetInvoicesStatus, selectInvoices } from 'src/invoicing/invoices/InvoicesSlice';
import useFilterInvoices from 'src/invoicing/invoices/useFilterInvoices';
import useOrderInvoices from 'src/invoicing/invoices/useSortInvoices';
import usePeriodQuickFilter from 'src/invoicing/invoices/filters/periodFilter/usePeriodQuickFilter';

export default function useInvoiceApp() {
  const dispatch = useDispatch();

  const getInvoicesStatus: string = useSelector(selectGetInvoicesStatus);
  const invoicesToFilter: InvoiceModel[] = useSelector(selectInvoices);

  const periodQuickFilter = usePeriodQuickFilter();

  const [dateFilters, setDateFilters] = useState<any>({ fromDate: periodQuickFilter.inputRadioThisMonthSoFar().fromDate, toDate: periodQuickFilter.inputRadioThisMonthSoFar().toDate });
  const [invoicesFilteredAndSorted, setInvoicesFilteredAndSorted] = useState<InvoiceModel[]>([]);
  const [invoicesFilters, setInvoicesFilters] = useState<InvoicesFiltersModel>(defaultInvoicesFilter);
  const [invoicesToDownload, setInvoicesToDownload] = useState<InvoiceModel[]>([]);
  const [isInvoiceDetailOpen, setIsInvoiceDetailOpen] = useState<boolean>(false);

  const { filterInvoices } = useFilterInvoices(invoicesToFilter, invoicesFilters);
  const { sortInvoices, invoicesSortStatus, setInvoicesSortStatus } = useOrderInvoices();

  useEffect(() => {
    dispatch(apiGetInvoices({ fromDate: dateFilters.fromDate, toDate: dateFilters.toDate }));
  }, []);

  useEffect(() => {
    const filteredInvoices = filterInvoices();
    const sortedFilteredInvoices = sortInvoices(filteredInvoices);

    setInvoicesFilteredAndSorted(sortedFilteredInvoices);
  }, [invoicesFilters, invoicesToFilter, invoicesSortStatus]);

  useEffect(() => {}, [getInvoicesStatus !== Status.LOADING]);

  return {
    dateFilters,
    getInvoicesStatus,
    invoicesFilters,
    invoicesFilteredAndSorted,
    invoicesSortStatus,
    invoicesToDownload,
    isInvoiceDetailOpen,
    setDateFilters,
    setInvoicesFilters,
    setInvoicesSortStatus,
    setInvoicesToDownload,
    setIsInvoiceDetailOpen,
  };
}
