import React from 'react';

import { LABEL_KEY } from 'src/constants/componentTexts/InvoiceDetailText';

import InvoicedServiceInput from 'src/invoicing/invoiceDetail/InvoicedServiceInput';

interface Props {
  id: string;
  isCustomService: boolean;
  prefixId: string;
  service: any;
  serviceLabel: string;
  services: any;
  serviceValidated: boolean;
  setServiceLabel: Function;
  setServices: Function;
}

export default function Label(props: Props) {
  const {
    id,
    isCustomService,
    prefixId,
    service,
    serviceLabel,
    services,
    serviceValidated,
    setServiceLabel,
    setServices,
  } = props;

  return (
    <InvoicedServiceInput
      className="InvoicedServiceWrapperInformationServiceContent"
      id={`InvoicedServiceContent${prefixId}${id}`}
      isCustomService={isCustomService}
      keyService={LABEL_KEY}
      keySetter={setServiceLabel}
      service={service}
      serviceIndex={id}
      services={services}
      serviceValidated={serviceValidated}
      setServices={setServices}
      type="text"
      value={serviceLabel}
    />
  );
}
