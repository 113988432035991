import React from 'react';

import { FALLBACK_INVOICE_STATUS } from 'src/constants/componentTexts/InvoiceText';

import InvoiceAircraftsNumber from 'src/invoicing/invoices/InvoiceAircraftsNumber';
import InvoiceCheckbox from 'src/invoicing/invoices/InvoiceCheckbox';
import InvoiceDates from 'src/invoicing/invoices/InvoiceDates';
import InvoicePicture from 'src/invoicing/invoices/InvoicePicture';
import InvoiceRow from 'src/invoicing/invoices/InvoiceRow';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';
import { hasInvoiceValidatedStatus } from 'src/invoicing/invoices/InvoicesLogic';
import useInvoice from 'src/invoicing/invoices/useInvoice';

interface Props {
  areInvoicesToDownload: boolean;
  invoice: InvoiceModel;
  invoicesToDownload: InvoiceModel[];
  isInvoiceDetailOpen: boolean,
  setInvoicesToDownload: Function;
  setIsInvoiceDetailOpen: Function;
}

export default function Invoice(props: Props) {
  const { invoice } = props;

  const {
    checkboxValue,
    getInvoiceAmountWithCurrency,
    isInvoiceSelected,
    onInvoiceCheckboxChange,
    onInvoiceClick,
  } = useInvoice(props);

  return (
    <tr className={`InvoicesTableRow ${isInvoiceSelected && 'InvoicesTableRowSelected'}`} onClick={onInvoiceClick}>
      <InvoiceCheckbox
        checkboxValue={checkboxValue}
        hasInvoiceValidatedStatus={hasInvoiceValidatedStatus}
        invoice={invoice}
        onInvoiceCheckboxChange={onInvoiceCheckboxChange}
      />
      <InvoiceRow
        additionalClassname={`InvoicesStatusValue${invoice?.status?.code || FALLBACK_INVOICE_STATUS}`}
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueStatus"
        value={invoice?.status?.label}
      />
      <InvoiceDates invoice={invoice} />
      <InvoiceRow
        additionalClassname="Airport"
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueAirport"
        value={invoice?.airport}
      />
      <InvoicePicture invoice={invoice} />
      <InvoiceAircraftsNumber invoice={invoice} />
      <InvoiceRow
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueAircraftType"
        value={invoice?.aircraft_type}
      />
      <InvoiceRow
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueRegistration"
        value={invoice?.aircraft_registration}
      />
      <InvoiceRow
        invoiceId={invoice.id}
        rowId="InvoiceTableBodyRowValueAmount"
        value={getInvoiceAmountWithCurrency()}
      />
    </tr>
  );
}
