import React from 'react';

import { ADD_ITEM_LOGO } from 'src/constants/picturesPath';

import { NEW_CONTRACT_FORM_DELAYS_RULES_TITLE } from 'src/constants/componentTexts/DelayRulesText';

import DelayRules from 'src/invoicing/services/delayRules/DelayRules';
import Loader from 'src/uiComponents/loader/Loader';
import NoDelayRule from 'src/invoicing/services/delayRules/NoDelayRule';

import {
  areServicesLoading,
  isApiCallStatusSuccessFullAndHasNoServiceToDisplay,
  isApiCallStatusSuccessFullAndHasServicesToDisplay,
} from 'src/invoicing/services/ServicesLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import useDelayRulesEditor from 'src/invoicing/services/delayRules/useDelayRulesEditor';

interface Props {
  contractByStepsFilled: boolean[];
  contractId: number | null;
  isDelaySubViewOpen: boolean;
  setIsDelaySubViewOpen: Function;
  setcontractByStepsFilled: Function;
  setDelayRuleAction: Function;
}

export default function DelayRulesEditor(props: Props) {
  const { setIsDelaySubViewOpen, setDelayRuleAction } = props;

  const {
    canAddADelayRule,
    contractAction,
    contractStatus,
    delayRules,
    delayRulesStatus,
    getPatchDelayRuleStatus,
    getPostDelayRuleStatus,
    onNewDelayRuleClick,
  } = useDelayRulesEditor(props);

  const handleDelayRulesDisplayBasedOnGetDelayRulesStatus = () => {
    if (shouldLoaderBeDisplayed([delayRulesStatus])) {
      return <Loader className="NewContractAppContentDelayRulesLoader" />;
    }
    if (isApiCallStatusSuccessFullAndHasServicesToDisplay(delayRulesStatus, delayRules)) {
      return (
        <DelayRules
          contractAction={contractAction}
          contractStatus={contractStatus}
          delayRules={delayRules}
          setIsDelaySubViewOpen={setIsDelaySubViewOpen}
          setDelayRuleAction={setDelayRuleAction}
        />
      );
    }
    if (isApiCallStatusSuccessFullAndHasNoServiceToDisplay(delayRulesStatus, delayRules)) {
      return <NoDelayRule />;
    }
    return null;
  };

  return (
    areServicesLoading(getPostDelayRuleStatus, getPatchDelayRuleStatus) ? (
      <Loader className="NewContractAppContentDelayRulesLoader" />
    ) : (
      <div className="NewContractAppContentDelayRulesForm">
        <div className="NewContractAppContentDelayRulesFormTitle">
          <p
            id="DelayRulesTitle"
            className="FormTitleFontSize NewContractAppContentDelayRulesFormTitleText"
          >
            {NEW_CONTRACT_FORM_DELAYS_RULES_TITLE}
          </p>
          {canAddADelayRule() && (
            <img
              className="NewContractAppContentDelayRulesFormTitleLogo"
              id="DelayRulesAddNewRules"
              src={ADD_ITEM_LOGO}
              alt="AddNewRulesLogo"
              aria-hidden="true"
              onClick={onNewDelayRuleClick}
            />
          )}
        </div>
        {handleDelayRulesDisplayBasedOnGetDelayRulesStatus()}
      </div>
    )
  );
}
