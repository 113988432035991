import { createSlice, current } from '@reduxjs/toolkit';

import { Status } from 'src/constants/status';

import {
  apiDeleteInvoiceService,
  apiGetInvoice,
  apiGetInvoices,
  apiGetInvoicesFilterParameter,
  apiPostInvoiceDetail,
  apiPostInvoiceToValidate,
  apiRefreshInvoiceinInvoices,
} from 'src/invoicing/invoices/InvoicesApiRequest';
import { refreshInvoicesWithReceivedInvoice } from 'src/invoicing/invoices/InvoicesLogic';

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    deleteServiceStatus: Status.DEFAULT,
    getInvoicesStatus: Status.DEFAULT,
    getInvoiceStatus: Status.DEFAULT,
    invoices: [],
    invoice: {},
    postInvoiceDetailServicesStatus: Status.DEFAULT,
    getinvoicesFiltersParametersStatus: Status.DEFAULT,
    postInvoiceToValidateStatus: Status.DEFAULT,
    invoicesFiltersParameters: {
      customers: [],
      company_business_groups: [],
    },
  },
  reducers: {
    resetPostInvoiceDetailServicesStatus(state) {
      state.postInvoiceDetailServicesStatus = Status.DEFAULT;
    },
    resetPostInvoiceToValidateStatus(state) {
      state.postInvoiceToValidateStatus = Status.DEFAULT;
    },
    resetDeleteInvoiceStatus(state) {
      state.deleteServiceStatus = Status.DEFAULT;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetInvoices.pending, (state) => {
        state.getInvoicesStatus = Status.LOADING;
      })
      .addCase(apiGetInvoices.fulfilled, (state, action) => {
        state.getInvoicesStatus = Status.SUCCESS;
        state.invoices = action.payload;
      })
      .addCase(apiGetInvoices.rejected, (state) => {
        state.getInvoicesStatus = Status.FAIL;
      })
      .addCase(apiGetInvoice.pending, (state) => {
        state.getInvoiceStatus = Status.LOADING;
      })
      .addCase(apiGetInvoice.fulfilled, (state, action) => {
        state.getInvoiceStatus = Status.SUCCESS;
        state.invoice = action.payload;
      })
      .addCase(apiGetInvoice.rejected, (state) => {
        state.getInvoiceStatus = Status.FAIL;
      })
      .addCase(apiRefreshInvoiceinInvoices.fulfilled, (state, action) => {
        const invoice = action.payload;
        const { invoices } = current(state);

        state.invoices = refreshInvoicesWithReceivedInvoice(invoice, invoices);
      })
      .addCase(apiGetInvoicesFilterParameter.pending, (state) => {
        state.getinvoicesFiltersParametersStatus = Status.LOADING;
      })
      .addCase(apiGetInvoicesFilterParameter.fulfilled, (state, action) => {
        state.invoicesFiltersParameters = action.payload;
        state.getinvoicesFiltersParametersStatus = Status.SUCCESS;
      })
      .addCase(apiGetInvoicesFilterParameter.rejected, (state) => {
        state.getinvoicesFiltersParametersStatus = Status.FAIL;
      })
      .addCase(apiPostInvoiceToValidate.pending, (state) => {
        state.postInvoiceToValidateStatus = Status.LOADING;
      })
      .addCase(apiPostInvoiceToValidate.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.postInvoiceToValidateStatus = Status.SUCCESS;
      })
      .addCase(apiPostInvoiceToValidate.rejected, (state) => {
        state.postInvoiceToValidateStatus = Status.FAIL;
      })
      .addCase(apiPostInvoiceDetail.pending, (state) => {
        state.postInvoiceDetailServicesStatus = Status.LOADING;
      })
      .addCase(apiPostInvoiceDetail.fulfilled, (state, action) => {
        state.postInvoiceDetailServicesStatus = Status.SUCCESS;
        state.invoice = action.payload;
      })
      .addCase(apiPostInvoiceDetail.rejected, (state) => {
        state.postInvoiceDetailServicesStatus = Status.FAIL;
      })
      .addCase(apiDeleteInvoiceService.pending, (state) => {
        state.deleteServiceStatus = Status.LOADING;
      })
      .addCase(apiDeleteInvoiceService.fulfilled, (state) => {
        state.deleteServiceStatus = Status.SUCCESS;
      })
      .addCase(apiDeleteInvoiceService.rejected, (state) => {
        state.deleteServiceStatus = Status.FAIL;
      });
  },
});

export const { resetDeleteInvoiceStatus, resetPostInvoiceDetailServicesStatus, resetPostInvoiceToValidateStatus } = invoicesSlice.actions;

export const selectDeleteInvoiceStatus = (state: any) => state.invoices.deleteServiceStatus;
export const selectGetInvoicesFilterParametersStatus = (state: any) => state.invoices.getinvoicesFiltersParametersStatus;
export const selectGetInvoicesStatus = (state: any) => state.invoices.getInvoicesStatus;
export const selectGetInvoiceStatus = (state: any) => state.invoices.getInvoiceStatus;
export const selectInvoice = (state: any) => state.invoices.invoice;
export const selectInvoices = (state: any) => state.invoices.invoices;
export const selectInvoicesFilterParameter = (state: any) => state.invoices.invoicesFiltersParameters;
export const selectPostInvoiceDetailServicesStatus = (state: any) => state.invoices.postInvoiceDetailServicesStatus;
export const selectPostInvoiceToValidateStatus = (state: any) => state.invoices.postInvoiceToValidateStatus;

export default invoicesSlice.reducer;
