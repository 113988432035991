export const INVOICES_TITLE = 'INVOICES';
export const NO_INVOICE_MESSAGE = 'No Invoice is set up yet';

export const DATE_EMPTY_VALUE = '';

export const DOWNLOAD_BUTTON_NAME = 'Download';
export const VALIDATE_BUTTON_NAME = 'Validate';

export const invoicesTitle = {
  status: 'Status',
  arrivalDate: 'Arrival Date',
  departureDate: 'Departure Date',
  airport: 'Airport',
  airline: 'Airline',
  arrivalFlight: 'Arrival Flight n°',
  departureFlight: 'Departure Flight n°',
  aircraftType: 'Aircraft Type',
  registration: 'Registration',
  amount: 'Amount (EXCL TAX)',
};

export const AMOUNT_FILTER_KEY = 'amount';
export const PERIOD_FILTER_KEY = 'date';
export const LOCALES = 'fr-FR';
export const PERIOD_QUICK_FILTER_DEFAULT_VALUE = 'inputRadioThisMonthSoFar';
export const DATE_FILTER_FROM_DATE_KEY = 'fromDate';
export const DATE_FILTER_TO_DATE_KEY = 'toDate';

export const SELECT_ALL_VALIDATED_INVOICES_TEXT = 'Select all validated invoices to download';

export const TO_CHECK_STATUS = { code: 'TO_CHECK', label: 'To check' };
export const VALIDATED_STATUS = { code: 'VALIDATED', label: 'Validated' };
export const MISSING_INFO_STATUS = { code: 'MISSING_INFO', label: 'Missing info' };
