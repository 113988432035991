import React, { ChangeEventHandler } from 'react';

import { CANNOT_SELECT_INVOICE_TEXT, SELECT_INVOICE_TEXT } from 'src/constants/componentTexts/InvoiceText';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';

interface Props {
  checkboxValue: boolean;
  hasInvoiceValidatedStatus: Function;
  invoice: InvoiceModel;
  onInvoiceCheckboxChange: ChangeEventHandler<HTMLInputElement>;
}

export default function InvoiceCheckbox(props: Props) {
  const {
    checkboxValue,
    hasInvoiceValidatedStatus,
    invoice,
    onInvoiceCheckboxChange,
  } = props;

  return (
    <td id={`InvoiceTableBodyRowValueCheckbox${invoice.id}Id`}>
      <input
        type="checkbox"
        className="InvoicesTableCheckbox"
        checked={hasInvoiceValidatedStatus(invoice?.status) && checkboxValue}
        onChange={onInvoiceCheckboxChange}
        disabled={!hasInvoiceValidatedStatus(invoice?.status)}
        title={hasInvoiceValidatedStatus(invoice?.status) ? SELECT_INVOICE_TEXT : CANNOT_SELECT_INVOICE_TEXT}
        id={`InvoiceCheckbox${invoice.id}Id`}
        onClick={(event) => event.stopPropagation()}
      />
    </td>
  );
}
