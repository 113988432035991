import React from 'react';
import { useSelector } from 'react-redux';

import { LOGIN_FORM_BUTTON } from 'src/constants/componentTexts/LoginText';

import PasswordInput from 'src/invoicing/login/PasswordInput';

import { selectUserName } from 'src/invoicing/login/LoginSlice';

interface Props {
  password: string;
  setPassword: Function;
  submitLogin: Function;
}

export default function LoginForm(props: Props) {
  const {
    password,
    setPassword,
    submitLogin,
  } = props;

  const username: string = useSelector(selectUserName);

  return (
    <div className="LoginFormWrapper">
      <form className="LoginForm" onSubmit={(event) => submitLogin(event, username)}>
        <input
          id="LoginFormUsernameInputId"
          className="DefaultFontSize LoginFormUserNameInput"
          type="text"
          name="username"
          disabled
          placeholder="Username"
          required
          defaultValue={username}
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
        />
        <button id="LoginFormSubmitButtonId" type="submit" className="DefaultFontSize LoginFormSubmitButton">
          {LOGIN_FORM_BUTTON}
        </button>
      </form>
    </div>
  );
}
