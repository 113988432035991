import { getTokenExpirationTimeLimit, isTokenExpiryValid } from 'src/invoicing/InvoicingLogic';
import { refreshAccessTokenApi } from 'src/invoicing/api/RefreshTokenApiRequest';
import { resetErrorMessageToken } from 'src/invoicing/InvoicingSlice';

export const isTokenValid = (refreshTokenExpiry: string) => {
  if (Date.parse(`${refreshTokenExpiry}Z`) > Date.parse(new Date().toJSON())) {
    return true;
  }
  return false;
};

export const shouldAccessTokenBeRefreshed = () => {
  const accessTokenExpiry = localStorage.getItem('accessTokenExpiry');

  if (accessTokenExpiry) {
    return !isTokenExpiryValid(getTokenExpirationTimeLimit(accessTokenExpiry));
  }
  return null;
};

export const refreshAccessTokenIfExpired = async (dispatch: Function) => {
  if (shouldAccessTokenBeRefreshed()) {
    await dispatch(refreshAccessTokenApi());
  } else {
    await dispatch(resetErrorMessageToken());
  }
};
