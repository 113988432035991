import React from 'react';

import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

interface Props {
  getInvoicedTurnaroundFlight: Function;
  getInvoicedTurnaroundFlightPath: Function;
  invoiceId: string;
}

export default function InvoicedTurnaroundFlights(props: Props) {
  const { getInvoicedTurnaroundFlight, getInvoicedTurnaroundFlightPath, invoiceId } = props;

  const turnaroundFlightsNumber = getInvoicedTurnaroundFlight();
  const turnaroundFlightsPath = getInvoicedTurnaroundFlightPath();

  const getNewClassNameIfTurnaroundIsArrivalOrDepartureOnly = () => (
    turnaroundFlightsNumber[1] === EMPTY_VALUE && 'InvoicedTurnaroundFlightNumberAutomaticWidth'
  );

  return (
    <div className="InvoicedTurnaroundFlights">
      <div
        className={`InvoicedTurnaroundFlightsNumber ${getNewClassNameIfTurnaroundIsArrivalOrDepartureOnly()}`}
        id={`InvoicedTurnaroundFlights${invoiceId}`}
      >
        <p className="InvoicedTurnaroundFlightNumber arrivalFlight">{turnaroundFlightsNumber[0]}</p>
        <p className="InvoicedTurnaroundFlightNumber flightsSeparator">{turnaroundFlightsNumber[1]}</p>
        <p className="InvoicedTurnaroundFlightNumber departureFlight">{turnaroundFlightsNumber[2]}</p>
      </div>
      <div className="InvoicedTurnaroundFlightPathWrapper" id={`InvoicedTurnaroundFlightPath${invoiceId}`}>
        <p className="InvoicedTurnaroundFlightPath originAirport">{turnaroundFlightsPath[0]}</p>
        <p className="InvoicedTurnaroundFlightPath airportHub">{turnaroundFlightsPath[1]}</p>
        <p className="InvoicedTurnaroundFlightPath destinationAirport">{turnaroundFlightsPath[2]}</p>
      </div>
    </div>
  );
}
