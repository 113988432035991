import { createSlice } from '@reduxjs/toolkit';
import { FAILED_REFRESH_ACCESS_TOKEN_MESSAGE } from 'src/constants/errorMessage';

import { refreshAccessTokenApi } from 'src/invoicing/api/RefreshTokenApiRequest';

interface InitialState {
  errorMessageToken: string | null,
  hasToDisconnect: boolean;
}

const initialState: InitialState = {
  errorMessageToken: null,
  hasToDisconnect: false,
};

export const invoicingSlice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    resetErrorMessageToken(state) {
      state.errorMessageToken = null;
    },
    resetHasToDisconnect(state) {
      state.hasToDisconnect = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(refreshAccessTokenApi.pending, (state) => {
        state.errorMessageToken = null;
      })
      .addCase(refreshAccessTokenApi.fulfilled, (state, action) => {
        state.errorMessageToken = null;
        localStorage.setItem('accessToken', action.payload.access_token);
        localStorage.setItem('accessTokenExpiry', action.payload.access_token_expiry);
      })
      .addCase(refreshAccessTokenApi.rejected, (state) => {
        state.hasToDisconnect = true;
        state.errorMessageToken = FAILED_REFRESH_ACCESS_TOKEN_MESSAGE;
        localStorage.setItem('accessToken', '');
      });
  },
});

export const { resetErrorMessageToken, resetHasToDisconnect } = invoicingSlice.actions;

export const selectErrorMessageToken = (state: any) => state.invoicing.errorMessageToken;
export const selectHasToDisconnect = (state: any) => state.invoicing.hasToDisconnect;

export default invoicingSlice.reducer;
