import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  invoicingDeleteApiRequestAsyncThunk,
  invoicingGetApiRequestAsyncThunk,
  invoicingPatchApiRequestAsyncThunk,
  invoicingPostApiRequestAsyncThunk,
} from 'src/invoicing/api/invoicingCustomApiRequestLogic';

export const apiGetContractParameters = createAsyncThunk('contract/apiGetContractParameters', async (_, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/contract_parameters' })
));

export const apiGetContractFilters = createAsyncThunk('contract/apiGetContractFilters', async (_, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/contract_parameters' })
));

export const apiGetContracts = createAsyncThunk('contract/apiGetContracts', async (_, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/contracts' })
));

export const apiGetContract = createAsyncThunk('contract/apiGetContract', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}` })
));

export const apiPostNewContract = createAsyncThunk('contract/apiPostNewContract', async (body: any, { dispatch, rejectWithValue, getState }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/contract', body })
));

export const apiPostDuplicateContract = createAsyncThunk('contract/apiPostDuplicateContract', async (body: { contract: any, contractId: number }, { dispatch, rejectWithValue, getState }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/contract/duplicate', body })
));

export const apiPatchContract = createAsyncThunk('contract/apiPatchContract', async (body: { contract: any, contractId: number }, { dispatch, rejectWithValue, getState }) => (
  invoicingPatchApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${body.contractId}`, body: body.contract })
));

export const apiDeleteNewContract = createAsyncThunk('contract/apiDeleteNewContract', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingDeleteApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}` })
));
