import React from 'react';

import FixedPrice from 'src/invoicing/services/price/fixedPrice/FixedPrice';
import VariablePrice from 'src/invoicing/services/price/variablePrice/VariablePrice';

import { AdditionalServiceSentInterface, AdditionalServiceReceivedInterface } from 'src/invoicing/services/additionalServices/AdditionalServicesInterface';
import {
  getAddInfoDatasourceLabel,
  getAdditionalServicePriceBasedOnUserdDataTypePicked,
  getAdditionalServiceTextBasedOnDataTypeToMesurePicked,
} from 'src/invoicing/services/additionalServices/AdditionalServicesLogic';

import {
  additionalServiceFixedPriceText,
  additionalServiceFormText,
  additionalServiceVariablePriceText,
  VARIABLE_CURRENCY,
} from 'src/constants/componentTexts/AdditionalServicesText';
import {
  FIXED_AMOUNT_CODE,
  PERCENTAGE_OF_BASE_PRICE_CODE,
} from 'src/constants/componentTexts/ServiceText';

interface Props {
  additionalService: AdditionalServiceSentInterface;
  additionalServiceFilled: AdditionalServiceReceivedInterface | null;
  additionalServiceParameters: any;
  additionalServicePrice: any;
  isFixedPriceChecked: boolean,
  isVariablePriceChecked: boolean,
  readOnly: boolean;
  setAddtionalServicePrice: Function;
}

export default function AdditionalServiceFormPrice(props: Props) {
  const {
    additionalService,
    additionalServiceFilled,
    additionalServiceParameters,
    additionalServicePrice,
    isFixedPriceChecked,
    isVariablePriceChecked,
    readOnly,
    setAddtionalServicePrice,
  } = props;

  return (
    <div className="ServicesBox">
      {isFixedPriceChecked && (
        <FixedPrice
          service={additionalServicePrice}
          serviceCurrency={additionalServiceParameters.currency.symbol}
          serviceName="NewAdditionalService"
          serviceText={getAdditionalServiceTextBasedOnDataTypeToMesurePicked(additionalService, additionalServiceFormText, additionalServiceFixedPriceText)}
          setService={setAddtionalServicePrice}
          serviceFilledPrice={getAdditionalServicePriceBasedOnUserdDataTypePicked(additionalServiceFilled, FIXED_AMOUNT_CODE, additionalService)}
          priceSpecification={additionalService?.data_type_to_mesure}
          readOnly={readOnly}
          valuesTypes={additionalServiceParameters?.value_types[1]?.id}
          serviceAddInfo={getAddInfoDatasourceLabel(additionalService, additionalServiceParameters)}
        />
      )}
      {isVariablePriceChecked && (
        <VariablePrice
          service={additionalServicePrice}
          serviceCurrency={VARIABLE_CURRENCY}
          serviceName="NewAdditionalService"
          serviceText={getAdditionalServiceTextBasedOnDataTypeToMesurePicked(additionalService, additionalServiceFormText, additionalServiceVariablePriceText)}
          setService={setAddtionalServicePrice}
          serviceFilledPrice={getAdditionalServicePriceBasedOnUserdDataTypePicked(additionalServiceFilled, PERCENTAGE_OF_BASE_PRICE_CODE, additionalService)}
          priceSpecification={additionalService?.data_type_to_mesure}
          readOnly={readOnly}
          valuesTypes={additionalServiceParameters?.value_types[1]?.id}
          serviceAddInfo={getAddInfoDatasourceLabel(additionalService, additionalServiceParameters)}
        />
      )}
    </div>
  );
}
