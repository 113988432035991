import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

import {
  selectCancellationRule,
  selectGetCancellationRuleStatus,
} from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';

export default function useGettingCancellationRuleSelected() {
  const cancellationRule: any = useSelector(selectCancellationRule);
  const getCancellationRuleStatus: string = useSelector(selectGetCancellationRuleStatus);

  useEffect(() => {}, [getCancellationRuleStatus !== Status.LOADING]);

  return {
    cancellationRule,
    getCancellationRuleStatus,
  };
}
