import React from 'react';

import { CUSTOM_SERVICE_TYPE } from 'src/constants/componentTexts/InvoiceDetailText';
import { DELETE_LOGO } from 'src/constants/picturesPath';

import { replaceUnderscoreToSpace } from 'src/utils/logic/regex/regex';

import Amount from 'src/invoicing/invoiceDetail/Amount';
import DeleteServiceModal from 'src/uiComponents/modal/DeleteServiceModal';
import InvoicedServiceDescription from 'src/invoicing/invoiceDetail/InvoicedServiceDescription';
import Label from 'src/invoicing/invoiceDetail/Label';
import ToggleOnOff from 'src/uiComponents/toggle/ToggleOnOff';

import useInvoicedService from 'src/invoicing/invoiceDetail/useInvoicedService';

interface Props {
  customServiceType?: string;
  invoiceId: number;
  id: string;
  invoiceCurrency: string;
  invoiceServices: any;
  isCustomService: boolean;
  service: any;
  services: any;
  setServices: Function;
}

export default function InvoicedService(props: Props) {
  const {
    customServiceType = null,
    invoiceId,
    id,
    invoiceCurrency,
    isCustomService,
    service,
    services,
    setServices,
  } = props;

  const {
    canServiceBeDeleted,
    descriptionTextDynamicHeight,
    getCustomPrefixIdIfCustomService,
    getDeletedPictureClassname,
    isDeleteServiceModalOpened,
    isDescriptionOpen,
    onCustomServiceDescriptionChange,
    onExistingServiceDescriptionChange,
    onDescriptionClick,
    serviceAmount,
    serviceDescription,
    serviceLabel,
    serviceValidated,
    setHasServiceDeleted,
    setIsDeleteServiceModalOpened,
    setServiceAmount,
    setServiceLabel,
    toggleCustomServiceStatus,
    toggleExistingServiceStatus,
  } = useInvoicedService(props);

  return (
    <>
      <div className="InvoicedServiceWrapper">
        <div className="InvoicedServiceWrapperInformation">
          <p
            id={`InvoicedServiceType${getCustomPrefixIdIfCustomService()}${id}`}
            className="InvoicedServiceWrapperInformationServiceType"
          >
            {replaceUnderscoreToSpace(customServiceType || service.type)}
          </p>
          {(isCustomService || service.type === CUSTOM_SERVICE_TYPE) && (
            <div className="InvoicesServiceDeleteCustomService">
              <img
                src={DELETE_LOGO}
                alt={DELETE_LOGO}
                className={`InvoicesServiceDeleteCustomServicePicture${getDeletedPictureClassname()}`}
                onClick={canServiceBeDeleted() ? () => setIsDeleteServiceModalOpened(true) : () => {}}
                aria-hidden="true"
                id={`InvoicesServiceDeleteCustomService${getCustomPrefixIdIfCustomService()}${id}Id`}
              />
            </div>
          )}
          <Label
            id={id}
            isCustomService={isCustomService}
            prefixId={getCustomPrefixIdIfCustomService()}
            service={service}
            serviceLabel={serviceLabel}
            services={services}
            setServices={setServices}
            setServiceLabel={setServiceLabel}
            serviceValidated={serviceValidated}
          />
          <div className="InvoicedServiceWrapperInformationServiceAmountContent">
            <Amount
              id={id}
              isCustomService={isCustomService}
              prefixId={getCustomPrefixIdIfCustomService()}
              service={service}
              serviceAmount={serviceAmount}
              setServiceAmount={setServiceAmount}
              services={services}
              setServices={setServices}
              serviceValidated={serviceValidated}
            />
            <p className="InvoicedServiceWrapperInformationServiceAmountCurrencyText">
              {invoiceCurrency}
            </p>
          </div>
        </div>
        <div className="InvoicedServiceDescriptionWrapper">
          <InvoicedServiceDescription
            descriptionTextDynamicHeight={descriptionTextDynamicHeight}
            getCustomPrefixIdIfCustomService={getCustomPrefixIdIfCustomService}
            isDescriptionOpen={isDescriptionOpen}
            onDescriptionChange={isCustomService ? onCustomServiceDescriptionChange : onExistingServiceDescriptionChange}
            onDescriptionClick={onDescriptionClick}
            serviceDescription={serviceDescription}
            serviceId={id}
          />
          <ToggleOnOff
            id={Number(id)}
            idPrefix="serviceStatus"
            onToggleClick={isCustomService ? toggleCustomServiceStatus : toggleExistingServiceStatus}
            toggleValue={serviceValidated}
          />
        </div>
      </div>
      {isDeleteServiceModalOpened && (
        <DeleteServiceModal
          canServiceBeDeleted={canServiceBeDeleted}
          customServiceType={customServiceType}
          invoiceId={invoiceId}
          serviceId={Number(id)}
          services={services}
          setHasServiceDeleted={setHasServiceDeleted}
          setIsDeleteServiceModalOpened={setIsDeleteServiceModalOpened}
        />
      )}
    </>
  );
}
