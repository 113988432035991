import React from 'react';

import ExternalLogin from 'src/invoicing/login/ExternalLogin';
import TarmacLogin from 'src/invoicing/login/TarmacLogin';

import useLogin from 'src/invoicing/login/useLogin';

import 'src/invoicing/login/Login.css';

export default function SSOLogin() {
  const {
    isDefaultLoginOpen,
    loginStatus,
    setUsername,
    submitSso,
    tarmacLoginErrorMessage,
    username,
  } = useLogin();

  return (
    !isDefaultLoginOpen ? (
      <ExternalLogin
        loginStatus={loginStatus}
        setUsername={setUsername}
        submitSso={submitSso}
        tarmacLoginErrorMessage={tarmacLoginErrorMessage}
        username={username}
      />
    ) : <TarmacLogin />
  );
}
