import { customStepsText } from 'src/constants/componentTexts/CustomStepText';
import {
  CREATE_ACTION,
  formattedFixedPriceLabels,
  getServiceErrorMessage,
  PERCENTAGE_OF_BASE_PRICE_SYMBOL,
  PERCENTAGE_OF_BASE_PRICE_TEXT,
  UPDATE_ACTION,
} from 'src/constants/componentTexts/ServiceText';

export const NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_NO_RULE_MESSAGE = 'No additional service is set up';

export const NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_FIRST_COLUMN_TITLE = 'Name of Services';
export const NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_SECOND_COLUMN_TITLE = 'Type of prices';
export const NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TABLE_THIRD_COLUMN_TITLE = 'Measurement';
export const NEW_CONTRACT_FORM_ADDITIONAL_SERVICES_TITLE = 'Additional Services';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TITLE = 'New Additional Service';
export const NEW_CONTRACT_FORM_EDIT_ADDITIONAL_SERVICE_TITLE = 'Edit Additional Service';
export const NEW_CONTRACT_FORM_VIEW_ADDITIONAL_SERVICE_TITLE = 'View Additional Service';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_CHECKBOX_TITLE = 'Pick one type of Additional Service price:';

export const NEW_CONTRACT_FORM_SERVICES_CANCEL_ADDITIONAL_SERVICE_FORM_BUTTON = 'Cancel';
export const NEW_CONTRACT_FORM_SERVICES_SEND_ADDITIONAL_SERVICE_FORM_BUTTON = 'Add this Additional service';
export const EDIT_CONTRACT_FORM_SERVICES_SEND_ADDITIONAL_SERVICE_FORM_BUTTON = 'Edit this Additional service';

export const ADDITIONAL_SERVICES_STEP_NUMBER = 6;
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PARAMETER_NUMBER = '1';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_NUMBER = '2';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER = '3';

export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_TEXT = 'Price';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_TEXT = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;

export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_FIXED_PRICE_LABEL = formattedFixedPriceLabels.UPPERCASE_WORDS_FIRST_LETTER_CODE;
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_VARIABLE_PRICE_LABEL = `Variable Price (${PERCENTAGE_OF_BASE_PRICE_SYMBOL})`;
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE__PRICE_LABEL = `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this additional service`;
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_NAME_OF_ADDITIONAL_SERVICE_LABEL = 'Name of Additional Service*';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_ARRIVAL_OR_DEPARTURE_SERVICE_LABEL = 'Arrival or Departure Additional Service*';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_DATA_TYPE_TO_MESURE_LABEL = 'Data type to mesure*';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TASK_DATASOURCE_LABEL = 'Task Datasource*';
export const NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_ADD_INFO_DATASOURCE_LABEL = 'Add info Datasource*';

export const DIFFERENCE_BETWEEN_TWO_TIMINGS_A_DATETIME_TEXT = 'A';
export const DIFFERENCE_BETWEEN_TWO_TIMINGS_B_DATETIME_TEXT = 'B';

export const ADD_SERVICE_EMPTY_VALUE = '';

export const ADD_SERVICE_ADD_INFO_TOGGLE_VALUE = 'Add Info';
export const ADD_SERVICE_TASK_TOGGLE_VALUE = 'Task';

export const VARIABLE_CURRENCY = '%';

export const NEW_ADDITIONAL_SERVICE_NAME = 'NewAdditionalService';

export const ADD_SERVICE_NAME = 'additional service';

export const NEW_ADDITIONAL_SERVICE_ERROR_MESSAGE = getServiceErrorMessage(CREATE_ACTION, ADD_SERVICE_NAME);
export const UPDATE_ADDITIONAL_SERVICE_ERROR_MESSAGE = getServiceErrorMessage(UPDATE_ACTION, ADD_SERVICE_NAME);

export const differenceBetweenTwoTimingsText = {
  aDatetimeText: 'A',
  bDatetimeText: 'B',
  firstPartMiddleText: 'Time Difference between ',
  secondPartMiddleText: ' and ',
  lastPartMiddleText: ' datetime',
};

export const additionalServiceFormText = {
  subtitleItalicText: 'The price of the additional service will be added to the base price of the turnaround',
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  additionalName: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_NAME_OF_ADDITIONAL_SERVICE_LABEL,
  arrDepAdditional: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_ARRIVAL_OR_DEPARTURE_SERVICE_LABEL,
  dataType: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_DATA_TYPE_TO_MESURE_LABEL,
  taskDatasource: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TASK_DATASOURCE_LABEL,
  taskDatasourceTimingReference: 'Start/End Datetime*',
  taskArrivalOrDeparture: 'Arrival or Departure',
  addInfoDatasource: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_ADD_INFO_DATASOURCE_LABEL,
  fixedPrice: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_FIXED_PRICE_LABEL,
  parameterNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PARAMETER_NUMBER,
  typeOfPriceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_NUMBER,
  typeOfPriceText: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_TYPE_OF_PRICE_TEXT,
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
  priceText: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_TEXT,
  variablePrice: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_VARIABLE_PRICE_LABEL,
  price: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE__PRICE_LABEL,
};

export const additionalServiceCompletionOfOneTaskFixedPricetext = {
  startWordText: 'If the task is ',
  subtitleArrivalSpecialText: `Not Applicable, In Progress or Did not Start, "${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this additional service NOT completed" will be added to the invoice (not compulsory to have a price for non completed task)`,
  subtitleTurnaroundSpecialText: `completed (late or on time), "${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this additional service completed" will be added to the invoice`,
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: 'The price of the additional service will be added to the base price of the turnaround',
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_TEXT,
  firstPrice: `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} if this additional service has been completed`,
  secondPrice: `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} if this additional service has NOT been done`,
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
};

export const additionalServiceYesNoFixedPricetext = {
  startWordText: 'If the answer is ',
  subtitleArrivalSpecialText: `NO, "${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE}for this additional Service if NO" will be added to the invoice`,
  subtitleTurnaroundSpecialText: `YES, "${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this additional Service if YES" will be added to the invoice`,
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: 'The price of the additional service will be added to the base price of the turnaround',
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_TEXT,
  firstPrice: `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this additional Service if YES`,
  secondPrice: `${formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE} for this additional Service if NO`,
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
};

export const additionalServiceNumericDataFixedPriceText = {
  startWordText: `Based on the number entered in AGOA, the ${formattedFixedPriceLabels.LOWERCASE_WORD_CODE} for the additional service can be different`,
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: 'The price of the additional service will be added to the base price of the turnaround',
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_TEXT,
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE,
  customStepDescriptionFirstPart: 'For ',
  customStepDescriptionSecondPart: '',
  customStepDescriptionAmount: `, the ${formattedFixedPriceLabels.LOWERCASE_WORD_CODE} is `,
  customStepDescriptionPriceAmount: '',
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
  customStepInputValue: '',
  customStepSeparatorBetweenTwoValue: 'to ',
};

export const additionalServiceDifference2TimingsFixedPriceText = {
  startWordText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: 'The price of the additional service will be added to the base price of the turnaround',
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_TEXT,
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: formattedFixedPriceLabels.UPPERCASE_FIRST_LETTER_CODE,
  customStepDescriptionFirstPart: 'If the duration between the two timings selected is between',
  customStepDescriptionSecondPart: '',
  customStepDescriptionAmount: `, the ${formattedFixedPriceLabels.LOWERCASE_WORD_CODE} for this additional service is `,
  customStepDescriptionPriceAmount: '',
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
  customStepInputValue: customStepsText.customStepInputValue,
  customStepSeparatorBetweenTwoValue: customStepsText.customStepAndSeparatorBetweenTwoValue,
};

export const additionalServiceCompletionOfOneTaskVariablePricetext = {
  startWordText: 'If the task is ',
  subtitleArrivalSpecialText: `Not Applicable, In Progress or Did not Start, "${PERCENTAGE_OF_BASE_PRICE_SYMBOL} if this additional service has NOT been done" will be added to the invoice (not compulsory to have a price for non completed task)`,
  subtitleTurnaroundSpecialText: `completed (late or on time), "${PERCENTAGE_OF_BASE_PRICE_SYMBOL} if this additional service has been completed" will be added to the invoice`,
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: `The price of the additional service will be calculated based on the ${PERCENTAGE_OF_BASE_PRICE_TEXT} of the turnaround and added to the turnaround price.`,
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: 'Variable Price',
  firstPrice: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} if this additional service has been completed`,
  secondPrice: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} if this additional service has NOT been done`,
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
};

export const additionalServiceYesNoVariablePricetext = {
  startWordText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: `YES, "${PERCENTAGE_OF_BASE_PRICE_SYMBOL} for this additional Service if YES" will be added ti the invoice`,
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: `The price of the additional service will be calculated based on the ${PERCENTAGE_OF_BASE_PRICE_TEXT} of the turnaround and added to the turnaround price.`,
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: 'Variable Price',
  firstPrice: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} for this additional Service if YES`,
  secondPrice: `${PERCENTAGE_OF_BASE_PRICE_SYMBOL} for this additional Service if NO`,
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
};

export const additionalServiceNumericDataVariablePriceText = {
  startWordText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: `The price of the additional service will be calculated based on the ${PERCENTAGE_OF_BASE_PRICE_TEXT} of the turnaround and added to the turnaround price.`,
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: 'Variable Price',
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: PERCENTAGE_OF_BASE_PRICE_SYMBOL,
  customStepDescriptionFirstPart: 'For ',
  customStepDescriptionSecondPart: '',
  customStepDescriptionAmount: ', the price for this service is ',
  customStepDescriptionPriceAmount: ' of the base price',
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
  customStepInputValue: '',
  customStepSeparatorBetweenTwoValue: 'to ',
};

export const additionalServiceDifference2TimingsVariablePriceText = {
  startWordText: '',
  subtitleArrivalSpecialText: '',
  subtitleTurnaroundSpecialText: '',
  turnaround: '',
  arrivalOnly: '',
  departureOnly: '',
  subtitleItalicText: `The price of the additional service will be calculated based on the ${PERCENTAGE_OF_BASE_PRICE_TEXT} of the turnaround and added to the turnaround price.`,
  subtitleItalicBoldText: '',
  subtitleListMainText: '',
  subtitleBoldTurnaroundText: '',
  subtitleBoldArrivalOnlyText: '',
  subtitleDepartureSpecialText: '',
  subtitleBoldDepartureOnlyText: '',
  priceText: 'Variable Price',
  customStepMinDurationText: customStepsText.customStepMinDurationText,
  customStepMaxDurationText: customStepsText.customStepMaxDurationText,
  customStepAmountText: PERCENTAGE_OF_BASE_PRICE_SYMBOL,
  customStepDescriptionFirstPart: 'If the duration between the two timings selected is between ',
  customStepDescriptionSecondPart: '',
  customStepDescriptionAmount: ', the price for this additional service is ',
  customStepDescriptionPriceAmount: ' of the base price',
  priceNumber: NEW_CONTRACT_FORM_NEW_ADDITIONAL_SERVICE_PRICE_NUMBER,
  customStepInputValue: customStepsText.customStepInputValue,
  customStepSeparatorBetweenTwoValue: customStepsText.customStepAndSeparatorBetweenTwoValue,
};

export const additionalServiceFixedPriceText: any = {
  COMPLETION_OF_ONE_TASK: additionalServiceCompletionOfOneTaskFixedPricetext,
  YES_NO: additionalServiceYesNoFixedPricetext,
  NUMERIC_DATA: additionalServiceNumericDataFixedPriceText,
  DIFFERENCE_BETWEEN_TWO_TIMINGS: additionalServiceDifference2TimingsFixedPriceText,
};

export const additionalServiceVariablePriceText: any = {
  COMPLETION_OF_ONE_TASK: additionalServiceCompletionOfOneTaskVariablePricetext,
  YES_NO: additionalServiceYesNoVariablePricetext,
  NUMERIC_DATA: additionalServiceNumericDataVariablePriceText,
  DIFFERENCE_BETWEEN_TWO_TIMINGS: additionalServiceDifference2TimingsVariablePriceText,
};

export enum AddServiceDataTypeToMesure { //eslint-disable-line
  COMPLETION_OF_ONE_TASK = 'COMPLETION_OF_ONE_TASK', //eslint-disable-line
  NUMERIC_DATA = 'NUMERIC_DATA', //eslint-disable-line
  YES_NO = 'YES_NO', //eslint-disable-line
  DIFFERENCE_BETWEEN_TWO_TIMINGS = 'DIFFERENCE_BETWEEN_TWO_TIMINGS', //eslint-disable-line
}

export const dataTypeWithPrice: string[] = [
  AddServiceDataTypeToMesure.COMPLETION_OF_ONE_TASK,
  AddServiceDataTypeToMesure.NUMERIC_DATA,
  AddServiceDataTypeToMesure.YES_NO,
  AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS,
];

export const dataTypeForCompletedDescriptionPrice: string[] = [
  AddServiceDataTypeToMesure.NUMERIC_DATA,
  AddServiceDataTypeToMesure.YES_NO,
  AddServiceDataTypeToMesure.DIFFERENCE_BETWEEN_TWO_TIMINGS,
];

export const dataTypeToMesureAuthorizedToDisplayAddInfosDataSource = ['YES_NO', 'NUMERIC_DATA'];
export const dataTypeToMesureAuthorizedToDisplayTaskDataSource = ['COMPLETION_OF_ONE_TASK', 'NUMERIC_DATA', 'YES_NO'];

export const additionalServiceEmptyPrice = {
  COMPLETION_OF_ONE_TASK: { amount_if_true: null, amount_if_false: null, amounts_type: null },
  NUMERIC_DATA: [{
    min_value: null, max_value: null, amount: null, order: 0,
  }],
  YES_NO: { amount_if_true: null, amount_if_false: null, amounts_type: null },
  DIFFERENCE_BETWEEN_TWO_TIMINGS: [{
    min_value: null, max_value: null, amount: null, order: 0,
  }],
};
