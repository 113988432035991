import { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { selectContractAction } from 'src/invoicing/contract/ContractSlice';

interface Props {
  nextComponent: string | null;
  setCurrentContractByStepType: Function;
}

export default function useAdditionalServicesByStep(props: Props) {
  const { nextComponent, setCurrentContractByStepType } = props;

  const contractAction: string = useSelector(selectContractAction);
  const [isAdditionalSubViewOpen, setIsAdditionalSubViewOpen] = useState<boolean>(false);
  const [additionalServiceAction, setAdditionalServiceAction] = useState<string>(ActionStatus.NEW);

  const submitAdditionalServices = (event: FormEvent) => {
    event.preventDefault();

    if (nextComponent) {
      setCurrentContractByStepType(nextComponent);
    }
  };

  return {
    additionalServiceAction,
    contractAction,
    isAdditionalSubViewOpen,
    setAdditionalServiceAction,
    setIsAdditionalSubViewOpen,
    submitAdditionalServices,
  };
}
