import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus, Status } from 'src/constants/status';

import { apiPatchContract, apiPostDuplicateContract, apiPostNewContract } from 'src/invoicing/contract/ContractApiRequest';
import {
  BasePrice,
  ContractParameters,
  Currency,
  StoredContract,
} from 'src/invoicing/contract/ContractInterface';
import {
  areResponseApiRequestStatusFailed,
  createPatchContractBody,
  getContractByWithBasePriceStepsFilled,
  getDefaultBasePrice,
  getSelectedContractAircraftModelsLabelsOrIds,
} from 'src/invoicing/contract/ContractLogic';
import {
  resetContractResponseStatus,
  resetPatchContractStatus,
  resetPostDuplicateContractStatus,
  selectContractParameters,
  selectContractResponseErrorMessage,
  selectContractResponseStatus,
  selectContractStatus,
  selectPatchContractStatus,
  selectPostDuplicateContractStatus,
  selectUserContractCurrency,
} from 'src/invoicing/contract/ContractSlice';

interface Props {
  contractAction: string;
  contractByStepsFilled: boolean[];
  nextComponent: string | null;
  setcontractByStepsFilled: Function;
  setCurrentContractByStepType: Function;
  setStoredContract: Function;
  storedContract: StoredContract;
}

export default function useBasePrice(props: Props) {
  const {
    contractAction,
    contractByStepsFilled,
    nextComponent,
    setcontractByStepsFilled,
    setCurrentContractByStepType,
    setStoredContract,
    storedContract,
  } = props;

  const dispatch = useDispatch();

  const contractParameters: ContractParameters = useSelector(selectContractParameters);
  const getContractSubmittedErrorMessage: any = useSelector(selectContractResponseErrorMessage);
  const getContractSubmittedStatus: string = useSelector(selectContractResponseStatus);
  const patchContractStatus: string = useSelector(selectPatchContractStatus);
  const postDuplicateContractStatus: string = useSelector(selectPostDuplicateContractStatus);
  const contractCurrency: Currency = useSelector(selectUserContractCurrency);
  const contractStatus: string = useSelector(selectContractStatus);

  const [basePrice, setBasePrice] = useState<BasePrice>(getDefaultBasePrice(storedContract));
  const [isFixedPriceChecked, setIsFixedPriceChecked] = useState<boolean>(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [previousContractSubmittedStatus, setPreviousContractSubmittedStatus] = useState<string>(useSelector(selectContractResponseStatus));
  const [previousPatchContractStatus, setPreviousPatchContractStatus] = useState<string>(useSelector(selectPatchContractStatus));
  const [previousPostDuplicateContractStatus, setPreviousPostDuplicateContractStatus] = useState<string>(useSelector(selectPostDuplicateContractStatus));

  const displayErrorIfSubmitFailed = () => {
    if (getContractSubmittedStatus !== previousContractSubmittedStatus
      || patchContractStatus !== previousPatchContractStatus
      || postDuplicateContractStatus !== previousPostDuplicateContractStatus) {
      if (areResponseApiRequestStatusFailed(getContractSubmittedStatus, patchContractStatus, postDuplicateContractStatus)) {
        setIsErrorModalOpen(!isErrorModalOpen);
      }
    }
  };

  const updateContractSubmittedStatusOnlyIfNeeded = () => {
    if (getContractSubmittedStatus !== previousContractSubmittedStatus) {
      setPreviousContractSubmittedStatus(getContractSubmittedStatus);
    }
  };

  const updatePatchContractStatusOnlyIfNeeded = () => {
    if (patchContractStatus !== previousPatchContractStatus) {
      setPreviousPatchContractStatus(patchContractStatus);
    }
  };

  const updatePostDuplicateContractStatusOnlyIfNeeded = () => {
    if (postDuplicateContractStatus !== previousPostDuplicateContractStatus) {
      setPreviousPostDuplicateContractStatus(patchContractStatus);
    }
  };

  useEffect(() => {
    updateContractSubmittedStatusOnlyIfNeeded();
    dispatch(resetContractResponseStatus());
  }, [getContractSubmittedStatus]);

  useEffect(() => {
    updatePatchContractStatusOnlyIfNeeded();
    dispatch(resetPatchContractStatus());
  }, [patchContractStatus]);

  useEffect(() => {
    updatePostDuplicateContractStatusOnlyIfNeeded();
    dispatch(resetPostDuplicateContractStatus());
  }, [postDuplicateContractStatus]);

  useEffect(() => {
    displayErrorIfSubmitFailed();
  }, [getContractSubmittedStatus,
    getContractSubmittedErrorMessage,
    patchContractStatus,
    postDuplicateContractStatus]);

  const submitContractAndBasePrice = (event: FormEvent) => {
    event.preventDefault();

    if (contractAction === ActionStatus.NEW) {
      setStoredContract({ ...storedContract, base_price: basePrice });
      dispatch(apiPostNewContract({ ...storedContract, base_price: basePrice, aircraft_models: getSelectedContractAircraftModelsLabelsOrIds(storedContract.aircraft_models, contractParameters.aircraft_models, 'id') }));
    } else if (contractAction === ActionStatus.EDIT) {
      setStoredContract({ ...storedContract, base_price: basePrice });
      dispatch(apiPatchContract(createPatchContractBody(storedContract, basePrice, contractParameters)));
    } else if (contractAction === ActionStatus.DUPLICATE) {
      setStoredContract({ ...storedContract, base_price: basePrice });
      dispatch(apiPostDuplicateContract(createPatchContractBody(storedContract, basePrice, contractParameters)));
    } else {
      setCurrentContractByStepType(nextComponent);
      setcontractByStepsFilled(getContractByWithBasePriceStepsFilled(contractByStepsFilled, Status.SUCCESS));
    }
  };

  return {
    basePrice,
    contractCurrency,
    contractStatus,
    dispatch,
    displayErrorIfSubmitFailed,
    getContractSubmittedErrorMessage,
    getContractSubmittedStatus,
    isErrorModalOpen,
    isFixedPriceChecked,
    patchContractStatus,
    postDuplicateContractStatus,
    setBasePrice,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    submitContractAndBasePrice,
    updateContractSubmittedStatusOnlyIfNeeded,
    updatePatchContractStatusOnlyIfNeeded,
    updatePostDuplicateContractStatusOnlyIfNeeded,
  };
}
