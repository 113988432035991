import React from 'react';

import { NEW_CONTRACT_FORM_EDIT_CANCELLATION_RULE_TITLE } from 'src/constants/componentTexts/CancellationRulesText';

import CancellationRuleForm from 'src/invoicing/services/cancellationRules/form/CancellationRuleForm';
import Loader from 'src/uiComponents/loader/Loader';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectPatchCancellationRuleStatus } from 'src/invoicing/services/cancellationRules/CancellationRulesSlice';
import useCancellationRuleParameters from 'src/invoicing/services/cancellationRules/useCancellationRuleParameters';
import useCancellationRuleSubmittionRequestStatus from 'src/invoicing/services/cancellationRules/useCancellationRuleSubmittionRequestStatus';
import useGettingCancellationRuleSelected from 'src/invoicing/services/cancellationRules/useGettingCancellationRuleSelected';

interface Props {
  contractId: number | null;
  setIsCancellationRuleSubViewOpen: Function;
}

export default function EditCancellationRule(props: Props) {
  const { contractId, setIsCancellationRuleSubViewOpen } = props;

  const { cancellationRuleParameters, cancellationRuleParametersStatus } = useCancellationRuleParameters(contractId);
  const { cancellationRule, getCancellationRuleStatus } = useGettingCancellationRuleSelected();
  useCancellationRuleSubmittionRequestStatus(selectPatchCancellationRuleStatus, setIsCancellationRuleSubViewOpen);

  return (
    shouldLoaderBeDisplayed([getCancellationRuleStatus, cancellationRuleParametersStatus]) ? (
      <Loader className="NewContractAppContentCancellationRulesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="EditCancellationRuleTitleId">{NEW_CONTRACT_FORM_EDIT_CANCELLATION_RULE_TITLE}</p>
        </div>
        <CancellationRuleForm
          cancellationRuleParameters={cancellationRuleParameters}
          contractId={contractId}
          setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
          cancellationRuleFilled={cancellationRule}
        />
      </div>
    )
  );
}
