import { useDispatch, useSelector } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { apiDeleteOperationType, apiGetOperationType } from 'src/invoicing/services/operationTypes/OperationTypesApiRequest';
import { OperationTypeReceivedInterface } from 'src/invoicing/services/operationTypes/OperationTypesInterface';
import { selectUserContractCurrency } from 'src/invoicing/contract/ContractSlice';
import { FIXED_AMOUNT_CODE } from 'src/constants/componentTexts/ServiceText';

interface Props {
  operationType: OperationTypeReceivedInterface;
  setIsOperationTypeSubViewOpen: Function;
  setOperationTypeAction: Function;
}

export default function useOperationType(props: Props) {
  const { operationType, setIsOperationTypeSubViewOpen, setOperationTypeAction } = props;

  const dispatch = useDispatch();

  const userCurrency = useSelector(selectUserContractCurrency);

  const deleteOperationType = () => {
    dispatch(apiDeleteOperationType(operationType.id));
  };

  const editOperationType = () => {
    dispatch(apiGetOperationType(operationType.id));
    setIsOperationTypeSubViewOpen(true);
    setOperationTypeAction(ActionStatus.EDIT);
  };

  const viewOperationType = () => {
    dispatch(apiGetOperationType(operationType.id));
    setIsOperationTypeSubViewOpen(true);
    setOperationTypeAction(ActionStatus.VIEW);
  };

  const getOperationTypeValuePrice = () => (
    operationType.price.amount_type.code === FIXED_AMOUNT_CODE
      ? `${operationType.price.amount} ${userCurrency.symbol}`
      : `${operationType.price.amount} %`
  );

  return {
    deleteOperationType,
    editOperationType,
    getOperationTypeValuePrice,
    viewOperationType,
  };
}
