import React from 'react';

import { AIRCRAFT_MODEL_LOGO } from 'src/constants/picturesPath';

interface Props {
  invoice: any;
}

export default function InvoicedTurnaroundAircraft({ invoice }: Props) {
  return (
    <div className="InvoicedTurnaroundAircraft">
      <img
        className="InvoicedTurnaroundAircfraftImg"
        src={AIRCRAFT_MODEL_LOGO}
        alt={AIRCRAFT_MODEL_LOGO}
      />
      <div className="InvoicedTurnaroundAircraftTypeAndRegistration">
        <p
          id={`InvoicedTurnaroundAircraftType${invoice.id}`}
          className="InvoicedTurnaroundAircraftType"
        >
          {invoice.aircraft_type}
        </p>
        <p
          id={`InvoicedTurnaroundRegistration${invoice.id}`}
          className="InvoicedTurnaroundRegistration"
        >
          {invoice.aircraft_registration}
        </p>
      </div>
    </div>
  );
}
