import React from 'react';

import {
  NEW_CONTRACT_FORM_SERVICES_CANCEL_CANCELLATION_RULE_FORM_BUTTON,
} from 'src/constants/componentTexts/CancellationRulesText';

import CancellationRuleFormCaracteristic from 'src/invoicing/services/cancellationRules/form/CancellationRuleFormCaracteristic';
import CancellationRuleFormPrice from 'src/invoicing/services/cancellationRules/form/CancellationRuleFormPrice';
import CancellationRuleFormPriceChoices from 'src/invoicing/services/cancellationRules/form/CancellationRuleFormPriceChoices';
import ErrorModal from 'src/uiComponents/modal/ErrorModal';
import ServicesFormButtons from 'src/invoicing/services/ServicesFormButtons';

import { CancelaltionRuleFilledInterface, CancellationRuleParametersInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';
import useCancellationRuleForm from 'src/invoicing/services/cancellationRules/form/useCancellationRuleForm';

interface Props {
  cancellationRuleFilled?: CancelaltionRuleFilledInterface | null;
  cancellationRuleParameters: CancellationRuleParametersInterface;
  contractId: number | null;
  setIsCancellationRuleSubViewOpen: Function;
  readOnly?: boolean;
}

export default function CancellationRuleForm(props: Props) {
  const {
    cancellationRuleFilled,
    cancellationRuleParameters,
    readOnly = false,
    setIsCancellationRuleSubViewOpen,
  } = props;

  const {
    cancellationRule,
    cancellationRuleResponseErrorMessage,
    getSendCancellationRuleButtoBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    setCancellationRule,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    submitCancellationRule,
  } = useCancellationRuleForm(props);

  return (
    <>
      <form className="ServicesForm" onSubmit={(event) => submitCancellationRule(event)}>
        <CancellationRuleFormCaracteristic
          cancellationRule={cancellationRule}
          cancellationRuleFilled={cancellationRuleFilled}
          cancellationRuleParameters={cancellationRuleParameters}
          readOnly={readOnly}
          setCancellationRule={setCancellationRule}
        />
        <CancellationRuleFormPriceChoices
          isFixedPriceChecked={isFixedPriceChecked}
          isVariablePriceChecked={isVariablePriceChecked}
          readOnly={readOnly}
          setIsFixedPriceChecked={setIsFixedPriceChecked}
          setIsVariablePriceChecked={setIsVariablePriceChecked}
        />
        <CancellationRuleFormPrice
          cancellationRule={cancellationRule}
          cancellationRuleFilled={cancellationRuleFilled}
          cancellationRuleParameters={cancellationRuleParameters}
          isFixedPriceChecked={isFixedPriceChecked}
          isVariablePriceChecked={isVariablePriceChecked}
          readOnly={readOnly}
          setCancellationRule={setCancellationRule}
        />
        <ServicesFormButtons
          cancelButtonText={NEW_CONTRACT_FORM_SERVICES_CANCEL_CANCELLATION_RULE_FORM_BUTTON}
          sendButtonText={getSendCancellationRuleButtoBasedOnUserAction()}
          setIsServicesSubViewOpen={setIsCancellationRuleSubViewOpen}
          isDisabled={readOnly}
        />
      </form>
      {isErrorModalOpen && (
        <ErrorModal
          setIsErrorModalOpen={setIsErrorModalOpen}
          errorMessage={cancellationRuleResponseErrorMessage}
        />
      )}
    </>
  );
}
