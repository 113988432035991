import { ChangeEvent } from 'react';

import { CUSTOM_SERVICE_TYPE, EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import { deepCopy } from 'src/utils/logic/copy';

interface Props {
  id: string;
  invoiceServices: any;
  isDescriptionOpen: boolean;
  service: any;
  serviceAmount: string;
  serviceLabel: string;
  services: any;
  setisDescriptionOpen: Function;
  setServices: Function;
  setServiceDescription: Function;
}

export default function useServiceDescription(props: Props) {
  const {
    id,
    invoiceServices,
    isDescriptionOpen,
    service,
    serviceAmount,
    serviceLabel,
    services,
    setisDescriptionOpen,
    setServices,
    setServiceDescription,
  } = props;

  const filledCustomServiceServiceDescriptionField = (servicesDescriptionCopy: any, descriptionValue: string | null) => {
    servicesDescriptionCopy.push({
      id: Number(service.id),
      description: descriptionValue,
      type: service.type,
      amount: serviceAmount,
      label: serviceLabel,
      status: service.status,
    });
  };

  const filledContractServiceServiceDescriptionField = (servicesDescriptionCopy: any, descriptionValue: string | null) => {
    servicesDescriptionCopy.push({
      id: Number(service.id),
      description: descriptionValue,
      type: service.type,
      status: service.status,
    });
  };

  const getServiceDescriptionIfChangedBefore = (servicesDescriptionCopy: any) => (
    servicesDescriptionCopy.find((description: { id: number; description: string, status: string }) => description?.id === Number(service.id))
  );

  const hadInvoiceServiceDescriptionBeforeChange = (serviceDescriptionFinded: any) => (
    invoiceServices.find((invoiceService: any) => ((Number(invoiceService.id) === serviceDescriptionFinded.id) && invoiceService.description))
  );

  const hasServiceDescriptionAndServiceSameValues = (servicesDescriptionCopy: any, indexOfExistingServiceDescription: number) => (
    !hadInvoiceServiceDescriptionBeforeChange === false && servicesDescriptionCopy[indexOfExistingServiceDescription].status === service.status
  );

  const withServiceDescriptionAlreadyChange = (servicesDescriptionCopy: any, serviceDescriptionFinded: any) => {
    const indexOfExistingServiceDescription = servicesDescriptionCopy.indexOf(serviceDescriptionFinded);

    if (hadInvoiceServiceDescriptionBeforeChange(serviceDescriptionFinded)) {
      servicesDescriptionCopy[indexOfExistingServiceDescription].description = null;
    } else if (hasServiceDescriptionAndServiceSameValues(servicesDescriptionCopy, indexOfExistingServiceDescription)) {
      servicesDescriptionCopy.splice(indexOfExistingServiceDescription, 1);
    } else {
      servicesDescriptionCopy[indexOfExistingServiceDescription].description = null;
    }
  };

  const serviceDescriptionWithEmptyValue = (servicesDescriptionCopy: any, serviceDescriptionFinded: any) => {
    if (serviceDescriptionFinded !== undefined) {
      withServiceDescriptionAlreadyChange(servicesDescriptionCopy, serviceDescriptionFinded);
    } else if (service.type === CUSTOM_SERVICE_TYPE) {
      filledCustomServiceServiceDescriptionField(servicesDescriptionCopy, null);
    } else {
      filledContractServiceServiceDescriptionField(servicesDescriptionCopy, null);
    }
    setisDescriptionOpen(!isDescriptionOpen);
  };

  const onExistingServiceDescriptionBlur = (descriptionValue: string) => {
    const servicesDescriptionCopy = deepCopy(services);
    const serviceDescriptionFinded = getServiceDescriptionIfChangedBefore(servicesDescriptionCopy);

    if (descriptionValue === EMPTY_VALUE) {
      serviceDescriptionWithEmptyValue(servicesDescriptionCopy, serviceDescriptionFinded);
    } else if (serviceDescriptionFinded !== undefined) {
      const indexOfExistingServiceDescription = servicesDescriptionCopy.indexOf(serviceDescriptionFinded);

      servicesDescriptionCopy[indexOfExistingServiceDescription].description = descriptionValue;
    } else if (service.type === CUSTOM_SERVICE_TYPE) {
      filledCustomServiceServiceDescriptionField(servicesDescriptionCopy, descriptionValue);
    } else {
      filledContractServiceServiceDescriptionField(servicesDescriptionCopy, descriptionValue);
    }
    setServices(servicesDescriptionCopy);
  };

  const onExistingServiceDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    setServiceDescription(event.target.value);
    onExistingServiceDescriptionBlur(event.target.value);
  };

  const onDescriptionClick = () => {
    setisDescriptionOpen(!isDescriptionOpen);
  };

  const onCustomServiceDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const customServicesCopy = deepCopy(services);
    customServicesCopy[id].description = null;

    if (event.target.value === EMPTY_VALUE) {
      setisDescriptionOpen(!isDescriptionOpen);
    } else {
      customServicesCopy[id].description = event.target.value;
    }

    setServiceDescription(event.target.value);
    setServices(customServicesCopy);
  };

  return {
    getServiceDescriptionIfChangedBefore,
    onCustomServiceDescriptionChange,
    onExistingServiceDescriptionChange,
    onDescriptionClick,
  };
}
