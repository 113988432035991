export interface OperationTypeReceivedInterface {
  id: number;
  operation_name: { id: number; label: string; };
  price: {
    amount_type: { code: string; label: string };
    amount: string;
  },
}

export interface PriceInterface {
  amount_type: string | null;
  amount: string | null;
}

export interface OperationTypeSentInterface {
  contract: number | null;
  operation_name: number | null;
  price: {
    amount_type: string | null;
    amount: string | null;
  },
}

export const defaultOperationType: OperationTypeSentInterface = {
  contract: null,
  operation_name: null,
  price: {
    amount_type: null,
    amount: null,
  },
};

export interface OperationTypeFilledInterface {
  id: number;
  operation_name: { id: number; label: string; };
  price: {
    amount_type: { code: string; label: string };
    amount: string | null;
  }
}

interface AmountTypeInterface {
  code: string;
  label: string;
}

interface OperationNameInterface {
  id: number;
  label: string;
}

interface CurrencyInterface {
  code: string;
  label: string;
  symbol: string
}
export interface OperationTypeParametersInterface {
  amount_types : AmountTypeInterface[];
  operation_types: OperationNameInterface[];
  currency: CurrencyInterface;
}
