import React from 'react';

import { stepperText } from 'src/constants/componentTexts/StepperText';

import 'src/uiComponents/stepper/Stepper.css';

interface Props {
  actualContractByStep: number;
  setCurrentContractByStepType: Function;
  contractByStepsFilled: boolean[];
}

export default function Stepper(props: Props) {
  const {
    actualContractByStep,
    contractByStepsFilled,
    setCurrentContractByStepType,
  } = props;

  const setActualByStepTextClassName = (stepperStep: number) => (
    actualContractByStep === stepperStep ? 'activeStepText' : ''
  );

  const setActualByStepBubbleClassName = (stepperStep: number) => (
    actualContractByStep === stepperStep ? 'activeStepBubble' : ''
  );

  const disableOnClickIfStepContractScope = (actualStep: number, contractByStepType: string) => {
    const contractStep: number = 1;
    const basePriceStep: number = 2;

    return (actualStep !== contractStep && actualStep !== basePriceStep) ? () => setCurrentContractByStepType(contractByStepType) : () => {};
  };

  const setBubbleClassNameToBlueIfFilled = (actualStep: number) => (
    (actualStep !== actualContractByStep && contractByStepsFilled[actualStep - 1]) ? 'filledBubble' : ''
  );

  return (
    <div className="StepperWrapper">
      <div className="StepperWrapperLine" />
      <div className="StepperWrapperBubblesWrapper">
        <div className="StepperWrapperBubblesWrapperBubbleWrapper">
          <p
            className={`StepperWrapperBubblesWrapperBubbleWrapperText disable 
            ${setActualByStepTextClassName(stepperText.contractScopeStepNumber)}`}
          >
            {stepperText.contractScope}
          </p>
          <div className={`StepperWrapperBubblesWrapperBubbleWrapperBubble 
            ${setActualByStepBubbleClassName(stepperText.contractScopeStepNumber)} 
            ${setBubbleClassNameToBlueIfFilled(stepperText.contractScopeStepNumber)}`}
          />
        </div>
        <div className="StepperWrapperBubblesWrapperBubbleWrapper">
          <p
            onClick={disableOnClickIfStepContractScope(actualContractByStep, 'basePriceByStep')}
            aria-hidden="true"
            className={`StepperWrapperBubblesWrapperBubbleWrapperText disable
            ${setActualByStepTextClassName(stepperText.basePriceStepNumber)}`}
          >
            {stepperText.basePrice}
          </p>
          <div className={`StepperWrapperBubblesWrapperBubbleWrapperBubble 
            ${setActualByStepBubbleClassName(stepperText.basePriceStepNumber)} 
            ${setBubbleClassNameToBlueIfFilled(stepperText.basePriceStepNumber)}`}
          />
        </div>
        <div className="StepperWrapperBubblesWrapperBubbleWrapper">
          <p
            onClick={disableOnClickIfStepContractScope(actualContractByStep, 'operationTypeByStep')}
            aria-hidden="true"
            className={`StepperWrapperBubblesWrapperBubbleWrapperText 
            ${setActualByStepTextClassName(stepperText.operationTypeStepNumber)}`}
          >
            {stepperText.operationTypes}
          </p>
          <div className={`StepperWrapperBubblesWrapperBubbleWrapperBubble 
            ${setActualByStepBubbleClassName(stepperText.operationTypeStepNumber)} 
            ${setBubbleClassNameToBlueIfFilled(stepperText.operationTypeStepNumber)}`}
          />
        </div>
        <div className="StepperWrapperBubblesWrapperBubbleWrapper">
          <p
            onClick={disableOnClickIfStepContractScope(actualContractByStep, 'cancellationRulesByStep')}
            aria-hidden="true"
            className={`StepperWrapperBubblesWrapperBubbleWrapperText 
            ${setActualByStepTextClassName(stepperText.cancellationRulesStepNumber)}`}
          >
            {stepperText.cancellationRules}
          </p>
          <div className={`StepperWrapperBubblesWrapperBubbleWrapperBubble 
            ${setActualByStepBubbleClassName(stepperText.cancellationRulesStepNumber)} 
            ${setBubbleClassNameToBlueIfFilled(stepperText.cancellationRulesStepNumber)}`}
          />
        </div>
        <div className="StepperWrapperBubblesWrapperBubbleWrapper">
          <p
            onClick={disableOnClickIfStepContractScope(actualContractByStep, 'delayRulesByStep')}
            aria-hidden="true"
            className={`StepperWrapperBubblesWrapperBubbleWrapperText 
            ${setActualByStepTextClassName(stepperText.delayRulesStepNumber)}`}
          >
            {stepperText.delayRules}
          </p>
          <div className={`StepperWrapperBubblesWrapperBubbleWrapperBubble 
            ${setActualByStepBubbleClassName(stepperText.delayRulesStepNumber)} 
            ${setBubbleClassNameToBlueIfFilled(stepperText.delayRulesStepNumber)}`}
          />
        </div>
        <div className="StepperWrapperBubblesWrapperBubbleWrapper">
          <p
            onClick={disableOnClickIfStepContractScope(actualContractByStep, 'additionalServicesByStep')}
            aria-hidden="true"
            className={`StepperWrapperBubblesWrapperBubbleWrapperText 
            ${setActualByStepTextClassName(stepperText.additionalServicesStepNumber)}`}
          >
            {stepperText.additionalServices}
          </p>
          <div className={`StepperWrapperBubblesWrapperBubbleWrapperBubble 
            ${setActualByStepBubbleClassName(stepperText.additionalServicesStepNumber)} 
            ${setBubbleClassNameToBlueIfFilled(stepperText.additionalServicesStepNumber)}`}
          />
        </div>
      </div>
    </div>
  );
}
