import React from 'react';

import { InvoicesSortStatus } from 'src/constants/status';
import { SORT_LOGO } from 'src/constants/picturesPath';

import useInvoicesContext from 'src/invoicing/invoices/useInvoicesContext';

interface Props {
  id: string;
  invoiceSortStatusAscending: InvoicesSortStatus,
  invoiceSortStatusDescending: InvoicesSortStatus
  text: string;
}

export default function InvoiceTableColumnHeader(props: Props) {
  const {
    id,
    invoiceSortStatusAscending,
    invoiceSortStatusDescending,
    text,
  } = props;

  const { invoicesSortStatus, setInvoicesSortStatus } = useInvoicesContext();

  const setInvoiceSortStatus = () => {
    if (invoicesSortStatus === invoiceSortStatusDescending) {
      return setInvoicesSortStatus(invoiceSortStatusAscending);
    }
    return setInvoicesSortStatus(invoiceSortStatusDescending);
  };

  return (
    <th
      id={id}
      className={`InvoicesTableTitle ${text}`}
      onClick={setInvoiceSortStatus}
    >
      {text}
      <img src={SORT_LOGO} alt="sortIcon" className="InvoicesTableSortIcon" />
    </th>
  );
}
