import React, { useEffect } from 'react';
import NumberInput from 'src/uiComponents/input/NumberInput';

interface Props {
  amountTypeCode: string;
  service: any;
  serviceCurrency: string;
  serviceFilledPrice?: any;
  serviceName: string;
  serviceText: any;
  setService: Function;
  typeOfPrice: string;
  readOnly: boolean;
}

export default function SinglePrice(props: Props) {
  const {
    amountTypeCode,
    service,
    serviceCurrency,
    serviceFilledPrice,
    serviceText,
    setService,
    typeOfPrice,
    readOnly,
  } = props;

  useEffect(() => {
    setService({ ...service, amount_type: amountTypeCode });
  }, []);

  return (
    <div className="ServicesBoxInputOperationTypeFixedPrice">
      <p
        className="FormTextFontSize ServicesBoxInputCurrencyText"
        id={`${typeOfPrice}CurrencyTextId`}
      >
        {serviceCurrency}
      </p>
      <NumberInput
        inputLabel={serviceText.price}
        isRequired
        formObjectKey="amount"
        setFormObject={setService}
        formObject={service}
        formObjectPreviousFilledValue={serviceFilledPrice.amount}
        isReadOnly={readOnly}
        amountTypeCode={amountTypeCode}
      />
    </div>
  );
}
