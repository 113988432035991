import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  invoicingDeleteApiRequestAsyncThunk,
  invoicingGetApiRequestAsyncThunk, invoicingPatchApiRequestAsyncThunk, invoicingPostApiRequestAsyncThunk,
} from 'src/invoicing/api/invoicingCustomApiRequestLogic';

export const apiGetContractDelayRules = createAsyncThunk('contract/apiGetContractDelayRules', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/delay_rules` })
));

export const apiGetContractDelayRuleParameters = createAsyncThunk('contract/apiGetContractDelayRuleParameters', async (contractId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/contract/${contractId}/delay_rule_parameters` })
));

export const apiGetDelayRule = createAsyncThunk('contract/apiGetDelayRule', async (delayRuleId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingGetApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/delay_rule/${delayRuleId}` })
));

export const apiPostDelayRule = createAsyncThunk('contract/apiPostDelayRule', async (body: any, { dispatch, getState, rejectWithValue }) => (
  invoicingPostApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: '/delay_rule', body })
));

export const apiPatchDelayRule = createAsyncThunk('contract/apiPatchDelayRule', async (body: { delayRule: any, delayRuleId: number }, { dispatch, getState, rejectWithValue }) => (
  invoicingPatchApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/delay_rule/${body.delayRuleId}`, body: body.delayRule })
));

export const apiDeleteDelayRule = createAsyncThunk('contract/apiDeleteDelayRule', async (delayRuleId: number, { dispatch, getState, rejectWithValue }) => (
  invoicingDeleteApiRequestAsyncThunk({ thunkApi: { dispatch, getState, rejectWithValue }, url: `/delay_rule/${delayRuleId}` })
));
