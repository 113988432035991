import { VALIDATED_STATUS } from 'src/constants/componentTexts/InvoiceAppText';
import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';
import { Status } from 'src/constants/status';

import { InvoiceModel } from 'src/invoicing/invoices/InvoicesInterface';
import { isNumberHasMaxTwoDecimals } from 'src/utils/logic/regex/regex';

export const isInvoicesLoading = (getInvoicesStatus: string) => (
  getInvoicesStatus === Status.LOADING
);

export const hasInvoicesToDisplay = (invoices: InvoiceModel[]) => (
  invoices.length !== 0
);

export const isSubmitFiltersDisabled = (dateFilters: { fromDate: string, toDate: string }) => (
  dateFilters.fromDate === EMPTY_VALUE || dateFilters.toDate === EMPTY_VALUE
);

export const getInvoicesToDownloadBasedOnCheckboxValue = (invoicesToDownload: InvoiceModel[], invoice: InvoiceModel, checkboxValue: boolean) => {
  const invoiceToRetrieved = invoicesToDownload.find((invoiceToFind: InvoiceModel) => invoice.id === invoiceToFind.id);

  if (!checkboxValue === true) {
    if (invoiceToRetrieved === undefined) {
      const invoicesToDownloadCopy = [...invoicesToDownload];

      invoicesToDownloadCopy.push(invoice);

      return invoicesToDownloadCopy;
    }
  } else if (!checkboxValue === false) {
    if (invoiceToRetrieved !== undefined) {
      const invoicesToDownloadCopy = invoicesToDownload.filter((invoiceToKeep: InvoiceModel) => invoiceToKeep.id !== invoiceToRetrieved.id);

      return invoicesToDownloadCopy;
    }
  }
  return invoicesToDownload;
};

export const hasInvoiceValidatedStatus = (invoiceStatus: { code: string, label: string } | null) => (
  invoiceStatus?.code === VALIDATED_STATUS.code
);

export const getInvoicesToDownloadWhenAllSelectedOrNot = (invoices: InvoiceModel[], areInvoicesToValidate: boolean) => {
  if (!areInvoicesToValidate === true) {
    const invoicesWithOnlyValidatedStatus = invoices.filter((invoicesToFind: InvoiceModel) => invoicesToFind?.status?.code === VALIDATED_STATUS.code);

    if (invoicesWithOnlyValidatedStatus !== undefined) {
      return [...invoicesWithOnlyValidatedStatus];
    }
  }
  return [];
};

export const displayAmountWithTwoDecimalIfDecimal = (amount: number | null) => {
  if (amount) {
    const amountSplitted = amount.toString().split('.');

    if (amountSplitted[1] && amountSplitted[1].length === 1) {
      return `${amount}0`;
    }
    return amount.toString();
  }
  return EMPTY_VALUE;
};

export const isAmountValidWithMax2Decimals = (amount: string) => (
  isNumberHasMaxTwoDecimals(amount) ? amount : EMPTY_VALUE
);

export const hasInvoicesToDownload = (invoicesToDownload: InvoiceModel[]) => (
  invoicesToDownload.length === 0
);

export const refreshInvoicesWithReceivedInvoice = (invoice: any, invoices: any) => {
  const researchedInvoice: any = invoices.find((invoiceToSearch: any) => invoiceToSearch.id.toString() === invoice.id.toString());
  const indexOfResearchedInvoice: number = invoices.findIndex((invoiceToSearch: any) => invoiceToSearch.id.toString() === invoice.id.toString());

  if (researchedInvoice !== undefined) {
    const invoicesCopy: any = [...invoices];

    invoicesCopy[indexOfResearchedInvoice] = { ...researchedInvoice, status: invoice.status, amount: invoice.amount };

    return invoicesCopy;
  }
  return invoices;
};
