import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/constants/status';

export default function useAdditionalServiceSubmittionRequestStatus(submitionStatusSelector: any, setIsAdditionalSubViewOpen: Function) {
  const patchAdditionalServiceStatus: string = useSelector(submitionStatusSelector);

  useEffect(() => {
    if (patchAdditionalServiceStatus === Status.SUCCESS) {
      setIsAdditionalSubViewOpen(false);
    }
  }, [patchAdditionalServiceStatus]);
}
