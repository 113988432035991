import React from 'react';

import { ActionStatus } from 'src/constants/status';
import { CANCELLATION_RULES_STEP_NUMBER } from 'src/constants/componentTexts/CancellationRulesText';

import CancellationRulesEditor from 'src/invoicing/services/cancellationRules/CancellationRulesEditor';
import ContractByStepNavigationButtons from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepNavigationButtons';
import EditCancellationRule from 'src/invoicing/services/cancellationRules/EditCancellationRule';
import NewCancellationRule from 'src/invoicing/services/cancellationRules/NewCancellationRule';
import Stepper from 'src/uiComponents/stepper/Stepper';
import ViewCancellationRule from 'src/invoicing/services/cancellationRules/ViewCancellationRule';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';
import useCancellationRulesByStep from 'src/invoicing/contractAndServicesByStep/servicesByStep/cancellationRulesByStep/useCancellationRulesByStep';

interface Props {
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  nextComponent: string | null;
  previousComponent: string | null;
  setCurrentContractByStepType: Function;
  setIsContractByStepManagerOpen: Function;
  setcontractByStepsFilled: Function;
  storedContract: StoredContract;
}

export default function CancellationRulesByStep(props: Props) {
  const {
    contractByStepsFilled,
    currentContractByStepType,
    storedContract,
    nextComponent,
    previousComponent,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    setcontractByStepsFilled,
  } = props;

  const {
    cancellationRuleAction,
    contractAction,
    isCancellationRuleSubViewOpen,
    setCancellationRuleAction,
    setIsCancellationRuleSubViewOpen,
    submitCancellationRules,
  } = useCancellationRulesByStep(props);

  const displayCancellationRuleSubViewActions = () => {
    switch (cancellationRuleAction) {
      case ActionStatus.EDIT:
        return (
          <EditCancellationRule
            contractId={storedContract.id}
            setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
          />
        );
      case ActionStatus.VIEW:
        return (
          <ViewCancellationRule
            contractId={storedContract.id}
            setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
          />
        );
      default:
        return (
          <NewCancellationRule
            contractId={storedContract.id}
            setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
          />
        );
    }
  };

  return (
    <>
      <Stepper
        actualContractByStep={CANCELLATION_RULES_STEP_NUMBER}
        setCurrentContractByStepType={setCurrentContractByStepType}
        contractByStepsFilled={contractByStepsFilled}
      />
      {isCancellationRuleSubViewOpen ? (
        displayCancellationRuleSubViewActions()
      ) : (
        <form onSubmit={(event) => submitCancellationRules(event)}>
          <CancellationRulesEditor
            contractId={storedContract.id}
            isCancellationRuleSubViewOpen={isCancellationRuleSubViewOpen}
            setIsCancellationRuleSubViewOpen={setIsCancellationRuleSubViewOpen}
            contractByStepsFilled={contractByStepsFilled}
            setcontractByStepsFilled={setcontractByStepsFilled}
            setCancellationRuleAction={setCancellationRuleAction}
          />
          <ContractByStepNavigationButtons
            contractAction={contractAction}
            currentContractByStepType={currentContractByStepType}
            nextComponent={nextComponent}
            previousComponent={previousComponent}
            setCurrentContractByStepType={setCurrentContractByStepType}
            setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
          />
        </form>
      )}
    </>
  );
}
