import React from 'react';

import { SAVE_LOGO } from 'src/constants/picturesPath';

interface Props {
  id: string;
  className: string;
}

export default function SaveButton(props: Props) {
  const { className, id } = props;

  return (
    <input
      id={id}
      src={SAVE_LOGO}
      alt={SAVE_LOGO}
      className={className}
      type="image"
    />
  );
}
