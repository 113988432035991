import React from 'react';

import {
  delayRuleFixedPriceText,
  delayRuleVariablePriceText,
} from 'src/constants/componentTexts/DelayRulesText';
import { FIXED_AMOUNT_CODE, PERCENTAGE_OF_BASE_PRICE_CODE } from 'src/constants/componentTexts/ServiceText';

import FixedPrice from 'src/invoicing/services/price/fixedPrice/FixedPrice';
import VariablePrice from 'src/invoicing/services/price/variablePrice/VariablePrice';

import { DelayRuleSentInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';
import { getPriceStepsBasedOnUserAmountTypePicked } from 'src/invoicing/services/ServicesLogic';

interface Props {
  delayRule: DelayRuleSentInterface;
  delayRuleFilled: any | null;
  delayRuleParameters: any;
  isFixedPriceChecked: boolean;
  isVariablePriceChecked: boolean;
  readOnly: boolean;
  setDelayRule: Function;
}

export default function DelayRuleFormPrice(props: Props) {
  const {
    delayRule,
    delayRuleFilled,
    delayRuleParameters,
    isFixedPriceChecked,
    isVariablePriceChecked,
    readOnly,
    setDelayRule,
  } = props;

  return (
    <div className="ServicesBox">
      {isFixedPriceChecked && (
        <FixedPrice
          service={delayRule}
          serviceCurrency={delayRuleParameters.currency.symbol}
          serviceName="NewDelayRule"
          serviceText={delayRuleFixedPriceText}
          setService={setDelayRule}
          serviceFilledPrice={getPriceStepsBasedOnUserAmountTypePicked(delayRuleFilled, FIXED_AMOUNT_CODE)}
          readOnly={readOnly}
          valuesTypes={delayRuleParameters?.value_types[0]?.id}
        />
      )}
      {isVariablePriceChecked && (
        <VariablePrice
          service={delayRule}
          serviceCurrency={delayRuleVariablePriceText.variablePriceMoney}
          serviceName="NewDelayRule"
          serviceText={delayRuleVariablePriceText}
          setService={setDelayRule}
          serviceFilledPrice={getPriceStepsBasedOnUserAmountTypePicked(delayRuleFilled, PERCENTAGE_OF_BASE_PRICE_CODE)}
          readOnly={readOnly}
          valuesTypes={delayRuleParameters?.value_types[0]?.id}
        />
      )}
    </div>
  );
}
