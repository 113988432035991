import React from 'react';

import {
  cancellationRuleFixedPriceText,
  cancellationRuleVariablePriceText,
} from 'src/constants/componentTexts/CancellationRulesText';
import { FIXED_AMOUNT_CODE, PERCENTAGE_OF_BASE_PRICE_CODE } from 'src/constants/componentTexts/ServiceText';

import FixedPrice from 'src/invoicing/services/price/fixedPrice/FixedPrice';
import VariablePrice from 'src/invoicing/services/price/variablePrice/VariablePrice';

import { CancellationRuleSentInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';
import { getPriceStepsBasedOnUserAmountTypePicked } from 'src/invoicing/services/ServicesLogic';

interface Props {
  cancellationRule: CancellationRuleSentInterface;
  cancellationRuleFilled: any | null;
  cancellationRuleParameters: any;
  isFixedPriceChecked: boolean;
  isVariablePriceChecked: boolean;
  readOnly: boolean;
  setCancellationRule: Function;
}

export default function CancellationRuleFormPrice(props: Props) {
  const {
    cancellationRule,
    cancellationRuleFilled,
    cancellationRuleParameters,
    isFixedPriceChecked,
    isVariablePriceChecked,
    readOnly,
    setCancellationRule,
  } = props;

  return (
    <div className="ServicesBox">
      {isFixedPriceChecked && (
        <FixedPrice
          service={cancellationRule}
          serviceCurrency={cancellationRuleParameters.currency.symbol}
          serviceName="NewCancellationRule"
          serviceText={cancellationRuleFixedPriceText}
          setService={setCancellationRule}
          serviceFilledPrice={getPriceStepsBasedOnUserAmountTypePicked(cancellationRuleFilled, FIXED_AMOUNT_CODE)}
          readOnly={readOnly}
          valuesTypes={cancellationRuleParameters?.value_types[0]?.id}
        />
      )}
      {isVariablePriceChecked && (
        <VariablePrice
          service={cancellationRule}
          serviceCurrency={cancellationRuleVariablePriceText.variablePriceMoney}
          serviceName="NewCancellationRule"
          serviceText={cancellationRuleVariablePriceText}
          setService={setCancellationRule}
          serviceFilledPrice={getPriceStepsBasedOnUserAmountTypePicked(cancellationRuleFilled, PERCENTAGE_OF_BASE_PRICE_CODE)}
          readOnly={readOnly}
          valuesTypes={cancellationRuleParameters?.value_types[0]?.id}
        />
      )}
    </div>
  );
}
