import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionStatus, ContractStatus } from 'src/constants/status';
import { contractStatusClassNames } from 'src/constants/componentTexts/ContractText';

import { apiGetContract } from 'src/invoicing/contract/ContractApiRequest';

interface Props {
  contract: any;
  setContractAction: Function;
  setIsNewContractOpen: Function;
}

export default function useContract(props: Props) {
  const { contract, setContractAction, setIsNewContractOpen } = props;

  const dispatch = useDispatch();

  const [statusClassName, setStatusClassName] = useState<string>('');

  const setContractBackgroundColorBasedOnContractStatus = (contractStatus: string) => {
    switch (contractStatus) {
      case ContractStatus.PAST:
        return setStatusClassName(contractStatusClassNames[ContractStatus.PAST]);
      case ContractStatus.IN_PROGRESS:
        return setStatusClassName(contractStatusClassNames[ContractStatus.IN_PROGRESS]);
      case ContractStatus.FUTURE:
        return setStatusClassName(contractStatusClassNames[ContractStatus.FUTURE]);
      default:
        return setStatusClassName('');
    }
  };

  useEffect(() => {
    setContractBackgroundColorBasedOnContractStatus(contract.status);
  }, [contract]);

  const editContract = () => {
    dispatch(apiGetContract(contract.id));
    setContractAction(ActionStatus.EDIT);
    setIsNewContractOpen(true);
  };

  const viewContract = () => {
    dispatch(apiGetContract(contract.id));
    setContractAction(ActionStatus.VIEW);
    setIsNewContractOpen(true);
  };

  const duplicateContract = () => {
    dispatch(apiGetContract(contract.id));
    setContractAction(ActionStatus.DUPLICATE);
    setIsNewContractOpen(true);
  };

  const getAircraftModelsFontSizebasedOnAircraftModelsLength = (aicraftModels: { id: number, label: string }[]) => {
    if (aicraftModels.length <= 2) {
      return 'normalFontSize';
    }
    if (aicraftModels.length === 3) {
      return 'smallerFontSize';
    }
    return 'smallFontSize';
  };

  return {
    duplicateContract,
    editContract,
    getAircraftModelsFontSizebasedOnAircraftModelsLength,
    statusClassName,
    viewContract,
  };
}
