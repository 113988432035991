import React from 'react';

import { ERROR_DELETE_INVOICE_SERVICE_MESSAGE } from 'src/constants/componentTexts/ModalText';

import CloseButton from 'src/uiComponents/buttons/CloseButton';
import ErrorModal from 'src/uiComponents/modal/ErrorModal';
import InvoicedDetail from 'src/invoicing/invoiceDetail/InvoicedDetail';
import InvoiceDetailTitle from 'src/invoicing/invoiceDetail/InvoiceDetailTitle';
import Loader from 'src/uiComponents/loader/Loader';
import SaveButton from 'src/uiComponents/buttons/SaveButton';

import useInvoiceDetail from 'src/invoicing/invoiceDetail/useInvoiceDetail';

import 'src/invoicing/invoiceDetail/InvoiceDetail.css';

interface Props {
  setIsInvoiceDetailOpen: Function;
}

export default function InvoiceDetail(props: Props) {
  const { setIsInvoiceDetailOpen } = props;

  const {
    customServices,
    hasCustomServicesOrServicesDescription,
    hasInvoiceStatusChangedBasedOnInvoiceStatusReceived,
    invoice,
    invoiceValidated,
    isErrorModalOpen,
    isInvoiceLoading,
    onInvoiceDetailClose,
    servicesDescription,
    setCustomServices,
    setIsErrorModalOpen,
    setServicesDescription,
    submitInvoiceDetail,
    toggleValidateInvoice,
  } = useInvoiceDetail(setIsInvoiceDetailOpen);

  return (
    <>
      <form className="InvoiceDetail" onClick={onInvoiceDetailClose} onSubmit={submitInvoiceDetail} aria-hidden="true">
        <div className="InvoiceDetailContent" onClick={(e) => e.stopPropagation()} aria-hidden="true">
          <InvoiceDetailTitle />
          {isInvoiceLoading() ? (
            <Loader className="NewContractAppContentOperationTypesLoader" />
          ) : (
            <>
              <div className="InvoiceDetailContentActions">
                {(hasCustomServicesOrServicesDescription() || hasInvoiceStatusChangedBasedOnInvoiceStatusReceived()) && (
                  <SaveButton
                    className="InvoiceDetailContentSaveButton"
                    id={`InvoiceDetailContentSave${invoice.id}`}
                  />
                )}
                <CloseButton
                  className="InvoiceDetailContentCloseButton"
                  id={`InvoiceDetailContentClose${invoice.id}`}
                  onClick={onInvoiceDetailClose}
                />
              </div>
              <InvoicedDetail
                customServices={customServices}
                invoice={invoice}
                invoiceValidated={invoiceValidated}
                servicesDescription={servicesDescription}
                setCustomServices={setCustomServices}
                setServicesDescription={setServicesDescription}
                toggleValidateInvoice={toggleValidateInvoice}
              />
            </>
          )}
        </div>
      </form>
      {isErrorModalOpen && <ErrorModal errorMessage={ERROR_DELETE_INVOICE_SERVICE_MESSAGE} setIsErrorModalOpen={setIsErrorModalOpen} />}
    </>
  );
}
