import React from 'react';

import { DATE_EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceAppText';

import InvoiceRow from 'src/invoicing/invoices/InvoiceRow';

import { convertStringToDDMMYYYYUTCDate } from 'src/utils/logic/converter/dateConvertor';

export default function InvoiceDate(props: { invoiceId: string, invoiceDate: string | null, rowId: string }) {
  const { invoiceId, invoiceDate, rowId } = props;

  return (
    <InvoiceRow
      invoiceId={invoiceId}
      rowId={rowId}
      value={invoiceDate ? convertStringToDDMMYYYYUTCDate(invoiceDate) : DATE_EMPTY_VALUE}
    />
  );
}
