import React from 'react';

import {
  NEW_CONTRACT_FORM_SERVICES_CANCEL_DELAY_RULE_FORM_BUTTON,
} from 'src/constants/componentTexts/DelayRulesText';

import DelayRuleFormCaracteristic from 'src/invoicing/services/delayRules/form/DelayRuleFormCaracteristic';
import DelayRuleFormPrice from 'src/invoicing/services/delayRules/form/DelayRuleFormPrice';
import DelayRuleFromPriceChoices from 'src/invoicing/services/delayRules/form/DelayRuleFormPriceChoices';
import ErrorModal from 'src/uiComponents/modal/ErrorModal';
import ServicesFormButtons from 'src/invoicing/services/ServicesFormButtons';

import { DelayRuleFilledInterface, DelayRuleParametersInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';
import useDelayRuleForm from 'src/invoicing/services/delayRules/form/useDelayruleForm';

interface Props {
  contractId: number | null;
  delayRuleFilled?: DelayRuleFilledInterface | null;
  delayRuleParameters: DelayRuleParametersInterface;
  setIsDelaySubViewOpen: Function;
  readOnly?: boolean;
}

export default function DelayRuleForm(props: Props) {
  const {
    delayRuleFilled, delayRuleParameters, setIsDelaySubViewOpen, readOnly = false,
  } = props;

  const {
    delayRule,
    delayRuleResponseErrorMessage,
    getSendDelayRuleTextButtonBasedOnUserAction,
    isErrorModalOpen,
    isFixedPriceChecked,
    isVariablePriceChecked,
    setDelayRule,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    setIsVariablePriceChecked,
    submitDelayRule,
  } = useDelayRuleForm(props);

  return (
    <>
      <form className="ServicesForm" onSubmit={(event) => submitDelayRule(event)}>
        <DelayRuleFormCaracteristic
          delayRule={delayRule}
          delayRuleFilled={delayRuleFilled}
          delayRuleParameters={delayRuleParameters}
          readOnly={readOnly}
          setDelayRule={setDelayRule}
        />
        <DelayRuleFromPriceChoices
          isFixedPriceChecked={isFixedPriceChecked}
          isVariablePriceChecked={isVariablePriceChecked}
          readOnly={readOnly}
          setIsFixedPriceChecked={setIsFixedPriceChecked}
          setIsVariablePriceChecked={setIsVariablePriceChecked}
        />
        <DelayRuleFormPrice
          delayRule={delayRule}
          delayRuleFilled={delayRuleFilled}
          delayRuleParameters={delayRuleParameters}
          isFixedPriceChecked={isFixedPriceChecked}
          isVariablePriceChecked={isVariablePriceChecked}
          readOnly={readOnly}
          setDelayRule={setDelayRule}
        />
        <ServicesFormButtons
          cancelButtonText={NEW_CONTRACT_FORM_SERVICES_CANCEL_DELAY_RULE_FORM_BUTTON}
          sendButtonText={getSendDelayRuleTextButtonBasedOnUserAction()}
          setIsServicesSubViewOpen={setIsDelaySubViewOpen}
          isDisabled={readOnly}
        />
      </form>
      {isErrorModalOpen && (
        <ErrorModal
          setIsErrorModalOpen={setIsErrorModalOpen}
          errorMessage={delayRuleResponseErrorMessage}
        />
      )}
    </>
  );
}
