import React from 'react';

import { ContractStatus } from 'src/constants/status';
import { IN_PROGRESS_LOGO, PAST_FUTURE_LOGO } from 'src/constants/picturesPath';

import { ContractFilters, ContractsObject, defaultContractFilters } from 'src/invoicing/contract/ContractInterface';

import useContractsStatusFilters from 'src/invoicing/contract/useContractsStatusFilters';

import StatusFilter from 'src/uiComponents/filters/StatusFilter';

interface Props {
  contractsFilters: ContractFilters;
  contractsFiltered: ContractsObject[];
  setContractsFilters: Function;
}

export default function ContractStatusFilters(props: Props) {
  const {
    contractsFilters,
    contractsFiltered,
    setContractsFilters,
  } = props;

  const {
    totalOfFutureContracts,
    totalOfInProgressContracts,
    totalOfPastContracts,
  } = useContractsStatusFilters(contractsFiltered);

  return (
    <div className="StatusFilterContainer">
      <StatusFilter
        defaultElementsFilter={defaultContractFilters}
        elementsFilters={contractsFilters}
        filterKey="past"
        filterIcon={PAST_FUTURE_LOGO}
        setElementsFilters={setContractsFilters}
        title={ContractStatus.PAST}
        totalOfElements={totalOfPastContracts}
      />
      <StatusFilter
        defaultElementsFilter={defaultContractFilters}
        elementsFilters={contractsFilters}
        filterKey="inProgress"
        filterIcon={IN_PROGRESS_LOGO}
        setElementsFilters={setContractsFilters}
        title={ContractStatus.IN_PROGRESS}
        totalOfElements={totalOfInProgressContracts}
      />
      <StatusFilter
        defaultElementsFilter={defaultContractFilters}
        elementsFilters={contractsFilters}
        filterKey="future"
        filterIcon={PAST_FUTURE_LOGO}
        setElementsFilters={setContractsFilters}
        title={ContractStatus.FUTURE}
        totalOfElements={totalOfFutureContracts}
      />
    </div>
  );
}
