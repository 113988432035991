import React, { createContext, ReactNode, useMemo } from 'react';
import { InvoicesSortStatus } from 'src/constants/status';

interface InvoicesContextModel {
  invoicesSortStatus: InvoicesSortStatus;
  setInvoicesSortStatus: Function;
}

export const InvoicesContext = createContext<InvoicesContextModel | null>(null);

interface Props extends InvoicesContextModel { children: ReactNode }

export function InvoicesContextProvider({ children, ...rest }: Props) {
  const defaultContextValues = useMemo(() => ({ ...rest }), [{ ...rest }]);
  return (
    <InvoicesContext.Provider value={defaultContextValues}>
      {children}
    </InvoicesContext.Provider>
  );
}
