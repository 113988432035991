import { ContractStatus } from 'src/constants/status';

import { ContractFilters, ContractsObject } from 'src/invoicing/contract/ContractInterface';

export default function useFilterContracts() {
  const getContractsFilteredByCustomer = (contractsFiltered: ContractsObject[], contractsFilters: ContractFilters) => (
    contractsFilters.customer ? contractsFiltered.filter((contract: any) => contract.customer.id === contractsFilters.customer) : contractsFiltered
  );

  const getContractsFilteredByProvider = (contractsFiltered: ContractsObject[], contractsFilters: ContractFilters) => (
    contractsFilters.provider ? contractsFiltered.filter((contract: any) => contract.provider.id === contractsFilters.provider) : contractsFiltered
  );

  const getContractsFilteredByAircraftModel = (contractsFiltered: ContractsObject[], contractsFilters: ContractFilters) => (
    contractsFilters.aircraft_model ? contractsFiltered.filter((contract: any) => (
      contract.aircraft_models.find((aircraftModel: any) => aircraftModel.id === contractsFilters.aircraft_model)
    )) : contractsFiltered
  );

  const filterContractsByStatus = (contract: ContractsObject, isStatusFilterOn: boolean, statusEnum: ContractStatus) => (
    isStatusFilterOn && contract.status === statusEnum
  );

  const getContractsFilteredByAirport = (contractsFiltered: ContractsObject[], contractsFilters: ContractFilters) => (
    contractsFilters.airport ? contractsFiltered.filter((contract: any) => contract.airport.code === contractsFilters.airport) : contractsFiltered
  );

  const getContractsFiltered = (contracts: ContractsObject[], contractsFilters: ContractFilters) => {
    const areAllStatusFiltersTurnedOff = !contractsFilters.past && !contractsFilters.inProgress && !contractsFilters.future;

    let contractsFiltered: ContractsObject[] = contracts;

    contractsFiltered = areAllStatusFiltersTurnedOff
      ? contracts
      : [
        ...contracts.filter((contract: ContractsObject) => filterContractsByStatus(contract, contractsFilters.past, ContractStatus.PAST)),
        ...contracts.filter((contract: ContractsObject) => filterContractsByStatus(contract, contractsFilters.inProgress, ContractStatus.IN_PROGRESS)),
        ...contracts.filter((contract: ContractsObject) => filterContractsByStatus(contract, contractsFilters.future, ContractStatus.FUTURE)),
      ];
    contractsFiltered = getContractsFilteredByAirport(contractsFiltered, contractsFilters);
    contractsFiltered = getContractsFilteredByCustomer(contractsFiltered, contractsFilters);
    contractsFiltered = getContractsFilteredByProvider(contractsFiltered, contractsFilters);
    contractsFiltered = getContractsFilteredByAircraftModel(contractsFiltered, contractsFilters);

    return contractsFiltered;
  };

  return {
    getContractsFiltered,
  };
}
