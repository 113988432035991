import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { EDIT_CONTRACT_TITLE } from 'src/constants/componentTexts/ContractText';
import { Status } from 'src/constants/status';

import ContractByStepManager from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepManager';
import Loader from 'src/uiComponents/loader/Loader';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectContract, selectGetContractStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  setIsNewContractOpen: Function;
}

export default function ContractAndServicesEditorByStep(props: Props) {
  const { setIsNewContractOpen } = props;

  const contract: any = useSelector(selectContract);
  const getContractStatus: string = useSelector(selectGetContractStatus);

  useEffect(() => {}, [getContractStatus !== Status.LOADING]);

  return (
    shouldLoaderBeDisplayed([getContractStatus]) ? (
      <Loader className="NewContractAppContentOperationTypesLoader" />
    ) : (
      <div className="DefaultBackGroundColor NewContractAppContent">
        <div className="NewContractAppContentTitle TitleFontSize">
          <p id="NewContractAppTitle">{EDIT_CONTRACT_TITLE}</p>
        </div>
        <ContractByStepManager setIsContractByStepManagerOpen={setIsNewContractOpen} filledContract={contract} />
      </div>
    )
  );
}
