import React from 'react';

import ListInput from 'src/uiComponents/input/ListInput';

interface Props {
  airports: any;
  filters: any;
  setFilters: Function;
}

export default function AirportFilter(props: Props) {
  const { airports, filters, setFilters } = props;

  return (
    <div className="InvoicesAirportFilter">
      <ListInput
        inputLabel="Airport"
        isRequired
        optionList={airports}
        formObjectKey="airport"
        setFormObject={setFilters}
        formObject={filters}
        optionInputValueField="code"
      />
    </div>
  );
}
