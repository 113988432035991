import React from 'react';

import { NEW_CONTRACT_FORM_EDIT_ADDITIONAL_SERVICE_TITLE } from 'src/constants/componentTexts/AdditionalServicesText';

import AdditionalServiceForm from 'src/invoicing/services/additionalServices/form/AdditionalServiceForm';
import Loader from 'src/uiComponents/loader/Loader';

import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectPatchAdditionalServiceStatus } from 'src/invoicing/services/additionalServices/AdditionalServicesSlice';
import useAdditionalServiceParameters from 'src/invoicing/services/additionalServices/useAdditionalServiceParameters';
import useAdditionalServiceSubmittionRequestStatus from 'src/invoicing/services/additionalServices/useAdditionalServiceSubmittionRequestStatus';
import useGettingAdditionalServiceSelected from 'src/invoicing/services/additionalServices/useGettingAdditionalServiceSelected';

interface Props {
  contractId: number | null;
  setIsAdditionalSubViewOpen: Function;
}

export default function EditAdditionalService(props: Props) {
  const { contractId, setIsAdditionalSubViewOpen } = props;

  const {
    additionalServiceParameters,
    getAdditionalServiceParametersStatus,
  } = useAdditionalServiceParameters(contractId);
  const { additionalService, getAdditionalServiceStatus } = useGettingAdditionalServiceSelected();
  useAdditionalServiceSubmittionRequestStatus(selectPatchAdditionalServiceStatus, setIsAdditionalSubViewOpen);

  return (
    shouldLoaderBeDisplayed([getAdditionalServiceStatus, getAdditionalServiceParametersStatus]) ? (
      <Loader className="NewContractAppContentAdditionalServicesLoader" />
    ) : (
      <div className="ServicesSubView">
        <div className="ServicesSubViewTitle">
          <p id="EditAdditionalServiceTitleId">{NEW_CONTRACT_FORM_EDIT_ADDITIONAL_SERVICE_TITLE}</p>
        </div>
        <AdditionalServiceForm
          additionalServiceFilled={additionalService}
          contractId={contractId}
          setIsAdditionalSubViewOpen={setIsAdditionalSubViewOpen}
          additionalServiceParameters={additionalServiceParameters}
        />
      </div>
    )
  );
}
