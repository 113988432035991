const hhmmTimeRegex: RegExp = /[0-9]{2}:[0-5][0-9]/;
const positiveAndDecimalNumberRegex: RegExp = /^(^[0-9]\d*(\.\d{0,2})?$)$/;
const regexFindUnderscore: RegExp = /_/g;
const regexFindSpaceAfterThreeDigits: RegExp = /\B(?=(\d{3})+(?!\d))/g;
const twoMaxDecimalNumbersRegex: RegExp = /^-?[0-9]\d*(\.\d{0,2})?$/;

export const addSpacesToNumber = (num: string) => (
  num.replace(regexFindSpaceAfterThreeDigits, ' ')
);

export const isNumberValid = (value: string) => (
  positiveAndDecimalNumberRegex.test(value)
);

export const isTimeFormatHHMM = (time: string): boolean => (
  hhmmTimeRegex.test(time)
);

export const replaceUnderscoreToSpace = (value: string) => (
  value.replace(regexFindUnderscore, ' ')
);

export const isNumberHasMaxTwoDecimals = (value: string) => (
  twoMaxDecimalNumbersRegex.test(value)
);
