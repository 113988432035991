import { useDispatch } from 'react-redux';

import { ActionStatus } from 'src/constants/status';

import { apiDeleteDelayRule, apiGetDelayRule } from 'src/invoicing/services/delayRules/DelayRulesApiRequest';
import { DelayRuleReceivedInterface } from 'src/invoicing/services/delayRules/DelayRulesInterface';

interface Props {
  delayRule: DelayRuleReceivedInterface;
  setDelayRuleAction: Function;
  setIsDelaySubViewOpen: Function;
}

export default function useDelayRule(props: Props) {
  const { delayRule, setDelayRuleAction, setIsDelaySubViewOpen } = props;

  const dispatch = useDispatch();

  const deleteDelayRule = () => {
    dispatch(apiDeleteDelayRule(delayRule.id));
  };

  const editDelayRule = () => {
    dispatch(apiGetDelayRule(delayRule.id));
    setIsDelaySubViewOpen(true);
    setDelayRuleAction(ActionStatus.EDIT);
  };

  const viewDelayRule = () => {
    dispatch(apiGetDelayRule(delayRule.id));
    setIsDelaySubViewOpen(true);
    setDelayRuleAction(ActionStatus.VIEW);
  };

  return {
    deleteDelayRule,
    editDelayRule,
    viewDelayRule,
  };
}
