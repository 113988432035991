import React from 'react';

import ListInput from 'src/uiComponents/input/ListInput';

interface Props {
  filters: any;
  providers: any;
  setFilters: Function;
}

export default function ProviderFilter(props: Props) {
  const { filters, providers, setFilters } = props;

  return (
    <div className="InvoicesProviderFilter">
      <ListInput
        inputLabel="Provider"
        isRequired
        optionList={providers}
        formObjectKey="provider"
        setFormObject={setFilters}
        formObject={filters}
      />
    </div>
  );
}
