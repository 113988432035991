import React from 'react';

import { cancellationRuleFormText } from 'src/constants/componentTexts/CancellationRulesText';

import ListInput from 'src/uiComponents/input/ListInput';

import { CancelaltionRuleFilledInterface, CancellationRuleSentInterface } from 'src/invoicing/services/cancellationRules/CancellationRulesInterface';

interface Props {
  cancellationRule: CancellationRuleSentInterface
  cancellationRuleFilled?: CancelaltionRuleFilledInterface | null;
  cancellationRuleParameters: any;
  readOnly?: boolean;
  setCancellationRule: Function;
}

export default function CancellationRuleFormCaracteristic(props: Props) {
  const {
    cancellationRule,
    cancellationRuleFilled,
    cancellationRuleParameters,
    readOnly,
    setCancellationRule,
  } = props;

  return (
    <div className="ServicesBox">
      <div className="ServicesBoxStepContent">
        <p className="ServicesBoxStepContentNumber">
          {cancellationRuleFormText.parameterNumber}
        </p>
      </div>
      <div className="ServicesBoxInput">
        <ListInput
          formObjectPreviousFilledValue={cancellationRuleFilled?.cause_of_cancellation?.label || ''}
          inputLabel={cancellationRuleFormText.causeOfCancellation}
          isRequired
          optionList={cancellationRuleParameters.causes_of_cancellation}
          formObjectKey="cause_of_cancellation"
          setFormObject={setCancellationRule}
          formObject={cancellationRule}
          isReadOnly={readOnly}
        />
      </div>
    </div>
  );
}
