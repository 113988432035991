import React from 'react';

import { basePriceFormText } from 'src/constants/componentTexts/BasePriceText';

import ContractByStepNavigationButtons from 'src/invoicing/contractAndServicesByStep/contractAndServicesByStepManager/ContractByStepNavigationButtons';
import CheckboxPriceTypeInput from 'src/uiComponents/input/CheckboxPriceTypeInput';
import ErrorModal from 'src/uiComponents/modal/ErrorModal';
import NumberInput from 'src/uiComponents/input/NumberInput';

import useBasePrice from 'src/invoicing/contract/basePrice/form/useBasePrice';
import { isFieldReadOnlyWhenContractInProgressOrViewContractAction } from 'src/invoicing/services/ServicesLogic';

import { StoredContract } from 'src/invoicing/contract/ContractInterface';

interface Props {
  contractAction: string;
  contractByStepsFilled: boolean[];
  currentContractByStepType: string;
  nextComponent: string | null;
  previousComponent: string | null;
  setCurrentContractByStepType: Function;
  setcontractByStepsFilled: Function;
  setIsContractByStepManagerOpen: Function;
  setStoredContract: Function;
  storedContract: StoredContract;
}

export default function BasePriceForm(props: Props) {
  const {
    contractAction,
    currentContractByStepType,
    nextComponent,
    previousComponent,
    setCurrentContractByStepType,
    setIsContractByStepManagerOpen,
    storedContract,
  } = props;

  const {
    basePrice,
    contractCurrency,
    contractStatus,
    getContractSubmittedErrorMessage,
    isErrorModalOpen,
    isFixedPriceChecked,
    setBasePrice,
    setIsErrorModalOpen,
    setIsFixedPriceChecked,
    submitContractAndBasePrice,
  } = useBasePrice(props);

  return (
    <>
      <form className="BasePriceServicesForm" onSubmit={async (event) => { await submitContractAndBasePrice(event); }}>
        <div className="ServicesBox">
          <div className="ServicesBoxStepContent">
            <p className="ServicesSubViewBoxTitle" id="NewBasePricePriceTitleId">
              {basePriceFormText.basePriceTitle}
            </p>
          </div>
          <div className="ServicesBoxCheckbox">
            <p className="InputWrapperLabel FormTextFontSize" id="CheckboxLabelId">
              {basePriceFormText.typeOfPriceCheckboxLabel}
            </p>
            <div className="ServicesBoxCheckboxContent">
              <CheckboxPriceTypeInput
                checkboxKey="fixed_price"
                checkboxName={basePriceFormText.fixedPrice}
                checkboxFixedPriceSetter={setIsFixedPriceChecked}
                checkboxVariablePriceSetter={() => {}}
                checkboxValue={isFixedPriceChecked}
                isRequired
                isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
              />
            </div>
          </div>
        </div>
        {isFixedPriceChecked && (
          <div className="ServicesBox">
            <div className="ServicesBoxStepContent">
              <p className="ServicesSubViewBoxTitle" id="NewBasePricePriceFixedPriceTitleId">
                {basePriceFormText.fixedPrice}
              </p>
            </div>
            <div className="ServicesBoxPriceContent">
              <div className="ServicesBoxInpuBasePriceFixedPrice">
                <p
                  className="FormTextFontSize ServicesBoxInputCurrencyText"
                  id="FixedPriceCompleteTurnaroundCurrencyTextId"
                >
                  {contractCurrency.symbol}
                </p>
                <NumberInput
                  formObjectPreviousFilledValue={storedContract.base_price?.amount_for_complete_turnaround || ''}
                  inputLabel={basePriceFormText.completeTurnaroundLabel}
                  isRequired
                  formObjectKey="amount_for_complete_turnaround"
                  setFormObject={setBasePrice}
                  formObject={basePrice}
                  isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
                />
              </div>
              <div className="ServicesBoxInpuBasePriceFixedPrice">
                <p
                  className="FormTextFontSize ServicesBoxInputCurrencyText"
                  id="FixedPriceCurrencyArrivalTurnaroundTextId"
                >
                  {contractCurrency.symbol}
                </p>
                <NumberInput
                  formObjectPreviousFilledValue={storedContract.base_price?.amount_for_arrival_only_turnaround || ''}
                  inputLabel={basePriceFormText.arrivalTurnaround}
                  isRequired
                  formObjectKey="amount_for_arrival_only_turnaround"
                  setFormObject={setBasePrice}
                  formObject={basePrice}
                  isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
                />
              </div>
              <div className="ServicesBoxInpuBasePriceFixedPrice">
                <p
                  className="FormTextFontSize ServicesBoxInputCurrencyText"
                  id="FixedPriceCurrencyDepartureTurnaroundTextId"
                >
                  {contractCurrency.symbol}
                </p>
                <NumberInput
                  formObjectPreviousFilledValue={storedContract.base_price?.amount_for_departure_only_turnaround || ''}
                  inputLabel={basePriceFormText.departureTurnaround}
                  isRequired
                  formObjectKey="amount_for_departure_only_turnaround"
                  setFormObject={setBasePrice}
                  formObject={basePrice}
                  isReadOnly={isFieldReadOnlyWhenContractInProgressOrViewContractAction(contractStatus, contractAction)}
                />
              </div>
            </div>
          </div>
        )}
        <ContractByStepNavigationButtons
          contractAction={contractAction}
          currentContractByStepType={currentContractByStepType}
          nextComponent={nextComponent}
          previousComponent={previousComponent}
          setCurrentContractByStepType={setCurrentContractByStepType}
          setIsContractByStepManagerOpen={setIsContractByStepManagerOpen}
        />
      </form>
      {isErrorModalOpen && (
        <ErrorModal
          setIsErrorModalOpen={setIsErrorModalOpen}
          errorMessage={getContractSubmittedErrorMessage}
        />
      )}
    </>
  );
}
