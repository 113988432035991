import { ChangeEvent, FormEvent, useState } from 'react';
import { EMPTY_VALUE } from 'src/constants/componentTexts/InvoiceDetailText';

import { InvoicesFiltersModel } from 'src/invoicing/invoices/InvoicesInterface';
import { isAmountValidWithMax2Decimals } from 'src/invoicing/invoices/InvoicesLogic';

interface Props {
  invoicesFilters: InvoicesFiltersModel;
  setInvoicesFilters: Function;
}

export default function useAmountFilter(props: Props) {
  const { setInvoicesFilters, invoicesFilters } = props;

  const [amountSelected, setAmountSelected] = useState('');
  const [selectedRadioButtonId, setSelectedRadioButtonId] = useState<string | null>(null);

  const onRadioButtonChange = (event: FormEvent<HTMLFieldSetElement>) => {
    const radioButton = event.target as HTMLInputElement;
    setInvoicesFilters({ ...invoicesFilters, amount: { ...invoicesFilters.amount, AmountFilteInputId: radioButton.id } });
  };

  const getAmountSelectedValue = (event: ChangeEvent<HTMLInputElement>) => (
    event.target.value !== EMPTY_VALUE ? isAmountValidWithMax2Decimals(event.target.value) : null
  );

  const onSelectedAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAmountSelected(event.target.value);
    setInvoicesFilters({
      ...invoicesFilters,
      amount: {
        ...invoicesFilters.amount,
        amountSelected: getAmountSelectedValue(event),
      },
    });
  };

  return {
    amountSelected,
    onRadioButtonChange,
    onSelectedAmountChange,
    selectedRadioButtonId,
    setSelectedRadioButtonId,
  };
}
