import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AircraftFilter from 'src/uiComponents/filters/AircraftFilter';
import AirportFilter from 'src/uiComponents/filters/AirportFilter';
import CustomerFilter from 'src/uiComponents/filters/CustomerFilter';
import Loader from 'src/uiComponents/loader/Loader';
import ProviderFilter from 'src/uiComponents/filters/ProviderFilter';

import { apiGetContractFilters } from 'src/invoicing/contract/ContractApiRequest';
import { ContractFilters } from 'src/invoicing/contract/ContractInterface';
import { getAllUserAirports } from 'src/invoicing/contract/ContractLogic';
import { shouldLoaderBeDisplayed } from 'src/invoicing/InvoicingLogic';
import { selectContractFiltersParameters, selectContractFiltersParametersStatus } from 'src/invoicing/contract/ContractSlice';

interface Props {
  contractsFilters: ContractFilters;
  setContractsFilters: Function;
}

export default function ContactsFilters(props: Props) {
  const { contractsFilters, setContractsFilters } = props;

  const dispatch = useDispatch();

  const getContractsFilters = useSelector(selectContractFiltersParameters);
  const getContractsFiltersStatus: string = useSelector(selectContractFiltersParametersStatus);

  useEffect(() => { dispatch(apiGetContractFilters()); }, []);

  return (
    <div className="ContractAppActionsFilters">
      {shouldLoaderBeDisplayed([getContractsFiltersStatus]) ? (
        <Loader className="NewContractAppContentCancellationRulesLoader" />
      ) : (
        <>
          <AirportFilter
            filters={contractsFilters}
            setFilters={setContractsFilters}
            airports={getAllUserAirports(getContractsFilters)}
          />
          <CustomerFilter
            filters={contractsFilters}
            setFilters={setContractsFilters}
            customers={getContractsFilters.companies}
          />
          <ProviderFilter
            filters={contractsFilters}
            setFilters={setContractsFilters}
            providers={getContractsFilters.companies}
          />
          <AircraftFilter
            filters={contractsFilters}
            setFilters={setContractsFilters}
            aircrafts={getContractsFilters.aircraft_models}
          />
        </>
      )}
    </div>
  );
}
